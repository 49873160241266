import { useRef } from 'react';
import { FiSearch } from 'react-icons/fi';

interface SearchProps {
  placeholder?: string;
  onChange?: (event: any) => void;
  id?: string;
  extraClassName?: string;
  inputClass?: string;
}

const SearchBox = (props: SearchProps) => {
  const { placeholder, onChange, id, extraClassName, inputClass } = props;
  const inputRef = useRef(null);

  return (
    <div
      className={`m-2 flex items-center gap-2 rounded-md border px-3 py-1.5 outline outline-0 focus-within:outline-1 focus-within:outline-brand-400 ${extraClassName}`}
      onClick={() => inputRef.current.focus()}
    >
      <FiSearch className="size-4 cursor-pointer" />
      <input
        ref={inputRef}
        type="text"
        autoComplete="off"
        placeholder={placeholder}
        onKeyUp={onChange}
        id={id || 'campaign-search-input'}
        className={`w-full text-sm font-bold text-gray-800 caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:bg-navy-700 dark:placeholder:text-white ${inputClass}`}
      />
    </div>
  );
};

export default SearchBox;
