import { handleEmailVerifierResultDownload } from "shared/email-verifier/event-handler/export-options";
import { fetchDataList, fetchExportData, uploadList } from "../api-handler/admin-verifier-api";
import { toast } from "react-toastify";
import { toastState } from "utility/utils";


export const fetchAdminVerifierList = async (smartStateUpdate: any) => {
    try {
        const response = await fetchDataList()
        if (response?.data) {
            smartStateUpdate({ data: response?.data });
        }
    } catch (error) {
        console.log(error);
    } finally {
    }
};

export const onCloseClickEvent = (smartStateUpdate: any) => {
    smartStateUpdate({ isOpen: false });
};

export const getAdminVerificationResultClickEvent = async (uuid: string, smartStateUpdate: any) => {
    try {
        smartStateUpdate({ loading: true });
        const response = await fetchExportData(uuid)
        if (response.data) {
            handleEmailVerifierResultDownload(response.data, `comparison-result-${new Date().getTime()}`);
        }
    } catch (error) {
        console.log(error);
    } finally {
        smartStateUpdate({ loading: false });
    }
};

export const handleEmailUploadClickEvent = async (data: any, smartStateUpdate: any) => {
    try {
        if (!data?.file_data?.[0]?.email) {
            toast.error("Please upload file with column name email")
            return
        }
        const response = await uploadList({ email_list: data.file_data, list_name: `test-${new Date().getTime()}` });
        if (response.data) {
            onCloseClickEvent(smartStateUpdate);
        }
    } catch (error) {
        console.log(error);
        toast.error('Something went wrong', toastState.error);
        onCloseClickEvent(smartStateUpdate);
    } finally {
        onCloseClickEvent(smartStateUpdate);
    }
};