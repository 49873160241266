import FroalaEditor from 'froala-editor';
// import ReactDOMServer from 'react-dom/server';
// import { TbBraces } from 'react-icons/tb';

interface Option {
  variable: string;
  name: string;
}

const registerMyDropdownCommand = (dynamicOptions: Option[], missedVariables: any[], editor: any, variable_area: string, updateSubject: (variable: string) => void) => {
  // const iconSVG = ReactDOMServer.renderToStaticMarkup(<TbBraces />);

  FroalaEditor.DefineIconTemplate(
    'customIconTemplate',
    '<svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M7 4a2 2 0 0 0 -2 2v3a2 3 0 0 1 -2 3a2 3 0 0 1 2 3v3a2 2 0 0 0 2 2"></path><path d="M17 4a2 2 0 0 1 2 2v3a2 3 0 0 0 2 3a2 3 0 0 0 -2 3v3a2 2 0 0 1 -2 2"></path></svg>'
  );

  FroalaEditor.DefineIcon('variable_dropdown', {
    NAME: 'variable_dropdown',
    template: 'customIconTemplate',
  });

  FroalaEditor.RegisterCommand('variableButton', {
    title: 'Variable',
    type: 'dropdown',
    focus: false,
    undo: false,
    icon: 'variable_dropdown',
    refreshAfterCallback: true,
    options: dynamicOptions.reduce((optionsObj: { [key: string]: string }, option) => {
      optionsObj[option.name] = option.variable;
      return optionsObj;
    }, {}),

    callback: function (cmd: string, val: string) {
      if (variable_area === 'subject') {
        const variable: Option = dynamicOptions?.find((item) => item?.name === val) || { variable: '', name: '' };
        updateSubject(variable?.variable + ' ');
      } else {
        const variable: Option = dynamicOptions?.find((item) => item?.name === val) || { variable: '', name: '' };
        const variableClass = missedVariables?.includes(variable?.variable) ? 'variable_missed' : 'variable_valid';
        editor?.html?.insert(`<span contenteditable='false' class='sparkle_variable ${variableClass} fr-deletable' id='unique_id'>${val}</span>&nbsp;`);
      }
    },
  });
};

export default registerMyDropdownCommand;
