import React from 'react';
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import { ModalComponentProp } from 'dto/types/views/brands';
import { useAppDispatch, useAppSelector } from 'redux-store';
import CreateBrand from './CreateBrand';
import { createModalHandleStateIV } from 'dto/initial-value/views/brands';
import { HandleChangeNewBrandModalDataEvent, HandleCloseNewBrandModalEvent, HandleCreateOrEditBrandDataEvent } from 'shared/brands/event-handler/brands-event';


function NewBrandModal(props: ModalComponentProp) {
  const { isOpen, onClose } = props;
  const custom = useAppSelector((state) => state?.custom);
  const dispatch = useAppDispatch();
  const [handleState, setHandleState] = React.useState(createModalHandleStateIV);

  const handleBrandDataChange = (state: any) => {
    HandleChangeNewBrandModalDataEvent(setHandleState, state);
  };

  const handleClose = () => {
    HandleCloseNewBrandModalEvent(setHandleState, onClose, dispatch);
  };

  React.useEffect(() => {
    const submitCreateBrandApi = async () => {
      await HandleCreateOrEditBrandDataEvent(setHandleState, handleState, custom?.flagStatus, handleClose, dispatch);
    };
    handleState?.brandData && submitCreateBrandApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleState?.brandData]);

  return (
    <div>
      <Modal isCentered isOpen={isOpen} onClose={handleClose} size="3xl">
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent overflow={'auto'} className="dark:bg-darkThree">
          <ModalBody className="w-full !p-0">
            <CreateBrand bodycustomClass={'h-[80vh] w-full '} onParentDataChange={handleBrandDataChange} onChangeloading={handleState?.loading} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default NewBrandModal;
