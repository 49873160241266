import React from 'react';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import Button from 'components/common/Button';
import TextField from 'components/forms/TextField';
import { BaseApi } from 'api/services/base-api';
import { toast } from 'react-toastify';

const FeedbackPopup = (props: any) => {
  const { isOpen, onClose, emailIds, fromEmail, table } = props;

  const [handleState, setHandleState] = React.useState({
    loading: false,
    feedback: '',
  });

  const handleFeedbackChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHandleState((prevState) => ({
      ...prevState,
      feedback: e.target.value,
    }));
  };

  const handleSubmitFeedback = async () => {
    try {
      setHandleState((prevState) => ({
        ...prevState,
        loading: true,
      }));
      const params = {
        fromEmail: fromEmail,
        emailIds: emailIds,
        feedback: handleState?.feedback,
      };
      const response = await BaseApi.post('/email/verify/feedback/single-emailverify', params);
      if (!response?.error) {
        toast.success('Feedback Sent, Thanks!');
        setHandleState((prevState) => ({
          ...prevState,
          loading: false,
          feedback: '',
        }));
        table.resetRowSelection();
        onClose();
      } else {
        setHandleState((prevState) => ({
          ...prevState,
          loading: false,
        }));
      }
    } catch (error) {
      setHandleState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  };
  return (
    <Modal isCentered isOpen={isOpen} onClose={() => {}} size="md">
      <ModalOverlay backdropFilter="blur(2px)" />
      <ModalContent overflow={'auto'} className="dark:bg-darkTwo">
        <ModalHeader className="mb-1 bg-[#FAFAFC] dark:bg-darkThree">
          <div className="flex items-center text-start  ">
            <span className="text-sm font-semibold text-navy-700">Feedback</span>
          </div>
        </ModalHeader>
        <ModalBody className="!p-3">
          <TextField
            extra="mb-4"
            inputClass="mt-2 dark:!bg-darkFive single_verifier_feedback_input resize-none !rounded-md"
            labelClass="!text-column dark:!text-white !font-[600] !ml-0 text-xs"
            label="How accurate was the result"
            placeholder="Enter your feedback..."
            id="feedback"
            cols={30}
            rows={6}
            onChange={handleFeedbackChange}
            value={handleState?.feedback}
            //   errorMessage={errorMessage?.geography_target}
            //   state={errorMessage?.geography_target ? 'error' : ''}
          />
        </ModalBody>
        <ModalFooter className="!-mt-2 !mb-1">
          <>
            <Button id="blacklist-close" name="Cancel" onClick={() => onClose()} customClass="!bg-[#F4F7FE] w-full !text-blackDark border border-2  !font-medium duration-300 w-40 mr-3 py-2 text-[0.80rem] active:scale-95 transition-transform duration-150 ease-out" />

            <Button
              id="add-user-invite-btn"
              disable={handleState?.loading}
              loading={handleState?.loading}
              name="Submit"
              onClick={handleSubmitFeedback}
              customClass={` w-full ${''} submit_feedback_button dark:border-none confirm_btn_otp_page !font-medium duration-300 w-[10.5rem] py-2.5 text-[0.80rem] active:scale-95 transition-transform duration-150 ease-out`}
            />
          </>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FeedbackPopup;
