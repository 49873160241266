import { Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, Spinner } from '@chakra-ui/react';
import InputField from 'components/forms/InputField';
import { PromoCodeIV } from 'dto/initial-value/views/promo-code';
import React from 'react';
import { BiSolidCheckShield } from 'react-icons/bi';
import { FaRegCircleCheck } from 'react-icons/fa6';
import { useAppDispatch } from 'redux-store';
import { HandleCloseModalEvent, HandleVerifyPromoCodeApi } from 'shared/promo-code/event-handler';

const PrmoCodeValidatorModal = (props: any) => {
  const { isOpen, onClose } = props;
  const inputRef = React.useRef(null);
  const [promoCode, setPromoCode] = React.useState(PromoCodeIV);

  const dispatch = useAppDispatch();

  const updatePromoCode = (val: any) => {
    setPromoCode((prev) => ({
      ...prev,
      ...val,
    }));
  };

  const handleVerifyPromoCode = async () => {
    await HandleVerifyPromoCodeApi(promoCode, inputRef, updatePromoCode,dispatch);
  };

  const handleClose = () => {
    HandleCloseModalEvent(setPromoCode, onClose);
  };

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      await HandleVerifyPromoCodeApi(promoCode, inputRef, updatePromoCode, dispatch);
    }
  };

  return (
    <Modal onClose={handleClose} isOpen={isOpen}>
      <ModalOverlay backdropFilter="auto" backdropBlur="3px" />
      <ModalContent top={'40'}>
        {/* <ModalCloseButton size={'sm'} className="text-paused-row" /> */}
        <ModalHeader className="!text-sm text-heading" paddingBottom={'0.4rem'}>
          Claim Rewards
        </ModalHeader>
        <ModalBody className="rounded-md">
          <div className="select-none pb-2">
            <h4 className="text-xs font-medium text-heading">Enter Promo Code</h4>
            <div className="my-3 flex w-full items-center rounded-md border pr-3">
              <InputField refs={inputRef} id='prmo_code_input' customClass="!mt-0 h-9" extra="w-full" inputClass="border-none" value={promoCode?.promo_code} onKeyDown={handleKeyDown} onChange={(event) => updatePromoCode({ promo_code: event?.target?.value, status: null })} />
              {!promoCode?.loading ? (
                promoCode?.status === 'valid' ? (
                  <div className={`flex w-fit items-center justify-center gap-0.5 whitespace-pre text-xs font-medium text-completed-color`}>
                    <BiSolidCheckShield size={15} />
                    Verified
                  </div>
                ) : (
                  <div id='verify_promo_code_button' className={`flex w-fit items-center justify-center gap-0.5 whitespace-pre text-xs font-medium ${promoCode?.promo_code?.length ? 'cursor-pointer text-blueSecondary' : 'text-gray-600'}`} onClick={handleVerifyPromoCode}>
                    <BiSolidCheckShield size={15} />
                    Verify Code
                  </div>
                )
              ) : (
                <Spinner color="#EAECF0" />
              )}
              {/* Verified */}
            </div>
            {promoCode?.status && (
              <div className="flex gap-1.5" >
                {promoCode?.status === 'valid' && <FaRegCircleCheck className={`text-completed-color`} size={15} />}
                {/* <FaRegCircleCheck className={`${promoCode?.status === 'valid' ? 'text-completed-color' : 'text-dangerred'} `} size={15} /> */}
                {/* <div className="text-xs">
              <div className="whitespace-pre font-medium text-completed-color">Success! 50 free verification credits added to your account.</div>
              <div className="text-success">Note: These credits are valid until [expiry date].</div>
            </div> */}
                <div id='promo_code_status' className={`text-xs font-medium ${promoCode?.status === 'valid' ? 'text-completed-color' : 'text-dangerred'}`}>
                  {
                    promoCode?.status === 'valid' ? (promoCode?.offerType === 'sparkle' ? `Success! ${promoCode?.sparkle} credits have been added to your balance.` : `Success! ${promoCode?.sparkle} free campaign credits per day added to your account.`) : ''
                    // : promoCode?.status === 'expired'
                    // ? 'This code has expired. Check our latest offers for active promotions.'
                    // : promoCode?.status === 'used'
                    // ? 'You have already used this promo code.'
                    // : 'Invalid promo code. Please try again or check our latest offers.'
                  }
                </div>
              </div>
            )}
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PrmoCodeValidatorModal;
