/* eslint-disable react-hooks/exhaustive-deps */
import CustomFilter from 'components/common/customFilter';
import Toggle from 'components/common/Toggle';
import React, { useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { MdAdd, MdViewWeek } from 'react-icons/md';
import { useAppDispatch, useAppSelector } from 'redux-store';
import { jwtEncrypt } from 'utility/utils';
import SelectEmailMethodPopup from './select-email-method';
import EmailAccountsList from './email-accounts-list';
import { senderAccountsFilterDataList } from 'lib/page-filters/sender-accounts';
import { setTableListData } from 'redux-store/common-table';
import { columnVisibilityIV } from 'dto/initial-value/views/sender-accounts';

const SenderAccountsList = () => {
    const dispatch = useAppDispatch();
    const tableFilterData = useAppSelector((state) => state?.commonTableList)
    const childRef = React.useRef(null);
    const [state, setState] = useState({ add_account_flag: false, column_visibility_flag: false })
    React.useEffect(() => {
        dispatch(setTableListData({ source: "sender_accounts", column_visibility: columnVisibilityIV, filter: '', search: '' }))
    }, [])

    const handleFilter = async (filteredData: any) => {
        const encryptedData = filteredData?.data?.length ? jwtEncrypt(filteredData) : '';
        dispatch(setTableListData({ filter: encryptedData }))
    };

    const handleTableSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setTableListData({ search: event.target.value.trim().toLowerCase() }))
    };

    return (
        <div className={`mt-2.5 w-full rounded-lg bg-white pb-2 pt-2.5 shadow-sm dark:bg-darkTwo dark:text-white sm:overflow-x-auto`}>
            <div className="relative mb-3 flex items-center py-0.5 sm:gap-32 md:px-10 lg:gap-32 lg:px-3 xl:justify-between">
                {/* Custom filter */}
                <div className="flex gap-2.5">
                    <div className="flex items-center gap-2 rounded-md border px-3 py-1.5 outline outline-0 focus-within:outline-2 focus-within:outline-brand-400 dark:border-darkBorder md:w-[13rem] lg:w-[26rem]">
                        <FiSearch className="size-4 cursor-pointer" />
                        <input
                            type="text"
                            placeholder="Search"
                            id="campaign-search-input"
                            className="w-full text-sm font-bold text-gray-800 caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:bg-darkTwo dark:placeholder:text-white "
                            onChange={handleTableSearch}
                        />
                    </div>
                    <CustomFilter optionData={senderAccountsFilterDataList} handleFilter={handleFilter} />
                </div>
                <div className="flex items-center gap-2">
                    <button onClick={() => setState((prev) => ({ ...prev, add_account_flag: !prev?.add_account_flag }))} className="sender_accounts_add_email_btn mx-1 flex items-center justify-center gap-0.5 rounded-md bg-brand-500 px-2 py-1.5 text-sm font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200" id='add-btn'>
                        <MdAdd className="float-right text-lg" /> <span className="text-nowrap text-[11px]">Add Accounts</span>
                    </button>
                    <div className={`relative cursor-pointer rounded-md bg-white outline outline-2 dark:bg-darkTwo dark:outline-darkBorder  ${state?.column_visibility_flag ? 'outline-menuBorder dark:outline-white' : 'outline-gray-300'}`}>
                        <button className="h-full w-full px-3 py-2" id="campaign-column-list-id" onClick={() => setState((prev) => ({ ...prev, column_visibility_flag: !prev?.column_visibility_flag }))}>
                            <MdViewWeek className="text-gray-700" />
                        </button>
                        {state?.column_visibility_flag && (
                            <div ref={childRef} className="absolute right-0 top-10 z-10 w-64 space-y-1 rounded-md border bg-white px-3 py-2 dark:border-darkBorder dark:bg-darkOne">
                                {tableFilterData?.column_visibility?.map((col: any, id: any) => (
                                    <div key={id} className="flex items-center justify-between">
                                        <h5 className="text-xs dark:text-white">{col._name}</h5>
                                        <Toggle
                                            checkedCondition={col?.[col.id]}
                                            changeFunction={(e: Event) => {
                                                // handleColumnData(col.id, col?.[col.id]);
                                            }}
                                            disabled={col?.disabled}
                                            className={`scale-50 col-list-${col?.name}`}
                                            id={`col-list-${col?.name}`}
                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <EmailAccountsList />
            <SelectEmailMethodPopup isOpen={state.add_account_flag} setOpen={setState} />
        </div>
    );
}

export default SenderAccountsList;
