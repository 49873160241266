import { Avatar, AvatarGroup } from '@chakra-ui/react'
import React from 'react'
import { BsDot } from 'react-icons/bs'

const Opportunities = () => {
  return (
    <div>
      <header className='border p-5 bg-white mt-[1px] relative z-10'>
        <h5 className='font-semibold text-[0.9rem] text-[#25324B]'>Opportunities</h5>
      </header>
      <main className='p-3 flex flex-wrap gap-3'>
       {[1,2,3,4,5].map((item, id)=>{ return <div key={id} className='rounded-md border w-[21rem] bg-white px-2.5 py-2 shadow-md flex flex-col gap-1 cursor-pointer'>
          <div className='flex gap-0.5 items-center'>
            <h5 className='text-[0.8rem] font-semibold text-[#2B3674]'>Burns Industries - ENT - New Deal</h5>
            <BsDot className='size-5 text-[#8F9BBA]' />
            <h5 className='text-[0.74rem] font-medium text-[#52586E]'>Apr 02, 2024</h5>
          </div>
          <div className='flex items-center gap-0.5'>
            <h5 className='text-[0.7rem] font-medium text-[#344054] bg-[#F2F4F7] px-2 py-0.5 rounded-xl'>Deal won</h5>
            <BsDot className='size-5 text-[#8F9BBA]' />
            <h5 className='text-[0.8rem] font-semibold text-[#52586E]'>$ 7000</h5>
            <BsDot className='size-5 text-[#8F9BBA]' />
            <AvatarGroup max={3} size={'xs'} spacing={'-8px'}>
              <Avatar name='Segun Adebayo' src='https://bit.ly/sage-adebayo' />
              <Avatar name='Kent Dodds' src='https://bit.ly/kent-c-dodds' />
              <Avatar name='Prosper Otemuyiwa' src='https://bit.ly/prosper-baba' />
            </AvatarGroup>
          </div>
          <div className='flex items-center'>
            <h5 className='text-[0.72rem] font-light text-[#52586E] px-1 py-0.5'>Last update - Apr 15, 2024  </h5>
            <BsDot className='size-5 text-[#8F9BBA]' />
            <h5 className='text-[0.7rem] font-semibold rounded-lg px-2 text-[#175CD3] bg-[#EFF8FF]'>Campaign 5</h5>           
          </div>
        </div>})}
      </main>
    </div>
  )
}

export default Opportunities