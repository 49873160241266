export const LOGIN = '/auth/login';
export const GOOGLE_USER_INFO = 'https://www.googleapis.com/oauth2/v3/userinfo';
export const GOOGLE_SIGNUP = '/auth/google/signup';
export const SIGNUP = '/auth/signup';
export const LOGIN_OTP_VERIFY = '/auth/verify/otp';
export const RESET_OTP = '/auth/resend/otp';
export const FORGOT_PASSWORD = '/auth/reset/password/request';
export const RESET_PASSWORD = '/auth/reset/password';
export const GENERATE_ACCESS_TOKEN = '/helper/generate/access/token';
export const PERSONAL_DETAILS = '/auth/user/personal/details';

export const USER_LIST = (userId: string) => `/auth/${userId}/list`;

export const buildUrl = (url: string, params: Record<string, string> = {}, query: Record<string, any> = {}) => {
  // common util function (*******)
  // const url = '/auth/{id}/list';
  // const params = {id:123};
  // const query = { limit: 2, offset: 5, search: 'test' };
  Object.keys(params).forEach((key) => {
    url = url.replace(`{${key}}`, params[key]);
  });

  const queryString = new URLSearchParams(query).toString();
  return queryString ? `${url}?${queryString}` : url;
};
