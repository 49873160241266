/* eslint-disable react-hooks/rules-of-hooks */

import { useFieldValidator, useFormValidator } from "hooks/useFormValidation";
import { blacklistValidationSchema } from "lib/validator-schema/blacklistSchema";
import { getErrorMessage } from "redux-store/custom";


export const HandleBlacklistFormChangeEvent = async ({ event, setHandleState,handleState, dispatch }: any) => {
    try {
      // const { id, value } = event.target;
      // dispatch(setBrandInput({ [id]: value }));
    //   setFormData((prev: any) => ({ ...prev, [event.target.id]: event.target.value }));
      setHandleState((prevState:any) => ({ ...prevState,  [event.target.id]: event.target.value }))
      const validate = await useFieldValidator(blacklistValidationSchema, event.target.id, event.target.value);
      console.log("validate",validate)
      dispatch(getErrorMessage(validate?.message));
    } catch (error) {
      console.error('HandleFormChangeEvent Error:', error);
    }
  };


  export const HandleBlacklistFormSubmitEvent = async ({handleState,dispatch}:any)=>{
    const validate = await useFormValidator(blacklistValidationSchema, handleState);
    if (validate?.error) {
      dispatch(getErrorMessage(validate?.message));
      return;
    }
}