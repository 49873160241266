import { toast } from 'react-toastify';
import { RemoveClearSelectData, setFetchGroupListData, setOffset, setTriggerCount } from 'redux-store/unified-inbox';
import { toastState } from 'utility/utils';
import { getInboxData, getInboxGroup, updateInboxData } from '../api-handler/inbox-api';

export const handleDeleteGroupFromSenderList = (groupId: string, setMessageData: any) => {
  setMessageData((prev: { data: [] }) => {
    const updatedData = prev?.data?.map((list: any) => {
      const finalGroupList = list?.groups_list?.filter((group: any) => group?.uuid !== groupId);
      return {
        ...list,
        groups_list: finalGroupList,
      };
    });
    return {
      ...prev,
      data: updatedData,
    };
  });
};

export const getGroupData = async (dispatch: any) => {
  try {
    const response = await getInboxGroup();
    if (response?.error) {
      toast.error(response?.message, toastState.error);
    } else {
      if (response?.data?.data && response?.data?.data?.length) {
        const finalData = await response?.data?.data?.sort((a: any, b: any) => (a?.name.toLowerCase() < b?.name.toLowerCase() ? -1 : a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : 0));
        const states = await response?.data?.data?.reduce((acc: any, val: any) => {
          acc[val.uuid] = { show: false, rename: false };
          return acc;
        }, {});
        const payload: any = {
          finalData,
          states,
        };
        dispatch(setFetchGroupListData(payload));
      }
    }
  } catch (err) {
    console.log(err);
  }
};

export const HandleClearConversationClickEvent = async ({ clearSelectData, dispatch, fetchDataTriggerEvent }: { clearSelectData: string[]; dispatch: any; fetchDataTriggerEvent: any }) => {
  try {
    if (clearSelectData.length) {
      const payload = {
        unified_inbox_status: 'cleared',
        uuid: clearSelectData,
      };
      const response = await updateInboxData(payload);
      if (response.status === 200) {
        toast.success(response.message, toastState.success);
        fetchDataTriggerEvent();
        dispatch(RemoveClearSelectData());
      }
    }
  } catch (error) {
    console.error('error', error);
  }
};

export const InboxInfinityScrollData = async ({ setIsFetching, setMessageData, query, inbox_status, dispatch }: { setIsFetching: any; setMessageData: any; query: any; inbox_status: any; dispatch: any }) => {
  try {
    setIsFetching(true);
    const data = {
      inbox_status,
      query: query,
    };
    const response = await getInboxData(data);
    const responseData: any = response.data;
    if (!responseData?.data) return setMessageData({ data: [], totalCount: 0 });
    if (responseData?.data?.length < 25) {
      setMessageData((prev: any) => ({ data: [...prev?.data, ...response?.data?.data], totalCount: prev?.data?.length }));
    } else {
      setMessageData((prev: any) => ({ ...prev, data: [...prev?.data, ...response?.data?.data] }));
    }
    // setQueryParams((prev) => ({
    //   ...prev,
    //   offset: prev?.offset + 25,
    // }));
    dispatch(setOffset(1));
  } catch (error) {
    console.log(error);
  } finally {
    setIsFetching(false);
  }
};

// Notification

export const handleNotificationMessageReadStatusClickevent = async ({ data, setReadMessages, dispatch, inboxData }: { data: any; setReadMessages: any; dispatch: any; inboxData: any }) => {
  try {
    dispatch(setTriggerCount((prev: boolean) => !prev));
    setReadMessages((prev: any) => [...prev, inboxData?.uuid]);
    if (data?.read_history?.is_viewed === false) {
      const payload = {
        uuid: data?.uuid,
        is_viewed: true,
        variant: 'notification',
        notification_id: data?.platform_message_details?.notification_id,
      };
      const response = await updateInboxData(payload);
      if (response.error === true) {
        toast.error(response.message, toastState.error);
      }
      // else {
      //   props.fetchTrigger();
      // }
    }
  } catch (error) {
    console.error(error);
  }
};
