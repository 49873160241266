import { handleStateDTO } from 'dto/types/views/personal-details';
import React from 'react';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'redux-store/custom';
import { cryptoDecrypt, toastState } from 'utility/utils';
import { useFormValidator } from 'hooks/useFormValidation';
import { personalDetailsValidationSchema } from 'lib/validator-schema/personalDetailsSchema';
import { LocalStorage } from 'services/local.storage.service';
import { CONSTANTS } from 'constants/constants';
import { GenerateAccessTokenApi, GetPersonalDetailsApi, UpdatePersonalDetailsApi } from '../api-handler';
import { LoggedUserDTO } from 'dto/types/redux-store/authentication';


export const HandleTimeZoneSearchChangeEvent = ({ event, setHandleState }: { event: React.ChangeEvent<HTMLInputElement>, setHandleState: React.Dispatch<React.SetStateAction<handleStateDTO>> }) => {
  setHandleState((prevState) => ({
    ...prevState,
    timeZoneSearch: event.target.value,
  }));
};

export const HandleImageUploadEvent = ({ data, setHandleState }: { data: any; setHandleState: React.Dispatch<React.SetStateAction<handleStateDTO>> }) => {
  setHandleState((prevState) => ({
    ...prevState,
    personalData: {
      ...prevState.personalData,
      picture: data?.file_Url,
    },
  }));
};

export const HandleChangeEvent = ({ event, setHandleState }: { event: React.ChangeEvent<HTMLInputElement>, setHandleState: React.Dispatch<React.SetStateAction<handleStateDTO>> }) => {
  const { id, value } = event.target;
  setHandleState((prevState) => ({
    ...prevState,
    personalData: {
      ...prevState.personalData,
      [id]: value,
    },
  }));
};

export const HandleUpdateProfilePictureEvent = async ({ image, dispatch, setLoggedUserDetails }: { image: string, dispatch: any, setLoggedUserDetails: React.Dispatch<React.SetStateAction<any>> }) => {
    try {
      const response = await GenerateAccessTokenApi({});

      if (!response.error) {
        LocalStorage.setString(CONSTANTS.LOGIN_STORAGE_VAR, response?.data?.data);
        const loginData = await cryptoDecrypt(response?.data?.data, true);
        dispatch(
          setLoggedUserDetails({
            uuid: loginData?.uuid,
            is_admin_account: loginData?.is_admin_account,
            first_name: loginData?.first_name,
            last_name: loginData?.last_name,
            email: loginData?.email,
            last_switch_organization_id: loginData?.last_switch_organization_id,
            last_switch_workspace_id: loginData?.last_switch_workspace_id,
            authToken: loginData?.authToken,
            picture: image,
          })
        );
      }
    } catch (error) {
      console.error('HandleUpdateProfilePictureEvent error: ', error);
    }
};

export const HandleUpdateUserPersonalDetailsEvent = async ({ handleState, dispatch, setHandleState, setLoggedUserDetails }: { handleState: handleStateDTO, dispatch: any, setHandleState: React.Dispatch<React.SetStateAction<handleStateDTO>>, setLoggedUserDetails: React.Dispatch<React.SetStateAction<LoggedUserDTO>> }) => {
    try {
      const validate = await useFormValidator(personalDetailsValidationSchema, handleState.personalData);
      if (validate?.error) {
        dispatch(getErrorMessage(validate?.message));
        return;
      }
      setHandleState((prevState) => ({ ...prevState, loading: true }));
      const updateResponse = await UpdatePersonalDetailsApi({
        ...(handleState?.personalData?.first_name && { first_name: handleState?.personalData?.first_name }),
        ...(handleState?.personalData?.last_name && { last_name: handleState?.personalData?.last_name }),
        ...(handleState?.personalData?.email && { email: handleState?.personalData?.email }),
        ...(handleState?.personalData?.mobile && { mobile: handleState?.personalData?.mobile }),
        ...(handleState?.personalData?.picture && { picture: handleState?.personalData?.picture }),
        ...(handleState?.personalData?.role && { role: handleState?.personalData?.role }),
        ...(handleState?.personalData?.country && { country: handleState?.personalData?.country }),
        ...(handleState?.personalData?.time_zone && { time_zone: handleState?.personalData?.time_zone }),
      });

      if (!updateResponse?.error) {
        dispatch(getErrorMessage({}));
        toast.success('Profile Updated Successfully', toastState.success);
        HandleUpdateProfilePictureEvent({ image: handleState?.personalData?.picture, dispatch, setLoggedUserDetails });
        setHandleState((prevState) => ({ ...prevState, loading: false }));
      } else {
        dispatch(getErrorMessage(updateResponse?.message));
        setHandleState((prevState) => ({ ...prevState, loading: false }));
      }
    } catch (error) {
      console.log('HandleUpdateUserPersonalDetailsEvent error: ', error);
    }
};

export const FetchUserPersonalDetailsEvent = async ({ setHandleState }: { setHandleState: React.Dispatch<React.SetStateAction<handleStateDTO>> }) => {
    try {
      setHandleState((prevState) => ({ ...prevState, loading: true }));
      const userResponse = await GetPersonalDetailsApi();
      setHandleState((prevState) => ({
        ...prevState,
        personalData: {
          ...prevState.personalData,
          first_name: userResponse?.data?.first_name,
          last_name: userResponse?.data?.last_name,
          email: userResponse?.data?.email,
          mobile: userResponse?.data?.mobile,
          picture: userResponse?.data?.picture,
          role: userResponse?.data?.role,
          country: userResponse?.data?.country,
          time_zone: userResponse?.data?.time_zone,
        },
      }));

      setHandleState((prevState) => ({ ...prevState, personalData: userResponse?.data }));
      setHandleState((prevState) => ({...prevState, loading: false }));
    } catch (error) {
      console.log('FetchUserPersonalDetailsEvent error: ', error);
    }
};