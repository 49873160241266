import * as Yup from 'yup';

interface FormDataDTO {
  [key: string]: any;
}

export const useFormValidator = async (schema: Yup.ObjectSchema<FormDataDTO>, data: Record<string, any>) => {
  try {
    await schema.validate(data, { abortEarly: false });
    return { error: false, message: {} };
  } catch (err: any) {
    if (err instanceof Yup.ValidationError) {
      const errors = err.inner.reduce((acc: any, curr: any) => {
        acc[curr.path] = curr.message;
        return acc;
      }, {});
      return { error: true, message: errors };
    }
    return { error: true, message: 'Validation failed unexpectedly' };
  }
};

export const useFieldValidator = async (schema: Yup.ObjectSchema<FormDataDTO>, fieldName: string, value: any) => {
  try {
    await schema.validateAt(fieldName, { [fieldName]: value });
    return { error: false, message: { [fieldName]: '' } };
  } catch (err: any) {
    return { error: true, message: { [fieldName]: err.message } };
  }
};
