import { NOTIFICATION_KEY, NOTIFICATION_SETTINGS_TYPE, NOTIFICATION_SUBSCRIBE } from 'api/endpoints/notification';
import { BaseApi } from 'api/services/base-api';

export const GetNotificationKeyData = async (key: string) => {
  try {
    const getUrl = NOTIFICATION_KEY(key);
    const response = await BaseApi.get(getUrl);
    return response;
  } catch (error) {
    console.error('NotificationGetOneKeyAPI Error:', error);
    throw error;
  }
};

export const SubscribeNotification = async (pushSubscription: any) => {
  try {
    const getUrl = NOTIFICATION_SUBSCRIBE;
    const response = await BaseApi.post(getUrl, { pushSubscription });
    return response;
  } catch (error) {
    console.error('NotificationSubscribeApi Error:', error);
    throw error;
  }
};

export const SetNotificationSettingsType = async (notificationSettingsType: string, settings: any) => {
  try {
    const getUrl = NOTIFICATION_SETTINGS_TYPE(notificationSettingsType);
    const response = await BaseApi.patch(getUrl, {
      setting_data: settings,
    });
    return response;
  } catch (error) {
    console.error('SetNotificationSettingsTypeAPI Error:', error);
    throw error;
  }
};
