import React from 'react';
import { Navigate, RouterProvider, createBrowserRouter, redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import store, { useAppSelector } from 'redux-store';
import ErrorBoundary from 'layouts/ErrorBoundary';
import Loader from 'layouts/Loader';
import SenderAccountsList from 'views/sender-accounts/pages';
import AddEmailAccounts from 'views/sender-accounts/pages/add-email-account';
import TrueVerifierTable from 'views/true-verifier/pages';
import PersonalDetails from 'views/personal-details/pages';
import BrandList from 'views/brands/pages';
import Blacklist from 'views/blacklist/pages';
import Team from 'views/team/pages';
import Notifications from 'views/notification/pages';
import AllInboxData from 'views/Inbox/pages';
import UnifiedEmailInboxData from 'views/Inbox/component/inboxVariant/UnifiedEmailInboxData';
import UnifiedNotificationInboxData from 'views/Inbox/component/inboxVariant/UnifiedNotificationInboxData';
import Rewards from 'views/rewards/pages';
import Security from 'views/security/pages';
import Billing from 'views/billing/pages';
import SingleEmailVerifier from 'views/single-email-verifier/pages';
import { LocalStorage } from 'services/local.storage.service';
import { LOGIN_STORAGE_VAR } from 'constants/constants';
import UnmatchedEmails from 'views/unmatched-emails/pages';
import CampaignListv2 from 'views/campaigns-v2';
import CRM from 'views/crm';
import Inbox from 'views/crm/features/inbox';
import OnboardSelectBrand from 'views/crm-onboarding';
import OnboardCloseStage from 'views/crm-onboarding/features/OnboardCloseStage';
import OnboardConnectCalendar from 'views/crm-onboarding/features/OnboardConnectCalendar';
import OnboardConnectSalesAccount from 'views/crm-onboarding/features/OnboardConnectSalesAccount';
import OnboardCustomizePipeline from 'views/crm-onboarding/features/OnboardCustomizePipeline';
import OnboardDefineStages from 'views/crm-onboarding/features/OnboardDefineStages';
import OnboardUploadProspects from 'views/crm-onboarding/features/OnboardUploadProspects';
import DonotEmailList from 'views/do-not-email';
import AddDoNotEmailList from 'views/do-not-email/AddDonotEmails';
import CreditAddons from 'views/admin/pages/CreditAddons';
import CouponSettingsList from 'views/admin/pages/CouponSettingsList';
import AdminVerificationList from 'views/admin-email-comparison/pages';

const SignIn = React.lazy(() => import('views/auth/pages/signin'));
const SignUp = React.lazy(() => import('views/auth/pages/signup'));
const ForgotPassword = React.lazy(() => import('views/auth/pages/forgot-password'));
const ResetPassword = React.lazy(() => import('views/auth/pages/reset-password'));
const ApiKeyGeneratingComponent = React.lazy(() => import('views/api-key/pages'));

const CampaignCreatePage = React.lazy(() => import('views/campaigns-v2/features/campaign-creation'));

const EmailVerificationList = React.lazy(() => import('views/email-verifier/pages'));
const EmailVerificationFlow = React.lazy(() => import('views/email-verifier/pages/EmailVerificationFlow'));
const EmailVerificationResult = React.lazy(() => import('views/email-verifier/pages/EmailVerificationResult'));

const HomeLayout = React.lazy(() => import('layouts/HomeLayout'));

const AppBrowserRoutes = (): JSX.Element => {
  const { environment } = useAppSelector((state) => state.globalsettings);

  const isAuthenticated = () => {
    return LocalStorage.getString(LOGIN_STORAGE_VAR);
  };

  const authLoader = async () => {
    if (isAuthenticated()) {
      return redirect(environment?.app_default_feature?.base_url);
    }
    return null;
  };

  const appRouter = createBrowserRouter([
    {
      path: '/',
      element: <HomeLayout />,
      children: [
        {
          path: '',
          element: <Navigate to={environment?.app_default_feature?.base_url} />,
        },
        {
          path: '/campaigns',
          children: [
            {
              path: '',
              element: <CampaignListv2 />,
            },
            {
              path: '/campaigns/create',
              element: <CampaignCreatePage />,
            },
            // {
            //   path: '/campaigns/insights/:uuid',
            //   element: <CampaignInsights />,
            // }, -- Change Campaign Insights
          ],
        },
        {
          path: '/blacklist-monitor',
          element: <Blacklist />,
        },
        {
          path: '/crm',
          children: [
            {
              path: '',
              element: <CRM />,
            },
            {
              path: 'inbox',
              element: <Inbox />,
            },
          ],
        },
        {
          path: '/unmatched-emails',
          children: [
            {
              path: '',
              element: <UnmatchedEmails />,
            }
          ],
        },
        {
          path: '/inbox',
          children: [
            {
              path: '',
              element: <AllInboxData />,
            },
            {
              path: 'email',
              element: <UnifiedEmailInboxData />,
            },
            {
              path: 'notification',
              element: <UnifiedNotificationInboxData />,
            },
          ],
        },
        {
          path: '/email-verifier',
          children: [
            {
              path: '',
              element: <EmailVerificationList />,
            },
            {
              path: 'create',
              element: <EmailVerificationFlow />,
            },
            {
              path: 'result',
              element: <EmailVerificationResult />,
            },
          ],
        },
        // {
        //   path: '/email-verifier',
        //   children: [
        //     {
        //       path: '',
        //       element: <EmailVerificationList />,
        //     },
        //     {
        //       path: 'create',
        //       element: <UploadEmailList />,
        //     },
        //     {
        //       path: 'result',
        //       element: <EmailVerificationResult />,
        //     },
        //   ],
        // }, -- Email verifier change old code 
        {
          path: '/true-verifier',
          element: <TrueVerifierTable />,
        },
        {
          path: 'sender-accounts',
          children: [
            {
              path: '',
              element: <SenderAccountsList />,
            },
            {
              path: 'add/email',
              element: <AddEmailAccounts />,
            },
            // {
            //   path: 'buy/domain',
            //   element: <BuyDomain />,
            // },
            // {
            //   path: 'add/number',
            //   element: <PurchaseNumbersList />,
            // },
          ],
        },


        {
          path: '/campaigns',
          children: [
            {
              path: '',
              element: <CampaignListv2 />,
            },
            {
              path: '/campaigns/create',
              element: <CampaignCreatePage />,
            },
            // {
            //   path: '/campaigns/insights/:id',
            //   element: <CampaignCreatePage />,
            // },
          ],
        },
        {
          path: '/email-verifier/v2',
          children: [
            {
              path: '',
              element: <EmailVerificationList />,
            },
            {
              path: 'create',
              element: <EmailVerificationFlow />,
            },
            {
              path: 'result',
              element: <EmailVerificationResult />,
            },
          ],
        },
        {
          path: '/unmatched-emails',
          element: <UnmatchedEmails />
        },
        {
          path: '/admin-verifier',
          element: <AdminVerificationList />
        },
        {
          path: '/true-verifier',
          element: <TrueVerifierTable />,
        },
        {
          path: '/brands',
          element: <BrandList />,
        },
        {
          path: '/blacklist-monitor',
          element: <Blacklist />,
        },
        {
          path: '/inbox',
          children: [
            {
              path: '',
              element: <AllInboxData />,
            },
            {
              path: 'email',
              element: <UnifiedEmailInboxData />,
            },
            {
              path: 'notification',
              element: <UnifiedNotificationInboxData />,
            },
          ],
        },
        {
          path: '/single-verifier',
          element: <SingleEmailVerifier />,
        },
        {
          path: '/brands',
          element: <BrandList />,
        },
        {
          path: '/crm/onboard',
          children: [
            {
              path: '',
              element: <OnboardSelectBrand />,
            },
            {
              path: 'upload/prospect',
              element: <OnboardUploadProspects />,
            },
            {
              path: 'connect/accounts',
              element: <OnboardConnectSalesAccount />,
            },
            {
              path: 'connect/calendars',
              element: <OnboardConnectCalendar />,
            },
            {
              path: 'connect/pipeline',
              element: <OnboardCustomizePipeline />,
            },
            {
              path: 'pipeline/stages',
              element: <OnboardDefineStages />,
            },
            {
              path: 'stages/close',
              element: <OnboardCloseStage />,
            }
          ],
        },
        // {
        //   path: 'admin-tickets',
        //   element: <AdminTicketTable />,
        // },
        // {
        //   path: 'admin-accounts',
        //   element: <AdminSenderAccounts />,
        // },
        // {
        //   path: 'admin-settings',
        //   element: <AdminSettings />,
        // },
        {
          path: 'admin/coupon',
          element: <CouponSettingsList />,
        },
        // {
        //   path: 'admin/app-integartion',
        //   element: <AppIntegrationSettings />,
        // },
        // {
        //   path: '/credits/settings',
        //   element: <CreditsettingsList />,
        // },
        // {
        //   path: 'subscription-plan',
        //   element: <Subscription />,
        // },
        {
          path: 'credit-addons',
          element: <CreditAddons />,
        },
        // {
        //   path: 'rolemenu-list',
        //   element: <RoleMenuList />,
        // }, Admin not in progress
        {
          path: 'settings',
          children: [
            {
              path: 'personal/details',
              index: true,
              element: <PersonalDetails />,
            },
            {
              path: 'team',
              element: <Team />,
            },
            {
              path: 'api',
              element: <ApiKeyGeneratingComponent />,
            },
            {
              path: 'notifications',
              element: <Notifications />,
            },
            {
              path: 'rewards',
              element: <Rewards />,
            },
            {
              path: 'security',
              element: <Security />,
            },
            {
              path: 'billing',
              element: <Billing />,
            },
            {
              path: 'donot/list',
              element: <DonotEmailList />,
            },
            {
              path: 'donot/list/add',
              element: <AddDoNotEmailList />,
            },
          ]
        },
      ]
    },
    // {
    //   path: '/user/icon',
    //   element: <EmailTrackOpen />,
    // }, Change from old folder
    {
      path: '/sign-in',
      element: <SignIn />,
      loader: authLoader,
    },
    {
      path: '/sign-up',
      element: <SignUp />,
      loader: authLoader,
    },
    {
      path: '/forgot-password',
      element: <ForgotPassword />,
      loader: authLoader,
    },
    {
      path: '/reset/password/:token/:uuid',
      element: <ResetPassword />,
    },
    {
      path: '*',
      element: <Navigate replace to={environment?.app_default_feature?.base_url} />,
    },
  ]);
  return (
    <HelmetProvider>
      <Provider store={store}>
        <ErrorBoundary>
          <React.Suspense fallback={<Loader />}>
            <RouterProvider router={appRouter} />
          </React.Suspense>
        </ErrorBoundary>
      </Provider>
    </HelmetProvider>
  );
};
export default AppBrowserRoutes;
