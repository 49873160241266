import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import Button from '../Button';
// import CustomSunEditor from 'sparkle-common/TextEditor/TextEditor';
import React from 'react';
import TextEditor from '../TextEditor';

const EditorPopup = (props: any) => {
  const { isOpen, onClose, title, handleMessageOnChange, message, isLoading, onSubmit, submitText, TopNode, type } = props;
  const editorRef = React.useRef(null);

  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose} size={type === 'note' ? '2xl' : '3xl'} closeOnOverlayClick={false} closeOnEsc={false}>
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent className="!p-0" overflow={'auto'}>
          <ModalHeader className="!m-0 !p-0">
            <div className="space-y-1 bg-heading  p-4">
              <h5 className=" text-sm font-thin text-white ">{title}</h5>
              <ModalCloseButton size="sm" color={'#fff'} id="add-blacklist-pop-close" className="mr-3 " />
            </div>
          </ModalHeader>
          <hr />
          <ModalBody className="!px-0">
            <div className={`flex ${type === 'note' ? 'h-[25rem]' : 'h-[30rem]'} flex-col gap-4 px-4 py-2`}>
              {TopNode}
              <TextEditor
                editorContent={message}
                onFocus={() => {
                  // dispatch(setVariableArea('message'));
                }}
                height={type === 'note' ? '17rem' : '15rem'}
                editorRef={editorRef}
                onChange={(value: any) => {
                  handleMessageOnChange(value);
                }}
                leftContent={
                  <div className=" !p-0 ">
                    <Button name={submitText} disable={props.disableCondition} loading={isLoading} onClick={() => onSubmit()} id="task-submit" />
                  </div>
                }
              />

              {/* <MessageBox
                quillRef={quillRef}
                value={message}
                editorClass="!border-none !h-[18rem]"
                toolBarClass="!p-0 bg-white border-none"
                onChange={(value) => handleMessageOnChange(value)}
                leftContent={
                  <div className=" !p-0 ">
                    <Button name={submitText} id="task-submit" disable={props.disableCondition} loading={isLoading} onClick={() => onSubmit()} />
                  </div>
                }
              /> */}
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default EditorPopup;
