export const INBOX_BASE_URL = '/unified/inbox';
export const INBOX_GROUP_URL = '/groups/linked/with/unified_inbox';
export const INBOX_THREAD_URL = 'crm/pipeline/opportunity/get/mail/thread';
export const INBOX_MESSAGE_COUNT = ({ variant }: { variant: string }) => `/unified/inbox/get/${variant}/unread/count`;
export const DELETE_GROUP_FROM_INBOX = '/groups/delete/into/linked/with/unified_inbox';
export const UPDATE_INBOX_GROUP = 'groups/link/with/unified_inbox';
export const INBOX_Email_BASE_URL = '/unified/inbox/email';
export const INBOX_EMAIL_GROUPS = '/groups/linked/with/unified_inbox';
export const UPDATE_INBOX_MESSAGE = '/crm/pipeline/opportunity/update/mail/communication';
export const INBOX_NOTIFICATION = `/unified/inbox/notification`;
