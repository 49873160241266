import React from 'react';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay } from '@chakra-ui/modal';
import Button from 'components/common/Button';
import InputField from 'components/forms/InputField';
import { toast } from 'react-toastify';
import { toastState } from 'utility/utils';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { BaseApi } from 'api/services/base-api';

const AddNewCardModal = (props: any) => {
  const { isOpen, onClose } = props;
  const stripe = useStripe();
  const elements = useElements();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [handleState, setHandleState] = React.useState({
    loading: false,
  });

  const [CardExtraData, setCardExtraData] = React.useState({
    set_default: false,
    name: '',
    nickname: '',
  });

  const handleInputChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCardExtraData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmitCardData = async () => {
    if (!stripe || !elements) {
      toast.error('Stripe has not loaded properly.');
      return;
    }
    setHandleState({ loading: true });
    const cardNumberElement = elements.getElement(CardNumberElement);
    if (!cardNumberElement) {
      toast.error('Card element not found.');
      setHandleState({ loading: false });
      return;
    }
    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
        billing_details: { name: CardExtraData?.name || '' },
        metadata: { nickname: CardExtraData?.nickname || '' },
      });

      if (error) {
        toast.error(`Error: ${error.message}`);
        setHandleState({ loading: false });
        return;
      }
      console.log('paymentMethod', paymentMethod);

      try {
        const response = await BaseApi.post(`/billing/usage/user/card`, { paymentMethodId: paymentMethod?.id, set_default: CardExtraData?.set_default });
        if (response.error) {
          toast.error(`Error attaching card: ${error.message}`, toastState.error);
        } else {
          toast.success('Card added successfully!', toastState.success);
          setCardExtraData({
            set_default: false,
            name: '',
            nickname: '',
          });
        }
      } catch (error) {
        console.error(error);
      }
    } catch (error: any) {
      toast.error(`Error attaching card: ${error.message}`);
    } finally {
      // Set loading to false once everything is complete
      setHandleState({ loading: false });
      onClose();
      setCardExtraData({
        set_default: false,
        name: '',
        nickname: '',
      });
    }
  };

  const handleDefaultChangeEvent = async () => {
    setCardExtraData((prev) => ({
      ...prev,
      set_default: !prev.set_default,
    }));
  };

  const cardStyle = {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        '::placeholder': {
          color: '#a0aec0',
        },
        iconColor: '#c4f0ff', // Add this to ensure the icon color is visible
      },
      invalid: {
        color: '#e53e3e',
      },
    },
  };
  return (
    <Modal isCentered isOpen={isOpen} onClose={() => {}} size="md">
      <ModalOverlay backdropFilter="blur(2px)" />
      <ModalContent overflow={'auto'} className="dark:bg-darkTwo">
        <ModalBody className="!p-4">
          <div className="flex w-full gap-3">
            <div className="w-9/12">
              {/* <InputField
                autoComplete="off"
                extra=""
                inputClass="-mt-5 dark:!bg-darkFive brand_name_input !rounded-md !h-9"
                labelClass="!text-column dark:!text-white !font-[600] !ml-0 text-xs"
                label="Card number"
                placeholder="Card Number"
                id="card_number"
                name="card_number"
                // type="number"
                //   value={formData?.name}?
                onChange={handleInputChangeEvent}
                //   errorMessage={errorMessage?.name}
                //   state={errorMessage?.name ? 'error' : ''}
              /> */}
              <p className="mx-1 mb-1 text-sm font-[500] text-paused-color">Card number</p>
              <div className="mx-1 rounded-lg border p-2 ">
                <CardNumberElement options={cardStyle} />
              </div>
            </div>
            <div className="w-3/12">
              <p className="mx-1 mb-1 text-sm font-[500] text-paused-color">CVC</p>
              <div className="mx-1 rounded-lg border p-2 ">
                <CardCvcElement options={cardStyle} />
              </div>

              {/* <InputField
                autoComplete="off"
                extra=""
                inputClass="-mt-5 dark:!bg-darkFive brand_name_input !rounded-md !h-9"
                labelClass="!text-column dark:!text-white !font-[600] !ml-0 text-xs"
                label="CVC"
                placeholder="CVC"
                id="cvc"
                name="cvc"
                onChange={handleInputChangeEvent}
                //   value={formData?.name}?
                //   onChange={handleChange}?
                //   errorMessage={errorMessage?.name}
                //   state={errorMessage?.name ? 'error' : ''}
              /> */}
            </div>
          </div>
          <div className="mt-3 flex w-full gap-3">
            <div className="w-9/12">
              <InputField
                autoComplete="off"
                extra=""
                inputClass="!mt-0 dark:!bg-darkFive brand_name_input !rounded-md !h-9 ml-1 name-input "
                labelClass=" !text-paused-color !text- dark:!text-white !font-[500] mx-1 !ml-1 text-sm !mt-0"
                customClass="!mt-0"
                label="Name on card"
                name="name"
                placeholder="Name on card"
                onChange={handleInputChangeEvent}
                id="name_on_card"
                type="text"
                //   value={formData?.name}?
                //   onChange={handleChange}?
                //   errorMessage={errorMessage?.name}
                //   state={errorMessage?.name ? 'error' : ''}
              />
            </div>
            <div className=" w-3/12">
              {/* <InputField
                autoComplete="off"
                extra=""
                inputClass="-mt-5 dark:!bg-darkFive brand_name_input !rounded-md !h-9"
                labelClass="!text-column dark:!text-white !font-[600] !ml-0 text-xs"
                label="Data"
                placeholder="Date"
                id="date"
                type="month"
                name="date"
                onChange={handleInputChangeEvent}
                //   value={formData?.name}?
                //   onChange={handleChange}?
                //   errorMessage={errorMessage?.name}
                //   state={errorMessage?.name ? 'error' : ''}
              /> */}
              <p className="mx-1 mb-2 text-sm font-[500] !text-paused-color">Expiry</p>
              <div className="mx-1 rounded-lg border p-2 ">
                <CardExpiryElement options={cardStyle} />
              </div>
            </div>
          </div>
          <div className="w-full">
            <InputField
              autoComplete="off"
              extra=""
              inputClass="!mt-0 dark:!bg-darkFive brand_name_input !rounded-md !h-9 ml-1 "
              labelClass="!text-paused-color dark:!text-white !font-[500] mx-1 !ml-1 text-sm !mt-0"
              customClass="!mt-0"
              label="Nick name (optional)"
              name="nickname"
              placeholder="Name on card"
              onChange={handleInputChangeEvent}
              id="nickname"
              type="text"
              //   value={formData?.name}?
              //   onChange={handleChange}?
              //   errorMessage={errorMessage?.name}
              //   state={errorMessage?.name ? 'error' : ''}
            />
          </div>
          <div className="ml-2 mt-3 flex gap-2">
            <input type="checkbox" id="set_as_default" checked={CardExtraData.set_default} onChange={handleDefaultChangeEvent} className="default-checkbox size-[0.9rem] cursor-pointer accent-brand-500 dark:accent-purple-600" />
            <span className="text-sm text-paused-color "> Make as default</span>
          </div>
        </ModalBody>
        <ModalFooter className="!-mt-2 !mb-1">
          <>
            <Button id="btn-close" name="Cancel" onClick={() => onClose()} customClass="!bg-[#F4F7FE] w-full !text-blackDark border border-2  !font-medium duration-300 w-40 mr-3 py-2 text-[0.80rem] active:scale-95 transition-transform duration-150 ease-out" />
            <Button
              id="add-btn"
              disable={handleState?.loading}
              loading={handleState?.loading}
              name="Submit"
              onClick={handleSubmitCardData}
              customClass={` w-full ${''} dark:border-none confirm_btn_otp_page !font-medium duration-300 w-[10.5rem] py-2.5 text-[0.80rem] active:scale-95 transition-transform duration-150 ease-out add-card-payment-btn`}
            />
          </>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddNewCardModal;
