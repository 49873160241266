import ArrowedTooltip from 'components/common/ArrowedTooltip';
import Button from 'components/common/Button';
import CustomFilter from 'components/common/customFilter';
import TableLoader from 'components/common/Loaders/TableViewLoader';
import InputField from 'components/forms/InputField';
import React from 'react';
import { FiSearch } from 'react-icons/fi';
import { IoIosInformationCircleOutline, IoMdArchive, IoMdCheckmark } from 'react-icons/io';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import CommonRowSettings from 'components/common/commonRowSetting/CommonRowSettings';
import moment from 'moment';
import { RxCross2 } from 'react-icons/rx';
import { GetSingleEmailVerifyResultDataAPI, PostEVThoroughVerificationAPI } from 'shared/email-verifier/api-handler/email-verification-flow';
import { BaseApi } from 'api/services/base-api';
import { TbReload } from 'react-icons/tb';
import { FaPen } from 'react-icons/fa';
// import { SINGLE_EMAIL_VERFIER_TABLESIZE } from 'dto/initial-value/views/single-email-verifier';
import InfiniteScrollComponent from 'components/common/infinityScroll';
import useDebounce from 'hooks/useDebounce';
import { useAppDispatch, useAppSelector } from 'redux-store';
import { HandleEmailFormChangeEvent } from 'shared/single-email-verifier/event-handler';
import { getErrorMessage } from 'redux-store/custom';
import { toast } from 'react-toastify';
import FeedbackPopup from '../components/FeedbackPopup';
import MultiselectGlobalOpt from 'components/common/muitiSelectcomponent/MultiselectGlobalOpt';
import ReCAPTCHA from 'react-google-recaptcha';
import { RECAPTCHA_SITE_KEY } from 'config/envConfig';

// import { MdAdd } from 'react-icons/md';

const SingleEmailVerifier = () => {
  const dispatch = useAppDispatch();
  const logedUser = useAppSelector((state) => state?.auth?.loggedUser);
  const [handleState, setHandleState] = React.useState({
    email: '',
    selectedOption: 'paid',
    loading: false,
    data: [],
    count: 0,
    selectedIds: [],
    globalOpt: false,
    verifyLoading: false,
    fetchData: false,
    searchEmail: '',
    limit: 25,
    offset: 1,
    sort: { columnName: '', order: '' },
    filter: '',
    feedBackPage: false,
    processingId: '',
    listDataLoading: false,
    disableUsage: false,
    feedBackState: false,
    feedBackEmailIds: [],
  });
  const [recaptchaValue, setRecaptchaValue] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [captchaVerified, setCaptchaVerified] = React.useState(false);
  const recaptchaRef = React.useRef<ReCAPTCHA | null>(null);
  const SITE_KEY = RECAPTCHA_SITE_KEY;

  // const handleCaptchaVerification = async (token: string | null) => {
  //   if (token) {
  //     const resp = await BaseApi.post('/email/verify/reCaptcha/validation', { token: token });
  //     console.log('----------------', resp);
  //     setCaptchaVerified(true);
  //   }
  // };

  // Function to handle reCAPTCHA verification success
  const handleCaptchaVerification = async (token: string | null) => {
    if (token) {
      try {
        // Validate the token with your backend API
        const resp = await BaseApi.post('/email/verify/reCaptcha/validation', { token });
        if (!resp?.error) {
          handleSingleRun();
          console.log('reCAPTCHA verified successfully');
          setRecaptchaValue(false); // Close the reCAPTCHA modal
          setCaptchaVerified(true);

          // Trigger the main function after successful verification
        } else {
          console.error('reCAPTCHA validation failed');
          setCaptchaVerified(false);
        }
      } catch (error) {
        console.error('Error verifying reCAPTCHA:', error);
        setCaptchaVerified(false);
      }
    }
  };

  const debouncedSearchValue = useDebounce(handleState?.searchEmail, 300);

  // const handleSubmit = () => {
  //   // e.preventDefault();
  //   handleSingleRun();
  //   // Only trigger reCAPTCHA if the selected option is "free"
  //   console.log("hitted")
  //   if (handleState?.selectedOption === 'free') {
  //     if (recaptchaRef.current) {
  //       recaptchaRef.current.reset(); // Reset before showing to ensure it's fresh
  //       recaptchaRef.current.execute();
  //       handleSingleRun();
  //     }
  //   } else {
  //     // Continue directly if not "free"
  //     handleSingleRun();
  //   }
  // };

  // Function to handle the form submission
  const handleSubmit = async () => {
    console.log('Submit button clicked');

    // Check if the selected option is "free"
    if (handleState?.selectedOption === 'free') {
      console.log("Option is 'free', triggering reCAPTCHA");
      setCaptchaVerified(false); // Reset the verification status
      setRecaptchaValue(true);

      // Trigger reCAPTCHA
      if (recaptchaRef.current) {
        await recaptchaRef.current.reset();
        await recaptchaRef.current.execute();
      }
    } else {
      // If not "free", proceed directly
      console.log("Option is not 'free', proceeding directly");
      handleSingleRun();
    }
  };
  React.useEffect(() => {
    const fetListData = async () => {
      setHandleState((prev) => ({
        ...prev,
        loading: true,
      }));
      dispatch(getErrorMessage({ email: '' }));
      const resp: any = await GetSingleEmailVerifyResultDataAPI({ limit: 25, offset: 0, search: handleState?.searchEmail, filter: handleState?.filter });
      if (!resp?.error) {
        setHandleState((prevState) => ({
          ...prevState,
          offset: 0,
          data: resp?.data?.data,
          count: resp?.data?.total_records?.count,
          loading: false,
        }));
      } else {
        setHandleState((prevState) => ({
          ...prevState,
          offset: 0,
        }));
      }
    };
    fetListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleState?.fetchData, debouncedSearchValue]);
  const errorMessage = useAppSelector((state) => state.custom.errorMessage);

  const fetchOneProcessingData = React.useCallback(async () => {
    const getOneData = await BaseApi.get(`/email/verify/single-emailverify/${handleState?.processingId}`);
    if (getOneData?.data) {
      if (getOneData?.data?.verify_status === 1) {
        setHandleState((prevState) => ({
          ...prevState,
          offset: 0,
          data: handleState?.data?.map((item) => {
            if (item?.email_verifier_id === getOneData?.data?.email_verifier_id) {
              return getOneData?.data;
            } else {
              return item;
            }
          }),
          disableUsage: false,
          processingId: '',
        }));
      }
    }
  }, [handleState.processingId, handleState?.data]);
  React.useEffect(() => {
    const intervalId = setInterval(() => {
      if (handleState?.processingId?.length) {
        handleState?.processingId?.length && fetchOneProcessingData();
      } else {
        clearInterval(intervalId);
      }
    }, 5000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleState?.processingId]);

  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHandleState((prevState) => ({
      ...prevState,
      selectedOption: e.target.value,
    }));
  };

  const handleSearchEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHandleState((prevState) => ({
      ...prevState,
      searchEmail: e.target.value,
    }));
  };

  const handleChangeMail = (e: React.ChangeEvent<HTMLInputElement>) => {
    HandleEmailFormChangeEvent({ e, dispatch });
    setHandleState((prevState) => ({
      ...prevState,
      email: e.target.value,
    }));
  };

  const handleArchive = async (uuids: string[]) => {
    const deleteResponse = await BaseApi.delete('/email/verify/delete/single-emailverify', { data: { uuid: uuids } });
    if (!deleteResponse?.error) {
      setHandleState((prevState) => ({
        ...prevState,
        data: handleState?.data?.filter((items) => !uuids.includes(items?.uuid)),
      }));
      toast.success('Archived Successfully');
    }
  };

  const options = [
    {
      icon: <FaPen className="text-brand-500 group-hover:text-white" />,
      accessName: 'feedback',
      name: 'Feedback',
      functioning: (rowuuid: string, rowDetails: any) =>
        setHandleState((prevState) => ({
          ...prevState,
          feedBackEmailIds: [rowDetails?.email],
          feedBackState: true,
        })),
    },
    {
      icon: <IoMdArchive className="text-brand-500 group-hover:text-white" />,
      accessName: 'archive',
      name: 'Archive',
      functioning: (rowuuid: string, rowDetails: any) => handleArchive([rowuuid]),
    },
  ];

  const multiSelectOptions = [
    {
      icon: <FaPen />,
      accessName: 'feedback',
      name: 'Feedback',
      functioning: (rowuuid: string, rowDetails: any) =>
        setHandleState((prevState) => ({
          ...prevState,
          feedBackState: true,
        })),
    },
    {
      icon: <IoMdArchive />,
      accessName: 'archive',
      name: 'Archive',
      functioning: (rowuuid: string, rowDetails: any) => {
        handleArchive(handleState?.selectedIds);
        table.resetRowSelection();
      },
    },
  ];
  const columnHelper = createColumnHelper<any>();

  const columnData = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <div className="!ml-7 flex items-center">
          <input type="checkbox" id="select_all" checked={table.getIsAllRowsSelected()} onChange={table.getToggleAllRowsSelectedHandler()} className="size-[0.9rem] cursor-pointer accent-brand-500 dark:accent-purple-600" />
        </div>
      ),
      cell: (info) => {
        return (
          <div className="!ml-7 flex items-center" onClick={(event) => event?.stopPropagation()}>
            <input
              type="checkbox"
              id={`row-${info?.row.id + 1}`}
              checked={info?.row.getIsSelected()}
              // disabled={!info?.row.getCanSelect()}
              onChange={info?.row.getToggleSelectedHandler()}
              className="size-[0.9rem] cursor-pointer accent-brand-500 dark:accent-purple-600"
            />
          </div>
        );
      },
    }),

    columnHelper.accessor('email', {
      id: 'email',
      enableSorting: false,
      header: () => <div className="text-md ">Email ID</div>,
      cell: (info) => (
        <div className="group relative">
          <h5 className="w-72 truncate  text-[14px] font-bold text-gray-900 dark:text-gray-200">{info.getValue()}</h5>
          {info.getValue()?.length > 25 && <ArrowedTooltip className="absolute left-0 top-full mt-1 hidden !min-w-fit !px-4 group-hover:block" toolTipId={`row-cdate-tooltip-${info?.row?.id}`} data={info.getValue()} />}
        </div>
      ),
    }),

    columnHelper.accessor('cdate', {
      id: 'cdate',
      header: () => <div className=" text-xs font-medium text-[#667085]">Added On</div>,
      cell: (info) => {
        const date = moment(info.getValue());
        const formattedDate = date.format('DD MMM YYYY');
        return <p className="w-full truncate text-xs font-semibold text-[#2B3674] dark:text-gray-200">{formattedDate}</p>;
      },
    }),

    columnHelper.accessor('email_status', {
      id: 'email_status',
      enableSorting: false,
      header: () => <div className="relative  text-xs font-medium text-[#667085]">Status</div>,
      cell: (info) => {
        return (
          <div>
            {info?.row?.original?.verify_details?.status?.result === 'valid' && (
              <div className="flex w-min items-center gap-0.5 rounded-full bg-[#ecfdf3] px-2 py-1">
                <IoMdCheckmark className="text-xs text-green-600" />
                <h5 className={`text-xs font-semibold text-green-600 status-index-${info?.row?.id}`}>Safe</h5>
              </div>
            )}
            {info?.row?.original?.verify_details?.status?.result === 'error' && (
              <div className="flex w-min items-center gap-0.5 rounded-full bg-[#fef3f2] px-2 py-1">
                <RxCross2 className="text-xs text-red-500" />
                <h5 className={`text-xs font-semibold text-red-500 status-index-${info?.row?.id}`}>Error</h5>
              </div>
            )}
            {info?.row?.original?.verify_details?.status?.result === 'catch_all' && (
              <div className="flex w-fit items-center gap-0.5 rounded-full bg-[#eef4ff] px-2 py-1">
                <RxCross2 className="text-xs text-blue-700" />
                <h5 className={`text-xs font-semibold text-blue-700 status-index-${info?.row?.id}`}>Catch All</h5>
              </div>
            )}
            {info?.row?.original?.verify_details?.status?.result === 'unknown' && (
              <div className="flex w-min items-center gap-0.5 rounded-full bg-draft-bg px-2 py-1">
                <RxCross2 className="text-xs text-draft-color" />
                <h5 className={`text-xs font-semibold text-draft-color status-index-${info?.row?.id}`}>Unknown</h5>
              </div>
            )}
            {info?.row?.original?.verify_details?.status?.result === 'invalid' && (
              <div className="flex w-fit items-center gap-0.5 rounded-full bg-[#fef3f2] px-2 py-1">
                <RxCross2 className="text-xs text-orange-700" />
                <h5 className={`text-xs font-semibold text-orange-700 status-index-${info?.row?.id}`}>Invalid</h5>
              </div>
            )}
            {info?.row?.original?.verify_status === 0 && (
              <div className="flex w-fit items-center gap-0.5 rounded-full bg-[#fefcf2] px-2 py-1">
                <TbReload className="animate-spin text-xs text-yellow-500" />
                <h5 className={`text-xs font-semibold text-yellow-500 status-index-${info?.row?.id}`}>Processing...</h5>
              </div>
            )}
          </div>
        );
      },
    }),

    columnHelper.accessor('classification', {
      id: 'classification',
      enableSorting: false,
      header: () => <div className="text-md ">Classification</div>,
      cell: (info) => (
        // <h5 className="group relative w-36 truncate text-[14px] font-bold text-gray-900 dark:text-gray-200">
        //   {info.getValue()}
        //   {info.getValue()?.length > 18 && (
        //     <ArrowedTooltip className={`ml-8 hidden !min-w-fit !px-4 group-hover:block `} toolTipId={`row-cdate-tooltip-${info?.row?.id}`} data={info.getValue()} />
        //   )}
        // </h5>
        <div className="group relative">
          <h5 className="w-full  truncate text-[14px] font-bold text-gray-900 dark:text-gray-200">{info?.row?.original?.verify_details?.status?.message}</h5>
          {info?.row?.original?.verify_details?.status?.message?.length > 25 && <ArrowedTooltip className="absolute left-0 top-full mt-1 hidden !min-w-fit !px-4 group-hover:block" toolTipId={`row-cdate-tooltip-${info?.row?.id}`} data={info?.row?.original?.verify_details?.status?.message} />}
        </div>
      ),
    }),

    columnHelper.accessor('uuid', {
      id: 'uuid',
      header: () => <div className="relative ">Action</div>,
      cell: (row) => {
        return <>{<CommonRowSettings uuidRow={row?.row?.original?.uuid} rowData={row?.row?.original} options={options} />}</>;
      },
    }),
  ];

  const table = useReactTable({
    data: handleState?.data,
    columns: columnData,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });

  // console.log('enjn', handleState);

  React.useEffect(() => {
    const tableSelectedData = table.getSelectedRowModel().rows.map((val: any) => val.original?.uuid);
    setHandleState((prevState) => ({
      ...prevState,
      selectedIds: tableSelectedData,
      feedBackEmailIds: table.getSelectedRowModel().rows.map((val: any) => val.original?.email),
    }));

    if (table.getSelectedRowModel().rows.length > 1)
      setHandleState((prevState) => ({
        ...prevState,
        globalOpt: true,
      }));
    else
      setHandleState((prevState) => ({
        ...prevState,
        globalOpt: false,
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getSelectedRowModel()]);

  const handleSingleRun = async () => {
    try {
      console.log('FUNC added hitted');
      setHandleState((prevState) => ({
        ...prevState,
        verifyLoading: true,
      }));
      let getOneData: any;
      const response = await PostEVThoroughVerificationAPI({ list_name: 'Test', email_list: [{ email: handleState?.email }] });
      if (response?.status === 200) {
        if (response?.data?.insert_id?.length) {
          getOneData = await BaseApi.get(`/email/verify/single-emailverify/${response?.data?.insert_id}`);
          if (!getOneData?.error) {
            setHandleState((prevState) => ({
              ...prevState,
              email: '',
              data: [getOneData?.data, ...handleState?.data],
              verifyLoading: false,
              disableUsage: handleState?.selectedOption === 'free',
              processingId: response?.data?.insert_id,
            }));

            dispatch(getErrorMessage({ email: '' }));
          }
        }
      }
    } catch (error) {
    } finally {
      setHandleState((prevState) => ({
        ...prevState,
        verifyLoading: false,
        // fetchData: false,
      }));
    }
  };

  const scrollFunction = React.useCallback(
    async (setIsFetching: any) => {
      const scrollfunc = async () => {
        try {
          setIsFetching(true);

          const response = await GetSingleEmailVerifyResultDataAPI({ limit: 25, offset: handleState?.offset + 1, search: handleState?.searchEmail, filter: handleState?.filter });

          const responseData: any = response.data;
          if (!responseData?.data) return setHandleState((prev) => ({ ...prev, data: [], count: 0 }));
          if (responseData?.data?.length < 25) {
            setHandleState((prev) => ({
              ...prev,
              data: [...prev?.data, ...responseData?.data],
              count: responseData?.total_records?.count,
            }));
          } else {
            setHandleState((prev) => ({
              ...prev,
              data: [...prev?.data, ...responseData?.data],
            }));
          }
          setHandleState((prev) => ({
            ...prev,
            offset: prev?.offset + 1,
          }));
        } catch (error) {
          console.log(error);
        } finally {
          setIsFetching(false);
        }
      };
      handleState?.data?.length && handleState?.data?.length < handleState?.count && scrollfunc();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleState?.data?.length, handleState?.filter, handleState?.sort?.columnName, handleState?.sort?.order, handleState?.offset]
  );

  return (
    <div className={`mt-2.5 w-full rounded-lg bg-white pb-2 pt-2.5 shadow-sm dark:bg-darkThree dark:text-white`}>
      {handleState?.selectedOption === 'free' && recaptchaValue && (
        <div className="fixed inset-0 z-[50] flex items-center justify-center bg-black bg-opacity-60">
          <ReCAPTCHA
            sitekey={SITE_KEY}
            size="normal" // Change to "normal" for pop-up modal design
            ref={recaptchaRef}
            onChange={handleCaptchaVerification}
            badge="inline" // Keeps the reCAPTCHA badge inline
          />
        </div>
      )}
      <div className="relative mb-3 py-0.5 sm:gap-32 md:px-10 lg:gap-32 lg:px-3">
        <div className="flex items-center justify-between gap-2">
          <div className="flex items-center gap-2">
            <div
              className="flex items-center gap-2 rounded-md border px-3 py-1.5 outline outline-0 focus-within:outline-2 focus-within:outline-brand-400 dark:border-darkBorder md:w-[13rem] lg:w-[18rem]"
              // onClick={() => searchRef.current.focus()

              // }
            >
              <FiSearch className="size-4 cursor-pointer" />
              <input
                // ref={searchRef}
                autoComplete="off"
                onChange={(e) => handleSearchEmail(e)}
                type="text"
                placeholder="Search"
                id="prospect-search-input"
                className="w-full search_single_verifier text-sm font-bold text-gray-800 caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:bg-darkThree dark:text-white dark:placeholder:text-white "
              />
            </div>
            <CustomFilter optionData={[]} handleFilter={() => {}} />
          </div>
          {/* Role Restriction for Adding Email Verify List */}
          <div className=" flex items-center gap-2">
            <div className="mr-3 flex items-center gap-3">
              <label className="flex items-center gap-1">
                <input
                  className="text-blueSecondary free_button"
                  type="radio"
                  value="free"
                  checked={handleState?.selectedOption === 'free'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    // setCaptchaVerified(false);
                    handleOptionChange(e);
                  }}
                />
                <span className={`text-xs ${handleState?.selectedOption === 'free' ? `font-semibold` : ``}`}>Free</span>
                <div className="group relative">
                  <IoIosInformationCircleOutline className="cursor-pointer" size={13} />
                  <ArrowedTooltip
                    className="absolute left-1/2 top-full mt-2 hidden !min-w-fit -translate-x-1/2 transform !px-4 group-hover:block"
                    data={'May experience delay during peak hours'}
                  />
                </div>
              </label>
              <label className="flex items-center gap-1">
                <input className="text-blueSecondary paid_button" type="radio" value="paid" checked={handleState?.selectedOption === 'paid'} onChange={handleOptionChange} />
                <span className={`text-xs ${handleState?.selectedOption === 'paid' ? `font-semibold` : ``}`}>Paid</span>
                <div className="group relative">
                  <IoIosInformationCircleOutline className="cursor-pointer" size={13} />
                  <ArrowedTooltip
                    className="absolute left-1/2 top-full mt-2 hidden !min-w-fit -translate-x-1/2 transform !px-4 group-hover:block"
                    data={'Bypass queuing during peak hours'}
                  />
                </div>
              </label>
            </div>
            <InputField
              autoComplete="off"
              customClass="!h-9 !mt-0"
              inputClass="!mt-0.5 !w-96 !rounded-md !h-9 !bg-white single_mail_input_field"
              labelClass="!text-paused-color !font-[500] !ml-0 !text-xs"
              readOnly={handleState?.verifyLoading || handleState?.disableUsage}
              placeholder="Enter user email"
              id="email"
              type="email"
              value={handleState?.email}
              onChange={handleChangeMail}
              // errorMessage={errorMessage?.email}
              state={errorMessage?.email ? 'error' : ''}
            />
            <Button
              id="add_run_test"
              name="Run Test"
              customClass="text-xs add_brand_btn !font-normal bg-blueSecondary add_run_test_btn dark:border-none"
              // LeftIcon={<MdAdd className="text-[16px]" />}
              loading={handleState?.verifyLoading}
              disable={handleState?.verifyLoading || handleState?.disableUsage}
              onClick={() => handleSubmit()}
            />
          </div>
        </div>
      </div>
      <div className="h-[80vh] overflow-y-auto overflow-x-scroll scroll-smooth xl:overflow-x-hidden">
        {!handleState?.loading ? (
          <>
            <div className="h-screen">
              <table className=" w-full">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header, index, arr) => {
                        const RowDataWidth = ['w-[5%]', 'w-[25%]', 'w-[20%]', 'w-[20%]', 'w-[25%]', 'w-[5%]'];
                        return (
                          <th
                            key={header.id}
                            colSpan={header.colSpan}
                            onClick={header.column.getToggleSortingHandler()}
                            className={`${RowDataWidth[index]} mb-2 border-y border-gray-200 bg-gray-50 py-4 text-start text-column dark:border-white/10 dark:bg-darkOne dark:!text-white `}
                          >
                            <div className="truncate text-xs">
                              {flexRender(header.column.columnDef.header, header.getContext())}
                              {{
                                asc: '',
                                desc: '',
                              }[header.column.getIsSorted() as string] ?? null}
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody className="divide-y-[1px] dark:divide-darkBorder">
                  {handleState?.data?.length ? (
                    table.getRowModel().rows.map((row) => {
                      return (
                        <tr key={row.id} className="hover:bg-hover dark:hover:bg-darkOne" id={`email-verifyrow${row.id} table-click`}>
                          {row.getVisibleCells().map((cell, i, arr) => {
                            const RowDataWidth = ['w-[5%]', 'w-[25%]', 'w-[20%]', 'w-[20%]', 'w-[25%]', 'w-[5%]'];
                            return (
                              <td
                                key={cell.id}
                                className={`cursor-pointer border-white/0 py-3 text-start text-sm font-semibold text-heading dark:text-white
                                  ${RowDataWidth[i]}`}
                                id="table-click"
                              >
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  ) : (
                    <tr></tr>
                  )}
                </tbody>
              </table>
              <InfiniteScrollComponent
                loadMore={scrollFunction}
                loader={
                  <TableLoader width="w-full" border="border border-gray-100" column={6} row={1} skeletonHeight="h-2" padding="px-5 py-4" columnWidth="4% 25% 22% 22% 25% 4%" />
                }
              />
            </div>
          </>
        ) : (
          <TableLoader
            width="w-full"
            border="border border-gray-100"
            column={10}
            row={13}
            skeletonHeight="h-2"
            padding="px-5 py-4"
            tableViewBg={{ headBg: 'bg-gray-50' }}
            columnWidth="4% 22% 10% 10% 10% 10% 10% 10% 10% 4%"
          />
        )}
      </div>
      {handleState?.feedBackState && (
        <FeedbackPopup
          isOpen={handleState?.feedBackState}
          onClose={() => {
            setHandleState((prevState) => ({
              ...prevState,
              feedBackState: false,
            }));
          }}
          fromEmail={logedUser?.email}
          emailIds={handleState?.feedBackEmailIds}
          table={table}
        />
      )}
      {handleState?.globalOpt && <MultiselectGlobalOpt options={multiSelectOptions} />}

      {/* {showGlobalOpt && <GlobalOpt handleOptions={handleOptions} roleAccess={roleStatus} />} */}
    </div>
  );
};

export default SingleEmailVerifier;
