import { AiOutlineSearch } from "react-icons/ai";
import { IoMdArchive } from "react-icons/io";
import { IoCloudUploadOutline } from "react-icons/io5";
import { MdEdit, MdOutlineCloudDownload } from "react-icons/md";

export const AddNewSenderSMTPData = {
  first_name: '',
  last_name: '',
  email: '',
  smtp_username: '',
  smtp_password: '',
  smtp_host: '',
  smtp_port: '',
  smtp_secure: '',
  daily_limit: 0,
  delay_between_emails: 0,
  campaign_settings: { daily_limit: 0, delay_between_emails: 0 },
  set_different_reply_to_address: '',
  imap_username: '',
  imap_password: '',
  imap_host: '',
  imap_port: '',
  imap_secure: '',
};

export const selectIntegratedEmailListIV = [
  {
    title: 'Connect your email IDs',
    description: 'Use your existing email addresses.',
    icon: <IoCloudUploadOutline className="mt-2 text-[3.125rem] text-darkBlue dark:!text-purple-300" />,
    source: "own_email",
    release_feature: true, // released
  },
  {
    title: 'Buy Email IDs',
    description: ' Purchase fully set up, ready-to-use email accounts. These come with full ownership of the domains and customizable email ids.',
    icon: <AiOutlineSearch className="text-[1.5rem] text-heading dark:!text-purple-300" />,
    source: "buy_email",
    release_feature: false, // coming soon
  },
];

export const columnWidthIV = ['w-[5%]', 'w-[20%]', 'w-[30%]', 'w-[10%]', 'w-[10%]', 'w-[10%]', 'w-[10%]', 'w-[5%]'];
export const queryParamsIV = { limit: 25, offset: 0, sort: { columnName: '', order: '' }, search: '', filter: '' }
export const columnVisibilityIV = [
  { _name: 'Name', id: 'name', name: true, disabled: true },
  { _name: 'Email accounts', id: 'email', email: true, disabled: true },
  { _name: 'Status', id: 'mailer_status', mailer_status: true, disabled: true },
  { _name: 'Daily limits', id: 'limit', limit: true },
  { _name: 'Health Score', id: '', none: true },
  { _name: 'Email groups', id: 'groups_list', groups_list: true },
]

export const confirmIV = {
  isOpen: false,
  headerContent: '',
  bodyContent: '',
  bodyRedContent: '',
  descriptionContent: '',
  // uuid: [],
}

export const tableRowActionItemsListIV = [
  {
    icon: <MdEdit className="text-brand-500 group-hover:text-white" />,
    accessName: 'edit',
    name: 'Edit',
    functioning: "handleRowDataEdit",
  },
  {
    icon: <IoMdArchive className="text-brand-500 group-hover:text-white" />,
    name: 'Archive',
    accessName: 'archieve_or_delete',
    functioning: "handleRowArchieve",
  },
  {
    icon: <MdOutlineCloudDownload className="text-brand-500 group-hover:text-white" />,
    name: 'Download',
    accessName: 'download',
    functioning: "handleRowDownload",
  },
];
export const bottomActionBarListIV = [
  // {
  //   icon: <MdEdit />,
  //   name: 'Edit',
  //   functioning: "handleMultiSelectEdit",
  // },
  {
    icon: <IoMdArchive />,
    name: 'Archive',
    functioning: "handleMultiSelectArchieve",
  },
  {
    icon: <MdOutlineCloudDownload />,
    name: 'Download',
    functioning: "handleMultiSelectDownload",
  },
];