import { AiOutlinePlusCircle } from 'react-icons/ai';
import Button from 'components/common/Button';
import { BiArrowBack } from 'react-icons/bi';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import OnboardBackground from 'components/common/crmOnboard/OnbardBackground';
import SelectConnectionAccount from 'components/common/crmOnboard/SelectConnectionAccount';

const OnboardConnectSalesAccount = () => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate('/crm/onboard/connect/calendars');
  };
  const handleBackClick = () => {
    navigate(-1); // Navigate to the previous page
  };
  const [selectAccountView, setSelectAccountView] = React.useState(false);
  const handleAccountView = () => {
    setSelectAccountView(true);
  };
  return (
    <>
      <OnboardBackground count={3}>
        <div className="flex w-full justify-between">
          <div className="flex flex-col justify-start gap-8 py-6">
            <div>
              <p className="text-3xl font-bold text-heading">
                Connect your Sales Inbox
              </p>
              <p className="mt-3 w-[45%] text-sm font-medium text-heading">
                Track your prospects replies by connection your sales inbox. You
                can connect up to 5 inboxes per pipeline.
              </p>
              <div className="mt-6 flex w-full gap-6">
                <div className="h-[22rem] w-[45%] rounded-md bg-white p-6 shadow-modalBody">
                  {!selectAccountView ? (
                    <div className="flex h-[19rem] flex-col items-center justify-center gap-1 rounded-sm border-[0.1rem] border-dashed border-[#7050ff] bg-bgUpload">
                      <div className="text-6xl text-blueSecondary">
                        <AiOutlinePlusCircle />
                      </div>
                      <Button
                        name="Add email accounts"
                        color="blueSecondary"
                        px="px-3"
                        customClass="mt-3 !bg-bgUpload  border-blueSecondary !font-bold"
                        onClick={handleAccountView}
                      />
                    </div>
                  ) : (
                    <SelectConnectionAccount />
                  )}
                </div>
                <div className="w-[35%]">
                  <p className="text-lg font-bold text-heading">
                    What are sales calendars?
                  </p>
                  <p className="mt-3 text-justify text-base font-medium text-heading">
                    Sales calendars are used to create, track appointments or
                    meeting with your prospects. You can connect multiple sales
                    calendar to this pipeline. But, ensure you connect al least
                    1 sales calendar. Sparkle will sync all your sales calendar
                    and keep you up to date of all upcoming meetings or
                    scheduled appointments.
                  </p>
                </div>
              </div>
              <div className="mt-8 flex items-center gap-8 ">
                <div
                  className="flex cursor-pointer items-center gap-2 text-xl text-blueSecondary"
                  onClick={handleBackClick}
                >
                  <BiArrowBack />
                  <p className="cursor-pointer text-sm font-medium text-brand-500 underline">
                    Back
                  </p>
                </div>
                <Button
                  type="button"
                  name="Next"
                  fontSize="sm"
                  px="px-6"
                  customClass="text-column font-medium text-sm"
                  onClick={() => onClick()}
                />
              </div>
            </div>
          </div>
        </div>
      </OnboardBackground>
    </>
  );
};

export default OnboardConnectSalesAccount;
