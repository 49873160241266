/* eslint-disable react-hooks/rules-of-hooks */
import { useFieldValidator, useFormValidator } from 'hooks/useFormValidation';
import { brandsValidationSchema } from 'lib/validator-schema/brandSchema';
import { setBrandInput } from 'redux-store/campaign-v2';
import { getErrorMessage } from 'redux-store/custom';

export const HandleFormChangeEvent = async ({ event, setFormData, dispatch }: any) => {
  try {
    // console.log('HITEEDE');
    // const { id, value } = event.target;
    // dispatch(setBrandInput({ [id]: value }));
    setFormData((prev: any) => ({ ...prev, [event.target.id]: event.target.value }));
    const validate = await useFieldValidator(brandsValidationSchema, event.target.id, event.target.value);
    dispatch(getErrorMessage(validate?.message));
  } catch (error) {
    console.error('HandleFormChangeEvent Error:', error);
  }
};

export const HandleFormSubmitEvent = async ({onParentDataChange,formData,dispatch}:any)=>{
    const validate = await useFormValidator(brandsValidationSchema, formData);
    if (validate?.error) {
      dispatch(getErrorMessage(validate?.message));
      return;
    }
    const { uuid, ...rest } = formData;
    dispatch(setBrandInput(formData));
    onParentDataChange({ uuid: formData?.uuid, ...rest });
}


