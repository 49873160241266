import { EMAIL_VERIFIER_LIST_DELETE, GET_EMAIL_VERIFIER_LIST_COUNT, GET_EMAIL_VERIFIER_RESULT_QUERY, GET_EMAIL_VERIFIER_RESULT_QUERY_PARAMS, GET_EMAIL_VERIFIER_RESULT_SINGLE } from 'api/endpoints/emailVerifierEndpoints'
import { BaseApi } from 'api/services/base-api'
import { AxiosResponse } from 'axios'

export const GetEmailVerifierListAPI = async (queryParams: string) => {
  try {
    const response: AxiosResponse<any, any> = await BaseApi.get(GET_EMAIL_VERIFIER_RESULT_QUERY(queryParams))
    return response
  } catch (error) {
    console.error('Email verifier list retrieval error occured ', error)
    throw error
  }
}

export const GetEmailVerifierListParamsAPI = async (listId: string, count: string | number) => {
  if (listId) {
    try {
      const response: AxiosResponse<any, any> = await BaseApi.get(GET_EMAIL_VERIFIER_RESULT_QUERY_PARAMS(listId, count))
      return response
    } catch (error) {
      console.error('Failed to fetch data:', error)
    }
  }
}

export const GetEmailVerifierListSingleAPI = async (listId: string, query: string) => {
  if (listId) {
    try {
      const response: AxiosResponse<any, any> = await BaseApi.get(GET_EMAIL_VERIFIER_RESULT_SINGLE(listId, query))
      return response
    } catch (error) {
      console.error('Failed to fetch data:', error)
    }
  }
}

export const GetEmailVerifierListCountAPI = async (listId: string) => {
  if (listId) {
    try {
      const response: AxiosResponse<any, any> = await BaseApi.get(GET_EMAIL_VERIFIER_LIST_COUNT(listId))
      return response
    } catch (error) {
      console.error('Failed to fetch data:', error)
    }
  }
}

export const DeleteEmailVerifierListAPI = async (params: Record<string, any>) => {
  try {
    const response: AxiosResponse<any, any> = await BaseApi.delete(EMAIL_VERIFIER_LIST_DELETE, params)
    return response
  } catch (error) {
    console.error('Email verifier list deletion error occured ', error)
    throw error
  }
}
