/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, ModalHeader, ModalFooter } from '@chakra-ui/modal';
import { useAppDispatch, useAppSelector } from 'redux-store';
import Toggle from 'components/common/Toggle';
import Button from 'components/common/Button';
import { AccordionState, NotificationPropsDto } from 'dto/types/views/notification';
import { NOTIFICATION_ACCORDION_DATA, NotificationPopupStateIV } from 'dto/initial-value/views/notification';
import { AccordionChangeSaveData, ChangeToogleDataFunction, CommonToogleFunction, IntialSettingsFunction } from 'shared/notification/event-handler/notification-event';

const NotificationSettingsPopup = (props: NotificationPropsDto) => {
  const { onClose, isOpen } = props;
  const { notificationSettingsData, notificationSettingsType } = useAppSelector((state) => state.notificationSettings);
  const [openAccordion, setOpenAccordion] = React.useState<AccordionState>(NotificationPopupStateIV);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    const initializeSettings = async () => {
      await IntialSettingsFunction(setOpenAccordion, notificationSettingsData);
    };
    initializeSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleAccordionClick = (index: number) => {
    setOpenAccordion((prevState) => ({
      ...prevState,
      activeIndex: prevState?.activeIndex === index ? null : index,
    }));
  };

  const handleSwitchToggle = (accordionIndex: number, keyIndex: number) => {
    ChangeToogleDataFunction(setOpenAccordion, accordionIndex, keyIndex);
  };
  const accordionData = NOTIFICATION_ACCORDION_DATA;

  const handleCommonSwitchToggle = (accordionIndex: number) => {
    CommonToogleFunction(setOpenAccordion, openAccordion, accordionIndex);
  };

  const isCommonSwitchOn = (accordionIndex: number) => {
    const switches = openAccordion.switches[accordionIndex];
    if (!switches) {
      return false;
    }
    return Object.values(switches).every((value) => value);
  };

  const handleSave = async () => {
    await AccordionChangeSaveData(notificationSettingsType, openAccordion, onClose, dispatch);
  };

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size={'2xl'} closeOnOverlayClick={false} closeOnEsc={false}>
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent overflow={'auto'} className="dark:bg-darkTwo">
          <ModalHeader className="mb-2 flex items-center justify-between !px-0 dark:bg-darkOne">
            <div className="items-center space-y-1 px-4 ">
              <h5 className="text-[18px] font-medium capitalize text-[#1E1E1E] dark:text-white">{notificationSettingsType ? notificationSettingsType?.split('_')[0] : 'Settings'}</h5>
              <p className="text-sm font-normal text-column dark:text-gray-600 ">
                Configure custom notifications settings for <span>{notificationSettingsType?.split('_')[0]}</span>
                <br /> You can find advanced options under each section.
              </p>
            </div>
            <ModalCloseButton size={'lg'} color={'#667085'} className="mr-3 scale-75 transform self-center text-[32px] hover:bg-white" />
          </ModalHeader>
          <hr className="-mt-2 dark:border-darkBorder" />
          <ModalBody className="mb-3 flex max-h-[25.5rem] cursor-pointer gap-4  overflow-y-auto  rounded-lg px-6 py-4">
            <div>
              <div className="mt-2 flex flex-col items-center justify-center gap-1 ">
                {accordionData?.map((item, accordionIndex) => (
                  <div className=" mb-3 w-[610px] rounded-lg border border-[#E5E5E5] px-6 py-2 dark:border-darkBorder ">
                    <div
                      key={accordionIndex}
                      className="mb-5 flex cursor-pointer items-center gap-4 "
                      onClick={(e) => {
                        handleAccordionClick(item?.index);
                      }}
                    >
                      <div className="flex w-full items-center">
                        <Toggle
                          className="scale-50"
                          checkedCondition={isCommonSwitchOn(item?.index) || false} // Common switch condition
                          changeFunction={(e: any) => {
                            e.stopPropagation();
                            handleCommonSwitchToggle(item?.index); // Common switch toggle
                          }}
                        />
                        <div className="grow items-start">
                          <p className="font-semibold text-heading dark:text-white">{item.title}</p>
                          {openAccordion?.activeIndex !== item?.index && <div className="text-sm text-column dark:text-gray-600">{item.description}</div>}
                        </div>
                        <div>
                          <BiChevronDown />
                        </div>
                      </div>
                    </div>
                    {openAccordion?.activeIndex === item?.index && (
                      <div>
                        <div className="ml-14 mt-3 text-sm text-column">
                          {item.keys.map((key, keyIndex) => (
                            <div key={keyIndex} className="flex items-center gap-6">
                              <span className="grow font-semibold text-[#2B3674]">{key}</span>
                              <div className="-mr-5">
                                <Toggle
                                  className="scale-50 "
                                  checkedCondition={openAccordion?.switches[item?.index]?.[keyIndex] || false}
                                  changeFunction={(e: any) => {
                                    e.stopPropagation();
                                    handleSwitchToggle(item?.index, keyIndex);
                                  }}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </ModalBody>
          <hr className="dark:border-darkBorder" />
          <ModalFooter>
            <div className="flex w-full justify-center gap-3 px-6 pb-1">
              <Button onClick={() => onClose()} type="button" name="Cancel" fontSize="sm" px="px-6" customClass=" font-medium text-sm w-1/2 !bg-[#fff] !text-[#344054]" />
              <Button
                type="button"
                name="Save"
                fontSize="sm"
                px="px-6"
                customClass="text-column font-medium text-sm w-1/2 dark:border-none"
                onClick={() => {
                  handleSave();
                }}
              />
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default NotificationSettingsPopup;
