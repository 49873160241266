import { AddBlacklistStateDTO, blacklistStateDTO, blacklistTableDataDTO } from 'dto/types/views/blacklist';

export const frequencyOptionsIV = [
  { value: 1, type: 'day' },
  { value: 3, type: 'days' },
  { value: 7, type: 'days' },
  { value: 30, type: 'days' },
];

export const ConnectionFailColor = '#175CD3'
export const BadNameColor = '#667085'
export const ListedColor = '#F04438'
export const DefaultColor = '#12B76A'

export const ConnectionFailText = 'Connection Fail'
export const BadNameText = 'Bad Name'
export const ListedText = 'Listed'

export const BLACKLIST ='blacklist'



export const blacklistStateIV: blacklistStateDTO = {
  showLoading: true,
  load: 0,
  fetchTrigger: false,
  isOpen: false,
  modalData: null,
  optionData: [],
  columns: [],
  selectedData: [],
  showGlobalOpt: false,
  showCreateModal: false,
  fetchGroupListTrigger: false,
  queryParams: {
    limit: 25,
    offset: 1,
    search: '',
    filter: '',
  },
};

export const blacklistTableDataIV: blacklistTableDataDTO = {
  data: [],
  count: 0,
};

export const AddBlacklistStateIV:AddBlacklistStateDTO ={
  blackList: [],
  groupname:'',
  loading:false,
  showTooltip:false,
  frequency:{
      value: '',
      type: 0,
    }
}


