// import { toastState } from 'common/utils/utility';
// import { BaseApi } from 'api/services/base-api';
import CustomBadge from 'components/common/badges/CustomBadge';
// import Button from 'components/common/Button';
// import React from 'react';
// import { FiArrowRight } from 'react-icons/fi';

const ChoosePackageCards = (props: any) => {
  const { plan, planType, setHandleState, handleState, value, setValue } = props;

  return (
    <div className="mt-4 ">
      <div
        className={`min-w-48 ${plan?.credits}_btn cursor-pointer rounded-md border ${handleState?.credit_addonId === plan?.uuid || value === plan?.credits ? '!border-blueSecondary bg-bgInside dark:bg-navy-900' : ' bg-white'} px-4 py-2 text-center shadow dark:!border-darkBorder dark:bg-darkThree`}
        onClick={() => {
          setValue(plan?.credits);
          setHandleState((prevState: any) => ({
            ...prevState,
            creditsCount:plan?.credits,
            credit_addonId: plan?.uuid,
            creditAmount: plan?.amount,
          }));
        }}
      >
        <>
          <div className="my-0 text-lg font-bold text-heading dark:text-white">{plan?.credits}</div>
          <div className="flex items-end justify-center gap-2">
            <div className="-mt-0.5 items-center font-normal text-heading dark:text-white"> {planType === 'subscription' ? `Sparkles/month` : `Sparkles`}</div>
            {plan?.discount_percentage !== 0 && planType === 'pay_as_you_go' && <CustomBadge content={`Save ${plan?.discount_percentage} %`} bgColor="green-100" color={'green-600'} extra="h-5 !font-semibold text-[.625rem] !mb-0.5 !px-2 !py-0.5" />}
          </div>
        </>
      </div>
    </div>
  );
};

export default ChoosePackageCards;
