export const BLACKLIST_GROUP_TAGS = '/groups/linked/with/blacklist';

export const BLACKLIST_VALIDATE = '/blacklist/validate'
                                     
export const BLACKLIST_LIST = '/blacklist/list';

export const BLACKLIST_DELETE = '/blacklist/list/delete';

export const BLACKLIST_PAUSE = '/blacklist/list/pause';

export const BLACKLIST_RESUME = '/blacklist/resume/validate';

export const BLACKLIST_RECHECK = '/blacklist/recheck/validate';

export const CREATE_TICKET_BLACKLIST = '/true/verify/create/new/blacklist/ticket';

export const DOWNLOAD_BLACKLIST_EXCELDATA = (uuids: string | string[]) => `/blacklist/excelresult/${uuids}`;

export const GET_ONE_BLACKLIST_DATA = (uuid: string) => `/blacklist/result/${uuid}`;

//-----------------------------------------------------------------------------------------------//

export const BLACKLIST_GROUPS_DELETE = '/groups/delete/into/linked/with/blacklist'

export const BLACKLIST_GROUP_CREATE = '/groups/link/with/blacklist'

export const GROUP_ONE_BLACKLIST = (uuid: string) => `/groups/${uuid}`;

export const GROUPS = '/groups'