import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import CustomBadge from 'components/common/badges/CustomBadge';
import Button from 'components/common/Button';
import { selectIntegratedEmailListIV } from 'dto/initial-value/views/sender-accounts';
import { EmailMethodSelectModalPropsDTO } from 'dto/types/views/sender-accounts';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';


const SelectEmailMethodPopup = (props: EmailMethodSelectModalPropsDTO) => {
    const [selectedAccountType, setSelectedAccountType] = useState("own_email");
    const { isOpen, setOpen } = props;
    const navigate = useNavigate();

    const handleContinue = () => {
        selectedAccountType === "own_email" && navigate('add/email');
        selectedAccountType === "buy_email" && navigate('buy/domain');
    };

    return (
        <div>
            <Modal isCentered isOpen={isOpen} onClose={() => setOpen((prev: { add_account_flag: boolean }) => ({ ...prev, add_account_flag: false }))} size={'3xl'}>
                <ModalOverlay backdropFilter="blur(2px)" />
                <ModalContent overflow={'auto'} className="!p-0 dark:bg-darkThree ">
                    <ModalHeader>
                        <div className="flex flex-col">
                            <h4 className="text-sm font-semibold text-heading dark:text-gray-300">Add Accounts</h4>
                            <span className="text-[11px] text-column dark:text-gray-500">Select the method of creating email accounts</span>
                        </div>
                        <ModalCloseButton size={'sm'} color={'column'} id="email-selection-method-pop-close" />
                    </ModalHeader>
                    <ModalBody className="!p-0">
                        <div className="flex items-center justify-center">
                            <div className="w-[48rem] rounded-md bg-white p-5 shadow-md dark:bg-darkThree">
                                <div className=" flex h-36 select-none gap-4">
                                    {selectIntegratedEmailListIV.map((item, index) => (
                                        <div
                                            id="upload_csv_section"
                                            className={`flex w-full cursor-pointer flex-col items-center justify-center rounded-md border ${selectedAccountType === item.source ? 'border-blueSecondary bg-bgInside dark:bg-navy-700' : ''} px-4`}
                                            key={index}
                                            onClick={item.release_feature ? () => setSelectedAccountType(item.source) : null}
                                        >
                                            {item.icon}
                                            <h4 className={`${selectedAccountType === item.source ? 'text-blackDark dark:text-white' : 'text-grayish-blue dark:text-white'} mt-1 flex items-center justify-center gap-2 text-[.8125rem] font-semibold`} >
                                                {item?.title}
                                            </h4>
                                            <span className={` text-center text-[.6875rem] font-medium ${selectedAccountType === item.source ? 'text-heading' : 'text-column'} dark:text-gray-300 `}>
                                                {item?.description}
                                            </span>
                                            {!item?.release_feature && (
                                                <div className="mt-2 bg-active-bg">
                                                    <CustomBadge content={'Coming soon'} bgColor="active-bg" color={'textBlue'} extra="h-5 !font-semibold text-[.625rem] !px-2 !py-0.5" />
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div className="mt-6 flex gap-4">
                                    <Button name="Cancel" customClass="w-full cancel_btn_sender_account bg-white border !text-gray-800 !font-semibold text-[.82rem]" onClick={() => setOpen((prev: { add_account_flag: boolean }) => ({ ...prev, add_account_flag: false }))} />
                                    <Button id="prospect_upload_continue_btn" name="Continue" customClass="continue_btn_sender_account w-full dark:border-none text-[.82rem]" onClick={handleContinue} />
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    );
};

export default SelectEmailMethodPopup;
