import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import AppBrowserRoutes from 'routes/routes';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import Intercom from '@intercom/messenger-js-sdk';
import { useAppSelector } from 'redux-store';
import { ENV_INTERCOM_APP_ID, ENV_INTERCOM_CREATED_AT } from 'config/envConfig';

const App = () => {
  const queryClient = new QueryClient();
  const { loggedUser } = useAppSelector((state) => state.auth);
  
  if (loggedUser) {
    Intercom({
      app_id: ENV_INTERCOM_APP_ID,
      user_id: loggedUser?.uuid,
      name: `${loggedUser?.first_name || ''} ${loggedUser?.last_name || ''}`,
      email: loggedUser?.email,
      created_at: ENV_INTERCOM_CREATED_AT,
    });
  } else {
    Intercom({
      app_id: ENV_INTERCOM_APP_ID,
    });
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider>
        <AppBrowserRoutes />
        <ToastContainer />
      </ChakraProvider>
    </QueryClientProvider>
  );
};

export default App;
