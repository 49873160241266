/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';

import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { MdAdd, MdOutlineWatchLater } from 'react-icons/md';
import React from 'react';
import useDebounce from 'hooks/useDebounce';
import { useAppDispatch, useAppSelector } from 'redux-store';
// import { BaseApi } from 'api/services/base-api';
import { calculateTimeAgo, checkRolePermissionStatus } from 'utility/utils';
import { blacklistFilterDataList } from 'lib/page-filters/blacklist';
import { BlacklistRowObjectDTO } from 'dto/types/views/blacklist';
import BlacklistGroupTags from '../components/BlacklistGroupTags';
import ArrowedTooltip from 'components/common/ArrowedTooltip';
import { IoMdCloudDone } from 'react-icons/io';
import { FaCirclePause } from 'react-icons/fa6';
import BlacklistRowSettings from '../components/BlacklistRowSettings';
import { FiSearch } from 'react-icons/fi';
import CustomFilter from 'components/common/customFilter';
import InfiniteScrollComponent from 'components/common/infinityScroll';
import TableLoader from 'components/common/Loaders/TableViewLoader';
import GlobalOption from '../components/GlobalOption';
import AddBlacklistModal from '../components/AddBacklistModal';
import ResultModal from '../components/ResultModal';
import { blacklistStateIV, blacklistTableDataIV } from 'dto/initial-value/views/blacklist';
import {
  BlacklistDeleteListData,
  BlacklistDownloadListData,
  BlacklistOptionHandler,
  BlacklistPauseListData,
  BlacklistRecheckListData,
  BlacklistResultModalData,
  BlacklistResumeListData,
  BlacklistScrollFunction,
  BlacklistTableData,
  DeleteGroupsFromList,
  FetchBlacklistGroupsData,
  FetchBlacklistListData,
  FilterBlacklistData,
  SearchBlacklistData,
} from 'shared/blacklist/event-handler/blacklist-event';
import { getErrorMessage } from 'redux-store/custom';

const Blacklist = () => {
  const tableRef = React.useRef(null);
  const BlacklistSearchRef = React.useRef(null);
  const [data, setData] = React.useState(blacklistTableDataIV);
  const [handleState, setHandleState] = React.useState(blacklistStateIV);
  const dispatch = useAppDispatch();
  const debouncedSearchValue = useDebounce(handleState?.queryParams?.search, 300);
  const custom = useAppSelector((state) => state?.custom);
  const [roleStatus, setRoleStatus] = React.useState(null);
  const userRolePermissionListStoreData = useAppSelector((state) => state?.globalsettings?.globalConfiguration?.user_organization_role_details);
  const { environment } = useAppSelector((state) => state.globalsettings);

  const fetchGroupList = async () => {
    await FetchBlacklistGroupsData(dispatch);
  };

  React.useEffect(() => {
    fetchGroupList();
  }, [dispatch, handleState?.fetchGroupListTrigger]);

  const grouplistTrigger = () => {
    setHandleState((prevState) => ({ ...prevState, fetchGroupListTrigger: !handleState?.fetchGroupListTrigger }));
  };

  const fetchList = async () => {
    await FetchBlacklistListData(setHandleState, setData, columnData, handleState);
  };

  React.useEffect(() => {
    fetchList();
  }, [handleState?.load, handleState?.fetchTrigger, handleState?.queryParams?.filter, debouncedSearchValue, custom?.globalDependencyLoading]);

  const hanleDeleteGroupFromSenderList = (groupId: string) => {
    DeleteGroupsFromList(setData, groupId);
  };

  React.useEffect(() => {
    const getRolePermissionStatus = async () => {
      const status = await checkRolePermissionStatus(userRolePermissionListStoreData, 'deliverability_blacklist', environment?.app_default_feature?.is_org_enabled);
      setRoleStatus(status);
    };
    getRolePermissionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom?.globalDependencyLoading, userRolePermissionListStoreData]);

  const handleBlacklistSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    SearchBlacklistData(setHandleState, event);
  };

  React.useEffect(() => {
    setHandleState((prevState) => ({ ...prevState, optionData: blacklistFilterDataList }));
  }, []);

  const handleFilter = async (filteredData: any) => {
    FilterBlacklistData(setHandleState, filteredData);
  };

  const handleResultModal = async (uuid: string, blacklistValue: number) => {
    await BlacklistResultModalData(setHandleState, uuid, blacklistValue);
  };

  const fetchMoreBlacklistData = React.useCallback(
    async (setIsFetching: React.Dispatch<React.SetStateAction<boolean>>) => {
      data?.data?.length && data?.data?.length < data?.count && BlacklistScrollFunction(setHandleState, setData, setIsFetching, handleState);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.data?.length, handleState?.queryParams?.filter, debouncedSearchValue, handleState?.queryParams?.offset]
  );

  const columnHelper = createColumnHelper<BlacklistRowObjectDTO>();
  const table = useReactTable({
    data: data.data,
    // data: data?.data,
    columns: handleState?.columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });

  const columnData = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <div className="ml-5 flex items-center pe-4 ps-2">
          <input
            type="checkbox"
            id="select-all"
            checked={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
            className="size-[0.9rem] cursor-pointer accent-brand-500 dark:accent-purple-600"
          />
        </div>
      ),
      cell: ({ row }) => (
        <div className="ml-5 flex items-center pe-4 ps-2">
          <input
            type="checkbox"
            id={`row-${row.id + 1}`}
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
            className="size-[0.9rem] cursor-pointer border border-[#D0D5DD] accent-brand-500 dark:accent-purple-600"
          />
        </div>
      ),
    }),
    columnHelper.accessor('blacklist_name', {
      id: 'blacklist_name',
      enableSorting: false,
      header: () => <div className="text-xs font-medium text-[#667085]">Monitor</div>,
      cell: (info) => <p className="w-full truncate text-[0.65rem] font-semibold text-[#2B3674] dark:text-gray-200">{info.getValue()}</p>,
    }),
    columnHelper.accessor('blacklist_type', {
      id: 'blacklist_type',
      enableSorting: false,
      header: () => <div className="text-xs font-medium !text-[#667085]">Type</div>,
      cell: (info) => {
        return (
          <>
            <p className="w-full truncate text-xs text-[#667085] dark:text-gray-400">{info.getValue()}</p>
          </>
        );
      },
    }),
    columnHelper.accessor('groups_list', {
      id: 'groups_list',
      enableSorting: false,
      header: () => <div className={`relative text-xs font-medium text-[#667085]`}>Group</div>,
      cell: (row) => {
        return (
          <>
            <BlacklistGroupTags
              groupTags={row.getValue()}
              row={row?.row?.original}
              fetchList={() => {}}
              grouplistTrigger={grouplistTrigger}
              fetchGroupList={fetchGroupList}
              hanleDeleteGroupFromSenderList={hanleDeleteGroupFromSenderList}
            />
          </>
        );
      },
    }),
    columnHelper.accessor('no_of_blacklist_count', {
      id: 'no_of_blacklist_count',
      enableSorting: false,
      header: () => <div className="relative text-xs font-medium !text-[#667085]">No of Blacklists</div>,
      cell: (info) => {
        const blacklistValue = info.getValue();
        const defaultValue = info.row?.original.total_count;
        const textColor = blacklistValue === 0 ? '#4CBE67' : '#F50B0B';
        return (
          <p
            className={`w-full truncate text-xs font-semibold ${blacklistValue === 0 ? 'cursor-default' : 'cursor-pointer'}`}
            style={{ color: textColor }}
            onClick={() => {
              handleResultModal(info?.row.original?.uuid, blacklistValue);
            }}
          >
            {blacklistValue}/{defaultValue}
          </p>
        );
      },
    }),

    columnHelper.accessor('cdate', {
      id: 'cdate',
      header: () => <div className="text-xs font-medium text-[#667085]">Added On</div>,
      cell: (info) => {
        const date = moment(info.getValue());
        const formattedDate = date.format('DD MMM YYYY');
        return <p className="w-full truncate text-xs font-semibold text-[#2B3674] dark:text-gray-200">{formattedDate}</p>;
      },
    }),
    columnHelper.accessor('last_checked', {
      id: 'last_checked',
      header: () => <div className="text-xs font-medium text-[#667085]">Last Checked</div>,
      cell: (info) => {
        const utcDateString = info.getValue();
        const userTimeZone = 'Asia/Kolkata';
        const utcDate = new Date(utcDateString);
        const localDate = utcDate.toLocaleString('en-US', {
          timeZone: userTimeZone,
        });
        const date = moment(new Date(localDate));
        return (
          <div className="group relative w-full text-sm font-semibold">
            <span className="text-[ #2B3674] w-full truncate text-xs font-semibold">{calculateTimeAgo(info.getValue())}</span>
            <ArrowedTooltip className={`hidden group-hover:block`} toolTipId={`row-cdate-tooltip-${info?.row?.id}`} data={date.format('DD MMM YYYY, hh:mm A')} />
          </div>
        );
      },
    }),
    columnHelper.accessor('blacklist_status', {
      id: 'blacklist_status',
      enableSorting: false,
      header: () => <div className="relative text-xs font-medium text-[#667085]">Status</div>,
      cell: (info) => {
        return (
          <div>
            {info.getValue() === 0 && (
              <div className="flex w-min items-center gap-0.5 rounded-full bg-draft-bg px-2 py-1">
                <MdOutlineWatchLater className="text-xs text-draft-color" />
                <h5 className={`text-xs font-semibold text-draft-color status-index-${info?.row?.id}`}>Pending</h5>
              </div>
            )}
            {info.getValue() === 1 && (
              <div className="flex w-min items-center gap-1 rounded-full bg-[#ECFDF3] px-2 py-1 text-[.7rem] font-semibold text-[#029A48]">
                <IoMdCloudDone color="#12B76A" />
                <span className={`status-index-${info?.row?.id}`}>Active</span>
              </div>
            )}
            {info.getValue() === 2 && (
              <div className="flex w-min items-center gap-1 rounded-full bg-[#F2F4F7] px-2 py-1 text-[.65rem] font-semibold text-[#344054]">
                <FaCirclePause />
                <span className={`status-index-${info?.row?.id}`}>Paused</span>
              </div>
            )}
            {info.getValue() === 3 && (
              <div className="flex w-min items-center gap-1 rounded-full bg-[#ECFDF3] px-2 py-1 text-[.65rem] font-semibold text-[#029A48]">
                <FaCirclePause />
                <span className={`status-index-${info?.row?.id}`}>Resume</span>
              </div>
            )}
          </div>
        );
      },
    }),
    columnHelper.accessor('uuid', {
      id: 'edit',
      header: () => <div className="relative font-thin"></div>,
      cell: (row) => {
        const blacklistStatus = row.row.original.blacklist_status;
        return (
          <div onClick={(event) => event?.stopPropagation()}>
            <BlacklistRowSettings
              status={1}
              id={row.row.id}
              uuidRow={row.getValue()}
              rowData={row.row.original}
              table={table}
              setData={setData}
              //   setLoad={setLoad}
              blacklistStatus={blacklistStatus}
              handleListPause={handleListPause}
              handleListDelete={handleListDelete}
              handleListResume={handleListResume}
              handleListDownload={handleListDownload}
            />
          </div>
        );
      },
    }),
  ];

  React.useEffect(() => {
    BlacklistTableData(setHandleState, table);
  }, [table.getSelectedRowModel()]);

  const handleListDelete = async (uuid?: Array<string>) => {
    await BlacklistDeleteListData(setHandleState, handleState, uuid, table);
  };

  const handleListPause = async (uuid?: Array<string>) => {
    await BlacklistPauseListData(setHandleState, handleState, uuid, table);
  };

  const handleListResume = async (uuid?: Array<string>) => {
    await BlacklistResumeListData(setHandleState, handleState, uuid, table);
  };

  const handleListRecheck = async (uuid?: Array<string>) => {
    await BlacklistRecheckListData(setHandleState, handleState, uuid, table);
  };

  const handleListDownload = async (uuid?: Array<string>) => {
    await BlacklistDownloadListData(uuid, handleState);
  };

  const handleOptions = (option: string) => {
    BlacklistOptionHandler(option, handleListDelete, handleListDownload, handleListPause, handleListResume, handleListRecheck);
  };

  //---------------------------------------------------------------------------------------------------------------//

  return (
    <>
      <div className={`mt-2.5 w-full rounded-lg bg-white pb-2 pt-2.5 shadow-sm  dark:bg-darkThree dark:text-white sm:overflow-x-auto `}>
        <div className="relative mb-3 flex items-center py-0.5 sm:gap-32 md:px-10 lg:gap-32 lg:px-3 xl:justify-between">
          <div className="flex gap-2.5">
            <div
              className="flex items-center gap-2 rounded-md border px-3 py-1.5 outline 
                         outline-0 focus-within:outline-2 focus-within:outline-brand-400 
                        dark:border-darkBorder md:w-[13rem] lg:w-[26rem]"
              onClick={() => BlacklistSearchRef.current.focus()}
            >
              <FiSearch className="size-4 cursor-pointer" />
              <input
                ref={BlacklistSearchRef}
                type="text"
                placeholder="Search"
                id="blacklist-search-input"
                className="w-full text-sm font-bold
                text-gray-800 caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:bg-darkThree dark:placeholder:text-white "
                onChange={handleBlacklistSearch}
              />
            </div>
            <CustomFilter optionData={handleState?.optionData} handleFilter={handleFilter} />
          </div>
          <div className="flex items-center gap-1">
            {/* Role Access for Creating Ticket */}
            {/* {roleStatus?.is_owner && (
              <button
                className="flex w-[6.5rem] items-center justify-center gap-0.5 rounded-md bg-brand-500 py-1.5 text-sm font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                onClick={() => navigate('/true-verifier')}
              >
                <FaRegNoteSticky className="float-right text-lg" /> <span className="text-nowrap text-[11px]">View Tickets</span>
              </button>
            )} */}
            {/* Role Access for Adding IP or Doamin */}
            {(roleStatus?.organization_owner || roleStatus?.organization_access_list?.add) && (
              <button
                id="blacklist-add-btn"
                className=" flex w-[6.5rem] items-center justify-center gap-0.5 rounded-md bg-brand-500 py-1.5 text-sm font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                onClick={
                  () => setHandleState((prevState) => ({ ...prevState, showCreateModal: true }))
                  // setShowCreateModal(true)
                }
              >
                <MdAdd className="float-right text-lg" /> <span className="text-nowrap text-[11px]">Add</span>
              </button>
            )}
          </div>
        </div>
        <div className="">
          {!handleState?.showLoading ? (
            <div className="h-[80vh] overflow-y-auto overflow-x-scroll scroll-smooth xl:overflow-x-hidden" ref={tableRef}>
              <table className=" sm:w-[150%] lg:w-[120%] xl:w-full">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header, index, arr) => {
                        return (
                          <th
                            key={header.id}
                            colSpan={header.colSpan}
                            onClick={header.column.getToggleSortingHandler()}
                            className={`
                       
                        mb-2 cursor-pointer border-y border-gray-200 bg-gray-50 py-4 text-start text-column dark:border-white/10 dark:bg-darkOne`}
                          >
                            <div className="text-[11px]">
                              {flexRender(header.column.columnDef.header, header.getContext())}
                              {{
                                asc: '',
                                desc: '',
                              }[header.column.getIsSorted() as string] ?? null}
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody className="divide-y-[1px] dark:divide-darkBorder">
                  {data?.data?.length ? (
                    table.getRowModel().rows.map((row) => {
                      return (
                        <tr key={row.id} className="hover:bg-hover dark:hover:bg-darkOne">
                          {row.getVisibleCells().map((cell, i) => {
                            return (
                              <td
                                key={cell.id}
                                className={`border-white/0 py-4 
                               text-heading dark:text-white 
                               ${i === 1 ? 'w-56' : i === 2 ? 'w-52' : i === 3 ? 'w-[521px]' : i === 4 ? 'w-32' : i === 5 ? 'w-36' : i === 6 ? 'w-32' : i === 7 ? 'w-44' : 'w-11'}
                               `}
                              >
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  ) : (
                    <tr></tr>
                  )}
                </tbody>
              </table>
              <InfiniteScrollComponent
                loadMore={fetchMoreBlacklistData}
                loader={
                  <TableLoader
                    width="w-full"
                    border="border border-gray-100"
                    column={10}
                    row={1}
                    skeletonHeight="h-2"
                    padding="px-5 py-4"
                    columnWidth="4% 22% 10% 10% 10% 10% 10% 10% 10% 4%"
                  />
                }
              />
            </div>
          ) : (
            <TableLoader
              width="w-full"
              border="border border-gray-100"
              column={10}
              row={13}
              skeletonHeight="h-2"
              padding="px-5 py-4"
              tableViewBg={{ headBg: 'bg-gray-50' }}
              columnWidth="4% 22% 10% 10% 10% 10% 10% 10% 10% 4%"
            />
          )}
        </div>
        {handleState?.showGlobalOpt && <GlobalOption handleOptions={handleOptions} selectedCount={handleState?.selectedData} />}
      </div>
      <AddBlacklistModal
        isOpen={handleState?.showCreateModal}
        onClose={() => {setHandleState((prevState) => ({ ...prevState, showCreateModal: false }));
        dispatch(getErrorMessage({ group: '', black_list: '', frequency: { value: '', type: 0 } }));
    }}
        onSuccess={() => setHandleState((prevState) => ({ ...prevState, fetchTrigger: !handleState?.fetchTrigger }))}
      />
      <ResultModal
        isOpen={handleState?.isOpen}
        onClose={() => {
          setHandleState((prevState) => ({ ...prevState, isOpen: false }));
        //   dispatch(getErrorMessage({ group: '', black_list: [], frequency: { value: '', type: 0 } }));
        }}
        modalData={handleState?.modalData}
      />
    </>
  );
};

export default Blacklist;
