import { setAndGetQueryParams, jwtDecrypt } from 'utility/utils';
import { FetchGroupListApi, FetchListApi, FetchSenderEmailListDownloadApi, FetchSingleGetApi, MultiArchieveSenderAccountApi } from '../api-handler/email-account-api';
import { setSenderAccountsDataList } from 'redux-store/sender-accounts';
import { bottomActionBarListIV, tableRowActionItemsListIV } from 'dto/initial-value/views/sender-accounts';
import { setTableListData } from 'redux-store/common-table';
import { TableColumnData } from 'lib/tables/sender-accounts/email-accounts-list';
import * as XLSX from 'xlsx';

export const FetchListEvent = async ({ dispatch, queryParams, commonTableList }: any) => {
  try {
    const query = setAndGetQueryParams([
      { key: 'limit', value: queryParams?.limit },
      { key: 'offset', value: queryParams?.offset },
      { key: 'search', value: commonTableList?.search },
      { key: 'filter', value: commonTableList?.filter },
    ]);
    const apiResponse = await FetchListApi(query);
    const decodeResponse = jwtDecrypt(apiResponse?.data);
    dispatch(setSenderAccountsDataList({ data_list: decodeResponse?.data || [], total_count: decodeResponse?.total_records?.count || 0 }));
  } catch (error) {
    console.log('FetchListEvent Error:', error);
  } finally {
  }
};

export const FetchGroupListEvent = async ({ dispatch }: any) => {
  try {
    const apiResponse = await FetchGroupListApi();
    dispatch(setSenderAccountsDataList({ group_data_list: apiResponse?.data?.data || [], group_total_count: apiResponse?.data?.total_records?.count || 0 }));
  } catch (error) {
    console.log('FetchGroupListEvent Error:', error);
  } finally {
  }
};

export const HandleRowDataEditEvent = async ({ rowUuid, setState }: any) => {
  try {
    const apiResponse = await FetchSingleGetApi(rowUuid);
    const decryptdata = await jwtDecrypt(apiResponse?.data);
    setState((prev: any) => ({ ...prev, editGetData: decryptdata }));
  } catch (error) {
    console.error('HandleRowDataEdit Error:', error);
  } finally {
  }
};

export const HandleRowDownloadEvent = async ({ rowUuid, setState }: any) => {
  try {
    const apiResponse = await FetchSenderEmailListDownloadApi(rowUuid);
    const downloadData = apiResponse?.data?.map((item: any, index: number) => ({
      'S.No': index + 1,
      Name: item?.name,
      'First Name': item?.first_name,
      'Last Name': item?.last_name,
      Email: item?.account_details?.email,
      'Account Status': item?.account_details?.mailer_status === 1 ? 'Connected' : 'Disconnected',
      'Daily Limit': item?.campaign_settings?.daily_limit,
      'Limit per Day': item?.campaign_settings?.limit_sent_per_day,
      Variant: item?.variant,
    }));
    handleExportData(downloadData);
  } catch (error) {
    console.error('HandleDownload Error:', error);
  } finally {
  }
};
export const handleExportData = async (data: any) => {
  try {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sender Accounts data');
    const wbout = XLSX.write(wb, { bookType: 'csv', type: 'binary' });
    const blob = new Blob([wbout], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'sender_accounts_report.csv';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  } catch (error) {}
};

export const HandleRowArchieveEvent = async ({ rowUuid, rowDetails, setState }: any) => {
  try {
    setState((prev: any) => ({
      ...prev,
      archieveConfirm: {
        isOpen: true,
        headerContent: 'Do you want to archive user',
        bodyContent: 'Archive:',
        bodyRedContent: rowDetails?.account_details?.email,
        descriptionContent: 'Do you confirm archiving user from Sender accounts',
        uuid: [rowUuid],
      },
    }));
  } catch (error) {
    console.error('HandleRowDataEdit Error:', error);
  } finally {
  }
};

export const HandleMultiSelectArchieveEvent = async ({ multiSelectIds, setState }: any) => {
  try {
    setState((prev: any) => ({
      ...prev,
      archieveConfirm: {
        isOpen: true,
        headerContent: 'Do you want to archive user',
        bodyContent: 'Archive:',
        bodyRedContent: multiSelectIds?.length,
        descriptionContent: 'Do you confirm archiving user from Sender accounts',
        uuid: multiSelectIds,
      },
    }));
  } catch (error) {
    console.error('HandleMultiSelectArchieveEvent Error:', error);
  } finally {
  }
};

export const CancelArchievePopupEvent = async ({ setState }: any) => {
  try {
    if (setState) {
      setState((prev: any) => ({
        ...prev,
        archieveConfirm: {
          ...prev.archieveConfirm,
          isOpen: false,
        },
      }));
    }
  } catch (error) {
    console.error('CancelArchievePopupEvent Error:', error);
  } finally {
  }
};

export const HandleMultiArchieveEvent = async ({ state, table, setState }: any) => {
  try {
    const apiResponse = await MultiArchieveSenderAccountApi({ id_list: state?.archieveConfirm?.uuid });
    if (!apiResponse?.error) {
      setState((prev: any) => ({
        ...prev,
        archieveConfirm: {
          isOpen: false,
          headerContent: '',
          bodyContent: '',
          bodyRedContent: '',
          descriptionContent: '',
          uuid: [],
        },
      }));
      table.resetRowSelection();
    }
  } catch (error) {
    console.error('HandleRowDataEdit Error:', error);
  } finally {
  }
};

export const UpdateTableRowActionItemsListEvent = async ({ functionMap, dispatch }: any) => {
  try {
    const tableRowActionItemsList = tableRowActionItemsListIV?.map((item: any) => {
      return { ...item, functioning: functionMap[item?.functioning] };
    });
    dispatch(setTableListData({ table_row_action_items_list: tableRowActionItemsList }));
  } catch (error) {
    console.error('UpdateTableRowActionItemsList Error:', error);
  } finally {
  }
};

export const BottomActionBarListEvent = async ({ functionMap, setState }: any) => {
  try {
    const bottomActionBarList = bottomActionBarListIV?.map((item: any) => {
      return { ...item, functioning: functionMap[item?.functioning] };
    });
    setState((prev: any) => ({ ...prev, bottomActionBarList: bottomActionBarList }));
  } catch (error) {
    console.error('UpdateTableRowActionItemsList Error:', error);
  } finally {
  }
};

export const UpdateTableColumnDataEvent = async ({ columnHelper, table, setState }: any) => {
  try {
    const columnData = TableColumnData({ columnHelper, table });
    setState((prev: any) => ({ ...prev, tableColumns: columnData }));
  } catch (error) {
    console.error('UpdateTableColumnDataEvent Error:', error);
  } finally {
  }
};
