/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter, ModalCloseButton, ModalHeader } from '@chakra-ui/modal';
import Button from 'components/common/Button';
import { mergeCardsPropsTypeDto } from 'dto/types/views/unmatched';

import { useAppDispatch } from 'redux-store';
import { handleMerge } from 'shared/unmatch/event-handler/unmatch-event';

const UnmatchMergeConfirmPopUp = (props: mergeCardsPropsTypeDto) => {
  const { isOpen, onClose, secondaryEmailData, primaryEmailData } = props;
  const dispatch = useAppDispatch();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'md'} isCentered={true}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Merge Confirmation</ModalHeader>
        <ModalCloseButton />
        <hr />
        <ModalBody>
          <div className="p-3">
            <p className="text-sm text-grayish-blue">
              Do you want to merge <span className="font-bold">{secondaryEmailData?.platform_message_details?.from?.address}</span> with <span className="font-bold">{primaryEmailData?.email}</span>?
            </p>
          </div>
        </ModalBody>
        <hr />
        <ModalFooter>
          <div className="mt-3 flex w-11/12 gap-3">
            <Button name="Cancel" customClass="w-full !bg-white !text-blueSecondary !border-blueSecondary font-semibold !text-[12px]" onClick={onClose} />
            <Button name="Merge" customClass="w-full font-semibold !text-[12px]" onClick={() => handleMerge(primaryEmailData, secondaryEmailData, onClose, dispatch)} />
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UnmatchMergeConfirmPopUp;
