import { VERIFY_PROMO_CODE } from "api/endpoints/promoCodeEndpiont";
import { BaseApi } from "api/services/base-api";

export const VerifyPromoCodeApi = async (data:{promo_code:string}) => {
    try {
        const response = await BaseApi.post(VERIFY_PROMO_CODE, data);
        return response;
      } catch (error) {
        console.error('GenerateAccessTokenApi Error:', error);
        throw error;
      }
}