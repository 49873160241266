import {
  bandsListEditAndConfirmDataDTO,
  brandsQueryParamsDTO,
  brandsTableDataDTO,
  brandStateDataDTO,
  createBrandFormDataDTO,
  createModalHandleStateDTO,
  tableDataDTO,
} from 'dto/types/views/brands';

export const brandsColumnWidth = ['w-[5%]', 'w-[15%]', 'w-[15%]', 'w-[15%]', 'w-[15%]', 'w-[15%]', 'w-[15%]', 'w-[5%]'];

export const bandsListEditAndConfirmDataIV: bandsListEditAndConfirmDataDTO = {
  newBrandModal: false,
  loading: false,
  deleteloading: false,
  roleStatus: null,
  editBrandData: {
    uuid: '',
    name: '',
    icp: '',
    pain_points: '',
    solution: '',
    geography_target: '',
    about_brand_details: '',
  },
  confirm: {
    isOpen: false,
    headerContent: '',
    bodyContent: '',
    bodyRedContent: '',
    descriptionContent: '',
    uuid: [],
  },
};

export const brandsTableDataIV: brandsTableDataDTO = {
  data: [],
  count: 0,
  loading: false,
};

export const brandsQueryParamsIV: brandsQueryParamsDTO = {
  limit: 25,
  offset: 0,
  sort: { columnName: '', order: '' },
  search: '',
  filter: '',
};

export const tableDataIV: tableDataDTO = {
  selectedIds: [],
  globalOpt: false,
};

export const brandStateDataIV: brandStateDataDTO = {
  handleState: bandsListEditAndConfirmDataIV,
  data: brandsTableDataIV,
  queryParams: brandsQueryParamsIV,
  tableSelection: tableDataIV,
};

export const createModalHandleStateIV: createModalHandleStateDTO = {
  loading: false,
  brandData: null,
};

export const createBrandFormDataIV: createBrandFormDataDTO = {
  uuid: '',
  name: '',
  icp: '',
  pain_points: '',
  solution: '',
  geography_target: '',
  about_brand_details: '',
};
