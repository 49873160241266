import { PayloadAction, createSlice } from '@reduxjs/toolkit';
const initialState: any = {
  error: null,
  loading: false,
  grouplist: [],
  searchList: [],
  toogleState: [],
  accountType: '',
  domain_list: [],
  domain_uuid: '',
  trigger:false,
  sender_accounts_page: 'email',
  columnRowOptions: {
    emailOption: [],
    linkedInOption: [],
  },
  numbersToBuy: [],
  countryCode: '',
};

const senderGroupListData = createSlice({
  name: 'senderGroupList',
  initialState,
  reducers: {
    setSenderFetchData: (state, action: PayloadAction<any>) => {
      state.grouplist = action.payload.finalData;
      state.searchList = action.payload.finalData;
      state.toogleState = action.payload.states;
    },
    setAccountType: (state, action: PayloadAction<any>) => {
      state.accountType = action.payload;
    },
    setDomainList: (state, action) => {
      state.domain_list = action.payload;
    },
    setDomainUUID: (state, action) => {
      state.domain_uuid = action.payload;
    },
    setSenderAccountsPage: (state, action) => {
      state.sender_accounts_page = action.payload;
    },
    setColumnRowOptions: (state, action) => {
      state.columnRowOptions = { ...state.columnRowOptions, ...action.payload };
    },
    setNumbersToBuy: (state, action) => {
      state.numbersToBuy = action.payload;
    },
    setCountryCode: (state, action: PayloadAction<any>) => {
      state.countryCode = action.payload;
    },
    setTrigger:(state)=>{
      state.trigger = !state.trigger
    }
  },
});

export const { setSenderFetchData, setTrigger, setAccountType, setDomainList, setDomainUUID, setSenderAccountsPage, setColumnRowOptions, setNumbersToBuy, setCountryCode } =
  senderGroupListData.actions;
export default senderGroupListData.reducer;
