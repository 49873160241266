import { generateDateInterval } from "utility/utils";

export const blacklistFilterDataList = [
    {
      id: '1',
      name: 'Status',
      uniqueColumnName: 'blacklist_status',
      conditions: [
        { id: '1', name: 'Is', uniqueName: 'is' },
        { id: '2', name: 'Is not', uniqueName: 'not' },
      ],
      resultValue: [
        { id: '1', name: 'Pending', uniqueName: 0 },
        { id: '2', name: 'Active', uniqueName: 1 },
        { id: '3', name: 'Paused', uniqueName: 2 },
        // { id: '4', name: 'Resume', uniqueName: '3' },
      ],
    },
    {
      id: '2',
      name: 'Added On',
      uniqueColumnName: 'cdate',
      conditions: [{ id: '1', name: 'Is', uniqueName: 'is' }],
      resultValue: [
        { id: '1', name: 'Today', uniqueName: generateDateInterval(0, 0) },
        { id: '2', name: 'Yesterday', uniqueName: generateDateInterval(1) },
        { id: '3', name: 'Last 7 days', uniqueName: generateDateInterval(7) },
        { id: '4', name: 'Last 30 days', uniqueName: generateDateInterval(29) },
        { id: '5', name: 'This week', uniqueName: generateDateInterval('week', 0) },
        { id: '6', name: 'This month', uniqueName: generateDateInterval('month', 0) },
      ],
    },
    {
      id: '3',
      name: 'Last Checked',
      uniqueColumnName: 'last_checked',
      conditions: [{ id: '1', name: 'Is', uniqueName: 'is' }],
      resultValue: [
        { id: '1', name: 'Today', uniqueName: generateDateInterval(0, 0) },
        { id: '2', name: 'Yesterday', uniqueName: generateDateInterval(1) },
        { id: '3', name: 'Last 7 days', uniqueName: generateDateInterval(7) },
        { id: '4', name: 'Last 30 days', uniqueName: generateDateInterval(29) },
        { id: '5', name: 'This week', uniqueName: generateDateInterval('week', 0) },
        { id: '6', name: 'This month', uniqueName: generateDateInterval('month', 0) },
      ],
    },
    {
      id: '4',
      name: 'Type',
      uniqueColumnName: 'blacklist_type',
      conditions: [
        { id: '1', name: 'Is', uniqueName: 'is' },
        { id: '2', name: 'Is not', uniqueName: 'not' },
      ],
      resultValue: [
        { id: '1', name: 'Domain', uniqueName: 'Domain' },
        { id: '2', name: 'Ip', uniqueName: 'IPv4' },
      ],
    },
  ];