export class SessionStorage {

    public static setItem(key: string, value: object | string): boolean {
      sessionStorage.setItem(key, JSON.stringify(value));
      return true;
    }
  
    public static getItem(key: string): object | string {
      try {
        return JSON.parse(sessionStorage.getItem(key) ?? '');
      } catch (error) {
        return '';
      }
    }
  
    public static getString(key: string): string {
      try {
        return sessionStorage.getItem(key) ?? '';
      } catch (error) {
        return '';
      }
    }
  
    public static setString(key: string, value: string): boolean {
      sessionStorage.setItem(key, value);
      return true;
    }
  
    public static clearItem(key: string): void {
      sessionStorage.removeItem(key);
    }
  
    public static clear(): void {
      sessionStorage.clear();
    }
  }