// Custom components
import React, { useState } from 'react';
import { MdClose } from 'react-icons/md';

function TagsField(props: {
  id?: string;
  label?: string;
  className?: string;
  placeholder?: string;
  placeholderTags?: { name: string; id: number }[];
  onChange?: any;
  pattern?: any;
  inputId?: string;
  extra?: string;
  [x: string]: any;
  tagsClass?: string;
  parentClassName ?: string;
}) {
  const { label, id, className, onChange, placeholderTags, placeholder, extra, tagsClass, pattern, parentClassName, inputId = 'username', ...rest } = props;


  const [tags, setTags] = useState([]);
  const [inputText, setInputText] = useState('');
  const inputRef = React.useRef(null);
  React.useEffect(() => {
    if (placeholderTags) {
      setTags(placeholderTags);
    }
  }, [placeholderTags]);

  const validateAndUpdate = (value: any) => {
    const data = value ? value?.trim() : '';
    const patternTest = pattern?.test(data);
    return patternTest;
  };
  const handleFocus = () => {
    if (inputRef?.current) inputRef?.current?.focus();
  };

  const keyPress = async (e: any) => {
    if (e.keyCode === 13 && e.target.value) {
      const test = pattern ? validateAndUpdate(e.target.value) : true;
      if (test) {
        setTags([
          ...tags,
          {
            name: e.target.value,
            id: tags.length === 0 ? 1 : tags[tags.length - 1].id + 1,
          },
        ]);
        setInputText('');
      }
    }
    // Backspace deleting code activates only when current input is empty
    if (!inputText && e.keyCode === 8) {
      const tempTag = tags.slice();
      tempTag.pop();
      setTags(tempTag);
      setInputText('');
      onChange(tempTag);
    }
  };

  const OnPasteTrigger = (e: any) => {
    e.preventDefault();
    const pasteText = e.clipboardData.getData('text');
    let pasteList = pasteText.split(/[\s,]+/).map((item: string, index: number) => {
      return { name: item, id: tags.length + (index + 1) };
    });
    pasteList = pattern ? pasteList.filter((item: any) => validateAndUpdate(item.name)) : pasteList;
    setTags([...tags, ...pasteList]);
    setInputText('');
    onChange([...tags, ...pasteList]);
  };

  const handleBlur = () => {
    const test = pattern ? validateAndUpdate(inputText) : true;
    if (test) {
      const finalTags = [
        ...tags,
        {
          name: inputText,
          id: tags.length === 0 ? 1 : tags[tags.length - 1].id + 1,
        },
      ];
      setTags(finalTags);
      setInputText('');
      onChange(finalTags);
    }
  };
  return (
    <div className = {parentClassName}>
      <label htmlFor={id} className={'mb-2 text-sm font-bold'}>
        {label}
      </label>
      <div
        className={`h-stretch dark:bg-darkThree relative flex min-h-10 cursor-text flex-row flex-wrap rounded-xl border border-gray-200 bg-none p-3 focus:border-brand-200 dark:!border-white/10 ${extra}`}
        {...rest}
        onBlur={handleBlur}
        onClick={handleFocus}
      >
        {tags?.length ? (
          <div id="email-lists" className="flex max-h-32 w-full  flex-wrap overflow-y-auto">
            {tags.map((tag, index) => {
              return (
                <div
                  className={`align-center mb-1.5 mr-1.5 flex h-7 cursor-default select-none items-center rounded-xl bg-brand-500 px-3 py-0.5 text-sm font-medium text-white hover:bg-brand-600  ${
                    tagsClass || ''
                  }`}
                  key={index}
                  id={`tag-${index}`}
                >
                  <p className="mr-1 w-full">{tag.name}</p>
                  <MdClose
                    className="cursor-pointer justify-end hover:scale-150"
                    onClick={() => {
                      setTags([...tags.filter((element) => element.id !== tag.id)]);
                      onChange([...tags.filter((element) => element.id !== tag.id)]);
                    }}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <></>
        )}
        <input
          autoComplete="off"
          onPaste={(e: any) => OnPasteTrigger(e)}
          onKeyUp={(e: any) => {
            if (e.keyCode === 13) {
              return onChange(tags);
            }
          }}
          type="text"
          onKeyDown={(e) => keyPress(e)}
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          id={inputId}
          placeholder={placeholder}
          ref={inputRef}
          className={`username flex w-full border-none bg-none p-0 text-sm outline-none dark:!bg-darkThree ${className}`}
        />
        {/* !bg-navy-800 */}
        {/* <button onClick={() => { setTags(initialTags); onChange([]) }}
          className="absolute right-0 bottom-0 mx-3 my-3 text-2xl duration-300 hover:scale-105 active:text-red-700 rounded-xl hover:text-red-500 text-red-400">
            <BiReset />
          </button> */}
      </div>
    </div>
  );
}

export default TagsField;
