export const UNMATCH_BASE_URL = `/unmatched/inbox`;
export const LINKED_GROUP_URL = `/groups/linked/with/unmatch_inbox`;
export const MAIL_IGNORE_URL = `/unmatched/inbox/ignore`;
export const FILTER_BY_RULE_URL = `/unmatched/inbox/by/rule`;
export const LINKED_GROUP_DELETE_URL = `/groups/delete/into/linked/with/unmatch_inbox`;
export const GROUP_BASE_URL = `/group`;
export const SENDER_ACCOUNT_LIST_URL = `/sender-accounts/email/list`;
export const CAMPAIGN_PROSPECT_LIST_URL = `/unmatched/inbox/get/campaign/prospects`;
export const THREAD_URL = `unmatched/inbox/thread`;
export const MERGE_URL = `/unmatched/inbox/merge`;
