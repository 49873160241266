/* eslint-disable react-hooks/rules-of-hooks */
import { useFieldValidator } from 'hooks/useFormValidation';
  import { singleVerifyValidationSchema } from 'lib/validator-schema/singleEmailVerifySchema';
import { getErrorMessage } from 'redux-store/custom';

export const HandleEmailFormChangeEvent = async ({ e, dispatch }: any) => {
    try {
        const validate = await useFieldValidator(singleVerifyValidationSchema, e.target.id, e.target.value);
        dispatch(getErrorMessage(validate?.message));
      } catch (error) {
        console.error('HandleFormChangeEvent Error:', error);
      }
};