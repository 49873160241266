import React from 'react';
import { FiSearch } from 'react-icons/fi';
import { MdAdd } from 'react-icons/md';

import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useAppDispatch, useAppSelector } from 'redux-store';
import useDebounce from 'hooks/useDebounce';
import { checkRolePermissionStatus } from 'utility/utils';
import { brandsFilterDataList } from 'lib/page-filters/brands';
// import { removeBrandInput, setIds } from 'redux-store/campaign-v2';
import ArrowedTooltip from 'components/common/ArrowedTooltip';
import CustomFilter from 'components/common/customFilter';
import Button from 'components/common/Button';
import InfiniteScrollComponent from 'components/common/infinityScroll';
import TableLoader from 'components/common/Loaders/TableViewLoader';
import MultiselectGlobalOpt from 'components/common/muitiSelectcomponent/MultiselectGlobalOpt';
import ConfirmationPopup from 'components/common/confirmationPopup/ConfirmationPopup';
import NewBrandModal from '../components/NewBrandModal';
import CommonRowSettings from 'components/common/commonRowSetting/CommonRowSettings';
import {
  brandCancelDeleteDataEvent,
  brandConfirmDeleteFunctionEvent,
  BrandRowSettingOptionsEvent,
  HandleAddNewBrandEvent,
  handleBrandFilterEvent,
  HandleFetchBrandListEvent,
  HandleFetchBrandScrollDataListEvent,
  multiSelectOptionsEvent,
  searchBrandListEvent,
} from 'shared/brands/event-handler/brands-event';
import { bandsListEditAndConfirmDataIV, brandsColumnWidth, brandsQueryParamsIV, brandsTableDataIV, tableDataIV } from 'dto/initial-value/views/brands';
import { brandsTableDataDTO } from 'dto/types/views/brands';

const BrandList = () => {
  const columnWidth = brandsColumnWidth;

  // const [brandsStateData, setBrandsStateData] = React.useState(brandStateDataIV);

  const [options, setOptions] = React.useState([]);
  const dispatch = useAppDispatch();
  const [brandData, setBrandData] = React.useState<brandsTableDataDTO>(brandsTableDataIV);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tableSelection, setTableSelection] = React.useState(tableDataIV);

  const [queryParams, setQueryParams] = React.useState(brandsQueryParamsIV);

  const debouncedSearchValue = useDebounce(queryParams?.search, 300);

  const [optionData, setOptionData] = React.useState<any[]>([]);

  const [handleState, setHandleState] = React.useState(bandsListEditAndConfirmDataIV);

  const custom = useAppSelector((state) => state?.custom);
  // const userRolePermissionListStoreData = useAppSelector((state) => state.auth.loggedUserRolePermissionList);
  const userRolePermissionListStoreDataV2 = useAppSelector((state) => state?.globalsettings?.globalConfiguration?.user_organization_role_details);
  // console.log("userRolePermissionListStoreDataV2",userRolePermissionListStoreDataV2)
  const { environment } = useAppSelector((state) => state.globalsettings);

  React.useEffect(() => {
    setOptionData(brandsFilterDataList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilter = async (filteredData: any) => {
    handleBrandFilterEvent(filteredData, setQueryParams);
  };

  const handleBrandConfirmCancel = () => {
    brandCancelDeleteDataEvent(setHandleState);
  };

  const handleBrandSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    searchBrandListEvent(setQueryParams, event);
  };

  const handleBrandConfirmSubmitDelete = async () => {
    await brandConfirmDeleteFunctionEvent(setQueryParams, setHandleState, handleState, custom?.flagStatus, dispatch);
  };

  const multiSelectOptions = multiSelectOptionsEvent(setQueryParams, tableSelection?.selectedIds, custom?.flagStatus, dispatch);

  const brandRowSettingOptions = BrandRowSettingOptionsEvent(setQueryParams, setHandleState, dispatch);

  React.useEffect(() => {
    const finalBradRowSettingOption = handleState?.roleStatus?.organization_owner
      ? brandRowSettingOptions
      : brandRowSettingOptions.filter((item) => handleState?.roleStatus?.hasOwnProperty(item?.accessName) && handleState?.roleStatus?.hasOwnProperty(item.accessName));

    setOptions(finalBradRowSettingOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleState?.roleStatus]);

  React.useEffect(() => {
    const fetchBrandsList = async () => {
      await HandleFetchBrandListEvent(setQueryParams, setHandleState, setBrandData, brandData, queryParams, table);
    };
    fetchBrandsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom?.flagStatus, queryParams?.filter, debouncedSearchValue, queryParams?.sort?.columnName, queryParams?.sort?.order, custom?.globalDependencyLoading]);

  React.useEffect(() => {
    const getRolePermissionStatus = async () => {
      const status = await checkRolePermissionStatus(userRolePermissionListStoreDataV2, 'brands', environment?.app_default_feature?.is_org_enabled);

      setHandleState((prevState) => ({ ...prevState, roleStatus: status }));
    };
    getRolePermissionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom?.globalDependencyLoading, userRolePermissionListStoreDataV2]);

  const columnHelper = createColumnHelper<any>();

  const columnData = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <div className="!ml-7 flex items-center">
          <input
            type="checkbox"
            id="select_all"
            checked={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
            className="size-[0.9rem] cursor-pointer accent-brand-500 dark:accent-purple-600"
          />
        </div>
      ),
      cell: (info) => {
        return (
          <div className="!ml-7 flex items-center" onClick={(event) => event?.stopPropagation()}>
            <input
              type="checkbox"
              id={`row-${info?.row.id + 1}`}
              checked={info?.row.getIsSelected()}
              // disabled={!info?.row.getCanSelect()}
              onChange={info?.row.getToggleSelectedHandler()}
              className="size-[0.9rem] cursor-pointer accent-brand-500 dark:accent-purple-600"
            />
          </div>
        );
      },
    }),

    columnHelper.accessor('name', {
      id: 'name',
      enableSorting: false,
      header: () => <div className="text-md">Name</div>,
      cell: (info) => (
        // <h5 className="group relative w-36 truncate text-[14px] font-bold text-gray-900 dark:text-gray-200">
        //   {info.getValue()}
        //   {info.getValue()?.length > 18 && (
        //     <ArrowedTooltip className={`ml-8 hidden !min-w-fit !px-4 group-hover:block `} toolTipId={`row-cdate-tooltip-${info?.row?.id}`} data={info.getValue()} />
        //   )}
        // </h5>
        <div className="group relative">
          <h5 className="w-36 truncate text-[14px] font-bold text-gray-900 dark:text-gray-200">{info.getValue()}</h5>
          {info.getValue()?.length > 18 && (
            <ArrowedTooltip
              className="absolute left-0 top-full mt-1 hidden !min-w-fit !px-4 group-hover:block"
              toolTipId={`row-cdate-tooltip-${info?.row?.id}`}
              data={info.getValue()}
            />
          )}
        </div>
      ),
    }),

    columnHelper.accessor('campaign_count', {
      id: 'campaign_count',
      enableSorting: false,
      header: () => <div>Campaigns</div>,
      cell: (info) => <h5 className={`ml-7 text-[12px] font-medium text-gray-700 dark:text-gray-300 status-index-${info?.row?.id}`}>{info.getValue()}</h5>,
    }),

    columnHelper.accessor('interests_count', {
      id: 'interests_count',
      enableSorting: false,
      header: () => <div>Interests</div>,
      cell: (info) => <h5 className=" ml-5 text-[12px] font-medium text-gray-700 dark:text-gray-300">{info.getValue()}</h5>,
    }),

    columnHelper.accessor('meetings_booked_count', {
      id: 'meetings_booked_count',
      header: () => <div className="text-start">Meeting booked</div>,
      cell: (info) => <h5 className=" ml-10 text-[12px] font-medium text-gray-700 dark:text-gray-300">{info.getValue()}</h5>,
    }),
    columnHelper.accessor('opportunities_won', {
      id: 'oportunities_won',
      header: () => <div className="text-start">Opportunities Won</div>,
      cell: (info) => <h5 className=" ml-12 text-[12px] font-medium text-gray-700 dark:text-gray-300">{info.getValue()}</h5>,
    }),
    columnHelper.accessor('total_deal_value', {
      id: 'total_deal_value',
      header: () => <div className="text-start">Total deal value</div>,
      cell: (info) => <h5 className=" ml-10 text-[12px] font-medium text-gray-700 dark:text-gray-300">{info.getValue()}</h5>,
    }),

    columnHelper.accessor('uuid', {
      id: 'uuid',
      header: () => <div className="relative -ml-2 w-min">Action</div>,
      cell: (row) => {
        return <>{<CommonRowSettings uuidRow={row?.row?.original?.uuid} rowData={row?.row?.original} options={options} />}</>;
      },
    }),
  ];

  const table = useReactTable({
    data: brandData?.data,
    columns: columnData,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });

  React.useEffect(() => {
    const tableSelectedData = table.getSelectedRowModel().rows.map((val: any) => val.original?.uuid);
    setTableSelection((prev) => ({ ...prev, selectedIds: tableSelectedData }));

    if (table.getSelectedRowModel().rows.length > 1) setTableSelection((prev) => ({ ...prev, globalOpt: true }));
    else setTableSelection((prev) => ({ ...prev, globalOpt: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getSelectedRowModel()]);

  // Scroll function
  const scrollFunction = React.useCallback(
    async (setIsFetching: any) => {
      brandData?.data?.length &&
        brandData?.data?.length < brandData?.count &&
        (await HandleFetchBrandScrollDataListEvent(setQueryParams, setBrandData, queryParams, setIsFetching));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [brandData?.data?.length, queryParams?.filter, queryParams?.sort?.columnName, queryParams?.sort?.order, queryParams?.offset]
  );

  // --------------------------------------------------------------------------------------------------------------

  return (
    <div className=" mt-3 min-h-[88vh] rounded-md bg-white dark:bg-darkTwo">
      <div className="-mt-2 flex w-full items-center rounded-tl-lg rounded-tr-lg border-b-2 bg-white  px-2 py-4 dark:border-darkBorder dark:bg-darkTwo sm:gap-32 lg:gap-32 xl:justify-between">
        <div className="flex items-center gap-2.5">
          <div
            className="ml-2 flex items-center gap-2 rounded-md border px-3 py-1.5 outline outline-0 focus-within:outline-2 focus-within:outline-brand-400 dark:border-darkBorder md:w-[13rem] lg:w-[26rem]"
            // onClick={() => searchRef.current.focus()}
          >
            <FiSearch className="size-4 cursor-pointer text-gray-800 dark:text-gray-300" />
            <input
              type="text"
              placeholder="Search"
              id="donot-search-input"
              className="w-full text-sm  text-gray-800 caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:bg-darkTwo dark:text-gray-300 dark:placeholder:text-white "
              onChange={handleBrandSearch}
            />
          </div>
          <CustomFilter optionData={optionData} handleFilter={handleFilter} />

          {/* {<CustomFilter />} */}
        </div>
        {(handleState?.roleStatus?.organization_owner || (!handleState?.roleStatus?.organization_owner && handleState?.roleStatus?.organization_access_list?.add)) && (
          <Button
            id="add_brand_btn"
            name="Add Brand"
            customClass="text-xs add_brand_btn !font-normal bg-blueSecondary add-user-btn dark:border-none"
            LeftIcon={<MdAdd className="text-[16px]" />}
            // disable={openPopUP}
            onClick={() => {
              HandleAddNewBrandEvent(setHandleState, dispatch);
            }}
          />
        )}
      </div>
      {!handleState?.loading ? (
        <>
          <div className="mb-2  h-[82vh]  overflow-y-auto overflow-x-scroll  scroll-smooth xl:overflow-x-hidden">
            <table className="w-full ">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header, index, arr) => {
                      const RowDataWidth = columnWidth;
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          onClick={header.column.getToggleSortingHandler()}
                          className={`${RowDataWidth[index]} cursor-default select-none border-b border-gray-100 bg-grayBorder py-3.5 text-start text-column dark:border-white/10 dark:bg-darkOne`}
                        >
                          <div className="text-[12px] font-semibold">
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: '',
                              desc: '',
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody className=" divide-y-[1px] dark:divide-darkBorder ">
                {brandData?.data?.length ? (
                  table.getRowModel().rows.map((row) => {
                    return (
                      <tr key={row.id} className={`cursor-default select-none text-start hover:bg-hover dark:hover:bg-darkOne`}>
                        {row.getVisibleCells().map((cell, i, arr) => {
                          const RowDataWidth = columnWidth;
                          return (
                            <td key={cell.id} className={`${RowDataWidth[i]} py-3.5 text-gray-700 dark:text-white`}>
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={9} className="pt-2 text-center text-[12px] font-medium text-gray-700">
                      No Result Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <InfiniteScrollComponent
              loadMore={scrollFunction}
              loader={
                <TableLoader width="w-full" border="border border-gray-100" column={6} row={1} skeletonHeight="h-2" padding="px-5 py-4" columnWidth="4% 25% 22% 22% 25% 4%" />
              }
            />
          </div>
        </>
      ) : (
        <TableLoader
          width="w-full"
          border="border border-gray-100"
          column={9}
          row={3}
          skeletonHeight="h-2"
          padding="px-5 py-4"
          tableViewBg={{ headBg: 'bg-gray-50' }}
          columnWidth="4% 14% 14% 14% 14% 12% 12% 12% 4%"
        />
      )}
      {tableSelection?.globalOpt && (handleState?.roleStatus?.organization_owner || handleState?.roleStatus?.organization_access_list?.delete) && (
        <MultiselectGlobalOpt options={multiSelectOptions} />
      )}

      <ConfirmationPopup
        isOpen={handleState?.confirm?.isOpen}
        loading={handleState?.deleteloading}
        headerContent={handleState?.confirm?.headerContent}
        bodyContent={handleState?.confirm?.bodyContent}
        bodyRedContent={handleState?.confirm?.bodyRedContent}
        descriptionContent={handleState?.confirm?.descriptionContent}
        handleCancel={handleBrandConfirmCancel}
        handleConfirmFunction={handleBrandConfirmSubmitDelete}
      />

      {handleState?.newBrandModal && (
        <NewBrandModal
          isOpen={handleState?.newBrandModal}
          onClose={() => {
            setHandleState((prevState) => ({ ...prevState, newBrandModal: false }));
          }}
          editData={handleState?.editBrandData}
        />
      )}
    </div>
  );
};

export default BrandList;
