import { toast } from 'react-toastify';
import { setSnoozeId, setSnoozePopupState } from 'redux-store/unified-inbox';
import { toastState } from 'utility/utils';
import { snoozUpdate } from '../api-handler/inbox-api';

export const handleCustomSnooze = async (snoozeId: string, dispatch: any, FetchDataTrigger: any, date: Date | null) => {
  try {
    console.log('snoozeId', snoozeId);
    const payload = {
      unified_inbox_status: 'snoozed',
      uuid: [snoozeId],
      snoozed_date: date,
    };
    const response = await snoozUpdate(payload);
    if (response.status === 200) {
      toast.success(response.message, toastState.success);
      dispatch(setSnoozeId(''));
      dispatch(setSnoozePopupState(false));
      FetchDataTrigger();
    } else {
      dispatch(setSnoozeId(''));
      dispatch(setSnoozePopupState(false));
    }
  } catch (error) {
    console.error('error', error);
  }
};

export const MergeDateAndTime = async ({ dateString, timeString, setDate }: { dateString: string; timeString: string; setDate: React.Dispatch<React.SetStateAction<string>> }) => {
  const date = new Date(dateString);
  const [time, period] = timeString.split(' ');
  let [hours, minutes] = time.split(':').map(Number);

  if (period.toLowerCase() === 'pm' && hours < 12) {
    hours += 12;
  } else if (period.toLowerCase() === 'am' && hours === 12) {
    hours = 0;
  }
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(0);
  date.setMilliseconds(0);
  const formatdate = date.toISOString();
  const currentDate = new Date(formatdate);
  const mongoDBDate = currentDate.toISOString();
  setDate(mongoDBDate);
};
