import React from 'react';
import Button from '../Button';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import { _removeDuplicates, CSVFileDownloader, toastState } from 'utility/utils';
// import { ReactSVG } from 'react-svg';
// import { uploadSVG } from 'assets/images/crm';
import { IoCloudUploadOutline } from 'react-icons/io5';
import { useDisclosure } from '@chakra-ui/hooks';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/modal';

interface PropsType {
  onUpload?: (flattenedData: any) => void;
  dependencies?: any;
  keys?: string[];
  confirmation?: boolean;
  sampleCSVData?: any;
  disable?: boolean;
  customClass?: string;
  pattern?: RegExp;
  patternKey?: string;
  removeDuplicateKey?: string[];
}

interface CommonType {
  [key: string]: number | string | undefined | Object | null;
}

const trimData = (data: any[]) => {
  console.log('data', data)
  return data?.map((item) => {
    const trim = { ...item };
    for (let key in trim) {
      trim[key] = trim[key]?.length ? trim[key]?.trim() : trim[key]
    }
    return trim
  })
}

const FileUploader = (props: PropsType) => {
  const {
    dependencies,
    onUpload,
    keys,
    confirmation,
    sampleCSVData,
    disable,
    customClass,
    pattern,
    patternKey,
    removeDuplicateKey
  } = props;
  const fileMapUpload = new Map();
  const dependency = dependencies || [];
  const [fileData, setFileData] = React.useState({
    file_name: '',
    file_data: [],
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const upload = (data: any) => {
    const finalData = {
      ...data,
      file_data: trimData(data?.file_data)
    }
    if (onUpload) {
      if (removeDuplicateKey?.length) {
        const duplicatesRemovedData = _removeDuplicates(finalData.file_data, removeDuplicateKey)
        onUpload({ file_name: finalData.file_name, file_data: duplicatesRemovedData })
      } else {
        onUpload(finalData)
      }
    };
    toast.success('File uploaded successfully', toastState.success);
  };

  const onDrop = React.useCallback(
    (acceptedFiles: any[]) => {
      if (!disable) {
        acceptedFiles.forEach((file) => {
          const reader = new FileReader();
          reader.onabort = () => console.log('file reading was aborted');
          reader.onerror = () => console.log('file reading has failed');
          fileMapUpload.set(reader, file);
          reader.onload = async (e: any) => {
            const fileAttr = fileMapUpload.get(e.target);
            if (
              !['csv', 'xlsx', 'xls'].includes(fileAttr.name.split('.').pop())
            ) {
              toast.error('This file format is not accepted', toastState.error);
              return;
            }
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const uploadedData: CommonType[] = XLSX.utils.sheet_to_json(sheet, { defval: '' });
            if (!keys || keys?.length <= 0) {
              setFileData({
                file_name: fileAttr.name,
                file_data: uploadedData,
              });
            } else {
              const finalData = uploadedData
                ?.map((data: CommonType) => {
                  const keyObject: CommonType = keys?.reduce(
                    (acc: CommonType, key: string) => {
                      if (data.hasOwnProperty(key)) {
                        acc[key] = data[key];
                      }
                      return acc;
                    },
                    {}
                  );
                  if (Object.keys(keyObject).length === 0) return null;
                  else return keyObject;
                })
                .filter(Boolean);
              if (finalData.length <= 0)
                return toast.error(
                  'Please go through the sample file',
                  toastState.error
                );

              setFileData({ file_name: fileAttr.name, file_data: finalData });
            }
            confirmation
              ? onOpen()
              : upload({ file_name: fileAttr.name, file_data: uploadedData });
          };
          reader.readAsArrayBuffer(file);
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dependency]
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const handleYes = () => {
    const patternTest = fileData?.file_data?.length ? fileData?.file_data?.every((item) => pattern.test(item[patternKey])) : false
    if (patternTest) {
      upload(fileData);
    } else {
      toast.error(`Invalid format detected. Please ensure you enter a valid "${patternKey}" and try again.`, toastState.error)
    }
    onClose();
  };

  const handleNo = () => {
    setFileData({ file_data: [], file_name: '' });
    onClose();
  };

  const handleDownloadSampleCSV = (event: any) => {
    event?.stopPropagation();
    CSVFileDownloader(sampleCSVData, 'sample.csv');
  };
  return (
    <div>
      <div className="h-full w-full pt-4">
        <div
          className="flex h-full !w-[43.75rem] cursor-pointer items-center justify-center rounded-xl border-dashed border-navy-700 "
          {...getRootProps({ className: 'dropzone' })}
        >
          <input
            {...getInputProps()}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
          <button className="h-full !w-full" disabled={disable}>
            <div
              id="file_uploader"
              className={`flex h-[14.0625rem] w-full flex-col items-center justify-center gap-1 rounded-md border-[.1rem] border-dashed 
                             p-3 dark:!border-none dark:!bg-darkTwo ${disable
                  ? 'border-gray-200 bg-gray-50'
                  : 'border-brand-500 bg-bgUpload'
                } ${customClass}`}
            >
              {/* <p className="ml-2 pb-3 text-[4.0625rem] text-navy-700"> */}
              {/* <MdOutlineCloudUpload className="text-brand-500 dark:text-white" /> */}
              {/* <ReactSVG
                                    src={uploadSVG}
                                    beforeInjection={(svg) =>
                                        svg.setAttribute('style', 'height: 3.125rem; width: 3.125rem;')
                                    }
                                /> */}
              <div className="pb-3">
                <IoCloudUploadOutline
                  className={`${disable ? 'text-gray-400' : 'text-darkBlue'
                    } text-[2.8125rem]`}
                />
              </div>
              {/* </p> */}
              <p
                className={`text-xs font-bold dark:text-white ${disable ? 'text-gray-400' : 'text-navy-700'
                  }`}
              >
                Drag & drop files or{' '}
                <span
                  className={`${disable ? 'text-gray-400' : 'text-purple-700'
                    } font-bold  underline dark:text-brand-400`}
                >
                  Browse
                </span>
              </p>
              <p
                className={`pt-1 text-[.625rem] ${disable ? 'text-gray-400' : 'text-gray-700'
                  }`}
              >
                Supported formats: CSV
              </p>
              {sampleCSVData && (
                <p
                  className={`text-[.625rem] hover:underline ${disable ? 'text-gray-400' : 'text-blueSecondary'
                    }`}
                  onClick={handleDownloadSampleCSV}
                >
                  Download Sample CSV file
                </p>
              )}
              <div className="mt-2 flex justify-center">
                <div
                  id="upload_box"
                  className={`rounded-md px-12 py-1.5 text-[.875rem] text-white ${disable ? 'bg-gray-300' : 'bg-blueSecondary'
                    }`}
                >
                  Upload CSV
                </div>
              </div>
            </div>
          </button>
        </div>
      </div>

      <Modal isOpen={isOpen} onClose={handleNo} size={'sm'} isCentered={true}>
        <ModalOverlay />
        <ModalContent className="px-2 pb-6 pt-12">
          <ModalBody>
            <h4 className="select-none text-center text-[18px] font-semibold text-heading">
              Do you want to add {fileData?.file_data?.length} entries from{' '}
              {fileData?.file_name}?
            </h4>
          </ModalBody>

          <ModalFooter className="flex !justify-center">
            <div className="mt-3 flex w-11/12 gap-3">
              <Button
                name="No"
                customClass="w-full !bg-white !text-blueSecondary !border-blueSecondary font-semibold !text-[12px]"
                onClick={handleNo}
              />
              <Button
                id="modal_popup_yes_btn"
                name="Yes"
                customClass="w-full font-semibold !text-[12px]"
                onClick={handleYes}
              />
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default FileUploader;
