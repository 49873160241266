import { IoArrowBackOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

const BackNavigate = (props: { children: React.ReactNode }) => {
    const { children } = props;
    const navigate = useNavigate();

    return (
        <div className="my-2 h-[calc(100vh-12vh)] flex flex-col  w-full rounded bg-[#f5faff] dark:bg-darkThree p-3 overflow-auto ">
            <p className="flex cursor-pointer items-center gap-3 text-sm font-medium text-heading dark:text-gray-200" onClick={() => navigate('/sender-accounts')}>
                <IoArrowBackOutline /> Back
            </p>

            {children}
        </div>
    );
};

export default BackNavigate;
