import React from 'react';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import Button from 'components/common/Button';
import InputField from 'components/forms/InputField';
import { CustomMenu } from 'components/common/customMenu';
import { BiChevronDown } from 'react-icons/bi';
import { BaseApi } from 'api/services/base-api';
const AutoCreditPopupModal = (props: any) => {
  const { isOpen, onClose } = props;
  const [open, setOpen] = React.useState(false);
  const [creditsDetails, setCreditsDetails] = React.useState([]);
  const [credits, setCredits] = React.useState({
    price: 0,
    sparkles: 0,
  });
  const handleSelect = (value: number, sparkles: number, credit_value_id: string) => {
    setCredits({ ...credits, price: value, sparkles: sparkles });
    setOpen(false);
  };

  const fetchCreditPricing = async () => {
    try {
      //   setCardLoading(true);
      const billingresponse = await BaseApi.get(`/credit/addons`);
      console.log('billingresponse', billingresponse);
      if (!billingresponse?.error) {
        setCreditsDetails(billingresponse?.data?.data || []);
        // setCardLoading(false);
      } else {
        // setCardLoading(false);
      }
    } catch (error) {}
  };
  React.useEffect(() => {
    fetchCreditPricing();
  }, []);
  return (
    <div>
      <Modal isCentered isOpen={isOpen} onClose={() => {}} size="2xl">
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent overflow={'auto'} className=" dark:bg-darkOne">
          <ModalHeader className="mb-1 bg-[#FAFAFC] dark:bg-darkTwo">
            <div className="flex items-center  gap-3">
              <h5 className="text-lg font-semibold text-heading dark:text-white">Automatically recharge</h5>
            </div>
          </ModalHeader>
          <ModalBody className="!p-3">
            <div className=" " id="user-management-add-modal">
              <div className="!mt-1  flex flex-col rounded-md  px-2 py-2 dark:bg-darkTwo">
                <p className="ml-2 text-[13px]  !font-semibold text-[#1B2559] dark:text-white">
                  When balance falls below
                  {/* <span className="text-red-500">*</span> */}
                </p>
                <InputField
                  autoComplete="off"
                  extra="-mt-2"
                  inputClass="mt-2 !w-full dark:!bg-darkFive brand_name_input !rounded-md !h-9"
                  labelClass="!text-column dark:!text-white !font-[600] !ml-0 text-xs"
                  placeholder="Sparkles"
                  id="name"
                  type="number"
                  //   min={5}
                  //   max={50}
                  //   value={value}
                  //   onChange={handleSliderChange}
                  rightElement={'Sparkles'}
                  //   errorMessage={errorMessage?.name}
                  //   state={errorMessage?.name ? 'error' : ''}
                />{' '}
                {/* {errorMessage?.name && <span className="ml-1 mt-1  flex items-center text-xs font-medium tracking-wide text-red-500 dark:!text-red-400">{errorMessage?.name}</span>} */}
              </div>

              <div className="!mt-1  flex flex-col rounded-md  px-2 py-2 dark:bg-darkTwo">
                <p className="ml-2 text-[13px]  !font-semibold text-[#1B2559] dark:text-white">
                Automatically recharge for
                  {/* <span className="text-red-500">*</span> */}
                </p>
                <CustomMenu
                  open={open}
                  onOpen={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  btnContent={`${credits?.sparkles} sparkles` || 'select'}
                  btnContentClassName="w-40 truncate "
                  btnClassName="w-full mt-2 px-1 justify-between border-blue-500 !h-9 dark:!bg-darkThree !text-column !text-sm !rounded-md !font-normal truncate !bg-white"
                  menuListClassName="w-full  h-36 overflow-auto !mt-0.5 !p-0"
                  rightIcon={<BiChevronDown size={18} />}
                  btnId="primary_goal_drop_down"
                >
                  {creditsDetails?.map((item: any, idx: number) => (
                    <button
                      key={idx}
                      id="primary_goal_0"
                      onClick={() => {
                        handleSelect(item?.amount, item?.credits, item?.uuid);
                      }}
                      className="text-coloumn w-full p-1 pl-3 text-start text-xs hover:bg-lightPrimary dark:hover:text-blackDark"
                    >
                      <div className="!w-32 truncate"> {item?.credits} Sparkles</div>
                    </button>
                  ))}
                </CustomMenu>{' '}
                {/* {errorMessage?.name && <span className="ml-1 mt-1  flex items-center text-xs font-medium tracking-wide text-red-500 dark:!text-red-400">{errorMessage?.name}</span>} */}
              </div>

              <div className="!-mt-1  flex flex-col rounded-md  px-2 py-2 dark:bg-darkTwo">
                <p className="ml-2 text-[13px]  !font-semibold text-[#1B2559] dark:text-white">Amount {/* <span className="text-red-500">*</span> */}</p>
                <InputField
                  autoComplete="off"
                  extra="-mt-2"
                  inputClass="mt-2 !w-full dark:!bg-darkFive brand_name_input !rounded-md !h-9"
                  labelClass="!text-column dark:!text-white !font-[600] !ml-0 text-xs"
                  placeholder="Sparkles"
                  id="name"
                  type="number"
                  readOnly={true}
                  //   min={5}
                  //   max={50}
                  //   value={value}
                  //   onChange={handleSliderChange}
                  //   rightElement={'Sparkles'}
                  //   errorMessage={errorMessage?.name}
                  //   state={errorMessage?.name ? 'error' : ''}
                />{' '}
                {/* {errorMessage?.name && <span className="ml-1 mt-1  flex items-center text-xs font-medium tracking-wide text-red-500 dark:!text-red-400">{errorMessage?.name}</span>} */}
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="!-mt-2 !mb-5">
            <Button
              id="cancel-workspace-management"
              name="Cancel"
              customClass="!bg-[#F4F7FE] w-full !text-[#A3AED0] border border-2 dark:!text-blackDark  !font-medium duration-300 w-40 mr-3 py-2 text-[0.80rem]"
              onClick={() => {
                onClose();
              }}
            />
            <Button id="save-workspace-management" name={`Confirm`} customClass="w-full !text-[#FFFFFF] !font-medium duration-300 w-[10.5rem] py-2.5 text-[0.80rem] dark:border-none" />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default AutoCreditPopupModal;
