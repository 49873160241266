import React from 'react'

type ButtonMenuProps = {
    condition: boolean;
    position ?: string;
    setCondition: (value :boolean) => void;
    menuData?: {name :string, Icon ?: React.JSX.Element, onclick ?: () => void}[]
    Comp?: React.JSX.Element;
}

const ButtonMenu = (props: ButtonMenuProps) => {
    const { condition, setCondition, menuData, Comp, position} = props
    return (
        <div className={`relative cursor-pointer rounded-md py-0.5 ${condition && 'bg-[#4318FF0A]'}`} tabIndex={0} onBlur={() => setCondition(false)}>
            {Comp}
            {condition && <div className={`border rounded-md flex flex-col bg-white absolute overflow-hidden w-full ${position}`}>
                {menuData.map((menu, id) => <div key={id} className='group flex gap-2 hover:bg-brand-500 hover:text-white transition-all duration-150 font-medium px-3 py-2 cursor-pointer create-email-btn' onClick={menu?.onclick}>
                    {menu?.Icon}
                    <h5 className="text-xs">{menu.name}</h5>
                </div>)}
            </div>}
        </div>
    )
}

export default ButtonMenu