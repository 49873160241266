import { Tabs, TabList, Tab, TabIndicator, TabPanels, TabPanel } from '@chakra-ui/react';
import React from 'react';
import Communication from './Communication';
// import Highlights from './Highlights';
// import Activity from './Activity';
import Notes from './Notes';
import Tasks from './Tasks';
import Opportunities from './Opportunities';
interface PropsDataType {
  selectedIndex: number;
  handleNavigateToTab: (index: number) => void;
}

const ModalView = (props: PropsDataType) => {
  const { handleNavigateToTab, selectedIndex } = props;
  return (
    <div className=" min-w-[730px] border-x-2 pt-1">
      <Tabs position="relative" variant="unstyled" index={selectedIndex} onChange={(index) => handleNavigateToTab(index)}>
        <TabList className="font-semibold">
          <Tab _selected={{ color: 'black' }} color={'#7C8493'} paddingX={'.65rem'}>
            <span className="text-[.85rem]">Communication</span>
          </Tab>
          {/* <Tab _selected={{ color: 'black' }} color={'#7C8493'} paddingX={'.65rem'}>
            <span className="text-[.85rem]">Highlights</span>
          </Tab>
          <Tab _selected={{ color: 'black' }} color={'#7C8493'} paddingX={'.65rem'}>
            <span className="text-[.85rem]">Activity</span>
          </Tab> */}
          <Tab _selected={{ color: 'black' }} color={'#7C8493'} paddingX={'.65rem'}>
            <span className="text-[.85rem]">Notes</span>
          </Tab>
          <Tab _selected={{ color: 'black' }} color={'#7C8493'} paddingX={'.65rem'}>
            <span className="text-[.85rem]">Tasks</span>
          </Tab>
          {/* <Tab _selected={{ color: 'black' }} color={'#7C8493'} paddingX={'.65rem'}><span className='text-[.85rem]'>Opportunities</span></Tab> */}
        </TabList>

        <TabIndicator mt="-2px" height="5px" className="scale-x-[85%] bg-brand-600" borderTopRadius="20px" />

        <TabPanels>
          <TabPanel padding={0}>
            <Communication />
          </TabPanel>
          {/* <TabPanel padding={0}>
            <Highlights />
          </TabPanel>
          <TabPanel padding={0}>
            <Activity />
          </TabPanel> */}
          <TabPanel padding={0}>
            <Notes />
          </TabPanel>
          <TabPanel padding={0}>
            <Tasks />
          </TabPanel>
          <TabPanel padding={0}>
            <Opportunities />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default ModalView;
