import { LOGIN_STORAGE_VAR } from 'constants/constants'
import { AppDispatch } from 'redux-store'
import { setAppSettings, setLoggedUserDetails, setLoggedUserRolePermissionList } from 'redux-store/authentication'
import { LocalStorage } from 'services/local.storage.service'
import { cryptoDecrypt } from 'utility/utils'
import { GetAppFeatureEnvironmentAPI, GetAuthUserConfigurationAPI, GetConfigMiddlewareAPI, NotifyOwnerPaymentAPI } from '../api-handler'
import { setEnvironment, setGlobalConfiguration } from 'redux-store/global-configuration'
import { NavigateFunction } from 'react-router-dom'
import { toast } from 'react-toastify'
import { setPaymentStatus } from 'redux-store/account-settings'
import { BILLING } from 'api/endpoints/billingEndpoints'
import { setSideBarMenu } from 'redux-store/custom'

export const handleAppStateManagement = (loggedUser: any, environment: any, dispatch: AppDispatch) => {
  const theme = LocalStorage.getItem('darkMode') === 'true' ? true : false
  updateLoggeduserDataToStore(dispatch)
  getConfigMiddleware(dispatch);
  if (!Object.keys(loggedUser).length) {
    return
  }

  dispatch(setAppSettings({ key: 'theme', value: theme ? 'dark' : 'light' }))
  if (environment?.app_default_feature?.is_org_enbaled) {
  }
}

export const updateLoggeduserDataToStore = async (dispatch: AppDispatch) => {
  const decodedData = await cryptoDecrypt(LocalStorage.getString(LOGIN_STORAGE_VAR), true)
  dispatch(
    setLoggedUserDetails({
      uuid: decodedData?.uuid,
      is_admin_account: decodedData?.is_admin_account,
      first_name: decodedData?.first_name,
      last_name: decodedData?.last_name,
      email: decodedData?.email,
      last_switch_organization_id: decodedData?.last_switch_organization_id,
      last_switch_workspace_id: decodedData?.last_switch_workspace_id,
      authToken: decodedData?.authToken,
      picture: decodedData?.picture,
    })
  )
  dispatch(setLoggedUserRolePermissionList(decodedData?.role_permission_list))
  const prevDarkMode = LocalStorage.getItem('darkMode')
  const theme = prevDarkMode === 'true' ? true : false
  theme && document.body.classList.add('dark')
}

export const getAuthUserConfiguration = async (data: string, dispatch: AppDispatch) => {
  try {
    const response :any= await GetAuthUserConfigurationAPI(data)
      if (!response?.error) {
        data ? dispatch(setGlobalConfiguration({ ...response?.data })) : dispatch(setGlobalConfiguration(response?.data));
      }
  } catch (err) {
      console.error(err);
  }
};

export const handleInsufficientBalanceFunction = async (userType: any,uuid :any, dispatch: AppDispatch, navigate: NavigateFunction, setBtnLoading: (value: React.SetStateAction<boolean>) => void ) => {
  if (userType === 'owner') {
    dispatch(setPaymentStatus(false));
    navigate(BILLING);
  } else {
    setBtnLoading(true);
    try {
      const response :any= await NotifyOwnerPaymentAPI(uuid)
      if (!response?.error) {
        dispatch(setPaymentStatus(false));
        toast.success('Notification sent Successfully');
      }
      setBtnLoading(false);
    } catch (error) {
      console.error('Error from Notify:', error);
    }
  }
};
        
export const fetchAppSettings = async (dispatch: AppDispatch) => {
  try {
    const response = await GetAppFeatureEnvironmentAPI();
    if (response?.data) {
      dispatch(setEnvironment(response?.data));
    }
  } catch (error) {
    console.log(error);
  }
};

export const getConfigMiddleware = async(dispatch: AppDispatch)=>{
  try {
    const response = await GetConfigMiddlewareAPI();
    dispatch(setSideBarMenu(response?.data));
  } catch (err) {
    console.error('Get Config Middleware Error occured', err);
  }
}