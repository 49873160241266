import { Tabs, TabList, Tab, TabIndicator, TabPanels, TabPanel } from '@chakra-ui/react';
import { tabViewDTO } from 'dto/initial-value/views/inbox';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'redux-store';
import { HandleFetchInboxMessageCount } from 'shared/inbox/event-handler/inbox-event';

const TabView = (props: any) => {
  const { handleTabSelect } = props;
  const { triggerCount, variant } = useAppSelector((state) => state.unifiedInboxData);
  const { sideBarFullView } = useAppSelector((state) => state.custom);

  const [tabDataCount, setTabDataCount] = useState<tabViewDTO>({
    pending_count: 0,
    cleared_count: 0,
    snoozed_count: 0,
    starred_count: 0,
  });
  useEffect(() => {
    HandleFetchInboxMessageCount({ variant, setTabDataCount });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerCount]);

  return (
    <div className={`h-full ${sideBarFullView ? 'w-[40rem]' : 'w-[48rem]'} border-b  pt-1`}>
      <Tabs position="relative" variant="unstyled">
        <TabList className="flex items-center justify-center font-semibold">
          <Tab _selected={{ color: '#4318FF' }} color={'#7C8493'} paddingX={'.65rem'} onClick={() => handleTabSelect('pending')}>
            <span className="text-[.85rem]">Pending</span>
          </Tab>
          {tabDataCount.pending_count ? <span className="flex h-5 w-8 items-center justify-center rounded-full bg-[#fef6ee] text-xs text-[#b93815]">{tabDataCount.pending_count > 10 ? '10+' : tabDataCount.pending_count}</span> : null}
          <Tab _selected={{ color: '#4318FF' }} color={'#7C8493'} paddingX={'.65rem'} onClick={() => handleTabSelect('cleared')}>
            <span className="text-[.85rem]">Cleared</span>
          </Tab>
          {tabDataCount.cleared_count ? <span className="flex h-5 w-8 items-center justify-center rounded-full bg-[#fef6ee] text-xs text-[#b93815]">{tabDataCount.cleared_count > 10 ? '10+' : tabDataCount.cleared_count}</span> : null}
          {variant !== 'Notification' && (
            <Tab _selected={{ color: '#4318FF' }} color={'#7C8493'} paddingX={'.65rem'} onClick={() => handleTabSelect('snoozed')}>
              <span className="text-[.85rem]">Snoozed</span>
              {tabDataCount.snoozed_count ? <span className="flex h-5 w-8 items-center justify-center rounded-full bg-[#fef6ee] text-xs text-[#b93815]">{tabDataCount.snoozed_count > 10 ? '10+' : tabDataCount.snoozed_count}</span> : null}
            </Tab>
          )}
          <Tab _selected={{ color: '#4318FF' }} color={'#7C8493'} paddingX={'.65rem'} onClick={() => handleTabSelect('important')}>
            <span className="text-[.85rem]">Important</span>
          </Tab>
          {tabDataCount.starred_count ? <span className="flex h-5 w-8 items-center justify-center rounded-full bg-[#fef6ee] text-xs text-[#b93815]">{tabDataCount.starred_count > 10 ? '10+' : tabDataCount.starred_count}</span> : null}
        </TabList>
        <TabIndicator mt="-3px" height="3px" className="scale-x-[85%] bg-brand-600" borderTopRadius="20px" />
        <TabPanels>
          <TabPanel padding={0}>{/* <Communication /> */}</TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default TabView;
