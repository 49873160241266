/* eslint-disable @typescript-eslint/no-unused-vars */
import Button from 'components/common/Button';
import React from 'react';
import Input from 'components/common/Input';
import { Card, Stack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { IoMdMore } from 'react-icons/io';
import { useAppSelector } from 'redux-store';
import { useDispatch } from 'react-redux';
import { setBrandId, setIsSwitchPipeline, setPipelineId } from 'redux-store/crm';
import OnboardBackground from 'components/common/crmOnboard/OnbardBackground';

const OnboardCloseStage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const containerRef = React.useRef(null);
  const [stages, setStages] = React.useState([{ text: '', color: '#4318FF' }]);
  const OnClosedChange = () => {};
  const OnClosedLostChange = () => {};
  const pipelineName = useAppSelector((state) => state.crmOnboard.pipelinename);
  const pipelineId = useAppSelector((state) => state.crmOnboard.pipelineid);
  const pipelineStages = useAppSelector((state) => state.crmOnboard.stages);
  const brandId = useAppSelector((state) => state.crmOnboard.brand);

  React.useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [stages]);

  const onPipelineClick = () => {
    dispatch(setIsSwitchPipeline(true));
    dispatch(setPipelineId(pipelineId));
    dispatch(setBrandId(brandId));
    navigate('/crm');
  };
  return (
    <OnboardBackground count={6}>
      <div className="flex justify-between gap-x-10">
        <div className="flex w-[35rem] flex-col justify-start gap-6 py-12">
          <div>
            <p className="w-[80%] text-justify text-sm font-medium text-heading">Setup your sales process</p>
            <p className="mt-4 text-[1.8125rem] font-bold text-heading">Define your deal stages</p>
            <p className="mt-3 w-[82%] text-justify text-sm font-medium text-heading">
              The final two stages in your pipeline should indicate whether the deal ended in a successful sale, or if the customer decided not to make a purchase. you can give
              these stages names too.
            </p>
          </div>
          <div>
            <p className="mb-4 text-[1.2rem] font-semibold text-heading">Closing Stage names</p>
            <div>
              <div className="max-h-48 overflow-y-auto p-1">
                <Input placeholder="Closed Won" customClass="mb-3 !w-[350px] " onChange={() => OnClosedChange()} />
                <Input onChange={() => OnClosedLostChange()} placeholder="Closed lost" customClass="mb-3 !w-[350px]" />
              </div>
            </div>
          </div>
          <div>
            <Button name="Complete" px="px-6" py="py-3" fontSize="xs" customClass="mt-6!" onClick={() => onPipelineClick()} />
          </div>
        </div>
        <div className="mt-9 h-full w-[51.5rem]">
          <div className="rounded-lg bg-[#fff] p-2">
            <Stack>
              <Card className="h-10 px-4 py-2">
                <p className="font-semibold text-[#2B3674]">{pipelineName ? pipelineName : ' '}</p>
              </Card>
            </Stack>
            <Card className="mt-2 h-[29rem] p-4">
              <div className="flex scale-y-[-1] transform overflow-x-auto">
                {pipelineStages.map((stage: any, index: number) => (
                  <div
                    className="pt-1.cust mr-2 flex min-w-[18rem] max-w-[18rem] scale-y-[-1] transform flex-col items-center border-t-[3px] pb-20 pr-4"
                    key={index}
                    //style={{ borderTopColor: '#2B3674' }}
                    style={{ borderTopColor: stage?.color_code }}
                  >
                    <div className="flex w-full items-center justify-between">
                      <div className="flex items-center gap-1.5 pl-2.5">
                        <h5 className="relative rounded-full bg-[#4318FF0D] p-2.5">
                          <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-[0.55rem] font-semibold">0</span>
                        </h5>
                        <h5 className="text-md font-semibold text-[#101828]">{stage?.stage_name}</h5>
                      </div>
                      <IoMdMore className="size-6 cursor-pointer rounded-md text-column hover:bg-gray-100" />
                    </div>
                  </div>
                ))}
              </div>
            </Card>
          </div>
        </div>
      </div>
    </OnboardBackground>
  );
};

export default OnboardCloseStage;
