/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { BiArrowBack } from 'react-icons/bi';
import { FiSearch } from 'react-icons/fi';
import { useCallback, useEffect, useRef, useState } from 'react';
import { VscListFilter } from 'react-icons/vsc';
import { toast } from 'react-toastify';
import InfiniteScrollComponent from 'components/common/infinityScroll';
// import Groups from 'views/campaigns-v2/features/campaign-creation/settings/features/sender-settings/Groups';
import { FaAngleDown } from 'react-icons/fa';
import { Avatar, Box, SkeletonText } from '@chakra-ui/react';
// import InboxSkeleton from 'views/Inbox/feature/inbox-skeleton/InboxSkeleton';
import { MdClear } from 'react-icons/md';
import UnmatchListRow from '../component/UnmatchListRow';
import { CustomMenu } from 'components/common/customMenu';
import InputField from 'components/forms/InputField';
import Button from 'components/common/Button';
import { AZ_BUCKET } from 'config/appConfig';
import { useAppDispatch, useAppSelector } from 'redux-store';
import SparkleRadio from 'components/common/Radio';
import { fetchAllData, fetchDataForScroll, fetchGrouplistData, handleClearFilterClick, handleClose, handleSearchandSetRulesOnClick, updateIgnoreStatusOnclick } from 'shared/unmatch/event-handler/unmatch-event';
import { checkRolePermissionStatus, jwtEncrypt, useDebounce } from 'utility/utils';
import { QueryParamsDto } from 'dto/types/views/unmatched';

const UnmatchedEmails = () => {
  const dispatch = useAppDispatch();
  const { fetchGrouplistTrigger } = useAppSelector((state) => state.unmatched);
  const [loading, setLoading] = useState(false);
  const { senderList, settings } = useAppSelector((state) => state.campaignv2);
  const { selectedSenderList, selectedGroupList } = settings;
  const [showGroup, setShowGroup] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const searchRef = useRef(null);
  const [queryParams, setQueryParams] = useState<QueryParamsDto>({ limit: 25, offset: 2, sort: { columnName: '', order: '' }, search: '', filter: '', sender_data: '' });
  const debouncedSearchValue = useDebounce(queryParams.search, 300);
  const { fetchAllDataTrigger } = useAppSelector((state) => state.unmatched);
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState({ data: [], total_count: 0 });
  const [mergeOption, setMergeOption] = useState([]);
  const colors = ['#4318FF', '#E7C100', '#7F56D9', '#7E4400', '#12B76A'];
  const [roleStatus, setRoleStatus] = useState(null);
  const userRolePermissionListStoreData = useAppSelector((state) => state?.globalsettings?.globalConfiguration?.user_organization_role_details);
  const { environment } = useAppSelector((state) => state.globalsettings);
  const [filter, setFilter] = useState({
    rules_type: 0, // 0 - filter search, 1 - ignore , 2 - ignore rule, 3 - match campaign rule, 4 - search campaign, 5 - new oppertunity
    filter_search: {
      from: '',
      to: '',
      domain: '',
      subject: '',
      keywords: '',
      date: '',
    },
    rule_ignore: {
      once: false,
      entire_domain: false,
      entire_email: false,
    },
  });
  const custom = useAppSelector((state) => state?.custom);

  function getRandomIndex(min = 0, max = 5) {
    return Math.floor(Math.random() * (max - min) + min);
  }

  const selectedsenderUuid = Object.entries(selectedSenderList)
    .map((item: any) => {
      if (item[1]) {
        return item[0];
      } else {
        return null;
      }
    })
    .filter(Boolean);
  const Groupids = Object.keys(selectedGroupList).filter((key) => selectedGroupList[key] === true);
  useEffect(() => {
    const searchBySender = async () => {
      try {
        setQueryParams((prev: any) => ({
          ...prev,
          sender_data: jwtEncrypt({ sender_id: selectedsenderUuid, group_list: Groupids }),
          offset: 2,
        }));
      } catch (error) {
        console.log(error);
      }
    };
    // (Object.keys(selectedSenderList)?.length || Groupids.length) && searchBySender();
    Object.keys(selectedsenderUuid)?.length || Groupids.length
      ? searchBySender()
      : setQueryParams((prev: any) => ({
          ...prev,
          sender_data: '',
          offset: 2,
        }));
  }, [selectedSenderList, selectedGroupList]);

  useEffect(() => {
    const getRolePermissionStatus = async () => {
      const status = await checkRolePermissionStatus(userRolePermissionListStoreData, 'unmatched', environment?.app_default_feature?.is_org_enbaled);
      setRoleStatus(status);
    };
    getRolePermissionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom?.globalDependencyLoading, userRolePermissionListStoreData]);
  const selectedSenderMail = senderList?.length ? senderList?.find((item: any) => item?.uuid === selectedsenderUuid[0]) : '';

  useEffect(() => {
    fetchAllData(setLoading, queryParams, setData);
  }, [debouncedSearchValue, fetchAllDataTrigger, queryParams.sender_data, queryParams.filter]);

  useEffect(() => {
    fetchGrouplistData(dispatch);
  }, [fetchGrouplistTrigger]);

  const handleChangeStage = (stage: number) => {
    setFilter((prev) => ({
      ...prev,
      rules_type: stage,
    }));
  };

  const handleInputChange = (key: string, event: any) => {
    const { name, value } = event?.target;

    if (event?.target.name === 'date') {
      if (!value) {
        setSelectedDate(null);
        setFilter((prev) => {
          const finalData: any = { ...prev };
          finalData[key][name] = '';
          return finalData;
        });
      } else {
        setSelectedDate(event?.target?.value);
        const date = new Date(event?.target.value);
        const isoString = date?.toISOString();

        setFilter((prev) => {
          const finalData: any = { ...prev };
          finalData[key][name] = isoString;
          return finalData;
        });
      }
    } else {
      setFilter((prev) => {
        const finalData: any = { ...prev };
        finalData[key][name] = value;
        return finalData;
      });
    }
  };

  const handleMailSearch = (event: any) => {
    setQueryParams((prev: any) => ({
      ...prev,
      search: event?.target?.value,
    }));
  };
  const handleRadioChange = (isChecked: boolean, name: string) => {
    setFilter((prev) => {
      const finalData: any = { ...prev };

      Object.keys(finalData?.rule_ignore).forEach((key) => {
        if (key !== 'apply_matched_results') {
          finalData.rule_ignore[key] = key === name ? isChecked : false;
        }
      });

      return finalData;
    });
  };

  const FetchMoreUnmatcheData = useCallback(
    async (setIsFetching: any) => {
      data?.data?.length && data?.data?.length < data?.total_count && fetchDataForScroll(setIsFetching, setQueryParams, queryParams, setData);
    },
    [data?.data?.length, debouncedSearchValue, queryParams.offset, queryParams?.sender_data, queryParams?.filter]
  );

  return (
    <div className={`mt-2.5 cursor-default select-none rounded-lg bg-white pb-2 pt-2.5 shadow-sm dark:bg-darkThree dark:text-white`}>
      <div className="relative mb-3 flex items-center py-0.5 sm:gap-32 md:px-10 lg:gap-32 lg:px-3 xl:justify-between">
        <div className="flex gap-2.5">
          <div className="flex items-center gap-2 rounded-md border px-3 py-1.5 outline outline-0 focus-within:outline-2 focus-within:outline-brand-400 dark:border-darkBorder md:w-[13rem] lg:w-[18rem]" onClick={() => searchRef.current.focus()}>
            <FiSearch className="size-4 cursor-pointer" />
            <input ref={searchRef} onChange={handleMailSearch} autoComplete="off" type="text" placeholder="Search" id="campaign-search-input" className="w-full text-sm font-bold text-gray-800 caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:bg-darkThree  dark:placeholder:text-white " />
          </div>
        </div>
        <div className="relative flex items-center gap-3">
          <div className="relative">
            <div className="flex w-[25rem] cursor-pointer items-center gap-1.5 rounded-md border border-blueSecondary bg-[#F9F5FF] px-3 py-2 !text-blueSecondary" onClick={() => setShowGroup(!showGroup)} id="select-sender-account">
              <div className="flex w-full items-center truncate">
                {selectedSenderMail ? (
                  <>
                    <Avatar src={selectedSenderMail?.picture || ''} name="J" bg={'#00B4DB'} size={'xs'} style={{ height: '20px', width: '20px' }} />
                    <h5 className="ml-2 w-[98%] truncate text-[0.825rem] !text-blueSecondary">
                      {selectedSenderMail?.name} ({selectedSenderMail?.account_details?.email})
                    </h5>
                  </>
                ) : (
                  <span className="text-xs !text-blueSecondary">Select</span>
                )}
              </div>
              {selectedsenderUuid.length > 1 ? <span className="w-fit rounded-full bg-lightPrimary p-1 text-xs text-column">+{selectedsenderUuid?.length - 1}</span> : <></>}
              <FaAngleDown className="size-2.5" onClick={() => setShowGroup(!showGroup)} />
            </div>
            {/* {showGroup ? <Groups type="email" onClose={closeMenu} /> : null} */}
          </div>
          <CustomMenu
            btnId="unmatched-filters"
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => handleClose(setIsOpen)}
            btnContent="Filters"
            leftIcon={<VscListFilter size={18} />}
            btnClassName={` border-2 ${isOpen ? 'border-menuBorder' : 'border-gray-100'} text-column rounded-md text-sm py-2 px-3.5 cursor-pointer my-2 mr-2`}
            menuListClassName={`border-2 !w-[18rem] right-0 rounded-md ${isOpen ? 'border-menuBorder' : 'border-gray-100'} `}
          >
            {filter?.rules_type === 0 && (
              <>
                <div className="border-b p-2">
                  <div className="flex cursor-pointer justify-end " id="clear-filter" onClick={() => handleClearFilterClick(setFilter, setQueryParams, setIsOpen, setSelectedDate)}>
                    <p className="text-sm font-medium text-blueSecondary  hover:text-blue-600">
                      Clear <MdClear className="inline-block" />
                    </p>
                  </div>
                  <InputField id="from" label="From" value={filter?.filter_search?.from} placeholder="jay@joytechnologies" name="from" onChange={(event: any) => handleInputChange('filter_search', event)} />
                  <InputField id="to" value={filter?.filter_search?.to} label="To" placeholder="Sam@hello.com" name="to" onChange={(event: any) => handleInputChange('filter_search', event)} />
                  <InputField id="domain" value={filter?.filter_search?.domain} label="Domain" placeholder="google.com" name="domain" onChange={(event: any) => handleInputChange('filter_search', event)} />
                  <InputField id="subject" value={filter?.filter_search?.subject} label="Subject" placeholder="subject" name="subject" onChange={(event: any) => handleInputChange('filter_search', event)} />
                  <InputField id="keyword" value={filter?.filter_search?.keywords} label="Keywords" placeholder="keywords" name="keywords" onChange={(event: any) => handleInputChange('filter_search', event)} />
                  <div className="p-1">
                    <label className={`text-xs font-medium text-heading dark:text-white`}>Date</label>
                    <input autoComplete="off" value={selectedDate && selectedDate} onChange={(event: any) => handleInputChange('filter_search', event)} type="date" name="date" className="mt-0.5 flex h-8 w-full items-center justify-center rounded-md border bg-white/0 px-2 py-1 text-xs  text-gray-700 outline-none" />
                  </div>
                </div>
                <div className="m-2 flex gap-2">
                  <Button name="Set rules" bg="white" color="gray-800" customClass="w-full transition-all duration-300" py="py-1.5" onClick={() => handleChangeStage(1)} />
                  <Button id="search" name="Search" customClass="w-full transition-all duration-300" py="py-1.5" onClick={() => handleSearchandSetRulesOnClick('search', setQueryParams, filter, setIsOpen)} />
                </div>
              </>
            )}

            {filter?.rules_type === 1 && (
              <>
                <div className="flex flex-col gap-3 border-b p-3">
                  <div className="flex cursor-pointer items-center gap-2 text-sm font-medium text-column dark:text-white" onClick={() => handleChangeStage(0)}>
                    <BiArrowBack className="text-xl" /> Select ignore rule{' '}
                  </div>
                  <InputField placeholder="Rule name" name="from" onChange={(event: any) => handleInputChange('filter_search', event)} />

                  <div className="flex gap-1.5">
                    <SparkleRadio checked={filter?.rule_ignore?.once} name="once" onChange={handleRadioChange} />{' '}
                    <div className="text-xs font-medium">
                      Ignore <span className="text-blueSecondary">once</span>
                    </div>
                  </div>
                  <div className="flex gap-1.5">
                    <SparkleRadio checked={filter?.rule_ignore?.entire_domain} name="entire_domain" onChange={handleRadioChange} />{' '}
                    <div className="text-xs font-medium">
                      Ignore entire <span className="text-blueSecondary">domain for future</span>
                    </div>
                  </div>
                  <div className="flex gap-1.5">
                    <SparkleRadio checked={filter?.rule_ignore?.entire_email} name="entire_email" onChange={handleRadioChange} />{' '}
                    <div className="text-xs font-medium">
                      Ignore entire <span className="text-blueSecondary">email</span> for future
                    </div>
                  </div>
                </div>
                <div className="mx-3 my-2 flex gap-2">
                  <Button name="Save rules" customClass="w-full transition-all duration-300" py="py-1.5" onClick={() => handleSearchandSetRulesOnClick('setrules', setQueryParams, filter, setIsOpen)} />
                </div>
              </>
            )}
          </CustomMenu>
        </div>
      </div>

      <div className="h-[calc(100vh-180px)] overflow-auto overflow-x-hidden ">
        <div className={`mx-4 rounded-md border dark:border-darkBorder`}>
          {loading ? (
            <>{/* <InboxSkeleton /> */}</>
          ) : (
            <>
              {data?.data?.length ? (
                <div>
                  <div>
                    {data.data.map((item, index) => (
                      <UnmatchListRow data={item} index={index} key={item.uuid} updateIgnoreStatus={updateIgnoreStatusOnclick} mergeOption={mergeOption} count={data?.data?.length} roleStatus={roleStatus} avatarColor={colors[getRandomIndex()]} />
                    ))}
                  </div>
                  <div>{data?.total_count >= 25 && data?.total_count !== data?.data?.length && <InfiniteScrollComponent loadMore={FetchMoreUnmatcheData} loader={<SkeletonText mt="4" noOfLines={4} spacing="4" skeletonHeight="2" />} />}</div>
                </div>
              ) : (
                <figure className="mt-10 flex  h-[62vh] flex-col items-center justify-center">
                  <img src={AZ_BUCKET?.SPARKLE_ILLUSTRATION} alt="not found" className="w-1/2 md:w-1/3 lg:w-1/4" />
                  <figcaption className="mt-3 font-bold text-heading">No Data Found</figcaption>
                </figure>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UnmatchedEmails;
