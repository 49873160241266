import { SetStateAction, useEffect, useState } from 'react';
import MiniCalendar from 'components/common/calendar/MiniCalendar';

const DateTimePicker = (props: any) => {
  const { mergeDateAndTime } = props;
  // const [value, setValue] = useState(new Date().setDate(new Date().getDate() + 1));
  const [value, setValue] = useState(() => {
    const today = new Date();
    today.setDate(today.getDate() + 1);
    return today;
  });
  const onDateChange = (val: any) => {
    setValue(val);
  };
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');
  const [period, setPeriod] = useState('');

  const handleHoursChange = (e: { target: { value: any } }) => {
    const value = e.target.value;
    if (value === '') {
      setHours('');
    } else if (value >= 1 && value <= 12) {
      setHours(value);
    }
  };

  const handleMinutesChange = (e: { target: { value: any } }) => {
    const value = e.target.value;
    if (value >= 0 && value <= 59) {
      setMinutes(value);
    }
  };

  const handlePeriodChange = (e: { target: { value: SetStateAction<string> } }) => {
    setPeriod(e.target.value);
  };

  useEffect(() => {
    mergeDateAndTime(value.toString(), `${hours}:${minutes} ${period}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, minutes, period, hours]);

  return (
    <div className={` left-8 top-8 z-10 w-[18rem] rounded-md border border-blueSecondary bg-white`} id="calendar">
      <MiniCalendar value={new Date(value)} onChange={onDateChange} />
      <div className="custom-time-picker flex justify-center gap-2 py-3">
        <input type="number" value={hours} onChange={handleHoursChange} className="border px-1 outline-none" min="1" max="12" />
        <span>:</span>
        <input type="number" value={minutes} onChange={handleMinutesChange} className="border px-1 outline-none" min="0" max="59" />
        <span>:</span>
        <select value={period} onChange={handlePeriodChange} className="border px-1 outline-none">
          <option value="AM">AM</option>
          <option value="PM">PM</option>
        </select>
      </div>
    </div>
  );
};

export default DateTimePicker;
