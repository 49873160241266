// import Widget from 'components/widget';
import { FcGoogle } from 'react-icons/fc';
import { SiMicrosoftoffice } from 'react-icons/si';
import { MdOutlineAttachEmail } from 'react-icons/md';
import { BsFiletypeCsv } from 'react-icons/bs';
// import AddSMTPSingleAccount from '../add-smtp/features/SingleAccount';
// import AddNewSMTP from '../add-smtp';
import { useState } from 'react';
import BackNavigate from 'views/sender-accounts/components/BackNavigate';
import Widget from 'components/common/widget';
import { HandleGoogleOAuthSetup } from 'shared/sender-accounts/event-handler/add-email-account-event';
import AddSingleSmtpAccount from '../smtp/AddSingleSmtpAccount';
import AddMultipleSmtpAccount from '../smtp/AddMultipleSmtpAccount';

const AddEmailAccounts = () => {
    const [smtp, setSmtp] = useState({ flag: false, type: '' });
    return (
        <BackNavigate>
            <div className="flex items-start justify-center  ">
                <div className="mt-4 w-3/5 rounded-md bg-white dark:!bg-darkTwo shadow-dropShadow">
                    <h1 className="px-4 pb-2 pt-4 font-medium text-column dark:text-white">Add a new email account</h1>
                    <hr />
                    {smtp.flag && smtp.type === '' ? (
                        <div className="flex flex-col justify-center gap-5 px-12 py-10">
                            <div>
                                <Widget
                                    onClick={() => {
                                        setSmtp((prev) => ({ ...prev, type: 'upload' }));
                                        console.log('Drawer rendered in the DOM');
                                    }}
                                    icon={<BsFiletypeCsv size={35} className="text-column" />}
                                    title={''}
                                    subtitle={'Bulk import from  CSV'}
                                />
                            </div>
                            <div>
                                <Widget
                                    icon={<MdOutlineAttachEmail size={35} className="text-column" />}
                                    title={''}
                                    subtitle={'Single Account'}
                                    onClick={() => {
                                        setSmtp((prev) => ({ ...prev, type: 'single' }));
                                    }}
                                />
                            </div>
                        </div>
                    ) : smtp.flag && smtp.type === 'single' ? (
                        <div className="px-4">
                            <AddSingleSmtpAccount />
                        </div>
                    ) : smtp.flag && smtp.type === 'upload' ? (
                        <div className="px-4 pb-4">
                            <AddMultipleSmtpAccount setSmtp={setSmtp} />
                        </div>
                    ) : (
                        <div className="flex flex-col justify-center gap-5 px-12 py-10">
                            <div>
                                <Widget
                                    onClick={async () => await HandleGoogleOAuthSetup()}
                                    icon={<FcGoogle size={35} />}
                                    title={'Google'}
                                    subtitle={'Gmail / G-Suite'}
                                />
                            </div>
                            <div>
                                <Widget icon={<SiMicrosoftoffice size={35} color="red" />} title={'Microsoft'} subtitle={'Office 365 / Outlook'} comingSoon={true} />
                            </div>
                            <div>
                                <Widget
                                    onClick={() => setSmtp((prev) => ({ ...prev, flag: true }))}
                                    icon={<MdOutlineAttachEmail size={35} color="silver" />}
                                    title={'Any Provider'}
                                    subtitle={'IMAP / SMTP'}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </BackNavigate>
    );
};

export default AddEmailAccounts;
