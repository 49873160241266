import moment from 'moment';
import {
    filterByRule,
    getMatchOption,
    getMessageThread,
    getSenderAccountList,
    getUnmatchGroupList,
    getUnmatchList,
    linkedDeleteGroup,
    mergeCards,
    updatelinkedGroupList,
    updateUnmatchEmails,
    updateUnmatchIgnoreRule,
} from '../api-handler/unmatch-api';
import { unmatchedTableDataDto, QueryParamsDto, readHistoryDto, unmatchDataDto, emailDataDto } from 'dto/types/views/unmatched';
import React from 'react';
import { toast } from 'react-toastify';
import { base64Decode, jwtEncrypt, toastState } from 'utility/utils';
import { jwtDecode } from 'jwt-decode';
import { setReplyThreadData } from 'redux-store/crm';
import { fetchGrouplistTrigger, setfetchAllDataTrigger, setFetchGroupListData } from 'redux-store/unmatched';

export const fetchAllData = async (
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    queryParams: QueryParamsDto,
    setData: React.Dispatch<React.SetStateAction<unmatchedTableDataDto>>
) => {
    try {
        setLoading(true);
        const response = await getUnmatchList(queryParams);
        if (response.error) {
            console.log(response.error);
        }
        setData({ data: response.data.data, total_count: response?.data?.total_count });
    } catch (error) {
        console.log(error);
    } finally {
        setLoading(false);
    }
};

export const getRandomIndex = (min = 0, max = 5) => {
    return Math.floor(Math.random() * (max - min) + min);
};

export const fetchGrouplistData = async (dispatch: any) => {
    try {
        const response = await getUnmatchGroupList();
        if (response?.error) {
            toast.error(response?.message, toastState.error);
        } else {
            if (response?.data?.data && response?.data?.data?.length) {
                const finalData = await response?.data?.data?.sort((a: any, b: any) =>
                    a?.name.toLowerCase() < b?.name.toLowerCase() ? -1 : a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : 0
                );
                const states = await response?.data?.data?.reduce((acc: any, val: any) => {
                    acc[val.uuid] = { show: false, rename: false };
                    return acc;
                }, {});
                const payload: any = {
                    finalData,
                    states,
                };
                dispatch(setFetchGroupListData(payload));
            }
        }
    } catch (err) {
        console.log(err);
    }
};

export const updateIgnoreStatusOnclick = async (e: any, status: string, uuid: string, ignored_contact?: string) => {
    e.stopPropagation();
    try {
        const payload = {
            uuid: uuid,
            ignored_status: status,
            ignored_contact,
        };
        const response = await updateUnmatchIgnoreRule(payload);
        if (response.error) {
            console.log(response.error);
            return;
        }
        // fetchAllData();
    } catch (error) {
        console.error(error);
    }
};

export const handleStageOnClick = (stage: number, setFilter: React.Dispatch<React.SetStateAction<number>>) => {
    setFilter((prev: any) => ({
        ...prev,
        rules_type: stage,
    }));
};

export const handleClose = (setIsOpen: React.Dispatch<React.SetStateAction<boolean>>) => {
    setIsOpen(false);
};

export const handleInputChange = (key: string, event: any, setSelectedDate: React.Dispatch<React.SetStateAction<Date>>, setFilter: React.Dispatch<React.SetStateAction<any>>) => {
    const { name, value } = event?.target;
    if (event?.target.name === 'date') {
        if (!value) {
            setSelectedDate(null);
            setFilter((prev: any) => {
                const finalData: any = { ...prev };
                finalData[key][name] = '';
                return finalData;
            });
        } else {
            setSelectedDate(event?.target?.value);
            const date = new Date(event?.target.value);
            const isoString = date?.toISOString();

            setFilter((prev: any) => {
                const finalData: any = { ...prev };
                finalData[key][name] = isoString;
                return finalData;
            });
        }
    } else {
        setFilter((prev: any) => {
            const finalData: any = { ...prev };
            finalData[key][name] = value;
            return finalData;
        });
    }
};

export const handleMailSearchOnchange = (event: any, setQueryParams: React.Dispatch<React.SetStateAction<QueryParamsDto>>) => {
    setQueryParams((prev: QueryParamsDto) => ({
        ...prev,
        search: event?.target?.value,
    }));
};

export const handleRadioOnChange = (isChecked: boolean, name: string, setFilter: React.Dispatch<React.SetStateAction<any>>) => {
    setFilter((prev: any) => {
        const finalData: any = { ...prev };
        Object.keys(finalData?.rule_ignore).forEach((key) => {
            if (key !== 'apply_matched_results') {
                finalData.rule_ignore[key] = key === name ? isChecked : false;
            }
        });
        return finalData;
    });
};

export const handleClearFilterClick = (
    setFilter: React.Dispatch<React.SetStateAction<any>>,
    setQueryParams: React.Dispatch<React.SetStateAction<QueryParamsDto>>,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setSelectedDate: React.Dispatch<React.SetStateAction<Date | null>>
) => {
    setFilter({
        rules_type: 0,
        filter_search: {
            from: '',
            to: '',
            domain: '',
            subject: '',
            keywords: '',
            date: '',
        },
        rule_ignore: {
            once: false,
            entire_domain: false,
            entire_email: false,
        },
    });
    setQueryParams((prev: any) => ({
        ...prev,
        filter: '',
        limit: 25,
        offset: 2,
    }));
    setSelectedDate(null);
    handleClose(setIsOpen);
};

export const fetchDataForScroll = async (
    setIsFetching: any,
    setQueryParams: React.Dispatch<React.SetStateAction<QueryParamsDto>>,
    queryParams: QueryParamsDto,
    setData: React.Dispatch<React.SetStateAction<unmatchedTableDataDto>>
) => {
    try {
        setIsFetching(true);
        const response = await getUnmatchList(queryParams);
        const responseData: any = response.data;
        if (!responseData?.data) return setData({ data: [], total_count: 0 });
        if (responseData?.data?.length < 25) {
            setData((prev) => ({ data: [...prev?.data, ...responseData?.data], total_count: prev?.data?.length }));
        } else {
            setData((prev) => ({ ...prev, data: [...prev?.data, ...responseData?.data] }));
        }
        setQueryParams((prev) => ({
            ...prev,
            offset: prev?.offset + 1,
        }));
    } catch (error) {
        console.error(error);
    } finally {
        setIsFetching(false);
    }
};

export const handleSearchandSetRulesOnClick = async (
    action: string,
    setQueryParams: React.Dispatch<React.SetStateAction<QueryParamsDto>>,
    filter: any,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
    if (action === 'search') {
        try {
            setQueryParams((prev: any) => ({
                ...prev,
                filter: jwtEncrypt({ action: 'search', ...filter.filter_search }),
                limit: 25,
                offset: 2,
            }));
        } catch (error) {
            console.error(error);
        }
    } else {
        try {
            const ignoreStatus = filter.rule_ignore.once ? 'ignore_once' : filter.rule_ignore.entire_domain ? 'ignore_future_domain' : 'ignore_future_email';
            const response = await filterByRule({ params: jwtEncrypt({ action: 'rule', ...filter.filter_search, ignored_status: ignoreStatus }) });
            if (response.status === 200) {
                handleClose(setIsOpen);
            }
        } catch (error) {
            console.error(error);
        }
    }
};

export const searchBySender = async (setQueryParams: React.Dispatch<React.SetStateAction<QueryParamsDto>>, selectedsenderUuid: Array<string>, groupids: Array<string>) => {
    try {
        setQueryParams((prev: any) => ({
            ...prev,
            sender_data: jwtEncrypt({ sender_id: selectedsenderUuid, group_list: groupids }),
            offset: 2,
        }));
    } catch (error) {
        console.log(error);
    }
};

export const handleMerge = async (primaryEmailData: any, secondaryEmailData: any, onClose: () => void, dispatch: any) => {
    const payload = {
        prospect_details: primaryEmailData,
        unmatched_message_id: secondaryEmailData?.uuid,
    };
    onClose();
    try {
        const response = await mergeCards(payload);
        if (response?.status === 200) {
            toast.success(response?.message, toastState.success);
            onClose();
            dispatch(setfetchAllDataTrigger());
        } else {
            toast.error(`${response.message}`, toastState.error);
            onClose();
        }
    } catch (error) {
        console.log(error);
    }
};

export const handleReadMessagesState = (data: readHistoryDto, setReadMessages: React.Dispatch<React.SetStateAction<Array<string>>>) => {
    if (data.read_history.is_viewed) {
        setReadMessages((prev: any) => [...prev, data?.uuid]);
    }
};

export const formatIndianDateTime = (timestamp: string) => {
    const date = new Date(timestamp);

    const options: Intl.DateTimeFormatOptions = {
        timeZone: 'Asia/Kolkata',
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    };

    const formattedDate = date.toLocaleDateString('en-IN', options).replace(',', '');

    return `${formattedDate}`;
};

export const fetchDbEmails = async (setIsLoading: React.Dispatch<React.SetStateAction<boolean>>, setEmailData: React.Dispatch<React.SetStateAction<any>>, data: unmatchDataDto) => {
    try {
        setIsLoading(true);
        setEmailData((prev: any) => ({ ...prev, loading: true }));
        const response = await getMessageThread({ uuid: data.uuid });
        if (response?.data) {
            const name = response?.data[0]?.platform_message_details?.from?.address || response?.data[0]?.platform_message_details?.from?.address?.split('@')[0];
            const date = moment(response?.data[0]?.platform_message_details?.mail_sent_date_local).format('MMM DD, hh:mm A');
            let message: string;
            if (response?.data[0]?.platform_message_details?.email_type === 'smtp_imap') {
                message = response.data?.map((item: any) => {
                    const attachment = item?.platform_message_details?.inbox_mail_data?.attachments
                        ?.map((attachment: any) => {
                            if (attachment?.filename) {
                                return attachment;
                            } else {
                                return null;
                            }
                        })
                        .filter(Boolean);
                    return { fullMessage: item?.platform_message_details?.inbox_mail_data?.html || '', attachment, ...item };
                });
                // message = inbox_mail_data?.text
            } else if (response.data[0]?.platform_message_details?.email_type === 'oauth2') {
                message = response?.data?.map((item: any) => {
                    const base64String = item?.platform_message_details?.inbox_mail_data?.payload?.parts?.[1]?.body?.data?.replace(/-/g, '+')?.replace(/_/g, '/');
                    const base64String2 = item?.platform_message_details?.inbox_mail_data?.payload?.body?.data?.replace(/-/g, '+')?.replace(/_/g, '/');
                    // const fullMessage = base64String ? atob(base64String) : base64String2 ? atob(base64String2) : item?.platform_message_details?.inbox_mail_data?.snippet;
                    const fullMessage = base64String ? base64Decode(base64String) : base64String2 ? base64Decode(base64String2) : item?.platform_message_details?.inbox_mail_data?.snippet;
                    const attachment = item?.platform_message_details?.inbox_mail_data?.payload?.parts
                        ?.slice(1)
                        .map((attachment: any) => {
                            if (attachment?.filename) {
                                return {
                                    filename: attachment.filename,
                                    contentType: attachment.mimeType,
                                    content: attachment.body?.content,
                                    size: attachment.body?.size,
                                };
                            } else {
                                return null;
                            }
                        })
                        .filter((attachment: any) => attachment !== null);
                    return { fullMessage, attachment, ...item };
                });
                // message = decodeURIComponent(atob(base64String))
            }
            const profile = {
                name,
                receiver_email: response.data[0]?.platform_message_details?.from?.address,
                subject: response.data[0]?.platform_message_details?.subject,
                message,
                date,
            };
            setEmailData((prev: any) => ({ ...prev, profile }));
        }
    } catch (error) {
        console.log('Unmatched email fetching error', error);
    } finally {
        setIsLoading(false);
        setEmailData((prev: any) => ({ ...prev, loading: false }));
    }
};

export const downloadFile = (attachment: any, saveAs: any) => {
    if (attachment?.content?.data && attachment?.filename && attachment?.contentType) {
        const byteArray = new Uint8Array(attachment.content.data);
        const blob = new Blob([byteArray], { type: attachment.contentType });
        const filename = attachment.filename || 'attachment';
        saveAs(blob, filename);
    } else {
        console.error('Invalid attachment data');
    }
};
export const toggleIndexInArray = (
    index: number,
    setRowIndex: React.Dispatch<React.SetStateAction<Array<string>>>,
    emailData: emailDataDto,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setEmailData: React.Dispatch<React.SetStateAction<any>>,
    data: unmatchDataDto
) => {
    setRowIndex((prevArr: any) => {
        if (prevArr.includes(index)) {
            return prevArr.filter((item: any) => item !== index);
        } else {
            if (!emailData?.profile?.receiver_email) {
                fetchDbEmails(setIsLoading, setEmailData, data);
            }
            return [...prevArr, index];
        }
    });
};

export const handleAccordionClick = (
    event: React.MouseEvent,
    index: number,
    setRowIndex: React.Dispatch<React.SetStateAction<Array<string>>>,
    emailData: emailDataDto,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setEmailData: React.Dispatch<React.SetStateAction<any>>,
    data: unmatchDataDto
) => {
    const targetElement = event.target as Element;
    if (!targetElement.closest('.group-tags-unmatched') && !targetElement.closest('.match-search-unmatched')) {
        toggleIndexInArray(index, setRowIndex, emailData, setIsLoading, setEmailData, data);
    }
};

export const getSenderEmail = async (sender_id: string) => {
    try {
        const response = await getSenderAccountList({ sender_id });
        if (!response.error) {
            const senderDataDecode: any = await jwtDecode(response.data);
            return senderDataDecode?.account_details?.email;
        }
        return null;
    } catch (error) {
        console.error(error);
    }
};

export const handleReply = async (item: any, sender_id: string, setShowTyper: React.Dispatch<React.SetStateAction<boolean>>, dispatch: any) => {
    const createPayload = (from: any, to: any, item: any, sender_id: string) => ({
        main_message_id: item.main_message_id,
        from,
        to,
        delivered_to: item.delivered_to,
        subject: item.subject,
        sender_id,
        type: 'reply',
        individual_message_id: item?.oauth_delivery_message_id || item?.individual_message_id || '',
    });
    let payload;
    if (item.type === 'sender') {
        if (item?.email_type === 'smtp_imap') {
            payload = createPayload(item?.inbox_mail_data?.from?.value, item?.inbox_mail_data?.to?.value, item, sender_id);
        } else {
            payload = createPayload(item.from, item.to, item, sender_id);
        }
    } else {
        if (item?.email_type === 'smtp_imap') {
            if (item?.inbox_mail_data?.to?.value?.length > 1) {
                const senderEmail = await getSenderEmail(sender_id);
                if (senderEmail) {
                    const matchingFromAddresses = await item?.inbox_mail_data?.to?.value?.filter((addressObj: any) => addressObj.address === senderEmail);
                    // console.log(matchingFromAddresses, 'matchingFromAddresses');
                    payload = createPayload(matchingFromAddresses, item?.inbox_mail_data?.from?.value, item, sender_id);
                }
            } else {
                payload = createPayload(item?.inbox_mail_data?.to?.value, item?.inbox_mail_data?.from?.value, item, sender_id);
            }
        } else {
            if (item.to?.length > 1) {
                const senderEmail = await getSenderEmail(sender_id);
                if (senderEmail) {
                    const matchingFromAddresses = await item?.to.filter((addressObj: any) => addressObj.address === senderEmail);
                    payload = createPayload(matchingFromAddresses, item?.from, item, sender_id);
                }
            } else {
                payload = createPayload(item?.to, item?.from, item, sender_id);
            }
        }
    }
    setShowTyper(true);
    dispatch(setReplyThreadData(payload));
};

export const handleMainReply = (item: any, sender_id: string, setShowTyper: React.Dispatch<React.SetStateAction<boolean>>, dispatch: any) => {
    // const smtpPattern = /<[^>]+@[^>]+>/;
    let payload;
    //item?.inbox_mail_data?.cc?.value ? item?.inbox_mail_data?.cc?.value :
    // item?.inbox_mail_data?.bcc?.value ? item?.inbox_mail_data?.bcc?.value :
    if (item.type === 'sender') {
        if (item?.email_type === 'smtp_imap') {
            payload = {
                from: item?.from,
                to: item?.to,
                delivered_to: item.delivered_to,
                individual_message_id: item.individual_message_id,
                main_message_id: item.main_message_id,
                subject: item.subject,
                sender_id: sender_id,
                type: 'reply',
            };
        } else {
            payload = {
                main_message_id: item.main_message_id,
                from: item.from,
                to: item.to,
                delivered_to: item.delivered_to,
                // cc: [],
                // bcc: [],
                subject: item.subject,
                sender_id: sender_id,
                type: 'reply',
                individual_message_id: item?.oauth_delivery_message_id,
            };
        }
    } else {
        if (item?.email_type === 'smtp_imap') {
            payload = {
                main_message_id: item.main_message_id,
                from: item?.to,
                to: item?.from,
                delivered_to: item.delivered_to,
                subject: item.subject,
                sender_id: sender_id,
                type: 'reply',
                individual_message_id: item.individual_message_id,
            };
        } else {
            payload = {
                main_message_id: item.main_message_id,
                from: item.to,
                to: item.from,
                delivered_to: item.delivered_to,
                subject: item.subject,
                sender_id: sender_id,
                type: 'reply',
                individual_message_id: item?.oauth_delivery_message_id,
            };
        }
    }
    // const payload = item.type === 'sender' ? createPayload(item?.from, item?.to) : createPayload(item?.to, item?.from);
    setShowTyper(true);
    dispatch(setReplyThreadData(payload));
};

export const handleReplyAll = (data: any, sender_id: string, setShowTyper: React.Dispatch<React.SetStateAction<boolean>>, dispatch: any) => {
    setShowTyper(false);
    let payload;
    if (data.type === 'sender') {
        if (data?.email_type === 'smtp_imap') {
            payload = {
                from: data?.inbox_mail_data?.from?.value || [data?.from],
                to: data?.inbox_mail_data?.to?.value || [data?.to],
                delivered_to: data?.platform_message_details?.delivered_to,
                cc: data?.inbox_mail_data?.cc?.value ? data?.inbox_mail_data?.cc?.value : [],
                bcc: data?.inbox_mail_data?.bcc?.value ? data?.inbox_mail_data?.bcc?.value : [],
                subject: data?.subject,
                sender_id: sender_id,
                message: '',
                main_message_id: data?.main_message_id,
                individual_message_id: data?.individual_message_id,
                type: 'reply',
            };
        } else {
            payload = {
                main_message_id: data?.main_message_id,
                from: data?.from,
                to: data?.to,
                delivered_to: data?.delivered_to,
                cc: data?.cc ? data?.cc : [],
                bcc: data?.bcc ? data?.bcc : [],
                subject: data?.subject,
                sender_id: sender_id,
                message: '',
                type: 'reply',
                individual_message_id: data?.oauth_delivery_message_id,
            };
        }
    } else {
        if (data?.email_type === 'smtp_imap') {
            payload = {
                from: data?.inbox_mail_data?.to?.value || data?.to,
                to: data?.inbox_mail_data
                    ? [...data?.inbox_mail_data?.from?.value, ...data?.inbox_mail_data?.to?.value?.slice(1, data?.inbox_mail_data?.to?.value?.length)]
                    : [data?.from, ...data?.to?.slice(1, data?.to?.length)],
                delivered_to: data?.delivered_to,
                cc: data?.inbox_mail_data?.cc?.value ? data?.inbox_mail_data?.cc?.value : [],
                bcc: data?.inbox_mail_data?.bcc?.value ? data?.inbox_mail_data?.bcc?.value : [],
                subject: data.subject,
                sender_id: sender_id,
                type: 'reply',
                message: '',
                individual_message_id: data?.individual_message_id,
            };
        } else {
            payload = {
                main_message_id: data.main_message_id,
                from: data.to,
                to: [data?.from, ...(data?.to?.length > 1 ? data?.to.slice(1) : [])],
                delivered_to: data.delivered_to,
                cc: data.cc ? data.cc : [],
                bcc: data.bcc ? data.bcc : [],
                subject: data.subject,
                sender_id: sender_id,
                type: 'reply',
                message: '',
                individual_message_id: data?.oauth_delivery_message_id,
            };
        }
    }
    // const payload = item.type === 'sender' ? createPayload(item.from, item.to) : createPayload(item.to, item.from);
    dispatch(setReplyThreadData(payload));
    setShowTyper(true);
};

export const handleForward = (data: any, sender_id: string, setShowTyper: React.Dispatch<React.SetStateAction<boolean>>, dispatch: any) => {
    setShowTyper(false);
    let payload;
    // const smtpPattern = /<[^>]+@[^>]+>/;
    if (data.type === 'sender') {
        if (data?.email_type === 'smtp_imap') {
            const toEmails = data?.inbox_mail_data?.to?.value?.map((item: any) => item.address).join(', ');

            payload = {
                from: data?.inbox_mail_data?.from?.value,
                to: [],
                delivered_to: data?.platform_message_details?.delivered_to,
                cc: data?.inbox_mail_data?.cc ? data?.inbox_mail_data?.cc?.value : [],
                bcc: data?.inbox_mail_data?.bcc ? data?.inbox_mail_data?.bcc?.value : [],
                subject: data?.subject,
                sender_id: sender_id,
                main_message_id: data?.main_message_id,
                message: '',
                individual_message_id: data?.individual_message_id,
                type: 'forward',
                date: moment(data?.platform_message_details?.date).format('MMM DD, hh:mm A'),
                originalMessagedata: `<br> -------Forwarded Message-------<br>from: ${data?.inbox_mail_data?.from?.value?.[0]?.address}<br>Date: ${moment(
                    data?.platform_message_details?.date
                ).format('MMM DD, hh:mm A')}<br>Subject: ${data?.subject} <br>To: ${toEmails}<br><br>`,
            };
        } else {
            const toEmailoriginalData = Array.isArray(data?.to?.value) ? data?.to.value?.map((item: any) => item.address).join(', ') : data?.to?.address || '';
            payload = {
                main_message_id: data?.main_message_id,
                from: data?.from,
                to: [],
                delivered_to: data?.delivered_to,
                cc: data?.cc ? data?.cc : [],
                bcc: data?.bcc ? data?.bcc : [],
                subject: data?.subject,
                sender_id: sender_id,
                message: '',
                type: 'forward',
                date: moment(data?.platform_message_details?.date).format('MMM DD, hh:mm A'),
                individual_message_id: data?.oauth_delivery_message_id,
                originalMessagedata: `<br> -------Forwarded Message-------<br>from: ${data?.from?.address}<br>Date: ${moment(data?.platform_message_details?.date).format(
                    'MMM DD, hh:mm A'
                )}<br>Subject: ${data?.subject} <br>To: ${toEmailoriginalData}<br><br>`,
            };
        }
    } else {
        if (data?.email_type === 'smtp_imap') {
            const toEmaildata = data?.inbox_mail_data?.to?.value?.map((item: any) => item.address).join(', ');
            payload = {
                from: data?.inbox_mail_data?.to?.value,
                to: [],
                delivered_to: data.delivered_to,
                cc: data?.inbox_mail_data?.cc?.value ? data?.inbox_mail_data?.cc?.value : [],
                bcc: data?.inbox_mail_data?.bcc?.value ? data?.inbox_mail_data?.bcc?.value : [],
                subject: data.subject,
                message: '',
                sender_id: sender_id,
                individual_message_id: data?.individual_message_id,
                main_message_id: data?.main_message_id,
                type: 'forward',
                date: moment(data?.platform_message_details?.date).format('MMM DD, hh:mm A'),
                originalMessagedata: `<br> -------Forwarded Message-------<br>from: ${data?.inbox_mail_data?.from?.value?.[0]?.address}<br>Date: ${moment(
                    data?.platform_message_details?.date
                ).format('MMM DD, hh:mm A')}<br>Subject: ${data?.subject} <br>To: ${toEmaildata}<br><br>`,
            };
        } else {
            const toEmaildoauth = Array.isArray(data?.to) ? data?.to.value?.map((item: any) => item.address).join(', ') : data?.to?.address || '';
            payload = {
                main_message_id: data.main_message_id,
                from: data.to,
                to: [],
                delivered_to: data.delivered_to,
                cc: data.cc ? data.cc : [],
                bcc: data.bcc ? data.bcc : [],
                subject: data.subject,
                sender_id: sender_id,
                message: '',
                type: 'forward',
                date: moment(data?.platform_message_details?.date).format('MMM DD, hh:mm A'),
                individual_message_id: data?.platform_message_details?.oauth_delivery_message_id,
                originalMessagedata: `<br> -------Forwarded Message-------<br>from: ${data?.from?.address}<br>Date: ${moment(data?.platform_message_details?.date).format(
                    'MMM DD, hh:mm A'
                )}<br>Subject: ${data?.subject} <br>To: ${toEmaildoauth}<br><br>`,
            };
        }
    }
    // const payload = item.type === 'sender' ? createPayload(item.from, item.to) : createPayload(item.to, item.from);

    dispatch(setReplyThreadData(payload));
    setTimeout(() => {
        setShowTyper(true);
    }, 500);
};

export const handleMessageReadStatus = async (data: any, setReadMessages: React.Dispatch<React.SetStateAction<Array<string>>>) => {
    try {
        setReadMessages((prev) => [...prev, data.uuid]);
        if (data?.read_history?.is_viewed === false) {
            const payload = {
                uuid: data?.uuid,
                is_viewed: true,
            };
            const response = await updateUnmatchEmails(payload);
            if (response.error === true) {
                toast.error(response.message, toastState.error);
            }
        }
    } catch (error) {
        console.error(error);
    } finally {
    }
};

export const handleMergeSelect = async (primaryData: object, setPrimaryMergeData: any, setSecondaryMergeData: any, setOpenMergeModal: any, data: any) => {
    setPrimaryMergeData(primaryData);
    setSecondaryMergeData(data);
    setOpenMergeModal(true);
};

export const fetchMatchOptionData = async (mergeSearch: string, setMergeOption: any) => {
    const query = { search: mergeSearch };
    try {
        const response = await getMatchOption({ query: query });
        if (response.error) {
            console.log(response.error);
        } else {
            setMergeOption(response?.data?.data);
        }
    } catch (error) {
        console.log(error);
    }
};

export const handleSearch = (event: React.ChangeEvent<HTMLInputElement>, setMergeOption: any) => {
    event.stopPropagation();
    const query = event?.target?.value;
    fetchMatchOptionData(query, setMergeOption);
};

export const handleDeleteGroup = async (uuids: string, val: any, groupList: any, setGroupTags: any, row: any) => {
    let uuid: string;
    if (!uuids) {
        const id = groupList.find((group: { name: string }) => group?.name === val?.name);
        setGroupTags((prev: any) => {
            const updatedGroup = prev.filter((group: any) => group.name !== val?.name);
            return updatedGroup;
        });
        uuid = id?.uuid;
    } else {
        uuid = uuids;
        setGroupTags((prev: any) => {
            const updatedGroup = prev.filter((group: any) => group.uuid !== uuid);
            return updatedGroup;
        });
    }
    try {
        await linkedDeleteGroup({
            group_id: uuid,
            variant_id: row?.uuid,
        });
    } catch (error) {
        console.log('Group removing from email error', error);
    }
};


export const handleOnChange = async (event: any, groups: any, row: { uuid: any }, setIsOpen: React.Dispatch<React.SetStateAction<boolean>>, setSearchedGroup: any, setGroups: any, setGroupTags: any, dispatch: any) => {
    event.stopPropagation();
    if (event.key === 'Enter') {
        const groupName = groups.some((val: { name: string }) => val?.name?.toLowerCase() === event?.target?.value?.toLowerCase());
        if (groupName) return;
        const name = event?.target?.value?.toLowerCase().replace(/\b\w/g, (char: string) => char.toUpperCase());
        const params = { name: name, variant_id: row?.uuid };
        await handleCreateGroup(params, setGroups, setSearchedGroup, setGroupTags, dispatch);
        setIsOpen(false);
    } else {
        const searchedValue = event?.target?.value?.toLowerCase();
        const searchedData = groups.length && (searchedValue ? groups?.filter((group: { name: string }) => group?.name?.toLowerCase()?.includes(searchedValue)) : groups);
        setSearchedGroup(searchedData);
    }
};

// Create new group and assaign to email when user press enter keywork in search box
async function handleCreateGroup(params: any, setGroups: any, setSearchedGroup: any, setGroupTags: any, dispatch: any) {
    try {
        setGroups((prev: any) => {
            const updatedList = prev.length
                ? [params, ...prev].sort((a: any, b: any) => (a?.name.toLowerCase() < b?.name.toLowerCase() ? -1 : a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : 0))
                : [params];
            return updatedList;
        });
        setSearchedGroup((prev: any) => {
            const updatedList = prev.length
                ? [params, ...prev].sort((a: any, b: any) => (a?.name.toLowerCase() < b?.name.toLowerCase() ? -1 : a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : 0))
                : [params];
            return updatedList;
        });
        setGroupTags((prev: any) => [params, ...prev]);

        const response: any = updatelinkedGroupList(params)
        if (response.status === 200) {
            dispatch(fetchGrouplistTrigger());
        }
    } catch (error) {
        console.error(error);
    }
}

export const handleClickOutside = (event: MouseEvent, tooltipRef: any, toggleRef: any, setShowOpt: React.Dispatch<React.SetStateAction<boolean>>) => {
    if (tooltipRef?.current && !tooltipRef?.current.contains(event.target) && !toggleRef?.current?.contains(event.target)) {
        setShowOpt(false);
    }
}

export const handleClick = async (val: any, setGroupTags: any, row: any, dispatch: any) => {
    setGroupTags((prev: any) => {
        const groupName = prev?.some((group: { uuid: string }) => group?.uuid === val.uuid);
        if (groupName) return prev;
        return [val, ...prev];
    });
    const params = { group_id: val?.uuid, variant_id: row?.uuid };
    try {
        const response = await updatelinkedGroupList(params)
        if (response.status === 200) {
            dispatch(fetchGrouplistTrigger());
        }
    } catch (error) {
        console.error(error);
    }
};