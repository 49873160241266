/* eslint-disable no-useless-escape */
const REGEX_PATTERNS = {
  EMAIL: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
  PHONE: /^\+?([0-9]{1,4})?\s?-?\.?\(?\d{1,4}\)?\s?-?\.?\d{1,4}\s?-?\.?\d{1,9}$/,
  URL: /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-z]{2,6})(\/[a-zA-Z0-9#-_.?&%=]*)?$/,
  DATE: /^\d{4}-\d{2}-\d{2}$/,
  ZIP_CODE: /^\d{5}(-\d{4})?$/,
  HEX_COLOR_CODE: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
  CREDIT_CARD: /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|3[47][0-9]{13}|6(?:011|5[0-9]{2})[0-9]{12})(?:[\s-]?[0-9]{4})?$/,
  IP_V4: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
  SLUG_PATTERN: /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
  USERNAME: /^[a-zA-Z0-9_.]{3,20}$/,
  NAME: /^[aA-zZ\s]+$/,
  ALPHA_NUMERIC: /^[a-zA-Z0-9\s]+$/,
  HTML_TAG: /<\/?[\w\s="/.':;#-\/]+>/,
  WHITE_SPACE: /^\s*$/,
  SMALL_ALPHA: /[a-z]/,
  CAPS_ALPHA: /[A-Z]/,
  NUMBER: /[0-9]/,
  SPECIAL_CHAR: /[@$!%*#?&]/,
};

export default REGEX_PATTERNS;
