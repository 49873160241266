import React from 'react';
import { RiVerifiedBadgeFill } from 'react-icons/ri';
import { MdMonitor, MdPhoneAndroid } from 'react-icons/md';
import moment from 'moment';
import { toast } from 'react-toastify';
import { TbLockCode, TbPasswordFingerprint } from 'react-icons/tb';
import { FaApple } from 'react-icons/fa';
import { SiPostman } from 'react-icons/si';

import { useAppSelector } from 'redux-store';
import { BaseApi } from 'api/services/base-api';
import { setAndGetQueryParams, toastState } from 'utility/utils';

const Button = React.lazy(()=> import('components/common/Button'));
const InfiniteScrollComponent = React.lazy(()=> import('components/common/infinityScroll'));
const TwoStepAuth = React.lazy(()=>import('../components/TwoStepAuth'));
const ChangePassword = React.lazy(()=>import('../components/ChangePassword'));

const Security = () => {
  const [handleState, setHandleState] = React.useState({
    twoStep: false,
    changePassword: false,
    logDetails: {
      twoFactorAuthenticationStatus: false,
      email: '',
      devices: [],
    },
    loading: false,
    initialLoading: true,
    twofactloading: false,
    showButton: false,
    totalCount: 0,
  });

  const globalConfig = useAppSelector((state) => state?.globalsettings?.globalConfiguration);

  const [queryParams, setQueryParams] = React.useState({
    limit: 25,
    offset: 1,
  });

  const loggedUser = useAppSelector((state) => state?.auth?.loggedUser);

  const handleDisableUserTwoFactorAuthetication = async () => {
    try {
      setHandleState((prevState) => ({
        ...prevState,
        twofactloading: true,
      }));

      const updateResponse = await BaseApi.patch(`/auth/user/2fa/disable`, {});
      if (!updateResponse?.error) {
        toast.success('2FA Disabled Successfully', toastState.success);
        setHandleState((prevState) => ({
          ...prevState,
          twofactloading: false,
          logDetails: {
            ...prevState.logDetails,
            twoFactorAuthenticationStatus: false,
          },
        }));
      } else {
        setHandleState((prevState) => ({
          ...prevState,
          twofactloading: false,
        }));
      }
    } catch (error) {
      setHandleState((prevState) => ({
        ...prevState,
        loading: false,
      }));
      console.log(error);
    }
  };

  const fetchUserLogDetails = async () => {
    try {
      // !handleState?.twoStep &&
      //   setHandleState((prevState) => ({
      //     ...prevState,
      //     loading: true,
      //   }));
      const query = setAndGetQueryParams([
        { key: 'limit', value: queryParams?.limit },
        { key: 'offset', value: queryParams?.offset },
      ]);
      const userLogResponse = await BaseApi.get(`/auth/user/log/details?${query}`);
      if (!userLogResponse?.error) {
        setHandleState((prevState) => ({
          ...prevState,
          showButton: true,
          logDetails: {
            ...prevState.logDetails,
            email: userLogResponse?.data?.email,
            devices: userLogResponse?.data?.data,
            twoFactorAuthenticationStatus: userLogResponse?.data?.two_factor_authentication_enabled,
          },
          totalCount: userLogResponse?.data?.total_records?.count,
        }));
      }
      setHandleState((prevState) => ({
        ...prevState,
        initialLoading: false,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    !handleState?.twoStep && fetchUserLogDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Scroll function
  const scrollFunction = React.useCallback(
    async (setIsFetching: any) => {
      const fetchEntryList = async () => {
        try {
          setIsFetching(true);
          const query = setAndGetQueryParams([
            { key: 'limit', value: queryParams?.limit },
            { key: 'offset', value: queryParams?.offset + 1 },
          ]);
          const response = await BaseApi.get(`/auth/user/log/details?${query}`);
          const responseData: any = response.data;
          if (!responseData?.data) return;
          setHandleState((prevState) => ({
            ...prevState,
            logDetails: {
              ...prevState.logDetails,
              devices: [],
            },
          }));
          if (responseData?.data?.length < 25) {
            setHandleState((prevState) => ({
              ...prevState,
              logDetails: {
                ...prevState.logDetails,
                devices: [...handleState?.logDetails?.devices, ...responseData?.data],
              },
            }));
            // setData((prev) => ({ ...prev, data: [...prev?.data, ...responseData?.data_list], count: prev?.data?.length }));
          } else {
            setHandleState((prevState) => ({
              ...prevState,
              logDetails: {
                ...prevState.logDetails,
                devices: [...handleState?.logDetails?.devices, ...responseData?.data],
              },
            }));
            // setData((prev) => ({ ...prev, data: [...prev?.data, ...responseData?.data_list] }));
          }
          setQueryParams((prev) => ({
            ...prev,
            offset: prev?.offset + 1,
          }));
        } catch (error) {
          console.log(error);
        } finally {
          setIsFetching(false);
        }
      };

      handleState?.logDetails?.devices?.length && handleState?.logDetails?.devices?.length < handleState?.totalCount && fetchEntryList();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleState?.logDetails?.devices?.length, queryParams?.offset]
  );

  return (
    <div className="mt-2 flex min-h-[90vh] w-full overflow-auto rounded-md bg-white p-2  dark:bg-darkOne dark:text-white">
      <div className="flex w-2/3 flex-grow flex-col p-4">
        <span className="text-md font-semibold">Authentication Methods</span>

        <div className="mt-2 flex items-center  gap-3">
          <div className="h-px w-full bg-gray-200 dark:!bg-darkBorder" />
        </div>
        {/* CHange Password */}
        <div className="mt-4">
          <div className="mt-4 flex gap-2">
            <TbPasswordFingerprint className="rounded-full bg-pink-100 p-2 text-purple-700" size={38} />
            <div className="flex flex-grow flex-col">
              <span className="text-md font-semibold">Log in with a password</span>
              <span className="text-xs dark:text-gray-600">Use a strong password to login to your sparkle account.</span>
            </div>

            <div>
              {handleState?.showButton && (
                <Button
                  name={`${loggedUser?.is_google ? `Create Password` : `Change Password`}`}
                  onClick={() =>
                    setHandleState((prevState) => ({
                      ...prevState,
                      changePassword: true,
                    }))
                  }
                  customClass="security_page_change_password bg-white dark:!bg-gray-100 border w-full !text-purple-800 font-semibold text-[.8125rem] active:scale-95 transition-transform duration-150 ease-out"
                />
              )}
            </div>
          </div>
          <div className="mt-2 flex items-center  gap-3">
            <div className="h-px w-full bg-gray-200 dark:!bg-darkBorder" />
          </div>
        </div>

        {/* Google signin disable
        <div className="mt-4">
          <div className="mt-4 flex gap-2">
            <FaGooglePlusG className="rounded-full bg-pink-100 p-2 text-purple-700" size={38} />
            <div className="flex flex-grow flex-col">
              <span className="text-md font-semibold">Sign in with Google</span>
              <span className="text-xs">Use {<span className=" font-semibold">{handleState?.logDetails?.email}</span>} Google’s credentials to login in Sparkle</span>
            </div>

            <div>
              {handleState?.showButton && (
                <Button
                  name="Disable"
                  // onClick={handleClose}
                  customClass="bg-white border w-full !text-red-500 !border-red-500 font-semibold text-[.8125rem] active:scale-95 transition-transform duration-150 ease-out"
                />
              )}
            </div>
          </div>
          <hr className="mt-4 w-full" />
        </div> */}

        {/* Two factor authendication */}
        <div className="mt-4">
          <div className="mt-4 flex gap-2">
            <TbLockCode className="rounded-full bg-pink-100 p-2 text-purple-700" size={38} />
            <div className="flex flex-grow flex-col">
              <span className="text-md font-semibold">Two-factor authentication</span>
              <span className="text-xs dark:text-gray-600">Add additional verification to your account.</span>
            </div>

            <div className="flex items-center">
              {globalConfig?.user_setting_details?.data?.two_factor_authentication_enabled && <RiVerifiedBadgeFill size={36} className="mr-2 text-green-500" />}
              {handleState?.showButton && (
                <Button
                  disable={handleState?.twofactloading}
                  loading={handleState?.twofactloading}
                  name={`${globalConfig?.user_setting_details?.data?.two_factor_authentication_enabled ? `Disable` : `Enable`}`}
                  onClick={() =>
                    globalConfig?.user_setting_details?.data?.two_factor_authentication_enabled
                      ? handleDisableUserTwoFactorAuthetication()
                      : setHandleState((prevState) => ({
                          ...prevState,
                          twoStep: true,
                        }))
                  }
                  customClass={`bg-white border w-full security_page_enable_2fa ${globalConfig?.user_setting_details?.data?.two_factor_authentication_enabled ? `!text-red-500 !border-red-500` : `!text-purple-800`}  font-semibold text-[.8125rem] active:scale-95 transition-transform duration-150 ease-out`}
                />
              )}
            </div>
          </div>
          <div className="mt-2 flex items-center  gap-3">
            <div className="h-px w-full bg-gray-200 dark:!bg-darkBorder" />
          </div>
        </div>
      </div>

      {/* Logged Devices details */}
      <div className="flex w-1/3 flex-grow flex-col p-4">
        <span className="text-md font-semibold">Where you’re logged in</span>
        <span className="text-wrap text-xs dark:text-gray-600 ">We’ll alert you via {<span className=" font-semibold">{handleState?.logDetails?.email}</span>} if there is any unusual activity on your account.</span>
        <div className="mt-2 flex items-center  gap-3">
          <div className="h-px w-full bg-gray-200 dark:!bg-darkBorder" />
        </div>

        <div className="m-2 h-[71vh] overflow-auto px-2">
          <div>
            {handleState?.initialLoading
              ? [...Array(10)].map((_, index) => (
                  <div key={index} className="mb-5 flex h-8 w-60 animate-pulse cursor-pointer select-none rounded-md">
                    <div className="flex h-full w-full flex-col items-start justify-center">
                      <div className="h-3 w-full rounded bg-gray-300"></div>
                      <div className="mt-1 h-2 w-3/4 rounded bg-gray-300"></div>
                    </div>
                  </div>
                ))
              : handleState?.logDetails?.devices?.length &&
                handleState?.logDetails?.devices?.map((item, index) => (
                  <div className="mt-3 flex items-center gap-2">
                    {item?.isiPhone ? (
                      <FaApple size={18} className="-mt-3" />
                    ) : item?.platform === 'Apple Mac' ? (
                      <FaApple size={18} className="-mt-3" />
                    ) : item?.platform === 'iPhone' ? (
                      <FaApple size={18} className="-mt-3" />
                    ) : item?.isDesktop ? (
                      <MdMonitor size={18} className="-mt-3" />
                    ) : item?.isBot === 'postman' ? (
                      <SiPostman size={18} className="-mt-3" />
                    ) : (
                      <MdPhoneAndroid size={18} className="-mt-3" />
                    )}
                    <div>
                      <span className="flex gap-2 text-sm font-semibold">
                        {item?.browser} • {item?.os}
                        {index === 0 && (
                          <div className="flex items-center space-x-2 rounded-full bg-green-100 px-2">
                            <span className="relative flex h-2 w-2">
                              <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-green-500 text-xs opacity-75"></span>
                              <span className="relative inline-flex h-2 w-2 rounded-full bg-green-600 text-xs"></span>
                            </span>
                            <span className="text-xs font-semibold !text-blackDark">Active</span>
                          </div>
                        )}
                      </span>
                      <span className="flex gap-2 text-xs text-column dark:text-gray-600">
                        {item?.region && item?.region + ' , '} {item?.country_name && item?.country_name + '  • '} {moment(new Date(item?.login_time)).format('DD MMM [at] hh:mm a')}
                      </span>
                    </div>
                  </div>
                ))}
          </div>
          <InfiniteScrollComponent
            customClassName="!bg-white"
            loadMore={scrollFunction}
            loader={
              <div key={`infiniteLoaderDevices`} className="mb-5 flex h-8 w-60 animate-pulse cursor-pointer select-none rounded-md">
                <div className="flex h-full w-full flex-col items-start justify-center">
                  <div className="h-3 w-full rounded bg-gray-300"></div>
                  <div className="mt-1 h-2 w-3/4 rounded bg-gray-300"></div>
                </div>
              </div>
            }
          />
        </div>
      </div>

      <TwoStepAuth
        setState={setHandleState}
        isOpen={handleState?.twoStep}
        onClose={() =>
          setHandleState((prevState) => ({
            ...prevState,
            twoStep: false,
            initialLoading: false,
          }))
        }
        email={handleState?.logDetails?.email}
      />

      {handleState?.changePassword && (
        <ChangePassword
          isOpen={handleState?.changePassword}
          onClose={() =>
            setHandleState((prevState) => ({
              ...prevState,
              changePassword: false,
            }))
          }
        />
      )}
    </div>
  );
};

export default Security;
