import { APP_FEATURE_ENVIRONMENT, AUTH_USER_CONFIGURATION, MIDDLEWARE_CONFIG, NOTIFY_OWNER_PAYMENT } from "api/endpoints/layoutEndpoints"
import { BaseApi } from "api/services/base-api"
import { AxiosResponse } from "axios"

export const GetAuthUserConfigurationAPI = async (key: string) => {
    try {
      const response: AxiosResponse<any, any> = await BaseApi.get(AUTH_USER_CONFIGURATION(key))
      return response
    } catch (error) {
      console.error('User Configuration Get Error occured ', error)
      throw error
    }
  }

export const NotifyOwnerPaymentAPI = async (uuid: string) => {
    try {
      const response: AxiosResponse<any, any> = await BaseApi.post(NOTIFY_OWNER_PAYMENT, { uuid })
      return response
    } catch (error) {
      console.error('Notifying Owner Payment Error occured ', error)
      throw error
    }
  }

export const GetAppFeatureEnvironmentAPI = async () => {
    try {
      const response: AxiosResponse<any, any> = await BaseApi.get(APP_FEATURE_ENVIRONMENT)
      return response
    } catch (error) {
      console.error('Fetching Environment Feature Error occured ', error)
      throw error
    }
  }

export const GetConfigMiddlewareAPI = async () => {
    try {
      const response: AxiosResponse<any, any> = await BaseApi.get(MIDDLEWARE_CONFIG)
      return response
    } catch (error) {
      console.error('Get Config Middleware Error occured ', error)
      throw error
    }
  }
