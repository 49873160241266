/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { SkeletonText } from '@chakra-ui/react';
import { fetchDataScrollEvent, fetchGroupLinkedWithInbox, fetchInboxData, handleClearConversation, hanleDeleteGroupFromSenderList } from 'shared/inbox/event-handler/inbox-event';
import { setAndGetQueryParams, toastState, useDebounce } from 'utility/utils';
import { useAppDispatch, useAppSelector } from 'redux-store';
import MessageRow from '../component/email/MessageRow';
import CommonLayout from '../component/common-Layout/CommonLayout';
import { AZ_BUCKET } from 'config/appConfig';
import { setInboxlistData, setOffset, setSnoozeId, setSnoozePopupState } from 'redux-store/unified-inbox';
import InboxSkeleton from '../component/inbox-skeleton/InboxSkeleton';
import NotificationListRow from '../component/notification/NotificationListRow';
import InfiniteScrollComponent from 'components/common/infinityScroll';
import SnoozPickerPopup from '../component/email/SnoozPickerPopup';
import { BaseApi } from 'api/services/base-api';
// import ClearEmailAlertPopup from './feature/ClearEmailAlertPopup';

const AllInboxData = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [mesagedata, setMessageData] = useState({
    totalCount: 0,
    data: [],
  });
  const custom = useAppSelector((state: { custom: any }) => state?.custom);
  const [fetchDataTrigger, setFetchDataTrigger] = useState(false);
  const { clearSelectData, inbox_status, isSnoozePopupOpen, searchQuery, limit, offset, fetchGrouplistTrigger, filter } = useAppSelector((state: { unifiedInboxData: any }) => state.unifiedInboxData);
  const colors = ['#4318FF', '#E7C100', '#7F56D9', '#7E4400', '#12B76A'];
  function getRandomIndex(min = 0, max = 5) {
    return Math.floor(Math.random() * (max - min) + min);
  }

  const debouncedSearchValue = useDebounce(searchQuery, 300);
  useEffect(() => {
    // (async () => {
    //   try {
    //     setLoading(true);
    //     const query = setAndGetQueryParams([
    //       { key: 'search', value: searchQuery },
    //       { key: 'limit', value: limit },
    //       { key: 'filter', value: filter },
    //     ]);
    //     const response = await BaseApi.get(`/unified/inbox/${inbox_status}?${query}`);
    //     if (response?.error) {
    //       toast.error(response?.message, toastState.error);
    //     } else {
    //       dispatch(setInboxlistData(response?.data?.data));
    //       setMessageData({
    //         totalCount: response?.data?.total_count,
    //         data: response?.data?.data,
    //       });
    //       // setMessageData(response.data);
    //     }
    //   } catch (err) {
    //     console.log(err);
    //   } finally {
    //     setLoading(false);
    //   }
    // })();
    console.log('1');
    fetchInboxData(setLoading, setMessageData, searchQuery, limit, filter, dispatch, inbox_status);
  }, [fetchDataTrigger, inbox_status, debouncedSearchValue, custom?.globalDependencyLoading, filter]);

  useEffect(() => {
    fetchGroupLinkedWithInbox(dispatch);
  }, [fetchGrouplistTrigger]);

  const InboxFetch = useCallback(
    async (setIsFetching: any) => {
      mesagedata?.data?.length && mesagedata?.data?.length < mesagedata?.totalCount && fetchDataScrollEvent(setIsFetching, setMessageData, searchQuery, limit, filter, offset, setOffset, dispatch, inbox_status);
    },
    [mesagedata?.data?.length, debouncedSearchValue, offset, filter]
  );

  const handleFetchDataTrigger = () => {
    setFetchDataTrigger((prev) => !prev);
  };

  return (
    <div>
      <CommonLayout handleClearConversation={handleClearConversation}>
        {loading ? (
          <InboxSkeleton />
        ) : (
          <>
            {!mesagedata?.data?.length ? (
              <figure className="mt-10 flex  h-[62vh] flex-col items-center justify-center">
                <img src={AZ_BUCKET?.SPARKLE_ILLUSTRATION} alt="not found" className="w-1/2 md:w-1/3 lg:w-1/4" />
                <figcaption className="mt-3 font-bold text-heading">No Data Found</figcaption>
              </figure>
            ) : (
              <div className="h-[calc(100vh-180px)] overflow-auto ">
                <div className="rounded-md border dark:!border-none">
                  {mesagedata?.data?.map((messagelist: any, index) => {
                    if (messagelist?.variant === 'email') {
                      return <MessageRow index={index} key={messagelist.uuid} inboxData={messagelist} fetchTrigger={handleFetchDataTrigger} count={mesagedata?.data?.length} AvatarColor={colors[getRandomIndex()]} hanleDeleteGroupFromSenderList={hanleDeleteGroupFromSenderList} />;
                    } else if (messagelist?.variant === 'notification') {
                      return <NotificationListRow index={index} key={messagelist.uuid} inboxData={messagelist} fetchTrigger={handleFetchDataTrigger} count={mesagedata?.data?.length} AvatarColor={colors[getRandomIndex()]} />;
                    } else {
                      return null; // Optionally handle other variants or return null for unhandled variants
                    }
                  })}
                </div>
                <div>{mesagedata?.totalCount >= 25 && mesagedata?.totalCount !== mesagedata?.data?.length && <InfiniteScrollComponent loadMore={InboxFetch} loader={<SkeletonText mt="4" noOfLines={4} spacing="4" skeletonHeight="2" />} />}</div>
              </div>
            )}
          </>
        )}
        <div>
          <SnoozPickerPopup
            isOpen={isSnoozePopupOpen}
            onClose={() => {
              dispatch(setSnoozePopupState(false));
              dispatch(setSnoozeId(''));
            }}
            FetchDataTrigger={handleFetchDataTrigger}
          />
        </div>
      </CommonLayout>
    </div>
  );
};

export default AllInboxData;
