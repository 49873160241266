import { FaSyncAlt } from 'react-icons/fa';
import { FaCirclePause, FaCirclePlay } from 'react-icons/fa6';
import { MdCloudDownload, MdDelete } from 'react-icons/md';

interface GlobalOptProps {
  handleOptions?: any;
  selectedCount?:any
}

const GlobalOption = (props: GlobalOptProps) => {
  const { handleOptions ,selectedCount} = props;
  return (
    <div
      id="globalOpt"
      className="absolute bottom-8 left-1/2 flex -translate-x-1/2 rounded-md bg-blackDark"
    >
      <div
        className="flex cursor-default items-center gap-2 rounded-s-md px-4 py-3.5 text-white transition-all hover:bg-brand-500"
        // onClick={() => handleOptions('download')}
      >
        <h5 className="text-sm">{selectedCount?.length || 0} Selected</h5>
      </div>
      <div
        className="flex cursor-default items-center gap-2 rounded-s-md px-4 py-3.5 text-white transition-all hover:bg-brand-500"
        onClick={() => handleOptions('download')}
      >
        <MdCloudDownload />
        <h5 className="text-sm">Download</h5>
      </div>
      <div
        className="flex cursor-default items-center gap-2 px-4 py-3.5 text-white transition-all hover:bg-brand-500"
        onClick={() => handleOptions('delete')}
      >
        <MdDelete />
        <h5 className="text-sm">Delete</h5>
      </div>
      <div
        className="flex cursor-default items-center gap-2 rounded-e-md px-4 py-3.5 text-white transition-all hover:bg-brand-500"
        onClick={() => handleOptions('pause')}
      >
        <FaCirclePause />
        <h5 className="text-sm">Pause</h5>
      </div>
      <div
        className="flex cursor-default items-center gap-2 rounded-e-md px-4 py-3.5 text-white transition-all hover:bg-brand-500"
        onClick={() => handleOptions('resume')}
      >
        <FaCirclePlay />
        <h5 className="text-sm">Resume</h5>
      </div>
      <div
        className="flex cursor-default items-center gap-2 rounded-e-md px-4 py-3.5 text-white transition-all hover:bg-brand-500"
        onClick={() => handleOptions('recheck')}
      >
        <FaSyncAlt />
        <h5 className="text-sm">Recheck</h5>
      </div>
    </div>
  );
};

export default GlobalOption;
