import * as Yup from 'yup';
import REGEX_PATTERNS from 'constants/regex-patterns';

export const smtpValidationSchema = Yup.object({
  first_name: Yup.string().trim().required('First name is required'),
  last_name: Yup.string().trim().required('Last name is required'),
  email: Yup.string().trim().required('Email is required').matches(REGEX_PATTERNS.EMAIL, 'Please enter a valid email address'),
  smtp_username: Yup.string().trim().required('SMTP username is required'),
  smtp_password: Yup.string().trim().required('SMTP password is required'),
  smtp_host: Yup.string()
    .trim()
    .matches(
      /^(?!:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(:\d{1,5})?$|^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(:\d{1,5})?$/,
      'Invalid SMTP host format'
    )
    .required('SMTP host is required'),
  smtp_port: Yup.string().trim().required('SMTP port is required'),
  smtp_secure: Yup.string().trim().required('SMTP secure is required'),
  imap_username: Yup.string().trim().required('Imap username is required'),
  imap_password: Yup.string().trim().required('Imap password is required'),
  imap_host: Yup.string().trim().required('Imap host is required'),
  imap_port: Yup.string().trim().required('Imap port is required'),
  imap_secure: Yup.string().trim().required('Imap secure is required'),
  daily_limit: Yup.number().required('Daily limit is required'),
  delay_between_emails: Yup.number().required('Delay b/w email is required'),
});
