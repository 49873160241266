import React from 'react';
import { Avatar } from '@chakra-ui/react';
import { BiHistory } from 'react-icons/bi';
import { FaInstagramSquare } from 'react-icons/fa';
import { FaLinkedin, FaTwitter } from 'react-icons/fa6';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { RiMessage2Line } from 'react-icons/ri';
import { calculateTimeAgo } from 'utility/utils';
import { setOppertunityDomain, setOppertunityId, setOpportunityInboxViewData, setProspectDetails } from 'redux-store/crm';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';


interface ContactCardType {
  multicard?: boolean;
  handleData?: (id: number, data: any, viewMore: boolean) => void;
  data?: any;
  viewMore?: any;
  id?: number;
  viewMultiCard?: boolean;
}
const ContactCard = (props: ContactCardType) => {
  const { data } = props;
  console.log(data, 'data');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const colors = ['#4318FF', '#E7C100', '#7F56D9', '#7E4400', '#12B76A'];

  const dateFormatter = (dateValue: string) => {
    const date = new Date(dateValue);
    // Convert to readable format (e.g., "20 June")
    const readableDate = date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'long',
    });
    return readableDate;
  };
  function getRandomIndex(min = 0, max = 5) {
    return Math.floor(Math.random() * (max - min) + min);
  }

  const handleClick = () => {
    dispatch(setOppertunityId(data?.uuid));
    dispatch(setOppertunityDomain(data?.opportunity?.prospect_email_domain));

    dispatch(
      setOpportunityInboxViewData({
        opportunity_id: data?.uuid,
        prospect_email_domain: data?.opportunity?.prospect_email_domain,
        brand_id: data.brand_id,
        pipeline_id: data.pipeline_id,
      })
    );

    console.log(
      data,
      {
        opportunity_id: data?.uuid,
        prospect_email_domain: data?.opportunity?.prospect_email_domain,
        brand_id: data.brand_id,
        pipeline_id: data.pipeline_id,
      },
      '>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>'
    );
    dispatch(setProspectDetails({ ...data, opportunity: [data.opportunity] }));
    navigate(`/crm/inbox?opportunityid=${data?.uuid}`);
  };
  return (
    <div className="relative flex cursor-pointer flex-col items-center justify-center" onClick={() => handleClick()}>
      <div className=" mt-0.5 flex w-full gap-2 rounded-md border border-[#e7ebf5] px-2.5 py-3 shadow-bottom">
        <div className="pointer-events-none flex flex-col items-center gap-1">
          <Avatar
            style={{ height: '18px', width: '18px' }}
            size={'xs'}
            className="border"
            src={data?.prospect_enrich_details[0]?.source_details?.photo_url}
            name={data?.prospect_enrich_details[0]?.source_details?.email?.split('@')[0][0]}
            bg={colors[getRandomIndex()]}
            color={'white'}
          />
          {data?.prospect_enrich_details[0]?.source_details?.facebook_url ? (
            <div>
              <a href={data?.prospect_enrich_details[0]?.source_details?.facebook_url} target="_blank" rel="noopener noreferrer">
                <FaInstagramSquare className="size-3 rounded-full text-paused-row" />
              </a>
            </div>
          ) : null}
          {data?.prospect_enrich_details[0]?.source_details?.twitter_url ? (
            <div>
              <a href={data?.prospect_enrich_details[0]?.source_details?.twitter_url} target="_blank" rel="noopener noreferrer">
                <FaTwitter className="size-3 rounded-full text-paused-row" />
              </a>
            </div>
          ) : null}

          {data?.prospect_enrich_details[0]?.source_details?.linkedin_url ? (
            <div>
              <a href={data?.prospect_enrich_details[0]?.source_details?.linkedin_url} target="_blank" rel="noopener noreferrer">
                <FaLinkedin className="size-3 rounded-full text-paused-row" />
              </a>
            </div>
          ) : null}
        </div>
        <div className="pointer-events-none mt-0.5 flex w-full flex-col gap-0.5">
          <div className="flex w-full justify-between">
            <div className="flex gap-2">
              <h5 className="text-[0.65rem] font-bold text-heading">
                {data.opportunity?.prospect_name ? data.opportunity?.prospect_name : data.opportunity?.prospect_email?.split('@')[0]}
              </h5>
              <p className="text-[0.65rem] font-medium text-column">{dateFormatter(data.cdate)}</p>
            </div>
            <div className="flex gap-1">
              <HiOutlineExternalLink className={`rounded-full border p-0.5 text-sm text-heading `} />
              <div className="relative flex">
                <RiMessage2Line className={`text-md rounded-full border p-0.5 text-column `} />
                {data.opportunity?.message_total_count > 0 && (
                  <span className="absolute bottom-[0.55rem] left-[0.8rem] flex size-[0.7rem] items-center  justify-center rounded-full bg-[#ede8ff] text-[0.4rem] font-bold text-blueSecondary">
                    {data.opportunity?.message_total_count}
                  </span>
                )}
              </div>
            </div>
          </div>
          <p className="text-[0.6rem] text-paused-row">{data.opportunity?.prospect_email}</p>

          <p className="flex items-center gap-1 text-[0.6rem] font-medium text-heading" key={data.id}>
            <span className="font-normal text-paused-row">
              <BiHistory />
            </span>
            {data.opportunity?.latest_message?.date ? `Mail ${calculateTimeAgo(data.opportunity?.latest_message?.date)} ` : 'Start new conversation'}
          </p>
          <p className="flex w-full justify-between text-[0.6rem] font-medium text-heading" key={data.id}>
            Next Task: {data.task?.cdate ? `task at ${dateFormatter(data?.task?.cdate)}` : 'No upcomming task'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
