const ROUTE_PATHS = {
    HOME: '/',
    LOGIN: '/login',
    SIGNUP: '/signup',
    DASHBOARD: '/dashboard',
    USER_PROFILE: '/user/profile',
    SETTINGS: '/settings',
    BLACKLIST_MONITOR:'/blacklist-monitor',
    CRM:'/crm',
    NOT_FOUND: '/404',
  };
  
  export default ROUTE_PATHS;
  