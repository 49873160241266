import React from 'react';
import PaymentHistory from './PaymentHistory';
import StripeCardSection from './StripeCardSection';

const PaymentAndBilling = () => {
  return (
    <div>
      <StripeCardSection />
      <PaymentHistory />
    </div>
  );
};

export default PaymentAndBilling;
