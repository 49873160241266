import { Box } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { MdOutlineCloudUpload } from 'react-icons/md';
import * as XLSX from 'xlsx';
// import { useAppDispatch } from 'store';
// import { setFlagStatus } from 'store/custom';
// import { setSmtpDrawerFlagStatus } from 'store/email-accounts';
// import { BaseApi } from 'common/api/common/base-api';
// import { convertArrayToObjectArray, CSVFileDownloader, toastState } from 'common/utils/utility';
import { toast } from 'react-toastify';
import { BaseApi } from 'api/services/base-api';
import { useAppDispatch } from 'redux-store';
import { setFlagStatus } from 'redux-store/custom';
import { setSmtpDrawerFlagStatus } from 'redux-store/email-accounts';
import { toastState, CSVFileDownloader, convertArrayToObjectArray } from 'utility/utils';

const AddNewSMTP = ({ setSmtp }: any) => {
  const dispatch = useAppDispatch();
  const reader = new FileReader();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [csvFlagStatus, setCsvFlagStatus] = useState(1);
  const [csvData, setCsvData] = useState([]);
  const [csvFinalData, setCsvFinalData] = useState([]);
  const sampleHeaders = ['First Name', 'Last Name', 'Email', 'SMTP Username', 'SMTP Password', 'SMTP Host', 'SMTP Port', 'SMTP Secure', 'Limit', 'Delay', 'Reply To Address', 'IMAP Username', 'IMAP Password', 'IMAP Host', 'IMAP Port', 'IMAP Secure'];

  const handleFileUpload = async (e: any) => {
    const file = e.target.files[0];
    reader.onload = async (e: any) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const fileHeaders: any = jsonData[0];

      // Validate the headers against the sample headers
      const isValidFormat = validateHeaders(fileHeaders, sampleHeaders);

      if (!isValidFormat) {
        console.log('error file');
        toast.error('File format is incorrect. Please upload the file as per the sample file.', toastState.error);
        e.target.value = '';
        return;
      }
      console.log(jsonData, 'jsonData');
      setCsvData(jsonData);
      setCsvFlagStatus(2);
    };
    reader.readAsArrayBuffer(file);
  };

  // Function to compare the uploaded headers with the expected sample headers
  const validateHeaders = (fileHeaders: string[], sampleHeaders: string[]) => {
    if (fileHeaders.length !== sampleHeaders.length) return false;

    return sampleHeaders.every((header, index) => header === fileHeaders[index]);
  };

  const handleVerifyEmailAccounts = async (jsonData: any) => {
    setCsvFinalData(jsonData.map((item: any) => ({ ...item, status: 'Pending' })));
    // for (let emailItem of jsonData) {
    //   try {
    //     setCsvFinalData((prev) => prev.map((item: any) => (emailItem?.smtp_username === item?.smtp_username ? { ...item, status: 'Processing' } : item)));
    //     const response = await BaseApi.post('/sender-accounts/smtp/create', emailItem);
    //     if (response?.error) {
    //       setCsvFinalData((prev) => prev.map((item: any) => (emailItem?.smtp_username === item?.smtp_username ? { ...item, status: 'Failed' } : item)));
    //     } else {
    //       setCsvFinalData((prev) => prev.map((item: any) => (emailItem?.smtp_username === item?.smtp_username ? { ...item, status: 'Complete' } : item)));
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
    // for (let emailItem of jsonData) {
    await Promise.all(
      jsonData?.map(async (emailItem: any) => {
        try {
          setCsvFinalData((prev) => prev.map((item: any) => (emailItem?.smtp_username === item?.smtp_username ? { ...item, status: 'Processing' } : item)));
          const response = await BaseApi.post('/sender-accounts/smtp/create', emailItem);
          if (response?.error) {
            setCsvFinalData((prev) => prev.map((item: any) => (emailItem?.smtp_username === item?.smtp_username ? { ...item, status: 'Failed' } : item)));
          } else {
            setCsvFinalData((prev) => prev.map((item: any) => (emailItem?.smtp_username === item?.smtp_username ? { ...item, status: 'Complete' } : item)));
          }
        } catch (error) {
          console.log(error);
        }
      })
    );
    // }
  };

  const handleDownloadSampleCSV = (event: any) => {
    event?.stopPropagation();
    const sampleData = [
      {
        'First Name': 'John',
        'Last Name': 'Doe',
        Email: 'john.doe@example.com',
        'SMTP Username': 'john.doe@example.com',
        'SMTP Password': 'password123',
        'SMTP Host': 'smtp.example.com',
        'SMTP Port': '465',
        'SMTP Secure': 'SSL',
        Limit: '100',
        Delay: '5',
        'Reply To Address': 'john.doe@example.com',
        'IMAP Username': 'john.doe@example.com',
        'IMAP Password': 'password123',
        'IMAP Host': 'imap.example.com',
        'IMAP Port': '993',
        'IMAP Secure': 'SSL',
      },
      {
        'First Name': 'Jane',
        'Last Name': 'Smith',
        Email: 'jane.smith@example.org',
        'SMTP Username': 'jane.smith@example.org',
        'SMTP Password': 'password456',
        'SMTP Host': 'smtp.example.org',
        'SMTP Port': '465',
        'SMTP Secure': 'SSL',
        Limit: '50',
        Delay: '10',
        'Reply To Address': 'jane.smith@example.org',
        'IMAP Username': 'jane.smith@example.org',
        'IMAP Password': 'password456',
        'IMAP Host': 'imap.example.org',
        'IMAP Port': '993',
        'IMAP Secure': 'SSL',
      },
      {
        'First Name': 'Michael',
        'Last Name': 'Johnson',
        Email: 'michael.johnson@example.net',
        'SMTP Username': 'michael.johnson@example.net',
        'SMTP Password': 'securepass789',
        'SMTP Host': 'smtp.example.net',
        'SMTP Port': '465',
        'SMTP Secure': 'SSL',
        Limit: '200',
        Delay: '2',
        'Reply To Address': 'michael.johnson@example.net',
        'IMAP Username': 'michael.johnson@example.net',
        'IMAP Password': 'securepass789',
        'IMAP Host': 'imap.example.net',
        'IMAP Port': '993',
        'IMAP Secure': 'SSL',
      },
      {
        'First Name': 'Emily',
        'Last Name': 'Davis',
        Email: 'emily.davis@example.co',
        'SMTP Username': 'emily.davis@example.co',
        'SMTP Password': 'mypassword123',
        'SMTP Host': 'smtp.example.co',
        'SMTP Port': '465',
        'SMTP Secure': 'SSL',
        Limit: '300',
        Delay: '1',
        'Reply To Address': 'emily.davis@example.co',
        'IMAP Username': 'emily.davis@example.co',
        'IMAP Password': 'mypassword123',
        'IMAP Host': 'imap.example.co',
        'IMAP Port': '993',
        'IMAP Secure': 'SSL',
      },
      {
        'First Name': 'Chris',
        'Last Name': 'Brown',
        Email: 'chris.brown@example.biz',
        'SMTP Username': 'chris.brown@example.biz',
        'SMTP Password': 'passbrown456',
        'SMTP Host': 'smtp.example.biz',
        'SMTP Port': '465',
        'SMTP Secure': 'SSL',
        Limit: '150',
        Delay: '3',
        'Reply To Address': 'chris.brown@example.biz',
        'IMAP Username': 'chris.brown@example.biz',
        'IMAP Password': 'passbrown456',
        'IMAP Host': 'imap.example.biz',
        'IMAP Port': '993',
        'IMAP Secure': 'SSL',
      },
    ];

    CSVFileDownloader(sampleData, 'sample.csv');
  };

  const MapVariables = () => {
    const variablesList = [
      { id: 'first_name', value: 'FirstName' },
      { id: 'last_name', value: 'LastName' },
      { id: 'email', value: 'Email' },
      { id: 'smtp_username', value: 'SMTPUsername' },
      { id: 'smtp_password', value: 'SMTPPassword' },
      { id: 'smtp_host', value: 'SMTPHost' },
      { id: 'smtp_port', value: 'SMTPPort' },
      { id: 'smtp_secure', value: 'SMTPSecure' },
      //   { id: 'limit', value: 'Limit' },
      { id: 'daily_limit', value: 'Limit' },
      //   { id: 'fixed_delay', value: 'FixedDelay' },
      { id: 'delay_between_emails', value: 'FixedDelay' },
      { id: 'set_different_reply_to_address', value: 'ReplyToAddress' },
      { id: 'imap_username', value: 'ImapUsername' },
      { id: 'imap_password', value: 'ImapPassword' },
      { id: 'imap_host', value: 'ImapHost' },
      { id: 'imap_port', value: 'ImapPort' },
      { id: 'imap_secure', value: 'ImapSecure' },
    ];
    const [selectedVariable, setSelectedVariable] = React.useState<{ [key: string]: null | string }>({});

    const [emailAccountsList, setEmailAccountsList] = useState([]);
    useEffect(() => {
      const csvArrayOfObj = convertArrayToObjectArray(csvData);
      setEmailAccountsList(csvArrayOfObj);
    }, []);

    const handleChangeVariable = (e: { target: { value: string } }, csvIndex: string) => {
      const variableName = e?.target?.value;
      //   let keyExistsStatus = false;
      //   const finalArrResult = emailAccountsList.map((item: any) => {
      //     if (!item.hasOwnProperty(variableName)) {
      //       if (variableName === 'daily_limit') {
      //         item.campaign_settings = { ...item?.campaign_settings };
      //         item.campaign_settings.daily_limit = item[csvIndex];
      //       }
      //       if (variableName === 'delay_between_emails') {
      //         item.campaign_settings = { ...item?.campaign_settings };
      //         item.campaign_settings.delay_between_emails = item[csvIndex];
      //       }
      //       item[variableName] = item[csvIndex];
      //       delete item[csvIndex];
      //     } else {
      //       keyExistsStatus = true;
      //     }
      //     return item;
      //   });

      const isKeyExist = Object.values(selectedVariable)?.includes(variableName);
      if (variableName !== 'select' && isKeyExist) {
        toast.error('Variable is already selected', toastState.error);
        return;
      }

      setSelectedVariable((prev) => ({
        ...prev,
        [csvIndex]: variableName === 'select' ? null : variableName,
      }));
    };
    const handleUploadAll = async () => {
      const selectedVariables = Object.values(selectedVariable);
      const requiredVariable = variablesList?.find((item) => !selectedVariables?.includes(item?.id));
      if (requiredVariable) {
        toast.error(`Select ${requiredVariable?.value} to proceed.`, toastState.error);
        return;
      }

      const convertedCsvData = emailAccountsList?.map((csvData) => {
        const updatedItem = Object.entries(csvData)?.reduce(
          (acc: any, [key, value]) => {
            const variable = selectedVariable[key];
            if (variable === 'daily_limit' || variable === 'delay_between_emails') {
              acc.campaign_settings[variable] = value;
            } else {
              acc[variable] = value;
            }
            return acc;
          },
          {
            first_name: null,
            last_name: null,
            email: null,
            smtp_username: null,
            smtp_password: null,
            smtp_host: null,
            smtp_port: null,
            smtp_secure: null,
            campaign_settings: { daily_limit: null, delay_between_emails: null },
            set_different_reply_to_address: null,
            imap_username: null,
            imap_password: null,
            imap_host: null,
            imap_port: null,
            imap_secure: null,
          }
        );

        return updatedItem;
      });

      setCsvFlagStatus(3);
      await handleVerifyEmailAccounts(convertedCsvData);
    };
    return (
      <>
        <div className="relative mt-4 h-5/6 w-full overflow-y-auto sm:rounded-lg">
          <table className=" w-full text-left text-sm text-gray-500 dark:text-gray-400 rtl:text-right">
            <thead className="border-b text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  COLUMN
                </th>
                <th scope="col" className="px-12 py-3">
                  VARIABLE TYPES
                </th>
                <th scope="col" className="px-6 py-3">
                  SAMPLE
                </th>
              </tr>
            </thead>
            <tbody>
              {csvData?.length &&
                csvData[0].map((csvItem: string, index: number) => {
                  return (
                    <tr key={index} className="h-32 cursor-default border-b bg-white dark:border-gray-700 dark:bg-gray-800">
                      <td className="w-8 whitespace-nowrap text-center font-medium text-gray-900 dark:text-white" id='variable_column'>{csvItem}</td>
                      <td className="">
                        <div className="">
                          <div className="mt-2 w-full rounded-xl border border-gray-200 text-sm font-normal text-gray-600 outline-none dark:!border-white/10 sm:w-full md:w-full">
                            <select id={`map_variable_${index}`} value={selectedVariable[csvItem] || 'select'} onChange={(e) => handleChangeVariable(e, csvItem)} className="text-lightFourth w-full rounded-xl bg-none p-3 text-sm outline-none hover:cursor-pointer dark:bg-navy-800 dark:text-white">
                              <option value="select" id={`variable_option_select`}>Select</option>
                              {variablesList?.map((item: any, index: number) => (
                                <option key={index} value={item?.id} id={`variable_option_${item?.id}`}>
                                  {item?.value}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </td>
                      <td className="pl-3 text-sm font-medium text-gray-900 dark:text-white">{csvData?.[1][index] ? csvData?.[1][index] : csvItem}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="mt-8 flex justify-center gap-4">
          <button
            onClick={() => {
              setCsvFlagStatus(1);
            }}
            className="rounded-lg border border-brand-500 px-12 py-2 text-sm text-brand-500 duration-300 hover:scale-105 hover:bg-brand-500 hover:text-white active:bg-brand-600"
          >
            Back
          </button>
          <button onClick={handleUploadAll} id='sender_account_upload_all' className="rounded-lg border border-brand-500 px-12 py-2 text-sm text-brand-500 duration-300 hover:scale-105 hover:bg-brand-500 hover:text-white active:bg-brand-600">
            Upload All
          </button>
        </div>
      </>
    );
  };

  return (
    <>
      {csvFlagStatus === 1 && (
        <div>
          {/* <div className="mt-8 flex justify-center gap-4">
            <button
              onClick={() => setSmtpFlag(false)}
              className="rounded-lg border border-brand-500 px-12 py-2 text-sm text-brand-500 duration-300 hover:scale-105 hover:bg-brand-500 hover:text-white active:bg-brand-600"
            >
              Back
            </button>
          </div> */}
          {/* export default AddNewSMTP; */}
          <Box className="mt-3 flex flex-col items-center">
            <div className="my-4 flex w-10/12 justify-center font-bold dark:!bg-navy-700">
              <h4>Upload a CSV</h4>
            </div>
            <div
              className="mb-3 flex h-64 w-11/12 cursor-pointer flex-col items-center justify-center rounded-xl  border-2 border-dashed border-gray-100 bg-[#fafcfe] p-3 duration-300 hover:scale-105 active:bg-gray-100 dark:!border-none dark:!bg-navy-700"
              onClick={() => {
                fileInputRef.current && fileInputRef.current.click();
              }}
            >
              <p className="text-[80px] text-navy-700">
                <MdOutlineCloudUpload className="text-brand-500 dark:text-white" />
              </p>
              <p className="text-lg font-bold text-navy-700 dark:text-white">
                Drop your files here, or <span className="font-bold text-brand-500 dark:text-brand-400">browse</span>
              </p>
              <div className="pt-2 text-sm text-gray-600">
                CSV files are allowed{' '}
                <span className="text-blueSecondary hover:underline" onClick={handleDownloadSampleCSV}>
                  Sample file
                </span>
              </div>

              <input type="file" ref={fileInputRef} className="hidden" onChange={(e) => handleFileUpload(e)} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
            </div>
            {/* <div
              onClick={() => {
                setSmtpFlag(false);
                dispatch(setFlagStatus(false));
                dispatch(setSmtpDrawerFlagStatus(true));
              }}
              className="add_single_account_btn m-2 flex w-10/12 cursor-pointer items-center gap-3 rounded-xl bg-white p-1 shadow-xl shadow-gray-200/30 duration-300 hover:scale-105 active:bg-gray-50"
            >
              <span className="rounded-lg bg-blue-50 p-4 text-3xl text-gray-700">
                <TfiEmail />
              </span>
              <span className="text-md p-2 font-semibold">Single Account</span>
            </div> */}
          </Box>
        </div>
      )}

      {csvFlagStatus === 2 && <MapVariables />}

      {csvFlagStatus === 3 && (
        <div>
          <div className="h-5/6">
            <div className="relative mt-4 w-full shadow-xl shadow-gray-100 sm:rounded-lg">
              <div className="flex justify-between rounded-md p-1 px-5 text-sm text-gray-600 shadow-sm shadow-gray-200">
                <span className="p-2">Email</span>
                <span className="p-2">Status</span>
              </div>
              {csvFinalData.map((item, index) => (
                <div key={index} className="flex justify-between border-b p-1 px-5 text-sm font-medium">
                  <div className={`p-2 py-6`}>{item.email}</div>
                  <div className={`p-2 py-6`}>
                    <span className={`rounded-md px-2 py-1 text-xs ${item.status === 'Complete' ? 'bg-green-100 text-green-500' : item.status === 'Processing' ? 'bg-brand-50 text-brand-500' : item.status === 'Pending' ? 'bg-gray-100 text-gray-700' : 'bg-red-100 text-red-500'}`}>{item.status}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-8 flex justify-center gap-4">
            <button
              onClick={() => {
                setCsvFlagStatus(2);
              }}
              className="rounded-lg border border-brand-500 px-12 py-2 text-sm text-brand-500 duration-300 hover:scale-105 hover:bg-brand-500 hover:text-white active:bg-brand-600"
            >
              Back
            </button>
            <button
              onClick={() => {
                setSmtp((prev: { flag: boolean }) => ({ ...prev, flag: false }));
                dispatch(setFlagStatus(false));
                dispatch(setSmtpDrawerFlagStatus(false));
              }}
              className="rounded-lg border border-brand-500 bg-brand-500 px-12 py-2 text-sm text-white duration-300 hover:scale-105 hover:text-white active:bg-brand-600"
            >
              Finish
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default AddNewSMTP;

// {/* <Box className="mt-3 flex flex-col items-center">
//             <div className="my-4 flex w-10/12 justify-center font-bold dark:!bg-navy-700">
//               <h4>Upload a CSV</h4>
//             </div>
//             <div
//               className="mb-3 flex h-64 w-11/12 cursor-pointer flex-col items-center justify-center rounded-xl  border-2 border-dashed border-gray-100 bg-[#fafcfe] p-3 duration-300 hover:scale-105 active:bg-gray-100 dark:!border-none dark:!bg-navy-700"
//               onClick={() => {
//                 fileInputRef.current && fileInputRef.current.click();
//               }}
//             >
//               <p className="text-[80px] text-navy-700">
//                 <MdOutlineCloudUpload className="text-brand-500 dark:text-white" />
//               </p>
//               <p className="text-lg font-bold text-navy-700 dark:text-white">
//                 Drop your files here, or <span className="font-bold text-brand-500 dark:text-brand-400">browse</span>
//               </p>
//               <div className="pt-2 text-sm text-gray-600">CSV files are allowed <span className='text-blueSecondary hover:underline' onClick={handleDownloadSampleCSV}>Sample file</span></div>

//               <input
//                 type="file"
//                 ref={fileInputRef}
//                 className="hidden"
//                 onChange={(e) => handleFileUpload(e)}
//                 accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
//               />
//             </div>
//              <div
//               onClick={() => {
//                 setSmtpFlag(false);
//                 dispatch(setFlagStatus(false));
//                 dispatch(setSmtpDrawerFlagStatus(true));
//               }}
//               className="add_single_account_btn m-2 flex w-10/12 cursor-pointer items-center gap-3 rounded-xl bg-white p-1 shadow-xl shadow-gray-200/30 duration-300 hover:scale-105 active:bg-gray-50"
//             >
//               <span className="rounded-lg bg-blue-50 p-4 text-3xl text-gray-700">
//                 <TfiEmail />
//               </span>
//               <span className="text-md p-2 font-semibold">Single Account</span>
//             </div>
//           </Box> */}
