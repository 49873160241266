/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import MessageRow from '../email/MessageRow';
import CommonLayout from '../common-Layout/CommonLayout';
import InboxSkeleton from '../inbox-skeleton/InboxSkeleton';
import SnoozPickerPopup from '../email/SnoozPickerPopup';
import { SkeletonText } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'redux-store';
import useDebounce from 'hooks/useDebounce';
import { AZ_BUCKET } from 'config/appConfig';
import { FetchEmailInboxData } from 'shared/inbox/event-handler/inbox-event';
import { HandleClearConversationClickEvent, handleDeleteGroupFromSenderList, InboxInfinityScrollData } from 'shared/inbox/event-handler/unified-email-inbox-data';
// import { getRandomIndex } from 'shared/unmatch/event-handler/unmatch-event';
import InfiniteScrollComponent from 'components/common/infinityScroll';
import { setSnoozeId, setSnoozePopupState } from 'redux-store/unified-inbox';
import { getInboxGroup } from 'shared/inbox/api-handler/inbox-api';

const UnifiedEmailInboxData = () => {
  const dispatch = useAppDispatch();
  const [mesagedata, setMessageData] = useState({ totalCount: 0, data: [] });
  const [loading, setLoading] = useState(false);
  const [fetchDataTrigger, setFetchDataTrigger] = useState(false);
  const { clearSelectData, inbox_status, variant, isSnoozePopupOpen } = useAppSelector((state) => state.unifiedInboxData);
  const { searchQuery, limit, offset, fetchGrouplistTrigger, filter } = useAppSelector((state) => state.unifiedInboxData);
  // const colors = ['#4318FF', '#E7C100', '#7F56D9', '#7E4400', '#12B76A'];
  const debouncedSearchValue = useDebounce(searchQuery, 300);

  useEffect(() => {
    FetchEmailInboxData(setLoading, setMessageData, searchQuery, limit, filter, dispatch, inbox_status);
    // fetchInboxData(setLoading, setMessageData, searchQuery, limit, filter, dispatch, inbox_status);
  }, [fetchDataTrigger, inbox_status, variant, debouncedSearchValue, filter]);

  useEffect(() => {
    getInboxGroup();
  }, [fetchGrouplistTrigger]);

  const fetchDataTriggerEvent = () => {
    setFetchDataTrigger((prev) => !prev);
  };

  const handleClearConversation = async () => {
    HandleClearConversationClickEvent({ dispatch, clearSelectData, fetchDataTriggerEvent });
  };

  const InboxFetch = useCallback(
    async (setIsFetching: any) => {
      const query = {
        limit: limit,
        offset: offset,
        search: searchQuery,
        filter: filter,
      };

      mesagedata?.data?.length && mesagedata?.data?.length < mesagedata?.totalCount && InboxInfinityScrollData({ setIsFetching, setMessageData, query, inbox_status, dispatch });
    },
    [mesagedata?.data?.length, debouncedSearchValue, offset, filter]
  );

  return (
    <CommonLayout handleClearConversation={handleClearConversation}>
      {loading ? (
        <InboxSkeleton />
      ) : (
        <div>
          {!mesagedata?.data?.length ? (
            <figure className="mt-10 flex  h-[62vh] flex-col items-center justify-center">
              <img src={AZ_BUCKET?.SPARKLE_ILLUSTRATION} alt="not found" className="w-1/2 md:w-1/3 lg:w-1/4" />
              <figcaption className="mt-3 font-bold text-heading">No Data Found</figcaption>
            </figure>
          ) : (
            <div className="h-[calc(100vh-180px)] overflow-auto ">
              <div className="rounded-md border">
                {/* AvatarColor={colors[getRandomIndex()]} */}
                {mesagedata?.data?.map((messagelist: any, index) => messagelist?.variant === 'email' && <MessageRow index={index} key={messagelist.uuid} inboxData={messagelist} fetchTrigger={() => fetchDataTriggerEvent()} hanleDeleteGroupFromSenderList={handleDeleteGroupFromSenderList} />)}
              </div>
              <div>{mesagedata?.totalCount >= 25 && mesagedata?.totalCount !== mesagedata?.data?.length && <InfiniteScrollComponent loadMore={InboxFetch} loader={<SkeletonText mt="4" noOfLines={4} spacing="4" skeletonHeight="2" />} />}</div>
            </div>
          )}
        </div>
      )}
      <>
        <SnoozPickerPopup
          isOpen={isSnoozePopupOpen}
          FetchDataTrigger={fetchDataTriggerEvent}
          onClose={() => {
            dispatch(setSnoozePopupState(false));
            dispatch(setSnoozeId(''));
          }}
        />
      </>
    </CommonLayout>
  );
};

export default UnifiedEmailInboxData;
