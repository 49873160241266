import { bandsListEditAndConfirmDataDTO, brandsQueryParamsDTO, brandsTableDataDTO, createModalHandleStateDTO } from 'dto/types/views/brands';
import { jwtEncrypt } from 'utility/utils';
import { deleteBrandData, GetBrandsListApi, GetOneBrandData, PatchBrandData, PostBrandData } from '../api-handler/brands-api';
import { removeBrandInput, setBrandInput, setIds } from 'redux-store/campaign-v2';
import { getErrorMessage, setFlagStatus } from 'redux-store/custom';
import { toast } from 'react-toastify';
import { BRAND_MESSAGES } from 'constants/messages';
import { AiFillDelete } from 'react-icons/ai';
import { MdEdit } from 'react-icons/md';
import { TbTrash } from 'react-icons/tb';
import { createBrandFormDataIV } from 'dto/initial-value/views/brands';

// FilterFunction
export const handleBrandFilterEvent = async (filteredData: any, setQueryParams: any) => {
  const encryptedData = jwtEncrypt(filteredData);
  setQueryParams((prev: any) => {
    if (!prev?.filter && !filteredData?.data?.length) return { ...prev };
    return { ...prev, filter: encryptedData };
  });
};

export const handleBrandSingleDeleteDataEvent = (setHandleState: any, rowuuid: string, rowDetails: any) => {
  setHandleState((prevState: any) => ({
    ...prevState,
    confirm: {
      ...prevState.confirm,
      isOpen: true,
      headerContent: 'Do you Want to Delete Brand',
      bodyContent: 'Delete Brand ',
      bodyRedContent: rowDetails?.name,
      descriptionContent: 'Do you confirm deleting Brand',
      uuid: [rowuuid],
    },
  }));
};

export const brandCancelDeleteDataEvent = (setHandleState: any) => {
  setHandleState((prevState: any) => ({
    ...prevState,
    confirm: {
      ...prevState.confirm,
      isOpen: false,
    },
  }));
};

export const searchBrandListEvent = (setQueryParams: React.Dispatch<React.SetStateAction<brandsQueryParamsDTO>>, event: React.ChangeEvent<HTMLInputElement>) => {
  setQueryParams((prev) => ({
    ...prev,
    search: event.target.value.trim().toLowerCase(),
  }));
};

export const editOneBrandDataEvent = async (
  setQueryParams: React.Dispatch<React.SetStateAction<brandsQueryParamsDTO>>,
  setHandleState: React.Dispatch<React.SetStateAction<bandsListEditAndConfirmDataDTO>>,
  rowuuid: string,
  dispatch: any
) => {
  try {
    setQueryParams((prev) => ({
      ...prev,
      offset: 0,
    }));
    const apiEditGetOneBrand = await GetOneBrandData(rowuuid);
    const brandData = {
      uuid:apiEditGetOneBrand?.data?.uuid,
      name: apiEditGetOneBrand?.data?.name,
      icp: apiEditGetOneBrand?.data?.icp,
      pain_points: apiEditGetOneBrand?.data?.pain_points,
      solution: apiEditGetOneBrand?.data?.solution,
      geography_target: apiEditGetOneBrand?.data?.geography_target,
      about_brand_details: apiEditGetOneBrand?.data?.about_brand_details,
    };
    setHandleState((prevState) => ({
      ...prevState,
      editBrandData: {
        uuid: apiEditGetOneBrand?.data?.uuid,
        ...brandData,
      },
    }));
    dispatch(setBrandInput(brandData));
    dispatch(setIds({ brand_id: apiEditGetOneBrand?.data?.uuid }));
    setHandleState((prevState) => ({ ...prevState, newBrandModal: true }));
  } catch (error) {}
};

export const brandConfirmDeleteFunctionEvent = async (
  setQueryParams: React.Dispatch<React.SetStateAction<brandsQueryParamsDTO>>,
  setHandleState: React.Dispatch<React.SetStateAction<bandsListEditAndConfirmDataDTO>>,
  handleState: bandsListEditAndConfirmDataDTO,
  flagStatus: boolean,
  dispatch: any
) => {
  try {
    setHandleState((prevState) => ({ ...prevState, deleteloading: true }));

    setQueryParams((prev) => ({
      ...prev,
      offset: 0,
    }));
    // const response = await BaseApi.delete('/brand', {
    //   data: { uuid: handleState?.confirm?.uuid },
    // });
    const response = await deleteBrandData(handleState?.confirm?.uuid);
    if (response?.error) {
      dispatch(getErrorMessage(response?.message));
      setHandleState((prevState) => ({ ...prevState, deleteloading: false }));
      // toast.error('Failed Deleting Brand', toastState.error);
    } else {
      dispatch(setFlagStatus(!flagStatus));
      setHandleState((prevState) => ({
        ...prevState,
        confirm: {
          ...prevState.confirm,
          isOpen: false,
        },
      }));

      setHandleState((prevState) => ({ ...prevState, deleteloading: false }));
      setQueryParams((prev) => ({
        ...prev,
        offset: 0,
      }));
      // toast.success('Brand Deleted Successfully', toastState.success);
    }
  } catch (error) {
    console.error('Brand list single delete error', error);
  }
};

export const deleteMultipleBrandsDataEvent = async (
  setQueryParams: React.Dispatch<React.SetStateAction<brandsQueryParamsDTO>>,
  selectedIds: string[],
  flagStatus: boolean,
  dispatch: any
) => {
  try {
    const response = await deleteBrandData(selectedIds);
    if (response?.error) {
      dispatch(getErrorMessage(response?.message));
    } else {
      toast.success(BRAND_MESSAGES?.DELETE_BRAND);
      setQueryParams((prev) => ({
        ...prev,
        offset: 0,
      }));
      dispatch(setFlagStatus(!flagStatus));
    }
  } catch (error) {
    console.log('Brand list single delete error', error);
  }
};

export const HandleFetchBrandListEvent = async (
  setQueryParams: React.Dispatch<React.SetStateAction<brandsQueryParamsDTO>>,
  setHandleState: React.Dispatch<React.SetStateAction<bandsListEditAndConfirmDataDTO>>,
  setBrandData: React.Dispatch<React.SetStateAction<brandsTableDataDTO>>,
  brandData: brandsTableDataDTO,
  queryParams: brandsQueryParamsDTO,
  table: any
) => {
  // console.log('call happens');
  try {
    // setLoading(true);
    setHandleState((prevState) => ({ ...prevState, loading: true }));
    const brandListResponse = await GetBrandsListApi({
      ...queryParams,
      sort: queryParams?.sort?.columnName ? `${queryParams?.sort?.columnName}%${queryParams?.sort?.order}` : '',
      offset: 0,
    });
    brandListResponse?.data?.data?.length
      ? setBrandData({ ...brandData, data: brandListResponse?.data?.data, count: brandListResponse?.data?.total_records?.count })
      : setBrandData({ ...brandData, data: [], count: 0 });
    table.resetRowSelection();
    // setLoading(false);
    setHandleState((prevState) => ({ ...prevState, loading: false }));

    setQueryParams((prev) => ({
      ...prev,
      offset: 2,
    }));
  } catch (error) {
    console.error('Error From Brand Table Page Catch block', error);
  }
};

export const HandleFetchBrandScrollDataListEvent = async (
  setQueryParams: React.Dispatch<React.SetStateAction<brandsQueryParamsDTO>>,
  setBrandData: React.Dispatch<React.SetStateAction<brandsTableDataDTO>>,
  queryParams: brandsQueryParamsDTO,
  setIsFetching: any
) => {
  try {
    setIsFetching(true);
    // const query = setAndGetQueryParams([
    //   { key: 'limit', value: queryParams?.limit },
    //   { key: 'offset', value: queryParams?.offset },
    //   { key: 'search', value: queryParams?.search },
    //   {
    //     key: 'sort',
    //     value: queryParams?.sort?.columnName ? `${queryParams?.sort?.columnName}%${queryParams?.sort?.order}` : '',
    //   },
    //   { key: 'filter', value: queryParams?.filter },
    // ]);
    // const response = await BaseApi.get(`/brand/statistics/list?${query}`);
    const response = await GetBrandsListApi({ ...queryParams, sort: queryParams?.sort?.columnName ? `${queryParams?.sort?.columnName}%${queryParams?.sort?.order}` : '' });

    const responseData: any = response.data;
    if (!responseData?.data) return setBrandData((prev) => ({ ...prev, data: [], count: 0 }));
    if (responseData?.data?.length < 25) {
      setBrandData((prev) => ({
        ...prev,
        data: [...prev?.data, ...responseData?.data],
        count: responseData?.total_records?.count,
      }));
    } else {
      setBrandData((prev) => ({
        ...prev,
        data: [...prev?.data, ...responseData?.data],
      }));
    }
    setQueryParams((prev) => ({
      ...prev,
      offset: prev?.offset + 1,
    }));
  } catch (error) {
    console.log(error);
  } finally {
    setIsFetching(false);
  }
};

export const HandleAddNewBrandEvent = (setHandleState: React.Dispatch<React.SetStateAction<bandsListEditAndConfirmDataDTO>>, dispatch: any) => {
  setHandleState((prevState) => ({
    ...prevState,
    editBrandData: {
      uuid: '',
      name: '',
      icp: '',
      pain_points: '',
      solution: '',
      geography_target: '',
      about_brand_details: '',
    },
  }));
  dispatch(removeBrandInput());
  dispatch(setIds({ brand_id: '' }));
  setHandleState((prevState) => ({ ...prevState, newBrandModal: true }));
};

export const HandleCloseNewBrandModalEvent = (setHandleState: React.Dispatch<React.SetStateAction<createModalHandleStateDTO>>, onClose: any, dispatch: any) => {
  setHandleState((prevState) => ({ ...prevState, brandData: null }));
  dispatch(getErrorMessage(createBrandFormDataIV));
  onClose();
};

export const HandleChangeNewBrandModalDataEvent = (setHandleState: React.Dispatch<React.SetStateAction<createModalHandleStateDTO>>, state: any) => {
  setHandleState((prevState) => ({ ...prevState, loading: true }));
  if (state?.uuid) {
    setHandleState((prevState) => ({ ...prevState, brandData: state }));
  } else {
    const { uuid, ...rest } = state;
    setHandleState((prevState) => ({ ...prevState, brandData: rest }));
  }
};

export const HandleCreateOrEditBrandDataEvent = async (
  setHandleState: React.Dispatch<React.SetStateAction<createModalHandleStateDTO>>,
  handleState: createModalHandleStateDTO,
  flagStatus: boolean,
  handleClose: any,
  dispatch: any
) => {
  if (handleState?.brandData?.uuid) {
    const brandUpdateResponse = await PatchBrandData(handleState?.brandData);
    console.log('brandCreateResponse', brandUpdateResponse);
    if (!brandUpdateResponse?.error) {
      //   toast.success(brandUpdateResponse?.message, toastState.success);
      setHandleState((prevState) => ({ ...prevState, loading: false }));

      dispatch(getErrorMessage(createBrandFormDataIV));

      dispatch(setFlagStatus(!flagStatus));
      handleClose();
    } else {
      dispatch(getErrorMessage(brandUpdateResponse?.message));
      setHandleState((prevState) => ({ ...prevState, loading: false }));
    }
  } else {
    const brandCreateResponse = await PostBrandData(handleState?.brandData);
    // console.log('brandCreateResponse', brandCreateResponse);
    setHandleState((prevState) => ({ ...prevState, loading: false }));
    if (brandCreateResponse?.error) {
      dispatch(getErrorMessage(brandCreateResponse?.message));
    } else {
      dispatch(getErrorMessage({}));
      dispatch(setFlagStatus(!flagStatus));

      handleClose();
    }
  }
};

// __________________________________________________________________________________________________ //

export const multiSelectOptionsEvent = (setQueryParams: React.Dispatch<React.SetStateAction<brandsQueryParamsDTO>>, selectedIds: string[], flagStatus: boolean, dispatch: any) => {
  return [
    {
      icon: <AiFillDelete />,
      name: 'Delete',
      functioning: () => deleteMultipleBrandsDataEvent(setQueryParams, selectedIds, flagStatus, dispatch),
    },
  ];
};

export const BrandRowSettingOptionsEvent = (
  setQueryParams: React.Dispatch<React.SetStateAction<brandsQueryParamsDTO>>,
  setHandleState: React.Dispatch<React.SetStateAction<bandsListEditAndConfirmDataDTO>>,
  // rowuuid: string,
  // rowDetails:any,
  dispatch: any
) => {
  return [
    {
      icon: <MdEdit className="text-brand-500 group-hover:text-white" />,
      accessName: 'edit',
      name: 'Edit',
      functioning: (rowuuid: string, rowDetails: any) => editOneBrandDataEvent(setQueryParams, setHandleState, rowuuid, dispatch),
    },
    {
      icon: <TbTrash className="text-brand-500 group-hover:text-white" />,
      accessName: 'delete',
      name: 'Delete',
      functioning: (rowuuid: string, rowDetails: any) => handleBrandSingleDeleteDataEvent(setHandleState, rowuuid, rowDetails),
    },
  ];
};
