import { Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, ModalHeader } from '@chakra-ui/modal';
import Button from 'components/common/Button';

import { useAppDispatch, useAppSelector } from 'redux-store';
import { removeProspectListResult, setIsProspectUploaded } from 'redux-store/crm-onboard';
import { toast } from 'react-toastify';
import { setPaymentStatus } from 'redux-store/custom';
import { setUpdateSettingsData } from 'redux-store/global-configuration';
import { KeyValuePair } from 'dto/types/views/email-verifier';
import { BaseApi } from 'api/services/base-api';
import { toastState } from 'utility/utils';

interface ModalDataType {
  email: string;
  multiResult: KeyValuePair[];
  result: string;
}
interface ModalComponentProps {
  isOpen?: boolean;
  onClose?: () => void;
  modalData?: ModalDataType;
}

const OnboardProspectPopup = (props: ModalComponentProps) => {
  const dispatch = useAppDispatch();
  const { prospect_list_result } = useAppSelector((state) => state?.crmOnboard);
  const { onClose, isOpen } = props;
  const brandId = useAppSelector((state) => state.crmOnboard.brand);
  const pipelineId = useAppSelector((state) => state.crmOnboard.pipelineid);

  const handleNextClick = async () => {
    try {
      const payload = {
        brand_id: brandId,
        pipeline_id: pipelineId,
        prospect_email_list: prospect_list_result,
      };
      const response = await BaseApi.post(`/crm/pipeline/opportunity`, payload);
      if (response?.status === 200) {
        onClose();
        dispatch(setIsProspectUploaded(true));
        dispatch(setUpdateSettingsData('billings'));
      }else if(response?.status === 402){
        dispatch(setPaymentStatus(true))
        // dispatch(getErrorMessage(postNewBlacklists?.message));
      }else {
        toast.error(`Please upload prospects with proper email id`, toastState.error);
        dispatch(removeProspectListResult());
        onClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size={'2xl'}>
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent overflow={'auto'}>
          <ModalHeader className="!px-0">
            <div className="space-y-1 px-4">
              <h5 className="text-sm text-heading">Verify</h5>
            </div>
          </ModalHeader>
          <ModalCloseButton id="email-verify-modal-close-button" />
          <ModalBody className="!px-0">
            <div className="overflow-hidden">
              <div className="mb-3 !h-96 !overflow-auto">
                <div className="w-screen">
                  <div className=" flex w-full items-center justify-start gap-8 border-b bg-[#F9FAFB] px-3 py-2 text-sm text-column ">
                    {prospect_list_result?.length &&
                      Object.keys(prospect_list_result[0]).map((key) => (
                        <h5 className=" !w-[150px] truncate" key={key}>
                          {key}
                        </h5>
                      ))}
                  </div>
                  {prospect_list_result.length &&
                    prospect_list_result.map((value: any, index: number) => (
                      <div className="flex w-full items-center justify-start gap-8 border-b bg-white p-3 text-sm text-column" key={index}>
                        {Object.keys(value).map((key) => (
                          <h5 className="flex w-[150px] items-center truncate font-semibold text-heading">{value[key]}</h5>
                        ))}
                      </div>
                    ))}
                </div>
              </div>

              <div className="flex w-full gap-4 px-6 py-2">
                <Button
                  type="button"
                  name="cancel"
                  fontSize="sm"
                  px="px-6"
                  color="text-column"
                  onClick={() => onClose()}
                  customClass="text-column font-medium text-sm w-1/2 !bg-white"
                />
                <Button
                  id={`verify_next-btn`}
                  type="button"
                  name="Next"
                  fontSize="sm"
                  px="px-6"
                  onClick={() => handleNextClick()}
                  customClass="text-column font-medium text-sm w-1/2"
                />
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default OnboardProspectPopup;
