import moment from 'moment';
import React, { useState } from 'react';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { MdAdd, MdCheck, MdOutlineHourglassEmpty, MdOutlineWatchLater, MdPauseCircle, MdViewWeek } from 'react-icons/md';
import { IoArrowDown, IoArrowUp } from 'react-icons/io5';
import { FiSearch } from 'react-icons/fi';
import { BaseApi } from 'api/services/base-api';
import { EmptyPageSVG } from 'assets/images/campaignv2';
import ArrowedTooltip from 'components/common/ArrowedTooltip';
import CustomFilter from 'components/common/customFilter';
import InfiniteScrollComponent from 'components/common/infinityScroll';
import TableLoader from 'components/common/Loaders/TableViewLoader';
import CheckBox from 'components/common/MultiSelectCheckBox';
import Toggle from 'components/common/Toggle';
import { colViewOpt } from 'dto/initial-value/views/campaign-v2';
import { CampaignsListRowObject } from 'dto/types/views/campaigns';
import { campaignFilterDataList } from 'lib/page-filters/campaign';
import { TbSparkles } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'redux-store';
import { clearCampaignV2 } from 'redux-store/campaign-v2';
import { setGlobalLoading, setTableRowsList, setGlobalOpt, clearStepCount } from 'redux-store/custom';
import { LocalStorage } from 'services/local.storage.service';
import { SessionStorage } from 'services/session.storage.service';
import { useDebounce, calculateTimeAgo, checkRolePermissionStatus, setAndGetQueryParams, jwtEncrypt } from 'utility/utils';
import RowSettings from './features/campaign-list/RowSettings';
import Button from 'components/common/Button';
import { CONSTANTS } from 'constants/constants';

const CampaignListv2 = () => {
  const [data, setData] = React.useState({
    data: [],
    count: 0,
  });
  const [queryParams, setQueryParams] = React.useState({ limit: 25, offset: 1, sort: { columnName: '', order: '' }, search: '', filter: '' });
  const [colViewOptions, setColViewOptions] = React.useState(false);
  const [columnOptions, setColumnOptions] = React.useState<{ [key: string]: boolean | string }[]>(colViewOpt);
  const loggedUser = useAppSelector((state) => state?.auth?.loggedUser);
  const [initialCreation, setInitialCreation] = useState(false);
  const [columns, setColumns] = React.useState([]);
  const tableRef = React.useRef(null);
  const parentRef = React.useRef(null);
  const childRef = React.useRef(null);
  const campaignSearchRef = React.useRef(null);
  const debouncedSearchValue = useDebounce(queryParams?.search, 300);

  const dispatch = useAppDispatch();
  const columnHelper = createColumnHelper<CampaignsListRowObject>();
  const { EDIT_ID } = CONSTANTS;
  const [optionData, setOptionData] = React.useState<any[]>([]);
  const table = useReactTable({
    data: data?.data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });
  const [loading, setLoading] = React.useState(false);

  const handleSort = (column: string) => {
    setQueryParams((prev) => ({
      ...prev,
      sort: {
        columnName: column,
        order: prev.sort.columnName === column ? (prev.sort.order === 'asc' ? 'desc' : prev.sort.order === 'desc' ? '0' : 'asc') : 'asc',
      },
    }));
  };
  const custom = useAppSelector((state) => state?.custom);
  const navigate = useNavigate();

  const fetchList = async () => {
    try {
      const response = await BaseApi.get(`/campaign/v2/list`);
      setData((prev) => ({
        ...prev,
        count: response?.data?.total_records?.count,
        data: response?.data?.data,
      }));
      table?.resetRowSelection();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const [roleStatus, setRoleStatus] = React.useState(null);
  const userRolePermissionListStoreData = useAppSelector((state) => state?.globalsettings?.globalConfiguration?.user_organization_role_details);

  const onSelect = (data: string) => {
    // dispatch(setTableRowsList(data));
  };

  const columnData = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <div className="ml-[0.6rem] flex items-center size-full">
          <input
            type="checkbox"
            id="select-all"
            checked={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
            className="size-[0.9rem] accent-brand-500 dark:accent-purple-600 cursor-pointer w-full block"
          />
        </div>
      ),
      cell: (info) => {
        return (
          <div className="ml-5 flex items-center">
            <CheckBox onSelect={onSelect} info={info} table={table} />
          </div>
        );
      },
    }),
    columnHelper.accessor('campaign_name', {
      id: 'campaign_name',
      enableSorting: false,
      header: (row) => <div className="flex size-full select-none items-center truncate font-medium">Campaign Name</div>,
      cell: (info) => (
        <>
          {
            <p className={`w-[300px] truncate pr-5 text-sm font-semibold row-campaign-${info?.row?.id}`} id={`campaign - ${info.getValue()}`}>
              {info.getValue()}
            </p>
          }
        </>
      ),
    }),
    columnHelper.accessor('cdate', {
      id: 'cdate',
      header: (row) => {
        return (
          <div className="column-cdate group flex size-full select-none items-center text-nowrap font-medium" onClick={() => handleSort('cdate')}>
            <h5 className="relative">
              Created On
              {!row.column.getIsSorted() && <IoArrowUp className="absolute -right-4 top-0.5 hidden group-hover:block" />}
              {row.column.getIsSorted() === 'asc' && <IoArrowDown className="absolute -right-4 top-0.5" />}
              {(row.column.getIsSorted() === 'desc' || !table.getState().sorting.length) && <IoArrowUp className="absolute -right-4 top-0.5" />}
            </h5>
          </div>
        );
      },
      cell: (row) => {
        const utcDateString = row.getValue();
        const userTimeZone = 'Asia/Kolkata';
        const utcDate = new Date(utcDateString);
        const localDate = utcDate.toLocaleString('en-US', { timeZone: userTimeZone });
        const date = moment(new Date(localDate));

        return (
          <div className="group relative w-full text-sm font-semibold">
            <span className={`w-28 row-cdate-${row?.row?.id}`}>{calculateTimeAgo(row.getValue())}</span>
            <ArrowedTooltip className={`hidden group-hover:block`} toolTipId={`row-cdate-tooltip-${row?.row?.id}`} data={date.format('DD MMM YYYY, hh:mm A')} />
          </div>
        );
      },
    }),
    columnHelper.accessor('campaign_status', {
      id: 'campaign_status',
      enableSorting: false,
      header: (row) => <div className="column-campaign_status relative flex size-full select-none items-center font-medium">Status</div>,
      cell: (row) => (
        <>
          {
            <>
              {row.getValue() === 0 && (
                <div className="flex w-min items-center gap-0.5 rounded-full bg-draft-bg px-2 py-1">
                  <MdOutlineWatchLater className="text-xs text-draft-color" />
                  <h5 className={`text-xs font-semibold text-draft-color status-index-${row?.row?.id}`}>Draft</h5>
                </div>
              )}
              {row.getValue() === 1 && (
                <div className="flex w-min items-center gap-0.5 rounded-full bg-active-bg px-2 py-1">
                  <MdOutlineHourglassEmpty className="text-xs text-active-color" />
                  <h5 className={`text-xs font-semibold text-active-color status-index-${row?.row?.id}`}>Active</h5>
                </div>
              )}
              {row.getValue() === 2 && (
                <div className="flex w-min items-center gap-0.5 rounded-full bg-rejected-bg px-2 py-1">
                  <MdOutlineWatchLater className="text-xs text-rejected-color" />
                  <h5 className={`text-xs font-semibold text-rejected-color status-index-${row?.row?.id}`}>Rejected</h5>
                </div>
              )}
              {row.getValue() === 3 && (
                <div className="flex w-min items-center gap-0.5 rounded-full bg-paused-bg px-2 py-1">
                  <MdPauseCircle className="text-xs text-paused-color" />
                  <h5 className={`text-xs font-semibold text-paused-color status-index-${row?.row?.id}`}>Paused</h5>
                </div>
              )}
              {row.getValue() === 4 && (
                <div className="flex w-min items-center gap-0.5 rounded-full bg-completed-bg px-2 py-1">
                  <MdCheck className="text-xs text-completed-color" />
                  <h5 className={`text-xs font-semibold text-completed-color status-index-${row?.row?.id}`}>Completed</h5>
                </div>
              )}
              {row.getValue() === 5 && (
                <div className="flex w-min items-center gap-0.5 text-nowrap rounded-full bg-yellow-100 px-2 py-1">
                  <MdOutlineWatchLater className="text-xs text-yellow-700" />
                  <h5 className={`text-xs font-semibold text-yellow-700 status-index-${row?.row?.id}`}>In Progress</h5>
                </div>
              )}
              {row.getValue() === 6 && (
                <div className="flex w-min items-center gap-0.5 rounded-full bg-rejected-bg px-2 py-1">
                  <MdPauseCircle className="text-xs text-rejected-color" />
                  <h5 className={`text-xs font-semibold text-rejected-color status-index-${row?.row?.id}`}>Error</h5>
                </div>
              )}
            </>
          }
        </>
      ),
    }),
    columnHelper.accessor('campaign_statistics', {
      id: 'campaign_statistics.sent_percentage',
      enableSorting: false,
      header: (row) => <div className="column-campaign_progress_step_percent relative flex size-full select-none items-center font-medium">Progress</div>,
      cell: (info) => {
        const row = info?.row?.original?.campaign_statistics;
        const sentPercentage = typeof row?.sent_percentage === 'number' ? (row?.sent_percentage % 1 !== 0 ? row?.sent_percentage.toFixed(1) : row?.sent_percentage) : row?.sent_percentage
        return (
          <>
            {
              <div className="flex-col">
                <h5
                  className={`text-xs font-bold 
                                 ${info.row.original.campaign_status === 3 ? 'text-[#8F9BBA]' : 'text-blackDark dark:text-white'}`}
                >
                  {sentPercentage || 0}%
                </h5>
                <div className="mt-0.5 h-[4px] w-5/12 rounded-full bg-gray-200">
                  <div
                  style={{width : `${sentPercentage || 0}%`}}
                    className={`${info.row.original.campaign_status === 3 ? 'bg-[#8F9BBA]' : 'bg-green-600'} h-[4px] rounded-full]`}
                  ></div>
                </div>
              </div>
            }
          </>
        );
      },
    }),
    columnHelper.accessor('campaign_statistics', {
      id: 'total_prospects_count',
      header: (row) => (
        <div
          className="column-total_prospects_count group flex size-full select-none items-center font-medium"
          onClick={() => handleSort('campaign_statistics.total_prospects_count')}
        >
          <h5 className="relative">
            Prospects
            {!row.column.getIsSorted() && <IoArrowDown className="absolute -right-4 top-0.5 hidden group-hover:block" />}
            {row.column.getIsSorted() === 'asc' && <IoArrowDown className="absolute -right-4 top-0.5" />}
            {row.column.getIsSorted() === 'desc' && <IoArrowUp className="absolute -right-4 top-0.5" />}
          </h5>
        </div>
      ),
      cell: (info) => (
        <>{<p className={`text-sm font-semibold row-total_prospects_count-${info?.row?.id}`}>{info?.row?.original?.campaign_statistics?.total_prospects_count || 0}</p>}</>
      ),
    }),
    columnHelper.accessor('campaign_statistics', {
      id: 'sent_count',
      header: (row) => (
        <div className="column-sent group flex size-full select-none items-center font-medium" onClick={() => handleSort('campaign_statistics.sent_count')}>
          <h5 className="relative">
            Sent
            {!row.column.getIsSorted() && <IoArrowDown className="absolute -right-4 top-0.5 hidden group-hover:block" />}
            {row.column.getIsSorted() === 'asc' && <IoArrowDown className="absolute -right-4 top-0.5" />}
            {row.column.getIsSorted() === 'desc' && <IoArrowUp className="absolute -right-4 top-0.5" />}
          </h5>
        </div>
      ),
      cell: (info) => {
        const row = info?.row?.original?.campaign_statistics;
        const sentPercentage = typeof row?.sent_percentage === 'number' ? (row?.sent_percentage % 1 !== 0 ? row?.sent_percentage.toFixed(1) : row?.sent_percentage) : row?.sent_percentage
        return (
          <>
            {
              <p className={`mr-6 text-sm font-semibold row-email_open_count-${info?.row?.id}`}>
                {row?.sent_count || 0} ({sentPercentage || 0}%)
              </p>
            }
          </>
        );
      },
    }),
    columnHelper.accessor('campaign_statistics', {
      id: 'open_count',
      header: (row) => (
        <div className="column-email_open_count group mr-6 flex size-full select-none items-center font-medium" onClick={() => handleSort('campaign_statistics.open_count')}>
          <h5 className="relative">
            Opened
            {!row.column.getIsSorted() && <IoArrowDown className="absolute -right-4 top-0.5 hidden group-hover:block" />}
            {row.column.getIsSorted() === 'asc' && <IoArrowDown className="absolute -right-4 top-0.5" />}
            {row.column.getIsSorted() === 'desc' && <IoArrowUp className="absolute -right-4 top-0.5" />}
          </h5>
        </div>
      ),
      cell: (info) => {
        const row = info?.row?.original?.campaign_statistics;
        const openPercentage = typeof row?.open_percentage === 'number' ? (row?.open_percentage % 1 !== 0 ? row?.open_percentage.toFixed(1) : row?.open_percentage) : row?.open_percentage
        return (
          <>
            {
              <p className={`mr-6 text-sm font-semibold row-email_open_count-${info?.row?.id}`}>
                {row?.open_count || 0} ({openPercentage || 0}%)
              </p>
            }
          </>
        );
      },
    }),
    columnHelper.accessor('campaign_statistics', {
      id: 'replied_count',
      header: (row) => (
        <div className="column-replied group mr-5 flex size-full select-none items-center font-medium" onClick={() => handleSort('campaign_statistics.replied_count')}>
          <h5 className="relative">
            Replied
            {!row.column.getIsSorted() && <IoArrowDown className="absolute -right-4 top-0.5 hidden group-hover:block" />}
            {row.column.getIsSorted() === 'asc' && <IoArrowDown className="absolute -right-4 top-0.5" />}
            {row.column.getIsSorted() === 'desc' && <IoArrowUp className="absolute -right-4 top-0.5" />}
          </h5>
        </div>
      ),
      cell: (info) => {
        const row = info?.row?.original?.campaign_statistics;
        const repliedPercentage = typeof row?.replied_percentage === 'number' ? (row?.replied_percentage % 1 !== 0 ? row?.replied_percentage.toFixed(1) : row?.replied_percentage) : row?.replied_percentage
        return (
          <>
            {
              <p className={`mr-5 text-sm font-semibold row-replied-${info?.row?.id}`}>
                {row?.replied_count || 0} ({repliedPercentage || 0}%)
              </p>
            }
          </>
        );
      },
    }),
    columnHelper.accessor('campaign_statistics', {
      id: 'bounced_count',
      header: (row) => (
        <div className="column-bounced group mr-5 flex size-full select-none items-center font-medium" onClick={() => handleSort('campaign_statistics.bounced_count')}>
          <h5 className="relative">
            Bounced
            {!row.column.getIsSorted() && <IoArrowDown className="absolute -right-4 top-0.5 hidden group-hover:block" />}
            {row.column.getIsSorted() === 'asc' && <IoArrowDown className="absolute -right-4 top-0.5" />}
            {row.column.getIsSorted() === 'desc' && <IoArrowUp className="absolute -right-4 top-0.5" />}
          </h5>
        </div>
      ),
      cell: (info) => {
        const row = info?.row?.original?.campaign_statistics;
        const bouncedPercentage = typeof row?.bounced_percentage === 'number' ? (row?.bounced_percentage % 1 !== 0 ? row?.bounced_percentage.toFixed(1) : row?.bounced_percentage) : row?.bounced_percentage
        return (
          <>
            {
              <p className={`mr-5 text-sm font-semibold row-bounced-${info?.row?.id}`}>
                {row?.bounced_count || 0} ({bouncedPercentage || 0}%)
              </p>
            }
          </>
        );
      },
    }),
    columnHelper.accessor('campaign_statistics', {
      id: 'unsubscribed_count',
      header: (row) => (
        <div
          className="column-email_unsubscribe_count group mr-5 flex size-full select-none items-center font-medium"
          onClick={() => handleSort('campaign_statistics.unsubscribed_count')}
        >
          <h5 className="relative">
            Unsubscribed
            {!row.column.getIsSorted() && <IoArrowDown className="absolute -right-4 top-0.5 hidden group-hover:block" />}
            {row.column.getIsSorted() === 'asc' && <IoArrowDown className="absolute -right-4 top-0.5" />}
            {row.column.getIsSorted() === 'desc' && <IoArrowUp className="absolute -right-4 top-0.5" />}
          </h5>
        </div>
      ),
      cell: (info) => {
        const row = info?.row?.original?.campaign_statistics;
        const unsubscribedPercentage = typeof row?.unsubscribed_percentage === 'number' ? (row?.unsubscribed_percentage % 1 !== 0 ? row?.unsubscribed_percentage.toFixed(1) : row?.unsubscribed_percentage) : row?.unsubscribed_percentage
        return (
          <>
            {
              <p className={`mr-5 text-sm font-semibold row-email_unsubscribe_count-${info?.row?.id}`}>
                {row?.unsubscribed_count || 0} ({unsubscribedPercentage || 0}%)
              </p>
            }
          </>
        );
      },
    }),
    columnHelper.accessor('uuid', {
      id: 'edit',
      header: () => <div className="relative w-min font-thin">{''}</div>,
      cell: (row) => {
        return (
          <>
            {
              <RowSettings
                fetchList={fetchList}
                fetchSingleList={fetchSingleList}
                status={row.row.original.campaign_status}
                id={row.row.id}
                uuidRow={row.getValue()}
                rowData={row.row.original}
              />
            }
          </>
        );
      },
    }),
  ];
  const { environment } = useAppSelector((state) => state.globalsettings);

  React.useEffect(() => {
    setOptionData(campaignFilterDataList);
    const getRolePermissionStatus = async () => {
      const status = await checkRolePermissionStatus(userRolePermissionListStoreData, 'campaigns', environment?.app_default_feature?.is_org_enbaled);
      setRoleStatus(status);
    };
    getRolePermissionStatus();
    const options = LocalStorage.getItem('colViewOpt') as { [key: string]: boolean | string }[];
    if (options) {
      setColumnOptions(options);
    }
    setColumns(columnData);
    dispatch(clearCampaignV2());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom?.globalDependencyLoading, userRolePermissionListStoreData]);

  React.useEffect(() => {
    LocalStorage.setItem('colViewOpt', columnOptions);
    const tempColumns = columnData.filter((val) => {
      const tempId = columnOptions.findIndex((option) => option.id === val.id);
      if (tempId === -1) return true;
      return columnOptions[tempId]?.[val.id] === true;
    });
    setColumns(tempColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnOptions]);

  React.useEffect(() => {
    // Campaign fetch function
    const fetchCampaignData = async () => {
      setLoading(true);
      if (queryParams.sort.order === '0') fetchList();
      else {
        try {
          const query = setAndGetQueryParams([
            { key: 'search', value: queryParams?.search },
            { key: 'sort', value: queryParams?.sort?.columnName ? `${queryParams?.sort?.columnName}%${queryParams?.sort?.order}` : '' },
            { key: 'filter', value: queryParams?.filter },
          ]);
          const response = await BaseApi.get(`/campaign/v2/list?${query}`);
          if (!response.error) {
            dispatch(setGlobalLoading(false));
          }
          const responseData = response.data;
          setInitialCreation(response?.data?.initial_creation);
          if (!responseData?.data) return setData({ data: [], count: 0 });
          if (responseData?.data?.length < 25) {
            setData({ data: [...responseData?.data], count: responseData?.data?.length });
          } else {
            setData({ data: [...responseData?.data], count: responseData?.total_records?.count });
          }
          setQueryParams((prev) => ({ ...prev, offset: 1 }));
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    };
    if (loggedUser?.uuid) {
      fetchCampaignData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams?.filter, debouncedSearchValue, queryParams?.sort?.columnName, queryParams?.sort?.order, custom?.globalDependencyLoading]);

  React.useEffect(() => {
    const tableSelectedData = table.getSelectedRowModel().rows.map((val) => val.original);
    dispatch(setTableRowsList(tableSelectedData));

    if (table.getSelectedRowModel().rows.length > 1) dispatch(setGlobalOpt(true));
    else dispatch(setGlobalOpt(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getSelectedRowModel()]);

  React.useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (parentRef.current && !parentRef.current.contains(event.target) && !childRef?.current?.contains(event.target)) {
        setColViewOptions(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [parentRef]);

  const handleFilter = async (filteredData: any) => {
    const encryptedData = jwtEncrypt(filteredData);
    setQueryParams((prev) => {
      if (filteredData?.data?.length) {
        return { ...prev, filter: encryptedData };
      } else {
        return { ...prev, filter: '' };
      }
      // if (!prev?.filter && !filteredData?.data?.length) return { ...prev, filter:'' };
      // return { ...prev, filter: encryptedData };
    });
  };

  const handleColumnData = (colId: string, colState: boolean) => {
    setColumnOptions((prevOptions) => {
      return prevOptions.map((option) => {
        if (option.id === colId) {
          return { ...option, [colId]: !colState };
        }
        return option;
      });
    });
    console.log(colId);
  };

  const handleCampaignSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueryParams((prev) => ({
      ...prev,
      search: event.target.value.trim().toLowerCase(),
    }));
  };

  const campaignFetch = React.useCallback(
    async (setIsFetching: React.Dispatch<React.SetStateAction<boolean>>) => {
      const fetchCampaignData = async () => {
        try {
          setIsFetching(true);
          const query = setAndGetQueryParams([
            { key: 'limit', value: queryParams?.limit },
            { key: 'offset', value: queryParams?.offset + 1 },
            { key: 'search', value: queryParams?.search },
            { key: 'sort', value: queryParams?.sort?.columnName ? `${queryParams?.sort?.columnName}%${queryParams?.sort?.order}` : '' },
            { key: 'filter', value: queryParams?.filter },
          ]);
          const response = await BaseApi.get(`/campaign/v2/list?${query}`);
          const responseData = response.data;
          if (!responseData?.data) return setData({ data: [], count: 0 });
          if (responseData?.data?.length < 25) {
            setData((prev) => ({ data: [...prev?.data, ...responseData?.data], count: prev?.data?.length }));
          } else {
            setData((prev) => ({ ...prev, data: [...prev?.data, ...responseData?.data] }));
          }
          setQueryParams((prev) => ({
            ...prev,
            offset: prev?.offset + 1,
          }));
        } catch (error) {
          console.log(error);
        } finally {
          setIsFetching(false);
        }
      };
      data?.data?.length && data?.data?.length < data?.count && fetchCampaignData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.data?.length, queryParams?.filter, debouncedSearchValue, queryParams?.sort?.columnName, queryParams?.sort?.order, queryParams?.offset, custom?.globalDependencyLoading]
  );

  const fetchSingleList = async (uuid: string) => {
    navigate('/campaigns/create');
    LocalStorage.setItem('edit_id', uuid);
  };

  const handleInsights = (data: any) => {
    if (data?.campaign_status !== 0) {
      navigate(`/campaigns/insights/${data?.uuid}`);
    }
  };

  return (
    <>
      <div className={`mt-2.5 w-full rounded-lg bg-white pb-2 pt-2.5 shadow-sm dark:bg-darkThree dark:text-white sm:overflow-x-auto`}>
        {initialCreation ? (
          <div className="flex h-[86vh] items-center justify-center">
            <div className="flex w-[21rem] flex-col items-center justify-center gap-3 rounded-lg px-8 py-5 shadow-darkShadow">
              <img src={EmptyPageSVG} alt="Create your First Campaign!" className="h-[10rem]" />
              <div className="flex flex-col items-center justify-center gap-2">
                <h5 className="text-md font-semibold text-heading">Hey, {loggedUser?.first_name} 👋</h5>
                <h5 className="text-xs font-medium text-column">Ready to start reaching out to your audience 🚀</h5>
              </div>
              {(roleStatus?.organization_owner || roleStatus?.organization_access_list?.create) && <Button
                name="Create campaign"
                onClick={() => {
                  navigate('/campaigns/create');
                  dispatch(clearCampaignV2());
                  LocalStorage.clearItem('edit_id');
                  SessionStorage.clearItem(EDIT_ID);
                }}
                id='create_campaign_btn'
                RightIcon={<TbSparkles />}
                rounded="rounded-full"
                fontSize="[0.8rem]"
                customClass="w-[10rem] h-[2.5rem] bg-[radial-gradient(50%_50%_at_50%_50%,_#A47CF3_0%,_#4318FF_100%)] shadow-bottomInset hover:shadow-box"
              />}
            </div>
          </div>
        ) : (
          <>
            <div className="relative mb-3 flex items-center py-0.5 sm:gap-32 md:px-10 lg:gap-32 lg:px-3 xl:justify-between">
              <div className="flex gap-2.5">
                <div
                  className="flex items-center gap-2 rounded-md border px-3 py-1.5 outline outline-0 focus-within:outline-2 focus-within:outline-purple-900 dark:border-darkBorder md:w-[13rem] lg:w-[26rem]"
                  onClick={() => campaignSearchRef.current.focus()}
                >
                  <FiSearch className="size-4 cursor-pointer" />
                  <input
                    ref={campaignSearchRef}
                    type="text"
                    placeholder="Search"
                    id="campaign-search-input"
                    className="w-full text-sm font-bold text-gray-800 caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:bg-darkThree dark:text-white dark:placeholder:text-white"
                    onChange={handleCampaignSearch}
                  />
                </div>
                {/* Custom filter */}
                <CustomFilter optionData={optionData} handleFilter={handleFilter} />
              </div>
              <div className="flex items-center gap-2">
                {(roleStatus?.organization_owner || roleStatus?.organization_access_list?.create) && (
                  <button
                    id="create_campaign_btn"
                    onClick={() => {
                      navigate('/campaigns/create');
                      dispatch(clearCampaignV2());
                      dispatch(clearStepCount())
                      LocalStorage.clearItem('edit_id');
                      SessionStorage.clearItem(EDIT_ID);
                    }}
                    className="mx-1 flex items-center justify-center gap-0.5 rounded-md bg-brand-500 px-2 py-1.5 text-sm font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-purple-800 dark:hover:bg-purple-600 dark:active:bg-purple-700"
                  >
                    <MdAdd className="float-right text-lg" /> <span className="text-nowrap text-[11px]">Create Campaign</span>
                  </button>
                )}
                <div
                  className={`relative cursor-pointer rounded-md bg-white outline outline-2 dark:bg-darkTwo ${colViewOptions ? 'outline-menuBorder dark:outline-purple-700' : 'outline-gray-300 dark:outline-darkBorder'
                    }`}
                  ref={parentRef}
                >
                  <button className="h-full w-full px-3 py-2" id="campaign-column-list-id" onClick={() => setColViewOptions(!colViewOptions)}>
                    <MdViewWeek className="text-gray-700 dark:text-white" />
                  </button>
                  {colViewOptions && (
                    <div
                      ref={childRef}
                      className="absolute right-0 top-10 z-10 w-64 space-y-1 rounded-md border bg-white px-3 py-2 dark:border-2 dark:border-darkBorder dark:bg-darkTwo"
                    >
                      {columnOptions?.map((col, id) => (
                        <div key={id} className="flex items-center justify-between">
                          <h5 className="text-xs dark:text-white">{col.name}</h5>
                          <Toggle
                            checkedCondition={col?.[col.id as string] as boolean}
                            changeFunction={(e: Event) => {
                              handleColumnData(col.id as string, col?.[col.id as string] as boolean);
                            }}
                            disabled={col?.disabled as boolean}
                            className={`scale-50 col-list-${col?.name}`}
                            id={`col-list-${col?.name}`}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {loading ? (
              <TableLoader
                width="w-full"
                border="border border-gray-100 dark:border-darkBorder"
                column={10}
                row={13}
                skeletonHeight="h-2"
                padding="px-5 py-4"
                tableViewBg={{ headBg: 'bg-gray-50 dark:bg-darkOne' }}
                columnWidth="4% 22% 10% 10% 10% 10% 10% 10% 10% 4%"
              />
            ) : (
              <div className="h-[80vh] overflow-y-auto overflow-x-scroll scroll-smooth xl:overflow-x-hidden" ref={tableRef}>
                <table className=" sm:w-[150%] lg:w-[120%] xl:w-full">
                  <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}
                      // className='sticky z-10 top-0'
                      >
                        {headerGroup.headers.map((header, index, arr) => {
                          return (
                            <th
                              key={header.id}
                              colSpan={header.colSpan}
                              onClick={header.column.getToggleSortingHandler()}
                              className={` ${index === 0 && 'w-[55px]'} ${index === arr.length - 1 && 'w-[20px]'} ${index === 1 && 'w-[300px]'
                                } mb-2 px-0 cursor-pointer border-gray-200 dark:border-darkThree text-[#667085] dark:text-white bg-[#F9FAFB] dark:bg-darkOne h-12 text-start`}
                            >
                              <hr />
                              <div className="text-xs size-full">
                                {flexRender(header.column.columnDef.header, header.getContext())}
                                {{
                                  asc: '',
                                  desc: '',
                                }[header.column.getIsSorted() as string] ?? null}
                              </div>
                              <hr />
                            </th>
                          );
                        })}
                      </tr>
                    ))}
                  </thead>
                  <tbody className="divide-y-[1px] dark:divide-darkOne">
                    {data?.data?.length ? (
                      table.getRowModel().rows.map((row) => {
                        return (
                          <tr key={row.id} className="cursor-pointer select-none hover:bg-hover dark:hover:bg-darkTwo" onDoubleClick={() => handleInsights(row?.original)}>
                            {row.getVisibleCells().map((cell) => {
                              return (
                                <td
                                  key={cell.id}
                                  className={`border-white/0 py-3 text-start ${cell.row.original.campaign_status === 3 ? 'text-[#8F9BBA]' : ' text-[#2B3674] dark:text-white'}`}
                                >
                                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })
                    ) : (
                      <tr></tr>
                    )}
                  </tbody>
                </table>
                <InfiniteScrollComponent
                  loadMore={campaignFetch}
                  loader={
                    <TableLoader
                      width="w-full"
                      border="border border-gray-100"
                      column={10}
                      row={1}
                      skeletonHeight="h-2"
                      padding="px-5 py-4"
                      columnWidth="4% 22% 10% 10% 10% 10% 10% 10% 10% 4%"
                    />
                  }
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CampaignListv2;
