import { NotificationPopupStateDTO } from 'dto/types/views/notification';

export const NOTIFICATION_DATA = {
  SERVICE_WORKER: 'serviceWorker',
  PUSH_MANAGER: 'PushManager',
  GRANTED: 'granted',
  BROWSER_NOTIFY: 'browser_notify',
  INBOX_NOTIFY: 'inbox_notify',
  EMAIL_NOTIFY: 'email_notify',
  SMS_NOTIFY: 'sms_notify',
};

export const NOTIFICATION_ACCORDION_DATA = [
  {
    title: 'Campaign pause',
    description: 'Assigned comments Resolved comments Comment assigned to me',
    index: 0,
    keys: ['Campaign paused!', 'Email Limit Reached', 'Holiday alert', 'Email disconnected', 'WhatsApp disconnected', 'LinkedIn disconnected', 'Task assigned', 'Email verification'],
  },
  {
    title: 'Tickets',
    index: 1,
    description: 'Assigned changes Recurring notifications Status changes',
    keys: ['Ticket resolved', 'Ticket replied', 'Blacklist notification'],
  },
  {
    title: 'Billing & credits',
    index: 2,
    description: 'Due Date changes  Due Date overdue Start date changes',
    keys: ['Credits over', 'Billing end alert', 'Billing over', 'Credits recharge', 'Billing recharge', 'Trail ended'],
  },
  // {
  //   title: 'Feature updates',
  //   index: 3,
  //   description: 'Manage feature update settings',
  //   keys: ['Blog', 'Feature update', 'LinkedIn post', 'Tools demo'],
  // },
  {
    title: 'User management',
    index: 3,
    description: 'Manage user settings',
    keys: ['Invite accepted'],
  },
];

export const NotificationPopupStateIV: NotificationPopupStateDTO = {
  activeIndex: null,
  switches: {},
};
