import { flexRender } from '@tanstack/react-table';
const TableBody = ({ data, table, handleClickAction, columnWidthIV }: any) => {
    return (
        <tbody className="divide-y-[1px] dark:divide-darkBorder ">
            {data?.length > 0 ? (
                table.getRowModel().rows.map((row: any) => {
                    return (
                        <tr key={row.id} className="hover:bg-hover dark:hover:bg-darkOne" onClick={() => handleClickAction}>
                            {row.getVisibleCells().map((cell: any, i: number) => {
                                return (
                                    <td
                                        key={cell.id}
                                        className={`${columnWidthIV[i]} border-white/0 py-3 text-heading ${(i === 1 || i === 2) && 'text-start'} text-center dark:text-white`}
                                    >
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })
            ) : (
                <tr>
                    <td colSpan={7} className="pt-2 text-center text-[10px] font-medium text-gray-700">
                        <h6>No Data Found</h6>
                    </td>
                </tr>
            )}
        </tbody>
    )
}

export default TableBody
