import * as Yup from 'yup';

export const brandsValidationSchema = Yup.object({
  name: Yup.string().trim().required('Name is required').min(5, 'Name must be at least 5 characters long'),
  icp: Yup.string().trim().required('ICP is required').min(5, 'ICP must be at least 5 characters long'),
  pain_points: Yup.string().trim().required('Pain points is required').min(5, 'Pain points must be at least 5 characters long'),
  solution: Yup.string().trim().required('Solution is required').min(5, 'Solution must be at least 5 characters long'),
  geography_target: Yup.string().trim().required('Geography target is required').min(5, 'Geography target must be at least 5 characters long'),
  about_brand_details: Yup.string().trim().required('About brand details is required').min(5, 'About brand details must be at least 5 characters long'),
});
