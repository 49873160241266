export const EMAIL_VERIFIER_LIST_CREATE = '/email-verifier/v2/create'
export const EMAIL_VERIFIER_LIST_DELETE = '/email/verify/list/delete'
export const EMAIL_VERIFIER_LIST_RESULT = '/email-verifier/v2/result'
export const EMAIL_VERIFIER_SCALE_VERIFICATION = '/email/verify/scale/verification'
export const EMAIL_VERIFIER_THOROUGH_VERIFICATION = '/email/verify/thorough/verification'
export const EMAIL_VERIFIER_USER_INTEGRATION = 'user/integration'
export const TRUE_VERIFIER_CREATE_TICKET = '/true/verify/create/ticket/with/email/verify'
export const USER_INTEGRATION_AUTH_MAILCHIMP = 'user/integration/auth/mailchimp'
export const APP_INTEGRATION_EMAIL_VERIFIER = 'app/integration/email_verifier'
export const USER_INTEGRATION_EMAIL_VERIFIER = 'user/integration/email_verifier'

export const SINGLE_EMAIL_VERIFY_GET_DATA ='/email/verify/single-emailverify'
export const ADD_EV_CUSTOM_VARIABLE ='/email/verify/create/custom-variable'

export const GET_EMAIL_VERIFIER_RESULT_QUERY = (queryParams: string) => `/email/verify/list?${queryParams}`
export const GET_EMAIL_VERIFIER_RESULT_QUERY_PARAMS = (listId: string, count: string | number) => `/email/verify/list/${listId}?limit=${count}&sort=_id%asc`
export const GET_EMAIL_VERIFIER_RESULT_SINGLE = (listId: string, query: string) => `/email/verify/list/${listId}?${query}&sort=_id%asc`
export const GET_EMAIL_VERIFIER_LIST_COUNT = (listId: string) => `/email/verify/list/counts/${listId}`
export const GET_USER_INTEGRATION_LIST = (id: string) => `/user/integration/list/${id}`
export const GET_USER_INTEGRATION_ACC = (id: string) => `user/integration/accounts/${id}`
export const GET_USER_INTEGRATION_SUB = (accId: string, listId: string) => `user/integration/subscribers/${accId}/${listId}`
export const START_INTEGRATION_VERIFICATION = (accId: string, listId: string) => `user/integration/contacts/${accId}/${listId}`
export const GET_SINGLE_INTEGRATION_DATA = (id: string) => `app/integration/single/${id}`
