export const APP_NAME = 'Sparkle';

// Pagination
export const DEFAULT_PAGE_SIZE = 10;
export const MAX_ITEMS_PER_PAGE = 100;
export const MIN_ITEMS_PER_PAGE = 5;

// API Configuration
export const API_TIMEOUT = 5000;
export const MAX_API_RETRIES = 3;
export const API_RETRY_DELAY = 1000;

// Cache Settings
export const CACHE_EXPIRY_TIME = 60 * 60 * 1000;

export const AZ_BUCKET_URL = 'https://sparkle-dev.azurewebsites.net';
export const AZ_BUCKET = {
  SPARKLE_LOGIN_PAGE_SIDE_IMG: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726739517758/auth.png`,
  SPARKLE_NAME_BLUE: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726739110745/sparkle-name-blue.png`,
  SPARKLE_NAME_WHITE: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726739517758/sparkle-name-white.png`,
  SPARKLE_LOGO_BLUE: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726739517758/sparkle-blue.png`,
  SPARKLE_LOGO_TRANSPARANT_WHITE: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726739517759/sparkle-transparant-white.png`,
  SPARKLE_LOGO_TRANSPARANT_BLUE: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726739517759/sparkle-trasparant.png`,
  SPARKLE_LOGO_WHITE: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726739517759/sparkle-white.png`,
  SPARKLE_CIRCLE_TICK_ICON: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726739846947/circle-tick-icon.png`,
  SPARKLE_FACEBOOK_HEADER_ICON: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726739846947/facebook-header-icon.png`,
  SPARKLE_FACEBOOK_ICON: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726739846947/facebook-icon.png`,
  SPARKLE_INSTAGRAM_ICON: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726739846947/instagram-icon.png`,
  SPARKLE_LINKEDIN_HEADER_ICON: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726739846947/linkedin-header-icon.png`,
  SPARKLE_LIKENDIN_ICON: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726739846948/linkedin-icon.png`,
  SPARKLE_LOCK_IMAGE: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726739846948/lock-image.png`,
  SPARKLE_WORLD_MAP: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726739846948/Mapsicle-Map.png`,
  SPARKLE_: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726739846948/twitter-header-icon.png`,
  SPARKLE_TWITTER_ICON: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726739846948/twitter-icon.png`,
  SPARKLE_EMPLOYEE_IMAGE: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726739846948/work-image.png`,
  SPARKLE_APOLLO: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726740647994/apollo.mini.io.jfif`,
  SPARKLE_ILLUSTRATION: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726740647994/Illustration.jfif`,
  SPARKLE_CRM_ONBOARD_VIEW: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726740647994/onboard-crm-view.jfif`,
  STRIPE_VISA_CARD: `${AZ_BUCKET_URL}/sparkle/az/bucket/1730980479024/Payment-method-icon.png`,
  STRIPE_MASTER_CARD: `${AZ_BUCKET_URL}/sparkle/az/bucket/1730982991912/Payment-method-icon.png`,
};
