import { flexRender } from '@tanstack/react-table';
import React from 'react'

const TableHeader = ({ table }: any) => {
    return (

        <thead>
            {table.getHeaderGroups().map((headerGroup: any) => (
                <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header: any, index: number, arr: any) => {
                        return (
                            <th
                                key={header.id}
                                colSpan={header.colSpan}
                                onClick={header.column.getToggleSortingHandler()}
                                className={`
                                                ${index === 0 && 'w-[35px]'}
                                                ${index === 1 && 'w-[200px] text-start'}
                                                ${index === 2 && 'w-[130px] text-start'}
                                                ${index >= 3 && index <= 5 && 'w-[130px]'}
                                                ${index === 6 && 'w-[100px]'}
                                                ${index === arr.length - 1 && 'w-[20px]'}
                                                mb-2 cursor-pointer border-y border-gray-200 bg-gray-50 py-4 text-center text-column dark:border-white/10 dark:bg-darkOne`}
                            >
                                <div className="text-[11px]">
                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                    {{
                                        asc: '',
                                        desc: '',
                                    }[header.column.getIsSorted() as string] ?? null}
                                </div>
                            </th>
                        );
                    })}
                </tr>
            ))}
        </thead>

    )
}

export default TableHeader
