import { BaseApi } from 'api/services/base-api';
import Toggle from 'components/common/Toggle';
import React from 'react';
// import MiniCardLoader from './MiniCardLoader';
import ChoosePackageCards from './ChoosePackageCards';
import InputField from 'components/forms/InputField';
import { MdVerifiedUser } from 'react-icons/md';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import Button from 'components/common/Button';
import { useAppDispatch, useAppSelector } from 'redux-store';
import AutoCreditPopupModal from './AutoCreditPopupModal';
import ConfirmationPopup from 'components/common/confirmationPopup/ConfirmationPopup';
import ChoosePackageLoader from './ChoosePackageLoader';
import { useNavigate, useSearchParams } from 'react-router-dom';
// import { setPaymentStatus } from 'redux-store/custom';
import { setCheckSparkles, setPaymentCards } from 'redux-store/account-settings';
import { setUpdateSettingsData } from 'redux-store/global-configuration';
import { toast } from 'react-toastify';
import { toastState } from 'utility/utils';

const PlansAndPackages = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const orderId = searchParams.get('order_id');
  const dispatch = useAppDispatch();

  const [handleState, setHandleState] = React.useState({
    selectedPlan: 'subscription',
    planMonthly: false,
    cardLoading: false,
    creditsDetails: [],
    verifyCode: false,
    cancelPayAsYouGo: false,
    cancelSubscription: false,
    source: 'monthly',
    packageLoading: false,
    credit_addonId: '',
    coupon_id: '',
    disableCoupon: false,
    cost_per_credit: 1,
    creditAmount: 0,
    creditAmoutwithDiscount: 0,
    creditsCount: 0,
    creditPerAmount: 0,
    loading: false,
  });

  React.useEffect(() => {
    const fetchCreditPricing = async () => {
      try {
        setHandleState((prevState) => ({
          ...prevState,
          packageLoading: true,
          cardLoading: true,
        }));
        const billingresponse = await BaseApi.get(`/credit/addons/source/${handleState?.source}`);
        if (!billingresponse?.error) {
          setHandleState((prevState) => ({
            ...prevState,
            creditsDetails: billingresponse?.data?.data,
            packageLoading: false,
            cost_per_credit: billingresponse?.data?.data?.[0]?.cost_per_credit,
            creditAmount: billingresponse?.data?.data?.[0]?.amount,
            creditsCount: billingresponse?.data?.data?.[0]?.credits,
            creditPerAmount: billingresponse?.data?.data?.[0]?.cost_per_credit,
            credit_addonId: billingresponse?.data?.data?.[0]?.uuid,
            cardLoading: false,
          }));
          const sortedData = billingresponse?.data?.data?.sort((a: { credits: number }, b: { credits: number }) => a.credits - b.credits);

          const minCredits = sortedData?.[0]?.credits;
          setValue(minCredits);
          //   setValue(billingresponse?.data?.data?.sort((a: { credits: number }, b: { credits: number }) => (a['credits'] > b['credits'] ? 1 : -1))[0]?.credits)
        } else {
          setHandleState((prevState) => ({
            ...prevState,
            packageLoading: false,

            cardLoading: false,
          }));
        }
      } catch (error) {}
    };
    fetchCreditPricing();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleState?.source]);
  const priceData = handleState?.creditsDetails?.find((item) => item?.uuid === handleState?.credit_addonId);

  const updateResponse = async () => {
    try {
      const response = await BaseApi.get(`/subscription/payment/status?order_id=${orderId}`);
      if (response?.data?.payment_status === 'SUCCESS') {
        toast.success('Credits added successfully', toastState.success);
        // dispatch(setPaymentStatus(true));
        dispatch(setCheckSparkles());
        dispatch(setUpdateSettingsData('billings'));
      } else {
        toast.error('Failed adding Credits ', toastState.error);
        dispatch(setPaymentCards({}));
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (orderId) {
      orderId && updateResponse();
      navigate('/settings/billing');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  console.log('handleState', handleState);

  const [value, setValue] = React.useState(priceData || handleState?.creditsDetails?.[0]?.credits);

  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(parseInt(e.target.value, 10));
    setHandleState((prevState) => ({
      ...prevState,
      credit_addonId: '',
      creditsCount: parseInt(e.target.value, 10),

      creditAmount: parseInt(e.target.value, 10) * handleState?.creditPerAmount,
    }));
  };

  const progressPercentage = ((value - handleState?.creditsDetails[0]?.credits) / (handleState?.creditsDetails[handleState?.creditsDetails?.length - 1]?.credits - handleState?.creditsDetails[0]?.credits)) * 100;
  const sparklesCheck = useAppSelector((state) => state?.globalsettings?.globalConfiguration?.billing_details);

  handleState?.creditsDetails?.sort((a: { credits: number }, b: { credits: number }) => (a['credits'] > b['credits'] ? 1 : -1));

  const handleCouponChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHandleState((prevState) => ({
      ...prevState,
      coupon_id: e.target.value,
    }));
  };

  const handlePayment = async () => {
    try {
      // setLoading(true);
      setHandleState((prevState) => ({
        ...prevState,
        loading: true,
      }));
      const response = await BaseApi.post(`/subscription/payment`, {
        ...(handleState?.credit_addonId ? { credit_addon_id: handleState?.credit_addonId } : {}),
        ...(handleState?.source ? { source: handleState?.source } : {}),
        ...(handleState?.creditsCount ? { credits: handleState?.creditsCount } : {}),
        ...(handleState?.coupon_id ? { discount_id: handleState?.coupon_id } : {}),
      });
      if (!response?.error) {
        if (response.data.sessionUrl) {
          window.location.href = `${response.data.sessionUrl}`;
        }
      } else {
        setHandleState((prevState) => ({
          ...prevState,
          loading: false,
        }));
      }
      //   if (response.data.sessionUrl) {
      //     window.location.href = `${response.data.sessionUrl}`;
      //   }
    } catch (error) {
      console.log(error);
    }
  };

  const validateCoupon = async () => {
    try {
      const couponResponse = await BaseApi.post(`/coupons/verify`, { coupon_id: handleState?.coupon_id });
      if (!couponResponse?.error && couponResponse?.data?.coupon_code === handleState?.coupon_id) {
        setHandleState((prevState) => ({
          ...prevState,
          verifyCode: true,
          coupon_id: couponResponse?.data?.uuid,
          creditAmoutwithDiscount: couponResponse?.data?.discount_value,
          disableCoupon: true,
        }));
      } else {
        setHandleState((prevState) => ({
          ...prevState,
          coupon_id: '',
          disableCoupon: false,
        }));
      }
    } catch (error) {}
  };

  return (
    <div className="w-full">
      <div className="items-center rounded-md bg-white py-2 shadow-sm">
        <div className="w-[88rem] ">
          {/* Choose Plan */}
          <>
            <div className="mx-3 mt-3 text-sm font-semibold text-heading">Choose a Purchase Option</div>
            <div className="mx-3 mt-3 flex  gap-3 ">
              <div
                className={`relative payg_btn mb-2 flex h-20 w-80 cursor-pointer select-none gap-1 rounded-md border p-2 dark:border-darkBorder dark:bg-darkTwo ${handleState?.selectedPlan === 'pay_as_you_go' ? '!border-blueSecondary bg-bgInside dark:bg-navy-900' : ''}`}
                onClick={() =>
                  setHandleState((prevState) => ({
                    ...prevState,
                    source: 'payg',
                    selectedPlan: 'pay_as_you_go',
                  }))
                }
                // onDoubleClick={handleSwitchOrganizationAndWorkspace}
                //   id={`workspace_${index}`}
              >
                <div className="">
                  <input
                    className="free_button mt-0 text-blueSecondary"
                    type="radio"
                    //   value="free"
                    checked={handleState?.selectedPlan === 'pay_as_you_go'}
                  />
                </div>
                <div className=" w-full ">
                  <h4 className={`text-sm font-bold ${handleState?.selectedPlan === 'pay_as_you_go' ? `text-blueSecondary` : `text-textBlack`} dark:text-white`}>Pay as you go</h4>
                  <p className={`text-xs  ${handleState?.selectedPlan === 'pay_as_you_go' ? `text-blue-700` : `text-paused-color`}  dark:text-gray-200`}>Purchase credits that never expire</p>
                </div>
              </div>

              <div
                className={` monthly_btn relative mb-2 flex h-20 w-80 cursor-pointer select-none gap-2 rounded-md border p-2 dark:border-darkBorder dark:bg-darkTwo ${handleState?.selectedPlan === 'subscription' ? '!border-blueSecondary bg-bgInside dark:bg-navy-900' : ''}`}
                onClick={() =>
                  setHandleState((prevState) => ({
                    ...prevState,
                    source: 'monthly',
                    selectedPlan: 'subscription',
                  }))
                }
                // onDoubleClick={handleSwitchOrganizationAndWorkspace}
                //   id={`workspace_${index}`}
              >
                <div className="">
                  <input
                    className="free_button mt-0 text-blueSecondary"
                    type="radio"
                    //   value="free"
                    checked={handleState?.selectedPlan === 'subscription'}
                  />
                </div>

                <div className=" w-full ">
                  <h4 className={`text-sm font-bold ${handleState?.selectedPlan === 'subscription' ? `text-blueSecondary` : `text-textBlack`} dark:text-white`}>Subscription</h4>
                  <p className={`text-xs  ${handleState?.selectedPlan === 'subscription' ? `text-blue-700` : `text-paused-color`}  dark:text-gray-200`}>Save 20% with auto-renewing monthly credits</p>
                  <div className="flex flex-row items-center">
                    <span className={`${handleState?.planMonthly ? 'text-xs font-semibold text-textBlack' : 'text-xs font-semibold text-blueSecondary'}`}>Monthly</span>
                    <Toggle
                      changeFunction={() => {
                        setHandleState((prevState) => ({
                          ...prevState,
                          planMonthly: !handleState?.planMonthly,
                        }));
                      }}
                      checkedCondition={handleState?.planMonthly}
                      className={` scale-[0.4]`}
                    />
                    <span className={`${handleState?.planMonthly ? 'text-xs font-semibold text-blueSecondary' : 'text-xs font-semibold text-textBlack'}`}>Annual</span>
                  </div>
                </div>
              </div>
            </div>
          </>
          {/* Choose Package */}
          <>
            <div className="mx-3 mt-3 text-sm font-semibold text-heading">Choose a Package</div>
            <div className="mx-3 -mt-1 flex gap-2">
              {handleState?.packageLoading
                ? [...Array(7)].map((_, index) => <ChoosePackageLoader key={index} />)
                : handleState?.creditsDetails?.map((item: any, idx: number) => <ChoosePackageCards value={value} setValue={setValue} setHandleState={setHandleState} handleState={handleState} key={idx} plan={item} planType={handleState?.selectedPlan} />)}
            </div>
          </>
          {/* Slider on Sparkles */}
          <>
            <div className="mx-3 mt-5 text-sm font-semibold text-heading">Or use the slider below to customize your package</div>
            <div className="mx-3 -mt-4  flex items-center gap-5 ">
              <div className="w-[80%]">
                <input
                  type="range"
                  min={handleState?.creditsDetails[0]?.credits}
                  max={handleState?.creditsDetails[handleState?.creditsDetails?.length - 1]?.credits}
                  value={value}
                  onChange={handleSliderChange}
                  className="h-1.5 w-full cursor-pointer appearance-none rounded-lg bg-gray-300"
                  style={{
                    background: `linear-gradient(to right, #12B76A ${progressPercentage}%, #e5e7eb ${progressPercentage}%)`,
                  }}
                />
              </div>
              <div className="">
                <InputField
                  autoComplete="off"
                  extra="mb-3"
                  inputClass="mt-2 custom_sparkle_input !w-40 dark:!bg-darkFive brand_name_input !rounded-md !h-9"
                  labelClass="!text-column dark:!text-white !font-[600] !ml-0 text-xs"
                  placeholder="Sparkles"
                  id="name"
                  type="number"
                  min={5}
                  max={50}
                  value={value}
                  onChange={handleSliderChange}
                  rightElement={'Sparkles'}
                  //   errorMessage={errorMessage?.name}
                  //   state={errorMessage?.name ? 'error' : ''}
                />
              </div>
            </div>
          </>
          {/* Dicount Cupon */}
          <>
            <div className="mx-3  text-sm font-semibold text-heading">Discount Code</div>
            <div className="flex items-center">
              <div className="mx-3 -mt-1 w-2/6 ">
                <InputField
                  autoComplete="off"
                  extra="mb-3"
                  inputClass="mt-2  dark:!bg-darkFive brand_name_input !rounded-md !h-9"
                  labelClass="!text-column dark:!text-white !font-[600] !ml-0 text-xs"
                  placeholder="Sparkles"
                  id="name"
                  type="text"
                  onChange={handleCouponChange}
                  readOnly={handleState?.disableCoupon}
                  onBlur={
                    () => handleState?.coupon_id && validateCoupon()
                    // setHandleState((prevState) => ({
                    //   ...prevState,
                    //   verifyCode: true,
                    // }))
                  }
                  //   value={value}
                  //   onChange={handleSliderChange}
                  rightElement={
                    <div className="flex items-center gap-1">
                      <MdVerifiedUser /> Verify Code
                    </div>
                  }
                  //   errorMessage={errorMessage?.name}
                  //   state={errorMessage?.name ? 'error' : ''}
                />
              </div>
              {handleState?.verifyCode ? (
                <span className="flex items-center gap-1 text-green-600">
                  <IoCheckmarkCircleOutline size={16} /> <span>Discount applied! You saved on this plan.</span>
                </span>
              ) : (
                <></>
              )}
            </div>
          </>
          {/* Confirm Price */}
          <>
            <div className="mx-3 mt-1 text-sm font-semibold text-heading">Confirm price</div>
            <div className=" mx-3 mt-1 flex items-center gap-1">
              <span className=" my-1  text-xl font-bold text-heading dark:text-white ">
                ${handleState?.creditAmount - handleState?.creditAmoutwithDiscount}
                {/* {priceData ? (priceData?.source === 'payg' ? `$${priceData?.amount} ` : `$${priceData?.amount}/Month`) : `${value && (parseInt(value)) && handleState?.creditsDetails[0]?.cost_per_credit ? parseInt(value) * handleState.creditsDetails[0].cost_per_credit : '$0'} `} */}
              </span>
              {handleState?.verifyCode && <span className=" my-1 ml-1 text-lg font-bold text-column line-through dark:text-white">${handleState?.creditAmount}</span>}
              <span className=" my-1 ml-1 text-sm text-heading  dark:text-white ">
                Oty: {handleState?.creditsCount} {handleState?.source === 'payg' ? `sparkles` : `sparkles/month`}. Cost per sparkle: ${parseFloat((handleState?.creditAmount / handleState?.creditsCount).toFixed(5)) || 0}
              </span>
            </div>
            <div className="mx-3 mt-3 w-1/6">
              <Button
                name={`Purchase Now`}
                loading={handleState?.loading}
                disable={handleState?.loading}
                onClick={() => handlePayment()}
                customClass={`purchase_now_sparkle_btn security_page_change_password dark:!bg-purple-800  w-full border border-blueSecondary dark:!border-none dark:!text-white font-semibold text-[.8125rem] active:scale-95 transition-transform duration-150 ease-out`}
              />
            </div>
          </>
        </div>
      </div>

      <div className="mt-3 w-full  items-center rounded-md bg-white py-2 shadow-sm">
        {/* Current Plan */}
        <>
          <div className="mt-3 px-3 text-sm font-semibold text-heading">Current Plan</div>
          <div className="mt-3 flex w-full gap-2 px-3">
            <div className={`relative mb-2 flex h-16 w-1/3  select-none gap-1 rounded-md border !border-[#f8f6ff] bg-[#f8f6ff] p-2 dark:border-darkBorder dark:bg-darkTwo `}>
              <div className=" w-full">
                <span className="flex justify-between">
                  <h4 className={`text-sm font-bold text-heading dark:text-white`}>Monthly</h4>
                  <p
                    className="cursor-pointer text-xs font-semibold text-red-500"
                    onClick={() => {
                      setHandleState((prevState) => ({
                        ...prevState,
                        cancelSubscription: true,
                      }));
                    }}
                  >
                    Cancel Subscription
                  </p>
                </span>
                <p className={`mt-1  text-xs font-semibold text-heading dark:text-gray-200`}>
                  {sparklesCheck?.data?.active_credits} of {sparklesCheck?.data?.remaining_credits} Sparkles Remaining
                </p>
              </div>
            </div>

            <div className={`relative mb-2 flex h-fit w-1/3  select-none gap-1 rounded-md border !border-[#f8f6ff] bg-[#f8f6ff] p-2 dark:border-darkBorder dark:bg-darkTwo `}>
              <div className=" w-full">
                <span className="flex">
                  <h4 className={`text-sm font-bold text-heading dark:text-white`}>Pay as you go</h4>
                </span>
                <p className={`mt-1  text-xs font-semibold text-heading dark:text-gray-200`}>
                  {sparklesCheck?.data?.active_credits} of {sparklesCheck?.data?.remaining_credits} Sparkles Remaining
                </p>
                {/* <div className="">
                  <div className="border border-red-500 items-start">
                    <Toggle
                      changeFunction={() => {
                        setHandleState((prevState) => ({
                          ...prevState,
                          cancelPayAsYouGo: true,
                        }));
                      }}
                      checkedCondition={handleState?.cancelPayAsYouGo}
                      className={` scale-[0.4]`}
                    />
                  </div>
                </div> */}
                <div className="flex items-center">
                  <div className="-px-4 -ml-4 flex items-center ">
                    <Toggle
                      changeFunction={() => {
                        setHandleState((prevState) => ({
                          ...prevState,
                          cancelPayAsYouGo: true,
                        }));
                      }}
                      checkedCondition={handleState?.cancelPayAsYouGo}
                      className="ml-0 scale-[0.4]"
                    />
                  </div>
                  <div className="text-xs font-semibold">Automatically rechange when credits fall below</div>
                </div>
                <div className="text-xs font-normal">1000 Sparkles will automatically be added when the balance falls below 250 Sparkles</div>
              </div>
            </div>
          </div>
        </>
      </div>
      <AutoCreditPopupModal
        isOpen={handleState?.cancelPayAsYouGo}
        onClose={() => {
          setHandleState((prevState) => ({
            ...prevState,
            cancelPayAsYouGo: false,
          }));
        }}
      />

      <ConfirmationPopup
        btn1={'Confirm Downgrade'}
        btn2={'Keep Current Plan'}
        descriptionContent={`Your new plan will take effect immediately. You'll keep your current Sparkles and receive your new monthly allowance starting today, {Date). Your billing cycle will now reset to this date.`}
        headerContent={`Plan Change Confirmation`}
        isOpen={handleState?.cancelSubscription}
        handleCancel={() => {
          setHandleState((prevState) => ({
            ...prevState,
            cancelSubscription: false,
          }));
        }}
      />
    </div>
  );
};

export default PlansAndPackages;
