import { TeamStateDTO } from 'dto/types/views/team';
import { SessionStorage } from 'services/session.storage.service';
import { checkRolePermissionStatus } from 'utility/utils';

export const HandleSwitchStateEvent = ({ state, smartStateUpdate }: { state: number; smartStateUpdate: any }) => {
  SessionStorage.setString('Active_tab', `${state}`);
  smartStateUpdate({ switchState: state });
  // setAddBtn(state === 1 ? 'Add User' : state === 2 ? 'Add New Role' : 'Add Workspace');
};

export const GetRolePermissionStatusForTeamsEvent = async ({ smartStateUpdate, userRolePermissionListStoreData, environment, teamState, ActiveTab }: { ActiveTab: string; teamState: TeamStateDTO; environment: any; userRolePermissionListStoreData: any; smartStateUpdate: any }) => {
  const roleStatus = await checkRolePermissionStatus(userRolePermissionListStoreData, 'role_management', environment?.app_default_feature?.is_org_enbaled);
  smartStateUpdate({ roleStatus });

  const userStatus = await checkRolePermissionStatus(userRolePermissionListStoreData, 'user_management', environment?.app_default_feature?.is_org_enbaled);
  smartStateUpdate({ userStatus });

  const workspaceStatus = await checkRolePermissionStatus(userRolePermissionListStoreData, 'workspace_management', environment?.app_default_feature?.is_org_enbaled);
  smartStateUpdate({ workspaceStatus });

  smartStateUpdate({
    switchState: parseInt(ActiveTab) || (workspaceStatus?.organization_access_list?.view || teamState?.workspaceStatus?.organization_owner ? 3 : userStatus?.organization_access_list?.view || teamState?.userStatus?.organization_owner ? 1 : roleStatus?.organization_access_list?.view || roleStatus?.organization_owner ? 2 : 0),
  });
};
