import { generateDateInterval } from 'utility/utils';

export const senderAccountsFilterDataList: any = [
  {
    id: '1',
    name: 'Status',
    uniqueColumnName: 'account_details.mailer_status',
    conditions: [
      { id: '1', name: 'Is', uniqueName: 'is' },
      { id: '2', name: 'Is not', uniqueName: 'not' },
    ],
    resultValue: [
      { id: '1', name: 'Connected', uniqueName: 1 },
      { id: '2', name: 'Disconected', uniqueName: 0 },
    ],
  },
  {
    id: '2',
    name: 'Daily limit',
    uniqueColumnName: 'campaign_settings.daily_limit',
    conditions: [
      { id: '1', name: 'More Than', uniqueName: 'greater_than' },
      { id: '2', name: 'Less than', uniqueName: 'less_than' },
    ],
    resultValue: [] as any,
    result_value_field_type: 'input',
  },
  {
    id: '3',
    name: 'Health Score',
    uniqueColumnName: 'account_details.email_health_score',
    conditions: [
      { id: '1', name: 'More Than', uniqueName: 'greater_than' },
      { id: '2', name: 'Less than', uniqueName: 'less_than' },
    ],
    resultValue: [] as any,
    result_value_field_type: 'input',
  },
  {
    id: '6',
    name: 'Created On',
    uniqueColumnName: 'cdate',
    conditions: [{ id: '1', name: 'Is', uniqueName: 'is' }],
    resultValue: [
      { id: '1', name: 'Today', uniqueName: generateDateInterval(0, 0) },
      { id: '2', name: 'Yesterday', uniqueName: generateDateInterval(1) },
      { id: '3', name: 'Last 7 days', uniqueName: generateDateInterval(7) },
      { id: '4', name: 'Last 30 days', uniqueName: generateDateInterval(29) },
      { id: '5', name: 'This week', uniqueName: generateDateInterval('week', 0) },
      { id: '6', name: 'This month', uniqueName: generateDateInterval('month', 0) },
    ],
  },
];
