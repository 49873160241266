import { DELETE_GROUP_FROM_INBOX, INBOX_BASE_URL, INBOX_Email_BASE_URL, INBOX_EMAIL_GROUPS, INBOX_GROUP_URL, INBOX_MESSAGE_COUNT, INBOX_NOTIFICATION, INBOX_THREAD_URL, UPDATE_INBOX_GROUP, UPDATE_INBOX_MESSAGE } from 'api/endpoints/inboxEndpoint';
import { BaseApi } from 'api/services/base-api';
import { buildUrl } from 'utility/utils';

export const getInboxData = async (data: Record<string, any>) => {
  try {
    const url = buildUrl({ url: `${INBOX_BASE_URL}/${data.inbox_status}`, query: data.query });
    const response = await BaseApi.get(url);
    return response;
  } catch (error) {
    console.error('getInboxData Error:', error);
    throw error;
  }
};

export const getInboxGroupData = async () => {
  try {
    const response = await BaseApi.get(INBOX_GROUP_URL);
    return response;
  } catch (error) {
    console.error('getInboxGroupData Error:', error);
    throw error;
  }
};

export const updateInboxData = async (data: Record<string, any>) => {
  try {
    const response = await BaseApi.patch(INBOX_BASE_URL, data);
    return response;
  } catch (error) {
    console.error('updateInboxData Error:', error);
    throw error;
  }
};

export const getThreadMessage = async (message_id: string) => {
  try {
    const response = await BaseApi.get(`${INBOX_THREAD_URL}/${message_id}`);
    return response;
  } catch (error) {
    console.error('getThreadMessage Error:', error);
    throw error;
  }
};

export const snoozUpdate = async (data: Record<string, any>) => {
  try {
    const response = await BaseApi.patch(INBOX_BASE_URL, data);
    return response;
  } catch (error) {
    console.error('snoozUpdate Error:', error);
    throw error;
  }
};

export const getInboxGroup = async () => {
  try {
    const response = await BaseApi.get(INBOX_EMAIL_GROUPS);
    return response;
  } catch (error) {
    console.error('snoozUpdate Error:', error);
    throw error;
  }
};

export const getInboxCountApi = async ({ variant }: { variant: string }) => {
  try {
    const url = await INBOX_MESSAGE_COUNT({ variant });
    const response = await BaseApi.get(url);
    return response;
  } catch (error) {
    console.error('Count Api Error:', error);
    throw error;
  }
};

export const DeleteGroupsFromInboxApi = async ({ data }: { data: { group_id: string; variant_id: string } }) => {
  try {
    const response = await BaseApi.delete(DELETE_GROUP_FROM_INBOX, { data: data });
    return response;
  } catch (error) {
    console.error('Count Api Error:', error);
    throw error;
  }
};

export const UpdateGroupsFromInboxApi = async ({ payload }: { payload: { group_id: string; variant_id: string } }) => {
  try {
    const response = await BaseApi.patch(UPDATE_INBOX_GROUP, payload);
    return response;
  } catch (error) {
    console.error('Count Api Error:', error);
    throw error;
  }
};

export const getEmailInboxDataApi = async (data: Record<string, any>) => {
  try {
    const url = buildUrl({ url: `${INBOX_Email_BASE_URL}/${data.inbox_status}`, query: data.query });
    const response = await BaseApi.get(url);
    return response;
  } catch (error) {
    console.error('getInboxData Error:', error);
    throw error;
  }
};

export const getNotificationInboxDataApi = async (data: Record<string, any>) => {
  try {
    const url = buildUrl({ url: `${INBOX_NOTIFICATION}/${data.inbox_status}`, query: data.query });
    const response = await BaseApi.get(url);
    return response;
  } catch (error) {
    console.error('getInboxData Error:', error);
    throw error;
  }
};

export const updateEmailMessage = async (payload: Record<string, any>) => {
  try {
    const response = await BaseApi.patch(UPDATE_INBOX_MESSAGE, payload);
    return response;
  } catch (error) {
    console.error('Update Message Error:', error);
    throw error;
  }
};
