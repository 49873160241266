import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import Button from 'components/common/Button';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux-store';
import { handleCustomSnooze, MergeDateAndTime } from 'shared/inbox/event-handler/snooze-pincker-popup';
import DateTimePicker from '../DateTimePicker';
import { InboxSnoozeModalPropsDTO } from 'dto/initial-value/views/inbox';

const SnoozPickerPopup = (props: InboxSnoozeModalPropsDTO) => {
  const { onClose, isOpen, FetchDataTrigger } = props;
  const dispatch = useAppDispatch();
  const { snoozeId } = useAppSelector((state) => state.unifiedInboxData);
  const [date, setDate] = useState(null);

  const mergeDateAndTime = (dateString: string, timeString: string) => {
    MergeDateAndTime({ dateString, timeString, setDate });
  };

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size={'sm'} closeOnOverlayClick={false} closeOnEsc={false}>
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent overflow={'auto'}>
          <ModalHeader className="!px-0">
            <div className="space-y-1 px-4">
              <h5 className="text-sm text-heading ">Pick date and time</h5>
            </div>
          </ModalHeader>
          <ModalCloseButton id="szooz" />
          <ModalBody className=" !px-0">
            <div className="relative flex flex-col items-center justify-center p-4">
              <DateTimePicker mergeDateAndTime={mergeDateAndTime} />
              <div className="flex w-full items-center justify-center gap-4 py-4">
                <Button type="button" name="Next" fontSize="sm" customClass="text-column font-medium text-sm w-[18rem]" onClick={() => handleCustomSnooze(snoozeId, dispatch, FetchDataTrigger, date)} />
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SnoozPickerPopup;
