/* eslint-disable no-sequences */
// import { CRYPTO_SECRET, JWT_SECRET } from '../../common/constants/constants';
import { ToastOptions, ToastPosition, toast } from 'react-toastify';
import jwtEncode from 'jwt-encode';
import { jwtDecode } from 'jwt-decode';
// import { LocalStorage, SessionStorage } from '../../common/api/common/storage';
import React from 'react';
import moment from 'moment';
import saveAs from 'file-saver';
import { ENV_CRYPTO_SECRET, ENV_JWT_SECRET } from 'config/envConfig';
import { deliveryDataType } from 'dto/types/views/campaignv2';
import { LocalStorage } from 'services/local.storage.service';
import { SessionStorage } from 'services/session.storage.service';
import { TIME_ZONE_DATE_FORMAT } from 'constants/date-time';
// import { deliveryDataType } from 'dto/types/views/campaignv2';

export const jwtEncrypt = (data: string | object) => {
  try {
    return jwtEncode(data, ENV_JWT_SECRET);
  } catch (error) {
    return null;
  }
};

export const jwtDecrypt = (data: string): any => {
  try {
    return jwtDecode(data);
  } catch (error) {
    return null;
  }
};

export const checkRolePermissionStatusOld = async (userRolePermissionListStoreData: any, menuNameId: string = '') => {
  // menuName - Campaigns, Email Accounts,
  try {
    // const status = await checkRolePermissionStatus(userRolePermissionListStoreData, 'Campaigns', 'delete')
    const userRolePermissionList: any = await cryptoDecrypt(userRolePermissionListStoreData, true);
    if (_isArray(userRolePermissionList)) {
      return { is_owner: true };
    }
    const accessItem = userRolePermissionList?.access_given && userRolePermissionList?.access_given.find((access: any) => access?.menu_list_id === menuNameId);
    if (!accessItem) {
      return {};
    }
    return accessItem?.options?.reduce((acc: any, option: any) => {
      acc[option.id] = option.value;
      return acc;
    }, {});
  } catch (error) {
    console.log('checkRolePermissionStatus Error:', error);
    return null;
  }
};

// export const checkRolePermissionStatus = async (userRolePermissionListStoreData: any, menuNameId: string = '') => {
//   // menuName - Campaigns, Email Accounts,
//   try {
//     if (!userRolePermissionListStoreData?.error) {
//       if (userRolePermissionListStoreData?.data?.organization_user_type === 'owner') {
//         return { organization_owner: true };
//       } else if (userRolePermissionListStoreData?.data?.organization_user_type === 'invite_user' && userRolePermissionListStoreData?.data?.role_access_list?.length) {
//         const accessItem = userRolePermissionListStoreData?.data?.role_access_list?.find((access: any) => access?.menu_list_id === menuNameId);

//         if (!accessItem) {
//           return { organization_owner: false, organization_access_list: {} };
//         }
//         const organization_access_list = accessItem?.action_list?.reduce((acc: any, option: any) => {
//           acc[option.id] = option.value;
//           return acc;
//         }, {});
//         return { organization_owner: false, organization_access_list };
//       }
//       return { organization_owner: false, organization_access_list: {} };
//     }
//     return { organization_owner: false, organization_access_list: {} };
//   } catch (error) {
//     console.log('checkRolePermissionStatus Error:', error);
//     return null;
//   }
// };

export const checkRolePermissionStatus = async (userRolePermissionListStoreData: any, menuNameId: string = '' , is_org:boolean) => {
  try {
    if (!userRolePermissionListStoreData?.error && is_org) {
      if (userRolePermissionListStoreData?.data?.organization_user_type === 'owner') {
        return { organization_owner: true };
      } else if (userRolePermissionListStoreData?.data?.organization_user_type === 'invite_user' && userRolePermissionListStoreData?.data?.role_access_list?.length) {
        const accessItem = userRolePermissionListStoreData?.data?.role_access_list?.find((access: any) => access?.menu_list_id === menuNameId);

        if (!accessItem) {
          return { organization_owner: false, organization_access_list: {} };
        }
        const organization_access_list = accessItem?.action_list?.reduce((acc: any, option: any) => {
          acc[option.id] = option.value;
          return acc;
        }, {});
        return { organization_owner: false, organization_access_list };
      }
      return { organization_owner: false, organization_access_list:{} };
    }

      return { organization_owner: true }; 
  } catch (error) {
    console.log('checkRolePermissionStatus Error:', error);
    return null;
  }
};

export const buildUrl = ({ url, params = {}, query = {} }: { url: string; params?: Record<string, string>; query?: Record<string, any> }) => {
  // common util function (*******)
  // const url = '/auth/{id}/list';
  // const params = {id:123};
  // const query = { limit: 2, offset: 5, search: 'test' };
  Object.keys(params).forEach((key) => {
    url = url.replace(`{${key}}`, params[key]);
  });

  const queryString = new URLSearchParams(query).toString();
  return queryString ? `${url}?${queryString}` : url;
};

export const generateDateInterval = (from: number | string, to: number = 1) => {
  const fromDate =
    typeof from === 'string'
      ? moment()
          .startOf(from as moment.unitOfTime.StartOf)
          .format('YYYY-MM-DD')
      : moment().subtract(from, 'days').format('YYYY-MM-DD');
  const currentDate = moment().subtract(to, 'days').add(1, 'days').format('YYYY-MM-DD');
  return `${fromDate},${currentDate}`;
};

export const cryptoDecrypt = async (cryptoData: any, jwtDecryptOptions: boolean = false, secretKeyHex: string = ENV_CRYPTO_SECRET) => {
  try {
    let encryptData = cryptoData;
    if (jwtDecryptOptions) {
      encryptData = jwtDecrypt(cryptoData);
    }
    const encData = new Uint8Array(encryptData?.data.match(/.{1,2}/g).map((byte: any) => parseInt(byte, 16)));
    const ivData = new Uint8Array(encryptData?.init_vector.match(/.{1,2}/g).map((byte: any) => parseInt(byte, 16)));
    const key = await window.crypto.subtle.importKey('raw', new Uint8Array(secretKeyHex.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))), 'AES-CBC', false, ['decrypt']);

    const decrypted = await window.crypto.subtle.decrypt({ name: 'AES-CBC', iv: ivData }, key, encData);

    return JSON.parse(new TextDecoder().decode(decrypted));
  } catch (error) {
    console.error('cryptoDecrypt Error:', error);
    return null;
  }
};

export const encryptLocalStorage = (key: string, data: string | object): boolean => {
  try {
    const encode = jwtEncrypt(data);
    return LocalStorage.setString(key, encode);
  } catch (error) {
    return false;
  }
};

export const encryptSessionStorage = (key: string, data: string | object): boolean => {
  try {
    const encode = jwtEncrypt(data);
    return SessionStorage.setString(key, encode);
  } catch (error) {
    return false;
  }
};

export const decryptSessionStorage = (key: string) => {
  try {
    const result = SessionStorage.getString(key);
    return jwtDecrypt(result);
  } catch (error) {
    return null;
  }
};
export const formValidation = (dataArr: { [x: string]: any }) => {
  let flag = false;
  // eslint-disable-next-line no-useless-escape
  const email_regex =
    // eslint-disable-next-line no-useless-escape
    /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  const pincode = /^[0-9]{6,6}$/;
  const mobile = /^[0-9]{10,10}$/;
  let errArr: any = {};
  for (var i in dataArr) {
    if (dataArr[i] === '') {
      flag = true;
      errArr[i] = i.charAt(0).toUpperCase() + i.slice(1).replace(/_/g, ' ') + ' is required';
    } else if (i === 'email' && dataArr[i] !== '') {
      if (!email_regex.test(dataArr[i])) {
        flag = true;
        errArr[i] = 'Please enter a valid Email ID';
      }
    } else if (i === 'mobile' && dataArr[i] !== '') {
      if (!mobile.test(dataArr[i])) {
        flag = true;
        errArr[i] = 'Mobile number must contain 10 digits';
      }
    } else if (i === 'pincode' && dataArr[i] !== '') {
      if (!pincode.test(dataArr[i])) {
        flag = true;
        errArr[i] = 'Pincode must be a 6 digit value';
      }
    } else if (i === 'password' && dataArr[i].length < 5) {
      flag = true;
      errArr[i] = 'Password length is too short';
    } else if (i === 'confirm_password' && dataArr[i] !== dataArr.password) {
      flag = true;
      errArr[i] = "Password and confirm password does n't match";
    }
  }
  return { errorStatus: flag, errorList: errArr };
};

export const validatePassword = (password: string): boolean | string => {
  const hasAlphabet = /[a-zA-Z]/.test(password);
  const hasSpecialChar = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/.test(password);
  const hasDigit = /\d/.test(password);
  if (password.length < 6 || !(hasAlphabet && hasSpecialChar && hasDigit)) {
    return false;
  }
  return true;
};

export const errorMessageFormatter = (errors: any) => {
  // eslint-disable-next-line no-sequences
  return errors.reduce((obj: any, item: any) => ((obj[item.property] = item.message), obj), {});
};

export const randomString = (length = 6) => {
  try {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let password = '';
    const array = new Uint32Array(length);
    crypto.getRandomValues(array);

    for (let i = 0; i < length; i++) {
      password += chars[array[i] % chars.length];
    }
    return password;
  } catch (err) {
    console.error(err);
  }
};

export const randomNumber = (min: number, max: number) => {
  try {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  } catch (error) {
    return false;
  }
};

export const _sortArray = (arr: string[]) => {
  try {
    return arr.filter((name, index) => arr.lastIndexOf(name) === index).sort((a, b) => (a < b ? -1 : 1));
  } catch (err) {
    return false;
  }
};

export const setAndGetQueryParams = (arr: object[]) => {
  try {
    var searchParams = new URLSearchParams();
    arr.forEach((item: any) => {
      if (item.value) {
        searchParams.set(item.key, item.value);
      }
    });
    return searchParams.toString();
  } catch (err) {
    return '';
  }
};
export const buildQueryAndParamUrl = (url: string, params: Record<string, string> = {}, query: Record<string, any> = {}) => {
  try {
    // const url = '/auth/{id}/list';
    // const params = {id:123};
    // const query = { limit: 2, offset: 5, search: 'test' };
    Object.keys(params).forEach((key) => {
      url = url.replace(`{${key}}`, params[key]);
    });

    const queryString = new URLSearchParams(query).toString();
    return queryString ? `${url}?${queryString}` : url;
  } catch (error) {
    return '';
  }
};
export const _emptyArray = (arr: string[]) => {
  try {
    return Array.isArray(arr) && arr.length === 0 ? true : false;
  } catch (err) {
    return false;
  }
};

export const _emptyObject = (obj = {}) => {
  try {
    return typeof obj === 'object' && !Array.isArray(obj) && obj !== null && Object.keys(obj).length === 0 ? true : false;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const _isObject = (obj = {}) => {
  try {
    return typeof obj === 'object' && !Array.isArray(obj) && obj !== null;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const _isArray = (arr: string[]) => {
  try {
    return typeof arr === 'object' && Array.isArray(arr) && arr !== null;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const _isStr = (str: {} | undefined) => {
  try {
    return typeof str === 'string' ? true : false;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const convertArrayToObjectArray = (array: any) => {
  const headers = array[0];
  const data = array.slice(1);

  const result = data.map((row: string[]) => {
    let obj: object = {};
    headers.forEach((header: string, index: number) => {
      obj = { ...obj, [header]: row[index] };
    });
    return obj;
  });

  return result;
};

export const _errorHandler = (err: string | Object) => {
  const isObject = _isObject(err);
  return isObject ? Object.values(err)?.[0] : err;
};

export const base16Decrypt = (salt: any, encoded: { match: (arg0: RegExp) => any[] }) => {
  const textToChars = (text: string) => text.split('').map((c: string) => c.charCodeAt(0));
  const applySaltToChar = (code: any) => textToChars(salt).reduce((a: number, b: number) => a ^ b, code);
  return encoded
    .match(/.{1,2}/g)
    .map((hex: string) => parseInt(hex, 16))
    .map(applySaltToChar)
    .map((charCode: number) => String.fromCharCode(charCode))
    .join('');
};

export const base16Encrypt = (salt: any, text: string) => {
  const textToChars = (text: string) => text.split('').map((c: string) => c.charCodeAt(0));
  const byteHex = (n: any) => ('0' + Number(n).toString(16)).substr(-2);
  const applySaltToChar = (code: any) => textToChars(salt).reduce((a: number, b: number) => a ^ b, code);

  return text.split('').map(textToChars).map(applySaltToChar).map(byteHex).join('');
};

export const toastState: Record<string, ToastOptions> = {
  success: {
    position: 'top-center' as ToastPosition,
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
  },
  info: {
    position: 'top-center' as ToastPosition,
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
  },
  error: {
    position: 'top-center' as ToastPosition,
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
  },
  warning: {
    position: 'top-center' as ToastPosition,
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
  },
};

// Custom hook for debouncing
export function useDebounce(value: string | boolean, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(() => {
    // Update debounced value after delay
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Cancel the timeout if value changes (also on component unmount)
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]); // Only re-call effect if value or delay changes

  return debouncedValue;
}

export const handleCopy = async (valueToCopy: string) => {
  try {
    await navigator.clipboard.writeText(valueToCopy);
    toast.success('Copied to clipboard', toastState.success);
  } catch (err) {
    console.error('Failed to copy: ', err);
  }
};

export const calculateTimeAgo = (timerange: string) => {
  try {
    const timestamp = moment(timerange);
    const now = moment();

    const years = now.diff(timestamp, 'years');
    timestamp.add(years, 'years');
    const months = now.diff(timestamp, 'months');
    timestamp.add(months, 'months');
    const weeks = now.diff(timestamp, 'weeks');
    timestamp.add(weeks, 'weeks');

    const seconds = now.diff(timestamp, 'seconds');
    const days = now.diff(timestamp.startOf('day'), 'days');
    if (seconds < 60) return 'Just now';
    if (years > 0) return years === 1 ? 'Year ago' : `${years} Years ago`;
    if (months > 0) return months === 1 ? 'Month ago' : `${months} Months ago`;
    if (weeks > 0) return weeks === 1 ? 'Week ago' : `${weeks} Weeks ago`;
    if (days > 0) return days === 1 ? 'Yesterday' : `${days} Days ago`;
    return 'Today';
  } catch (err) {
    console.error(err);
    return '';
  }
};

export const formatDateIntoDDMYYYY = (date: string | Date) => {
  const changedDate = new Date(date).toDateString().split(' ').splice(1);
  const temp = changedDate[0];
  changedDate[0] = changedDate[1];
  changedDate[1] = temp;
  return changedDate.join(' ').toString();
};

export const formatDateIntoDDMM = (date: string | Date, M?: 'short' | 'numeric' | '2-digit' | 'long' | 'narrow', reverseFormat?: boolean) => {
  const changedDate = new Date(date);
  const day = changedDate.getUTCDate();
  const month = changedDate.toLocaleString('default', { month: M || 'short' });
  return reverseFormat ? `${month} ${day}` : `${day} ${month}`;
};

export const isDateInRange = (date: Date | string, fromDate: Date | string, toDate: Date | string) => {
  try {
    const changedDate = new Date(date).setHours(0, 0, 0, 0);
    const fromDateFull = new Date(fromDate).setHours(0, 0, 0, 0);
    const toDateFull = new Date(toDate).setHours(0, 0, 0, 0);
    const status = changedDate >= fromDateFull && changedDate <= toDateFull;
    return status;
  } catch (err) {
    console.error(err);
    return '';
  }
};

export const convertArrayOfObjectsToCSV = (array: any): string => {
  if (array.length === 0) {
    return '';
  }
  const headers = Object.keys(array[0]).join(',');
  const rows = array.map((obj: any) => Object.values(obj).join(','));
  return [headers, ...rows].join('\n');
};

export const CSVFileDownloader = (data: any, file_name: string) => {
  const csvContent = convertArrayOfObjectsToCSV(data);
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  saveAs(blob, file_name);
};

export const generateRandomReportNumber = () => {
  let randomNumber = Math.floor(Math.random() * 1000000);
  return 'report-' + randomNumber;
};

export const dateFormateCdate = (from: number | string, to: number = 1) => {
  const fromDate =
    typeof from === 'string'
      ? moment()
          .startOf(from as moment.unitOfTime.StartOf)
          .format('YYYY-MM-DD')
      : moment().subtract(from, 'days').format('YYYY-MM-DD');
  const currentDate = moment().subtract(to, 'days').format('YYYY-MM-DD');
  return `${fromDate},${currentDate}`;
};

export const removeDuplicates = (stages: any) => {
  const seen = new Set();
  return stages.filter((stage: any) => {
    const duplicate = seen.has(stage.stage_name);
    seen.add(stage.stage_name);
    return !duplicate;
  });
};

export const hexToRGBA = (hex: string, opacity: number) => {
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const getPercentage = (num :String | number) =>{
  return Math.floor((Number(num) / Number(num)) * 100);
}

export const generateUUID = () => {
  if (typeof crypto !== 'undefined' && crypto.randomUUID) {
    return crypto.randomUUID();
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    // eslint-disable-next-line no-mixed-operators
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const _getQueryParams = (key: string): any => {
  const location = window.location;
  if (location?.search?.includes(key)) {
    const queryParams = new URLSearchParams(location.search);
    return jwtDecrypt(queryParams.get(key));
  }
};

export const dig: any = (obj: any, target: any) =>
  target in obj
    ? obj[target]
    : Object.values(obj).reduce((acc, val) => {
        if (acc !== undefined) return acc;
        if (typeof val === 'object') return dig(val, target);
        return null;
      }, undefined);
// Find nested object values

export const _removeDuplicates = (data: any[], keys: string[] | 'all') => {
  const uniqueTracker: any = {};
  const finalData = data.filter((item) => {
    let itemStr = JSON.stringify(item);
    if (keys !== 'all') {
      const value = keys.map((key) => item[key]).join('');
      itemStr = value;
    }
    if (!uniqueTracker[itemStr]) {
      uniqueTracker[itemStr] = true;
      return true;
    }
    return false;
  });
  return finalData;
};

export const _patternTest = (method: string, string: string) => {
  const patterns: { [key: string]: RegExp } = {
    // email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+(-?[a-zA-Z0-9]+)*(\.[a-zA-Z0-9]+(-?[a-zA-Z0-9]+)*)*\.[a-zA-Z]{2,}$/,
    // eslint-disable-next-line no-useless-escape
    email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  };

  return patterns[method].test(string);
};
export const base64Decode = (base64String: string) => {
  try {
    const decodedString = atob(base64String);
    const utf8String = decodeURIComponent(
      decodedString
        .split('')
        .map((char) => '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );
    return utf8String;
  } catch (error) {
    console.error('Error decoding Base64 string:', error);
    return null;
  }
};

export const formatDataWithTimezone = (utcDateString :string | Date, userTimeZone :string)=>{
  const utcDate = new Date(utcDateString);
  const localDate = utcDate.toLocaleString('en-US', {
    timeZone: userTimeZone,
  });
  const date = moment(new Date(localDate));
  return date.format(TIME_ZONE_DATE_FORMAT)
}

export const getDateRange = (option: any) => {
  switch (option) {
    case 'today':
      return [moment().startOf('day').toDate(), moment().endOf('day').toDate()];

    case 'yesterday':
      return [moment().subtract(1, 'days').startOf('day').toDate(), moment().subtract(1, 'days').endOf('day').toDate()];

    case 'this_week':
      return [moment().startOf('week').toDate(), moment().endOf('week').toDate()];

    case 'last_week':
      return [moment().subtract(1, 'week').startOf('week').toDate(), moment().subtract(1, 'week').endOf('week').toDate()];

    case 'this_month':
      return [moment().startOf('month').toDate(), moment().endOf('month').toDate()];

    case 'last_month':
      return [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()];
    case 'this_year':
      return [moment().startOf('year').toDate(), moment().endOf('year').toDate()];

    case 'last_year':
      return [moment().subtract(1, 'year').startOf('year').toDate(), moment().subtract(1, 'year').endOf('year').toDate()];

    default:
      return false;
  }
};

export const getCampaignStatus = (value: number) => {
  switch (value) {
    case 0:
      return 'Draft';
    case 1:
      return 'Active';
    case 2:
      return 'Rejected';
    case 3:
      return 'Paused';
    case 4:
      return 'Completed';
    default:
      return '';
  }
};

export const getDeliveryDataPattern = (channelAction: string): { delivery_data: { version_a?: deliveryDataType; version_b?: deliveryDataType } } => {
  switch (channelAction) {
    case 'direct_message':
      return {
        delivery_data: {
          version_a: { message: '', attachments: [''] },
        },
      };
    case 'connection_request':
      return {
        delivery_data: {
          version_a: { message: '', connection_note: false },
        },
      };
    case 'post_engagement':
      return {
        delivery_data: {
          version_a: { message: '', reaction: 'like', disable_comment: false, auto_comment: false, attachments: [''] },
        },
      };
    case 'profile_view':
      return {
        delivery_data: undefined,
      };

    default:
      return {
        delivery_data: {
          version_a: { subject: '', message: '' },
          version_b: { subject: '', message: '' },
        },
      };
  }
};

export const hasNullValue = (item: any, empty?: any): boolean => {
  const emptyState = empty || null;
  return Object.values(item).some((value) => value === emptyState);
};
// Check whether all the keys in objects have value

export const showPage = (path: string) => {
  const RestrictedLoggedPages = ['/', '/sign-in', 'sign-up', 'forgot-password'];
  return RestrictedLoggedPages.includes(path);
};

export const restrictionPlan = (feature: string, userType: any) => {
  if (userType?.plan_type === 'free' || userType?.plan_access_feature?.hasOwnProperty(feature)) {
    if (!userType?.plan_access_feature?.[feature]) {
      toast.error('Upgrade your plan, Your Plan does not have access to this Feature', toastState.error);
      return false;
    }
  } else {
    return true;
  }
};

export const parseQueryString = (queryString: string): Record<string, string> => {
  const query: Record<string, string> = {};
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  pairs.forEach((pair) => {
    const [key, value] = pair.split('=');
    query[decodeURIComponent(key)] = decodeURIComponent(value || '');
  });
  return query;
};

export const deepClone = <T>(obj: T): T => {
  return JSON.parse(JSON.stringify(obj));
};

export const debounce = (func: (...args: any[]) => void, wait: number): ((...args: any[]) => void) => {
  let timeout: any;
  return function executedFunction(...args: any[]) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};
