import { EMAIL_VERIFIER_USER_INTEGRATION } from 'api/endpoints/emailVerifierEndpoints'
import { BaseApi } from 'api/services/base-api'
import { AxiosResponse } from 'axios'

export const PostEmailVerifierUserIntegrationAPI = async (data: any) => {
  try {
    const response: AxiosResponse<any, any> = await BaseApi.post(EMAIL_VERIFIER_USER_INTEGRATION, data)
    return response
  } catch (error) {
    console.error('Failed to fetch data:', error)
  }
}
 