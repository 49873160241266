import { Button, Menu, MenuButton, MenuItem, MenuList, Skeleton } from '@chakra-ui/react';
import { BaseApi } from 'api/services/base-api';
import { toastState } from 'utility/utils';
import React, { useEffect, useState } from 'react';
import { BiSolidDownArrow } from 'react-icons/bi';
import { FaPen } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useAppSelector } from 'redux-store';
const OpportunityDetails = () => {
  const { opportunityInboxViewData } = useAppSelector((state) => state.crm);
  const { opportunity_id, pipeline_id } = opportunityInboxViewData;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [roleStatus, setRoleStatus] = React.useState(null);
  const custom = useAppSelector((state) => state?.custom);
  const { loggedUser } = useAppSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [stageData, setStageData] = useState([]);
  const [edit, setEdit] = useState({ stage_name: '', owner: '' });
  const inputRefs = React.useRef<any>();
  const [data, setData] = useState<any>();
  const [assigneeList, setAssigneeList] = useState([]);
  const dateFormatter = (dateValue: string) => {
    const date = new Date(dateValue);
    const readableDate = date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'long',
      hour: '2-digit' as 'numeric' | '2-digit',
      minute: '2-digit' as 'numeric' | '2-digit',
      hour12: true,
    });
    return readableDate;
  };

  const handleEditClick = () => {
    if (inputRefs.current) {
      inputRefs.current.focus();
    }
  };
  const handleOpportunityUpdate = async (value: string, variant: string, lable?: string) => {
    try {
      const data: {
        opportunity_id: string;
        cby?: string;
        stage_id?: string;
        deal_value?: number;
      } = { opportunity_id };
      if (variant === 'owner') {
        setEdit((prev) => ({ stage_name: prev.stage_name, owner: lable }));
        data.cby = value;
      }
      if (variant === 'deal_value') data.deal_value = parseFloat(value);
      if (variant === 'stage') {
        setEdit((prev) => ({ stage_name: lable, owner: prev.owner }));
        data.stage_id = value;
      }
      const response = await BaseApi.patch(`/crm/pipeline/opportunity/single`, data);
      if (response?.error) {
        toast.error('Failed to update opportunity');
      }
    } catch (error) {
      toast.error('Failed to update Opportunity');
      console.log(error);
    }
  };
  const fetchOpportunityDetails = async (isMounted?: boolean) => {
    try {
      setLoading(true);
      const response = await BaseApi.get(`/crm/pipeline/opportunity/owner/${opportunity_id}`);
      if (response?.error) {
        toast.error(response?.message, toastState.error);
      } else {
        if (isMounted) {
          setEdit({
            stage_name: response?.data[0]?.stage?.stage_name,
            owner: response?.data[0]?.owner ? response?.data[0]?.owner?.first_name + ' ' + response?.data[0]?.owner?.last_name : '',
          });
          setData(response.data[0]);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyChange = async (e: any) => {
    if (e.keyCode === 13) {
      await handleOpportunityUpdate(e.target.value, 'deal_value');
      // await updateStageName();
    }
  };
  const handleSave = async (e: { target: { value: string } }) => {
    await handleOpportunityUpdate(e.target.value, 'deal_value');
  };
  useEffect(() => {
    let isMounted = true;
    const fetchUsersList = async () => {
      try {
        const response = await BaseApi.get(`/workspace/${loggedUser?.workspace_id}`);
        if (response?.error) {
          toast.error('Failed to fetch Tasks');
        }
        if (isMounted) {
          setAssigneeList(response?.data?.user_list);
        }
      } catch (error) {
        toast.error('Failed to fetch Tasks');
        console.log(error);
      }
    };
    fetchUsersList();
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunity_id]);
  useEffect(() => {
    let isMounted = true;
    fetchOpportunityDetails(isMounted);
    return () => {
      isMounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunity_id]);

  const fetchStageData = async () => {
    try {
      if (pipeline_id) {
        const response = await BaseApi.get(`/crm/pipeline/opportunity/by/pipeline/${pipeline_id}`);
        if (response?.error) {
          toast.error(response?.message, toastState.error);
        } else {
          const sortedStages = await response?.data?.sort((a: any, b: any) => a.order - b.order);
          setStageData(sortedStages);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
    }
  };

  useEffect(() => {
    (async () => {
      fetchStageData();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipeline_id]);

  // const userRolePermissionListStoreData = useAppSelector((state) => state?.globalsettings?.globalConfiguration?.user_organization_role_details);
  React.useEffect(() => {
    const getRolePermissionStatus = async () => {
      // const status = await checkRolePermissionStatus(userRolePermissionListStoreData, 'crm');
      // setRoleStatus(status);
    };
    getRolePermissionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom?.globalDependencyLoading]);
  return (
    <div className="flex w-full flex-col border-b">
      <p className="text-sm font-bold text-[#25324B]">Opportunity Details</p>
      <div className={`flex w-full items-center justify-between py-2 `}>
        <div>
          <p className="text-[0.6rem] text-column">Opportunity owner</p>

          <p className="text-[0.8rem] font-bold text-heading">{loading ? <Skeleton height="20px" width="150px" /> : edit?.owner || ''}</p>
        </div>
        {(roleStatus?.organization_access_list?.edit || roleStatus?.organization_owner) && (
          <div className={`flex size-3 items-center justify-center gap-1 pb-1 `}>
            <Menu>
              <MenuButton as={Button} _hover={{ bg: '#fff' }} _active={{ bg: '#fff' }} bg="#fff" className="bg-white !px-2 !text-base !font-semibold  ">
                <BiSolidDownArrow className="size-3" />
              </MenuButton>
              <MenuList minW="0" className="!h-[73px] !w-[200px] truncate !rounded-lg bg-white !p-0  shadow-lg  " style={{ width: '120px', height: '73px' }}>
                {assigneeList?.map((item) => (
                  <MenuItem
                    key={item?.uuid}
                    onClick={() => handleOpportunityUpdate(item.uuid, 'owner', `${item.first_name} ${item.last_name}`)}
                    className="flex items-center space-x-2 rounded-t-lg !py-2 !pe-2 !ps-4 text-sm !text-[#2B3674] hover:rounded-t-lg hover:!bg-[#4318FF] hover:!text-white "
                  >
                    <span>
                      {item.first_name} {item.last_name}
                    </span>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </div>
        )}
      </div>
      <div className={`flex w-full items-end justify-between py-2 `}>
        <div>
          <p className="text-[0.6rem] text-column">Last contacted</p>
          <p className="text-[0.8rem] font-bold text-heading">
            {data?.opportunity?.latest_message?.platform_message_details?.date
              ? dateFormatter(data?.opportunity?.latest_message?.platform_message_details?.date)
              : 'Start new conversation'}
          </p>
        </div>
      </div>

      <div className={`flex w-full items-center justify-between py-2 `}>
        <div>
          <p className="text-[0.6rem] text-column">Deal Value</p>
          {/* <p className="text-[0.8rem] font-bold text-heading">{data?.deal_value}</p> */}
          {loading ? (
            <Skeleton height="20px" width="150px" />
          ) : (
            <>
              {(roleStatus?.organization_access_list?.edit || roleStatus?.organization_owner) && (
                <input
                  type="number"
                  defaultValue={data?.deal_value}
                  className="text-[0.8rem] font-bold text-heading outline-none"
                  ref={inputRefs}
                  onKeyDown={handleKeyChange}
                  onBlur={handleSave}
                />
              )}
            </>
          )}
        </div>
        {(roleStatus?.organization_access_list?.edit || roleStatus?.organization_owner) && (
          <div className={`flex size-3 cursor-pointer items-center justify-center gap-1 pb-1 `} onClick={handleEditClick}>
            <FaPen />
          </div>
        )}
      </div>
      <div className={`flex w-full items-center justify-between py-2 `}>
        <div>
          <p className="text-[0.6rem] text-column">Deal Stage</p>
          <p className="text-[0.8rem] font-bold text-heading">{loading ? <Skeleton height="20px" width="150px" /> : edit?.stage_name || ''}</p>
        </div>
        {(roleStatus?.organization_access_list?.edit || roleStatus?.organization_owner) && (
          <div className={`flex size-3 items-center justify-center gap-1 pb-1 `}>
            <Menu>
              <MenuButton as={Button} _hover={{ bg: '#fff' }} _active={{ bg: '#fff' }} bg="#fff" className="bg-white !px-2 !text-base !font-semibold  ">
                <BiSolidDownArrow className="size-3" />
              </MenuButton>
              <MenuList minW="0" className="!h-[73px] !w-[200px] truncate !rounded-lg bg-white !p-0  shadow-lg  " style={{ width: '120px', height: '73px' }}>
                {stageData?.map((item: { stage_name: string; uuid: string }) => (
                  <MenuItem
                    key={item?.uuid}
                    onClick={() => handleOpportunityUpdate(item?.uuid, 'stage', item?.stage_name)}
                    className="flex items-center space-x-2 rounded-t-lg !py-2 !pe-2 !ps-4 text-sm !text-[#2B3674] hover:rounded-t-lg hover:!bg-[#4318FF] hover:!text-white "
                  >
                    <span>{item?.stage_name}</span>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </div>
        )}
      </div>
      {data?.campaign?.campaign_name ? (
        <div className={`flex w-full items-end justify-between py-2 `}>
          <div>
            <p className="text-[0.6rem] text-column">Campaign</p>
            <p className="text-[0.8rem] font-bold text-heading">{data?.campaign?.campaign_name}</p>
          </div>
        </div>
      ) : null}
      <div className={`flex w-full items-end justify-between py-2 `}>
        <div>
          <p className="text-[0.6rem] text-column">Source </p>
          <p className="text-[0.8rem] font-bold text-heading">{data?.variant === 'Uploaded' ? 'Uploaded Lead' : 'Referal Lead'}</p>
        </div>
      </div>
    </div>
  );
};

export default OpportunityDetails;
