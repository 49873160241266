import React, { ErrorInfo, ReactNode } from "react";
interface Props {
    children: ReactNode;
}
interface State {
    hasError: boolean;
}
export { };

declare global {
    interface Window {
        gtag: (command: string, action: string, params: object) => void;
    }
}

class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the alternative UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("Error caught by ErrorBoundary: ", error, errorInfo);
        this.logErrorToServices(error, errorInfo);
    }

    logErrorToServices(error: Error, errorInfo: ErrorInfo) {
        if (typeof window.gtag === 'function') {
            window.gtag('event', 'exception', {
                description: `${error.toString()}: ${errorInfo.componentStack}`,
                fatal: true
            });
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="flex flex-col items-center justify-center min-h-screen bg-red-100 text-red-600">
                    <h1 className="text-2xl font-semibold">Something went wrong.</h1>
                    <p>Please try refreshing the page, or contact support if the problem persists.</p>
                </div>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
