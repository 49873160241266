/* eslint-disable no-useless-escape */
import { useState } from 'react';
import { MdDns } from 'react-icons/md';
import { useAppDispatch } from 'redux-store';
import NotificationSettingsPopup from '../components/NotificationSettingsPopup';
import { BrowserConfigurationFunction, FetchNotificationDataFunction } from 'shared/notification/event-handler/notification-event';
import { NOTIFICATION_DATA } from 'dto/initial-value/views/notification';

const Notifications = () => {
  const [isopen, setIsopen] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const fetchNotificationData = async (key: string) => {
    await FetchNotificationDataFunction(setIsopen, key, dispatch);
  };

  const BrowserSettings = async () => {
    await BrowserConfigurationFunction(fetchNotificationData)
  };

  const handleSettingsOption = async (key: string) => {
    if (key === NOTIFICATION_DATA?.BROWSER_NOTIFY) {
      BrowserSettings();
    } else {
      fetchNotificationData(key);
    }
  };

  return (
    <div className="mt-3 flex min-h-[80vh] w-full items-center justify-center bg-white py-3 dark:!bg-darkOne dark:!text-white">
      <div className="w-[46rem] rounded-md p-10 shadow-lg dark:shadow-darkBorder">
        <div>
          <h3 className="mb-6 text-lg font-bold text-heading dark:!text-bglightSkyBlue">Notification Settings</h3>
          <div>
            <div
              className="mb-5 flex cursor-pointer gap-4 rounded-lg border border-[#E5E5E5] px-6 py-4 dark:border-darkBorder"
              onClick={() => {
                handleSettingsOption(NOTIFICATION_DATA?.INBOX_NOTIFY);
              }}
            >
              <div>
                <MdDns className="text-column dark:text-gray-300" size={'20px'} />
              </div>
              <div className=" grow ">
                <div className="mt-0 pb-0 font-semibold text-heading dark:text-gray-200">Inbox</div>
                <p className="text-sm text-column dark:text-gray-400">
                  <span>Default &nbsp;</span>Sparkle's recommendation settings
                </p>
              </div>
              <div className="self-start">
                <div className="flex h-10 w-48 items-center justify-center rounded-md border border-[#D0D5DD] text-column dark:border-darkBorder dark:text-gray-400 ">Default</div>
              </div>
            </div>
            <div
              className="mb-5 flex cursor-pointer gap-4 rounded-lg border border-[#E5E5E5] px-6 py-4 dark:border-darkBorder"
              onClick={() => {
                handleSettingsOption(NOTIFICATION_DATA?.EMAIL_NOTIFY);
              }}
            >
              <MdDns className="text-column dark:text-gray-300" size={'20px'} />

              <div className="self-top grow">
                <p className="font-semibold text-heading dark:text-gray-200">Email</p>
                <p className="text-sm text-column dark:text-gray-400">
                  <span>Default &nbsp;</span>Sparkle's recommendation settings
                </p>
              </div>
              <div>
                <div className="flex h-10 w-48 items-center justify-center rounded-md border border-[#D0D5DD] text-column dark:border-darkBorder dark:text-gray-400 ">Default</div>
              </div>
            </div>
            <div
              className="mb-5 flex cursor-pointer gap-4 rounded-lg border border-[#E5E5E5] px-6 py-4 dark:border-darkBorder"
              onClick={() => {
                handleSettingsOption('browser_notify');
              }}
            >
              <MdDns className="text-column dark:text-gray-300" size={'20px'} />

              <div className="self-top grow">
                <p className="font-semibold text-heading dark:text-gray-200">Browser</p>
                <p className="text-sm text-column dark:text-gray-400">
                  <span>Default &nbsp;</span>Sparkle's recommendation settings
                </p>
              </div>
              <div>
                <div className="flex h-10 w-48 items-center justify-center rounded-md border border-[#D0D5DD] text-column dark:border-darkBorder dark:text-gray-400 ">Enable Notification</div>
              </div>
            </div>
            <div
              className="flex cursor-pointer gap-4 rounded-lg border border-[#E5E5E5] px-6  py-4 dark:border-darkBorder"
              onClick={() => {
                handleSettingsOption(NOTIFICATION_DATA?.SMS_NOTIFY);
              }}
            >
              <MdDns className="text-column dark:text-gray-300" size={'20px'} />

              <div className="grow align-top">
                <p className="font-semibold text-heading dark:text-gray-200">SMS</p>
                <p className="text-sm text-column dark:text-gray-400">
                  <span>Default &nbsp;</span>Sparkle's recommendation settings
                </p>
              </div>
              <div>
                <div className="flex h-10 w-48 items-center justify-center rounded-md border border-[#D0D5DD] text-column dark:border-darkBorder dark:text-gray-400 ">Default</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isopen && (
        <NotificationSettingsPopup
          isOpen={isopen}
          onClose={() => {
            setIsopen(false);
          }}
        />
      )}
    </div>
  );
};

export default Notifications;
