import { useState } from 'react';
import { IoMdMore } from 'react-icons/io';
import { useAppSelector } from 'redux-store';

interface RowData {
  id?: string;
  uuidRow?: string;
  status?: number;
  table?: any;
  setData?: any;
  rowData?: any;
  options?: any;
  bodyClassName?: any;
  buttonClassName?: any;
  fetchList?: () => void;
}

const CommonRowSettings = (props: RowData) => {
  const { id, uuidRow, options, rowData, bodyClassName, buttonClassName } = props;
  const custom = useAppSelector((state) => state?.custom);
  // console.log(uuidRow);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const dispatch = useAppDispatch();
  const [showOptions, setShowOptions] = useState(false);

  return (
    <>
      <div
        className={`relative mr-3 w-min cursor-pointer rounded-md  py-0.5 ${showOptions && 'bg-[#4318FF0A]'}`}
        onMouseOver={(e) => e.stopPropagation}
        tabIndex={0}
        onBlur={() => {
          setShowOptions(false);
        }}
      >
        <IoMdMore
          className="custom-row-setting-btn z-0 text-lg text-gray-700"
          id={`edit-btn-${id}`}
          onClick={(event) => {
            event?.stopPropagation();
            setShowOptions(true);
          }}
        />
        {showOptions && !custom?.globalOpt && (
          <div className={`${bodyClassName} absolute -left-[75px] z-10 flex flex-col overflow-hidden rounded-md border bg-white dark:border-darkBorder dark:bg-darkTwo`}>
            {options?.map((item: any, idx: number) => (
              <div
                key={idx}
                className={`${buttonClassName} ${item?.name}-btn delete_list group flex h-8 cursor-default items-center gap-2 px-3 font-medium transition-all duration-150 hover:bg-brand-500 hover:text-white ${options?.length - 1 === idx ? '' : 'border-b'}`}
                id={item?.id || ''}
                onClick={() => item?.functioning(uuidRow, rowData)}
              >
                {item?.icon}
                <h5 className="text-xs">{item?.name}</h5>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default CommonRowSettings;
