import React, { useCallback, useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/modal';
import { Button, Menu, MenuButton, MenuItem, MenuList, SkeletonText } from '@chakra-ui/react';
import { FiSearch } from 'react-icons/fi';
import { IoMdMore } from 'react-icons/io';
import { TbTrash } from 'react-icons/tb';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'redux-store';
import { setBrandId, setPipelineId, setSelectPipelineModal } from 'redux-store/crm';
import { getBrand } from 'redux-store/crm-onboard';
import { BaseApi } from 'api/services/base-api';
import DeleteConfirmModal from 'components/common/crm/DeleteConfrimModal';
import InfiniteScrollComponent from 'components/common/infinityScroll';
import { SelectPipelineType } from 'dto/types/views/crm';
import { useDebounce, checkRolePermissionStatus, setAndGetQueryParams, toastState, jwtEncrypt } from 'utility/utils';

const SelectPipelinePopup = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { openSelectPipelinePopup } = useAppSelector((state) => state.crm);
  const custom = useAppSelector((state) => state?.custom);
  const [queryParams, setQueryParams] = useState({ limit: 25, offset: 2, search: '' });
  const debouncedSearchValue = useDebounce(queryParams.search, 300);
  const [pipelineList, setPipelineList] = React.useState({ data: [], count: 0 });
  const [selectedPipelineId, setSelectedPipelineId] = React.useState('');
  const [fetchtrigger, setFetchTrigger] = React.useState(false);
  const [dataLoading, setDataLoading] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState('');
  const cardLoading = Array(9).fill(null);
  const colors = ['#4318FF', '#E7C100', '#7F56D9', '#7E4400', '#12B76A'];
  const getColorClass = (index: number) => colors[index % colors.length];
  const getColorStyle = (index: number) => ({
    backgroundColor: colors[index % colors.length],
  });

  const [roleStatus, setRoleStatus] = React.useState(null);
  const userRolePermissionListStoreData = useAppSelector((state) => state?.globalsettings?.globalConfiguration?.user_organization_role_details);
  const { environment } = useAppSelector((state) => state.globalsettings);

  React.useEffect(() => {
    const getRolePermissionStatus = async () => {
      const status = await checkRolePermissionStatus(userRolePermissionListStoreData, 'crm', environment?.app_default_feature?.is_org_enbaled);
      setRoleStatus(status);
    };
    getRolePermissionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom?.globalDependencyLoading,userRolePermissionListStoreData]);

  React.useEffect(() => {
    (async () => {
      const query = setAndGetQueryParams([{ key: 'search', value: queryParams.search }]);
      try {
        setDataLoading(true);
        const response = await BaseApi.get(`/crm/pipeline?${query}`);
        if (response?.error) {
          toast.error(response?.message, toastState.error);
        } else {
          setPipelineList({ data: response?.data?.data, count: response?.data?.total_records?.count });
        }
      } catch (err) {
        console.error('fetch Pipeline error Error:', err);
      } finally {
        setDataLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchtrigger, custom?.globalDependencyLoading, debouncedSearchValue]);

  const handlePipelineSearchChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueryParams((prev) => ({
      ...prev,
      search: event?.target?.value,
    }));
  };

  const onPipelinePopupClose = async () => {
    dispatch(setSelectPipelineModal(false));
    setSelectedPipelineId('');
  };

  const handleSelectedPipeline = async (item: SelectPipelineType) => {
    setSelectedPipelineId(item.uuid);
    const data = await jwtEncrypt({
      pipeline_id: item.uuid,
      brand_id: item.brand_id,
      pipelineName: item.pipeline_name,
    });

    if (item.is_first_visit) {
      if (roleStatus?.organization_access_list?.edit || roleStatus?.organization_owner) {
        dispatch(getBrand(item?.brand_id));
        navigate('/crm/onboard');
        onPipelinePopupClose();
      } else {
        toast.warning("Selected Pipeline has no data , you don't have access to onboard. Select any other pipeline", toastState.warning);
      }
      return;
    } else {
      const payload = {
        uuid: item.uuid,
        pipeline_name: item.pipeline_name,
      };
      dispatch(setPipelineId(payload));
      dispatch(setBrandId(item.brand_id));
      navigate(`/crm?_id=${data}`);
      onPipelinePopupClose();
    }
  };

  const handleDeletePipeline = async () => {
    try {
      const response = await BaseApi.delete(`/crm/pipeline/`, { data: { delete_ids: [deleteId] } });
      if (response.error) {
        toast.error(response.message);
      } else {
        toast.success('Pipeline deleted successfully', toastState.success);
        setFetchTrigger((prev) => !prev);
      }
    } catch (error) {
      console.error('Error deleting stage:', error);
      toast.error('Failed to delete Pipeline');
    } finally {
      // setFetchTrigger((prev) => !prev);
      setDeleteId('');
      setOpenDeleteModal(false);
    }
  };

  const handleConfirmDelete = (id: string) => {
    setDeleteId(id);
    setOpenDeleteModal(true);
  };

  const FetchMorePipelineData = useCallback(
    async (setIsFetching: any) => {
      const fetchData = async () => {
        try {
          setIsFetching(true);
          const query = setAndGetQueryParams([
            { key: 'limit', value: queryParams.limit },
            { key: 'offset', value: queryParams.offset },
            { key: 'search', value: queryParams.search },
          ]);
          const response = await BaseApi.get(`/crm/pipeline/?${query}`);
          const responseData = response.data;
          if (!responseData?.data) return setPipelineList({ data: [], count: 0 });
          if (responseData?.data?.length < 25) {
            setPipelineList((prev) => ({ data: [...prev?.data, ...responseData?.data], count: prev?.data?.length }));
          } else {
            setPipelineList((prev) => ({ ...prev, data: [...prev?.data, ...responseData?.data] }));
          }
          setQueryParams((prev) => ({
            ...prev,
            offset: prev?.offset + 1,
          }));
        } catch (error) {
          console.error(error);
        } finally {
          setIsFetching(false);
        }
      };
      pipelineList?.data?.length && pipelineList?.data?.length < pipelineList?.count && fetchData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pipelineList?.data?.length, debouncedSearchValue, queryParams.offset]
  );

  return (
    <>
      <div>
        <Modal isOpen={openSelectPipelinePopup} size={'auto'} onClose={() => onPipelinePopupClose()} isCentered={true}>
          <ModalOverlay />
          <ModalContent className=" !w-[58rem] dark:bg-darkThree">
            {/* <ModalCloseButton className="z-10 text-gray-700" /> */}
            <header className="mt-4 flex flex-col items-center justify-center gap-y-2">
              <h3 className="text-[20px] font-semibold text-[#2B3674] dark:text-purple-500">All pipelines</h3>
              <p className="text-xs text-[#667085] dark:text-gray-200">Select the pipeline you want to view.</p>
            </header>
            <ModalBody className="!relative h-[360px] !overflow-auto !rounded-md  !px-0 py-2">
              <div className="mt-5 px-4">
                <div className="mb-2 flex items-center justify-center px-4">
                  <div
                    className={`m-2 flex w-2/4 items-center gap-2 rounded-md border px-3 py-1.5 outline outline-0 focus-within:outline-1 focus-within:outline-brand-400 dark:border-darkBorder `}
                    // onClick={() => inputRef.current.focus()}
                  >
                    <FiSearch className="size-4  cursor-pointer dark:text-white" />
                    <input
                      // ref={inputRef}
                      type="text"
                      autoComplete="off"
                      placeholder="Search..."
                      // onKeyUp={onChange}
                      onChange={handlePipelineSearchChanges}
                      id={'pipeline-search-input'}
                      className={`w-full text-sm font-bold text-gray-800 caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:bg-darkThree dark:text-gray-200  dark:placeholder:text-white`}
                    />
                  </div>
                </div>
                <hr className="mb-3 dark:border-darkBorder" />
                {!dataLoading ? (
                  <>
                    <div className="h-[23.125rem]  overflow-y-auto ">
                      {pipelineList?.data?.length ? (
                        <>
                          <div className="flex flex-wrap content-start gap-3">
                            {pipelineList?.data?.map((item, index) => {
                              return (
                                <div>
                                  <div
                                    key={item?.uuid}
                                    className={`brand-pipeline-card-${index} relative mb-2 flex h-24 w-72 cursor-pointer select-none justify-around rounded-md border p-2 dark:border-darkBorder dark:bg-darkTwo ${
                                      selectedPipelineId === item.uuid ? 'border-blueSecondary bg-bgInside' : ''
                                    }`}
                                    onClick={() => handleSelectedPipeline(item)}
                                  >
                                    <div className="flex h-full  items-center justify-center pr-1.5">
                                      <div className="flex h-12 w-12 items-center justify-center overflow-hidden rounded-full bg-imagebg p-2">
                                        <div className={`${getColorClass(index)}  flex h-8 w-8 items-center justify-center rounded-full`} style={getColorStyle(index)}>
                                          <span className="text-center text-xl font-bold text-white ">{item?.pipeline_name?.charAt(0).toUpperCase()}</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex h-full w-3/5 flex-col items-start justify-center">
                                      <h4 className="brand-pipeline-name w-full truncate text-base font-bold text-textBlack dark:text-gray-200">{item?.pipeline_name}</h4>
                                      {/* <span className="mt-0.5  text-xs text-paused-color">200 prospects</span> */}
                                    </div>
                                    {(roleStatus?.organization_access_list?.delete || roleStatus?.organization_owner) && (
                                      <button className="ml-5">
                                        <Menu>
                                          <MenuButton
                                            as={Button}
                                            // _hover={{ bg: '#fff' }}
                                            // _active={{ bg: '#fff' }}
                                            // bg="#fff"
                                            className={`!bg-white !px-2 !text-base !font-semibold dark:!bg-darkTwo dark:hover:!bg-darkTwo pipeline-card-menu-${index} hover:!bg-white active:!bg-white dark:active:!bg-darkTwo `}
                                            onClick={(event) => {
                                              event?.stopPropagation();
                                            }}
                                          >
                                            <IoMdMore className="text-[20px] text-[#8F9BBA] dark:hover:!text-purple-400" />
                                          </MenuButton>
                                          <MenuList
                                            minW="0"
                                            w={'115px'}
                                            className="!h-[38px] !w-[115px] !rounded-lg bg-white !p-0 shadow-lg dark:!border-darkBorder dark:!bg-darkTwo  "
                                          >
                                            <MenuItem
                                              onClick={(event) => {
                                                event.stopPropagation();
                                                handleConfirmDelete(item.uuid);
                                              }}
                                              className={`pipeline-delete-menu flex items-center space-x-2 rounded-lg !py-2 !pe-2 !ps-4 text-sm !text-[#2B3674] hover:rounded-lg hover:!bg-[#4318FF] hover:!text-white dark:!border-darkBorder dark:!bg-darkTwo dark:!text-gray-200 dark:hover:!text-red-500`}
                                            >
                                              <TbTrash className="text-lg" />
                                              <span>Delete</span>
                                            </MenuItem>
                                          </MenuList>
                                        </Menu>
                                      </button>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div>
                            {pipelineList?.count >= 25 && pipelineList?.count !== pipelineList?.data?.length && (
                              <InfiniteScrollComponent loadMore={FetchMorePipelineData} loader={<SkeletonText mt="4" noOfLines={4} spacing="4" skeletonHeight="2" />} />
                            )}
                          </div>
                        </>
                      ) : (
                        <h1 className="mx-auto text-center dark:text-white">No data Found</h1>
                      )}
                    </div>
                  </>
                ) : (
                  <div className="flex h-[23.125rem] flex-wrap content-start gap-3 overflow-y-auto">
                    {cardLoading.map((_, index) => (
                      <div
                        key={index}
                        className=" flex h-28 w-72 animate-pulse cursor-pointer select-none rounded-md border bg-bgInside p-2 dark:border-darkOne dark:bg-darkBorder"
                      >
                        <div className="flex h-full w-1/5 items-center justify-center pr-1.5">
                          <div className="h-12 w-12 rounded-full bg-gray-300 dark:bg-blue-800"></div>
                        </div>
                        <div className="flex h-full w-full flex-col items-start justify-center">
                          <div className="h-4 w-full rounded bg-gray-400 dark:bg-blue-800"></div>
                          <div className="mt-1 h-3 w-3/4 rounded bg-gray-400 dark:bg-blue-800"></div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
      </div>
      <DeleteConfirmModal
        isOpen={openDeleteModal}
        onClose={() => {
          setOpenDeleteModal(false);
          setDeleteId('');
        }}
        handleDelete={handleDeletePipeline}
      />
    </>
  );
};

export default SelectPipelinePopup;
