import { Spinner } from '@chakra-ui/react';
import React from 'react';

const Loader: React.FC = () => {
    return (
        <div className="flex justify-center items-center min-h-screen">
            {/* <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div> */}
            <Spinner
                thickness="3px"
                speed="0.4s"
                color='blue.500'
                className="absolute left-1/2 top-1/2"
                size="xl"
            />
        </div>
    );
};

export default Loader;
