import { useAppSelector } from 'redux-store';
import ContactCard from './ContactCard';

import { Skeleton } from '@chakra-ui/react';

const CompanyMutualContacts = (props: any) => {
  const { mutualContactData, loading } = props;
  const { opportunityInboxViewData } = useAppSelector((state) => state.crm);
  const { opportunity_domain } = opportunityInboxViewData;

  return (
    <div className="mt-1 flex w-full flex-col gap-2 ">
      {loading ? (
        <>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </>
      ) : (
        <>
          {mutualContactData?.length ? (
            <p className="text-sm font-bold text-[#25324B]">
              Contacts from <span className="capitalize">{opportunity_domain?.split('.')[0]}</span>
            </p>
          ) : null}
          {mutualContactData?.map((item: any) => (
            <div key={item.uuid}>
              <ContactCard data={item} />
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default CompanyMutualContacts;
