import { CHAT_LIST, CREATE_TICKET, SEND_MESSAGE, SUPSCRIPTION_PAYMENT_STATUS, TRUE_VERIFIER_LIST, UPDATE_TICKET } from 'api/endpoints/trueVerifierEndPoints';
import { BaseApi } from 'api/services/base-api';
import { buildUrl } from 'utility/utils';

export const GetSupscriptionPaymentStatusAPI = async (orderId: string) => {
  try {
    const getUrl = SUPSCRIPTION_PAYMENT_STATUS(orderId);
    const response = await BaseApi.get(getUrl);
    return response;
  } catch (error) {
    console.error('GetSupscriptionPaymentStatusAPI Error:', error);
    throw error;
  }
};

export const FetchTrueVerifyListAPI = async (query: Record<string, any>) => {
  try {
    const getUrl = buildUrl({ url: TRUE_VERIFIER_LIST, query });
    const response = await BaseApi.get(getUrl);
    return response;
  } catch (error) {
    console.error('FetchTrueVerifyListAPI Error:', error);
    throw error;
  }
};

export const FetchTChatListAPI = async (ticketId: string) => {
  try {
    const getUrl = CHAT_LIST(ticketId);
    const response = await BaseApi.get(getUrl);
    return response;
  } catch (error) {
    console.error('FetchTrueVerifyListAPI Error:', error);
    throw error;
  }
};

export const SendMessageAPI = async (params: Record<string, any>) => {
  try {
    const response = await BaseApi.post(SEND_MESSAGE, params);
    return response;
  } catch (error) {
    console.error('FetchTrueVerifyListAPI Error:', error);
    throw error;
  }
};

export const UpdateTicketAPI = async (params: Record<string, any>) => {
  try {
    const response = await BaseApi.patch(UPDATE_TICKET, params);
    return response;
  } catch (error) {
    console.error('FetchTrueVerifyListAPI Error:', error);
    throw error;
  }
};

export const CreateNewTicketAPI = async (params: Record<string, any>) => {
  try {
    const response = await BaseApi.post(CREATE_TICKET, params);
    return response;
  } catch (error) {
    console.error('FetchTrueVerifyListAPI Error:', error);
    throw error;
  }
};

