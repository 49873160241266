import { CAMPAIGN_PROSPECT_LIST_URL, FILTER_BY_RULE_URL, GROUP_BASE_URL, LINKED_GROUP_DELETE_URL, LINKED_GROUP_URL, MAIL_IGNORE_URL, MERGE_URL, SENDER_ACCOUNT_LIST_URL, THREAD_URL, UNMATCH_BASE_URL } from 'api/endpoints/unmatchEndpoints';
import { BaseApi } from 'api/services/base-api';
import { buildUrl } from 'utility/utils';

export const getUnmatchList = async (query: Record<string, any>) => {
  try {
    const url = buildUrl({ url: UNMATCH_BASE_URL, query: query });
    const response = await BaseApi.get(url);
    return response;
  } catch (error) {
    console.error('getUnmatchList Error:', error);
    throw error;
  }
};

export const updatelinkedGroupList = async (data: Record<string, any>) => {
  try {
    const response = await BaseApi.patch(LINKED_GROUP_URL, data);
    return response;
  } catch (error) {
    console.error('updatelinkedGroupList Error:', error);
    throw error;
  }
};

export const getUnmatchGroupList = async () => {
  try {
    const response = await BaseApi.get(LINKED_GROUP_URL);
    return response;
  } catch (error) {
    console.error('getUnmatchGroupList Error:', error);
    throw error;
  }
};

export const updateUnmatchIgnoreRule = async (data: Record<string, any>) => {
  try {
    const response = await BaseApi.patch(MAIL_IGNORE_URL, data);
    return response;
  } catch (error) {
    console.error('updateUnmatchGroupList Error:', error);
    throw error;
  }
};

export const filterByRule = async (data: Record<string, any>) => {
  try {
    console.log(data);
    const response = await BaseApi.get(`${FILTER_BY_RULE_URL}/${data.params}`);
    return response;
  } catch (error) {
    console.error('filterByRule Error:', error);
    throw error;
  }
};

export const linkedDeleteGroup = async (data: Record<string, any>) => {
  try {
    const response = await BaseApi.delete(LINKED_GROUP_DELETE_URL, data);
    return response;
  } catch (error) {
    console.error('linkedDeleteGroup Error:', error);
    throw error;
  }
};

export const deleteGroup = async (data: Record<string, any>) => {
  try {
    const url = buildUrl({ url: GROUP_BASE_URL, params: data });
    const response = await BaseApi.delete(url);
    return response;
  } catch (error) {
    console.error('deleteGroup Error:', error);
    throw error;
  }
};

export const updateGroup = async (data: Record<string, any>) => {
  try {
    const response = await BaseApi.delete(GROUP_BASE_URL, data);
    return response;
  } catch (error) {
    console.error('updateGroup Error:', error);
    throw error;
  }
};

export const getMessageThread = async (data: Record<string, any>) => {
  try {
    const response = await BaseApi.get(`${THREAD_URL}/${data.uuid}`);
    return response;
  } catch (error) {
    console.error('getMessageThread Error:', error);
    throw error;
  }
};

export const getSenderAccountList = async (data: Record<string, any>) => {
  try {
    const url = buildUrl({ url: SENDER_ACCOUNT_LIST_URL, params: data });
    const response = await BaseApi.get(url);
    return response;
  } catch (error) {
    console.error('getSenderAccountList Error:', error);
    throw error;
  }
};

export const updateUnmatchEmails = async (data: Record<string, any>) => {
  try {
    const response = await BaseApi.patch(UNMATCH_BASE_URL, data);
    return response;
  } catch (error) {
    console.error('getSenderAccountList Error:', error);
    throw error;
  }
};

export const getMatchOption = async (data: Record<string, any>) => {
  try {
    const url = buildUrl({ url: CAMPAIGN_PROSPECT_LIST_URL, query: data.query });
    console.log(url, 'url');
    const response = await BaseApi.get(url);
    return response;
  } catch (error) {
    console.error('getSenderAccountList Error:', error);
    throw error;
  }
};

export const mergeCards = async (data: Record<string, any>) => {
  try {
    const response = await BaseApi.patch(MERGE_URL, data);
    return response;
  } catch (error) {
    console.error('getSenderAccountList Error:', error);
    throw error;
  }
};
