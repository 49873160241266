import React from 'react';
import { useDisclosure } from '@chakra-ui/hooks';
import { FaCheckCircle } from 'react-icons/fa';
import { ReactSVG } from 'react-svg';

import { card1SVG, card2SVG, card3SVG, card4SVG, card5SVG, card6SVG } from 'assets/images/rewards';
import Button from 'components/common/Button';
import { AiFillGift } from 'react-icons/ai';
import PrmoCodeValidatorModal from '../components/PrmoCodeValidatorModal';

const StepsModal = React.lazy(() => import('../components/StepsModal'));

const Rewards = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const scratchCards = new Array(6).fill('card');
  const svgCollection = [card1SVG, card2SVG, card3SVG, card4SVG, card5SVG, card6SVG];
  const [promoModalOpen, setPromoModalOpen] = React.useState(false);
  const handleClosePromoCodeModal = () => {
    setPromoModalOpen(false);
  };

  return (
    <div className="noscroll mt-2.5 h-[88vh] w-full space-y-5 overflow-y-hidden rounded-md bg-white px-7  py-3 dark:bg-darkOne dark:text-white">
      <div className="h-48 bg-gradient-to-r from-[#FFFFFF] to-[#4318FF10] p-3">
        <div className="flex justify-end">
          <Button id='claim_rewards' LeftIcon={<AiFillGift size={13} />} name="Claim Rewards" onClick={() => setPromoModalOpen(true)} />
        </div>
      </div>
      <div className="grid h-[26rem] w-full grid-cols-4 gap-y-5 overflow-auto">
        {scratchCards?.map((card, i) => (
          <div className="hover:scale- flex h-[13.5rem] w-[18.4rem] cursor-pointer flex-col items-center rounded-md border border-[#4286F580] bg-[#4286F50A]" key={i} onClick={() => onOpen()}>
            <ReactSVG
              src={svgCollection[i]}
              beforeInjection={(svg: any) => {
                svg.setAttribute('width', '190');
                return svg;
              }}
            />
            <h5 className="-mt-0.5 w-11/12 text-center text-[0.85rem] font-semibold leading-4 text-heading">Scratch card worth up to 50 Sparkle credits</h5>
            <h5 className="mt-2 flex items-center gap-1.5 text-[0.9rem] font-medium text-column">
              <FaCheckCircle className="-mt-0.5 rounded-full bg-white text-[#00BA00]" />
              <span>Unlocked</span>
            </h5>
          </div>
        ))}
      </div>
      <PrmoCodeValidatorModal isOpen={promoModalOpen} onClose={handleClosePromoCodeModal} />
      <StepsModal stepOpen={isOpen} stepClose={onClose} />
    </div>
  );
};

export default Rewards;
