import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
// import Button from '../Button';
import React from 'react';
import { FaRegCircleCheck } from 'react-icons/fa6';
import Button from '../Button';

const ConfirmationPopup = (props: any) => {
  const { isOpen, loading, handleConfirmFunction, headerContent, bodyContent, bodyRedContent, descriptionContent, handleCancel, btn1 = 'Cancel', btn2 = 'Delete' } = props;
  return (
    <div>
      <Modal isCentered isOpen={isOpen} onClose={() => { }} size="lg">
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent overflow={'auto'} className="dark:bg-darkThree">
          <ModalHeader className="mb-1 bg-[#FAFAFC] dark:bg-darkTwo">
            <div className="flex items-center gap-2 ">
              <FaRegCircleCheck size={32} className="rounded-full border-4 border-red-100 bg-red-200 p-1  text-red-600 " />
              <span className="text-sm text-blackDark dark:text-gray-200">{headerContent}</span>
            </div>
          </ModalHeader>
          <ModalBody className="!p-3">
            <>
              <div className=" text-center">
                <h1 className="font-semibold text-blackDark dark:text-gray-200">
                  {bodyContent} <span className="text-red-500">{bodyRedContent}</span>
                </h1>
                <h5 className="text-xs text-column dark:text-gray-400">{descriptionContent}</h5>
                {/* <h5 className="text-xs text-column">from their Workspace</h5> */}
              </div>
            </>
          </ModalBody>
          <ModalFooter className="!-mt-2 !mb-1">
            <>
              <Button
                id="blacklist-close"
                // name="Cancel"
                name={btn1}
                onClick={handleCancel}
                customClass="!bg-[#F4F7FE] w-full !text-blackDark border border-2  !font-medium duration-300 w-40 mr-3 py-2 text-[0.80rem]"
              />
              <Button
                id="add-user-invite-btn"
                disable={loading}
                loading={loading}
                name={btn2}
                // name="Delete"
                onClick={handleConfirmFunction}
                customClass={` w-full ${loading ? '' : '!bg-danger'} dark:border-none confirm_popup_confirm_btn !font-medium duration-300 w-[10.5rem] py-2.5 text-[0.80rem]`}
              />
            </>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ConfirmationPopup;
