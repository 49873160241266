import { BRAND, BRANDS_LIST, GET_ONE_BRAND } from 'api/endpoints/brandsEndpoints';
import { BaseApi } from 'api/services/base-api';
import { buildUrl } from 'utility/utils';

export const GetBrandsListApi = async (queryParams: Record<string, any>) => {
  try {
    const getUrl = buildUrl({ url: BRANDS_LIST, query: queryParams });
    const response = await BaseApi.get(getUrl);
    return response;
  } catch (error) {
    console.error('BrandsListApi Error:', error);
    throw error;
  }
};

export const GetOneBrandData = async (rowuuid: string) => {
  try {
    const getUrl = GET_ONE_BRAND(rowuuid);
    const response = await BaseApi.get(getUrl);
    return response;
  } catch (error) {
    console.error('BrandsListGetOneApi Error:', error);
    throw error;
  }
};

export const deleteBrandData = async (uuids: string[]) => {
  try {
    const getUrl = BRAND;
    const response = await BaseApi.delete(getUrl, {
      data: { uuid: uuids },
    });
    return response;
  } catch (error) {
    console.error('BrandsListDeleteApi Error:', error);
    throw error;
  }
};

export const PatchBrandData = async (data:any) => {
  try {
    const getUrl = BRAND;
    const response = await BaseApi.patch(getUrl,data);
    return response;
  } catch (error) {
    console.error('BrandsListApi Error:', error);
    throw error;
  }
};

export const PostBrandData = async (data:any) => {
  try {
    const getUrl = BRAND;
    const response = await BaseApi.post(getUrl,data);
    return response;
  } catch (error) {
    console.error('BrandsListApi Error:', error);
    throw error;
  }
};