import React from 'react';

interface PropsType {
  checked?: boolean;
  onChange?: (checked: boolean, name: string) => void;
  name?: string;
}
const SparkleRadio = (props: PropsType) => {
  const { checked, onChange, name } = props;
  const [radioChecked, setRadioChecked] = React.useState(false);

  React.useEffect(() => {
    setRadioChecked(checked);
  }, [checked]);

  const handleChange = (checked: boolean) => {
    // setRadioChecked(checked)
    onChange?.(checked, name);
  };

  return (
    <div
      className={`size-[.875rem] cursor-pointer transition-all duration-300 ${
        radioChecked ? 'border border-blueSecondary bg-buttonBackground' : 'border border-gray-200 bg-white'
      } flex items-center justify-center rounded-full`}
      onClick={() => handleChange(!radioChecked)}
    >
      <div className={`size-[.375rem] rounded-full ${radioChecked ? 'bg-blueSecondary' : ''}`} />
    </div>
  );
};

export default SparkleRadio;
