const CustomBadge = (props:{
    content : string | JSX.Element;
    color: string;
    bgColor?: string;
    extra ?: string;
    id ?: string
    key ?: string;
}) => {
    const {color, bgColor, extra, content, id, key} = props
  return (
    <div id={id} className={`inline-block p-2 bg-${bgColor} text-${color} font-bold rounded-full ${extra}`} key={key}>
        {content}
    </div>
  )
}

export default CustomBadge