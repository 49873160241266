import React from 'react';

const ChoosePackageLoader = () => {
  return (
    <div className="mt-4">
      <div className="min-w-48 animate-pulse rounded-md border bg-white px-4 py-2 text-center shadow dark:!border-darkBorder dark:bg-darkThree">
        <>
          {/* Credits Loader */}
          <div className="mx-auto my-0 h-6 w-16 rounded-md bg-gray-200 dark:bg-darkTwo"></div>
          <div className="mt-2 flex items-end justify-center gap-2">
            {/* Plan Type Loader */}
            <div className="h-4 w-28 rounded-md bg-gray-200 dark:bg-darkTwo"></div>
            {/* Discount Badge Loader */}
          </div>
        </>
      </div>
    </div>
  );
};

export default ChoosePackageLoader;
