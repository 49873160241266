import { GENERATE_OAUTH_URL, SMTP_CREATE } from 'api/endpoints/senderAccountEndpoints';
import { BaseApi } from 'api/services/base-api';

export const GenerateOAuthUrlApi = async () => {
  try {
    const response = await BaseApi.get(GENERATE_OAUTH_URL);
    return response;
  } catch (error) {
    console.error('GenerateOAuthUrlApi Error:', error);
    throw error;
  }
};

export const SmtpCreateApi = async (data: any) => {
  try {
    const response = await BaseApi.post(SMTP_CREATE, data);
    return response;
  } catch (error) {
    console.error('SmtpCreateApi Error:', error);
    throw error;
  }
};
