import { Avatar } from '@chakra-ui/react';
import React, { useEffect } from 'react';

import { AiFillMail } from 'react-icons/ai';
// import { CgMailReply } from 'react-icons/cg';
import { ImReply } from 'react-icons/im';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux-store';
import { setisReplyEditorOpen, setReplyThreadData } from 'redux-store/crm';
import { checkRolePermissionStatus } from 'utility/utils';

type ChatSectionProps = {
  message: {
    type: string;
    subject: string;
    message: string;
    platform: string;
    IncomingId: string;
    timeStamp: string;
    date: string;
    main_message_id: string;
    from: any;
    to: any;
    delivered_to: string;
    cc: any;
    bcc: any;
    platform_message_details: any;
    sender_id?: string;
  };
  handleOnClickChat: (id: string) => void;
  index?: number;
};

const ChatSection = (props: ChatSectionProps) => {
  const dispatch = useDispatch();
  const custom = useAppSelector((state) => state?.custom);
  const { message, handleOnClickChat } = props;
  const dateFormatter = (dateValue: string) => {
    const date = new Date(dateValue);
    const readableDate = date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'long',
      hour: '2-digit' as 'numeric' | '2-digit',
      minute: '2-digit' as 'numeric' | '2-digit',
      hour12: true,
    });
    return readableDate;
  };

  useEffect(() => {
    return () => {
      dispatch(setisReplyEditorOpen(false));
    };
  }, [dispatch]);

  const handlereplyEditor = () => {
    // message?.platform_message_details.cc ? message?.platform_message_details.cc :
    //  message?.platform_message_details.bcc ? message?.platform_message_details.bcc :
    const payload = {
      from: message?.platform_message_details?.to,
      to: message?.platform_message_details?.from,
      subject: message?.platform_message_details?.subject,
      main_message_id: message?.platform_message_details?.main_message_id,
      sender_id: message?.sender_id,
      type: 'reply',
      individual_message_id: message?.platform_message_details?.individual_message_id,
    };
    dispatch(setReplyThreadData(payload));
    dispatch(setisReplyEditorOpen(true));
  };

  const [roleStatus, setRoleStatus] = React.useState(null);
  const userRolePermissionListStoreData = useAppSelector((state) => state?.globalsettings?.globalConfiguration?.user_organization_role_details);
  const { environment } = useAppSelector((state) => state.globalsettings);

  React.useEffect(() => {
    const getRolePermissionStatus = async () => {
      const status = await checkRolePermissionStatus(userRolePermissionListStoreData, 'crm', environment?.app_default_feature?.is_org_enbaled);
      setRoleStatus(status);
    };
    getRolePermissionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom?.globalDependencyLoading,userRolePermissionListStoreData]);
  return (
    <div
      className={`flex cursor-pointer  gap-2 ${message?.platform_message_details?.type === 'sender' ? 'justify-end' : ''} chat-message-${props?.index}`}
      onClick={() => handleOnClickChat(message?.platform_message_details?.main_message_id)}
    >
      {message?.platform_message_details?.type !== 'sender' && (
        <Avatar
          className="border"
          style={{ height: '33px', width: '33px' }}
          name={message?.platform_message_details?.from?.address?.split('@')[0][0]}
          bg="#811a6e"
          color={'white'}
        />
      )}
      <div className={`${message?.platform_message_details?.type === 'sender' ? 'w-[26.9rem]' : 'w-[24.5rem]'} group flex flex-col gap-1`}>
        <div className="flex justify-between ">
          <h5 className="text-[0.73rem] font-semibold text-paused-color">
            {message?.platform_message_details?.type === 'sender'
              ? 'You'
              : message?.platform_message_details?.from?.name
              ? message?.platform_message_details?.from?.name
              : message?.platform_message_details?.from?.address.split('@')[0]}
          </h5>
          <h5 className="text-[0.65rem] font-medium text-column">{message?.platform_message_details?.date ? dateFormatter(message?.platform_message_details?.date) : null}</h5>
        </div>
        <div
          className={`relative border border-graybg px-[.7rem] py-[0.38rem] ${
            message?.platform_message_details?.type === 'sender' ? 'rounded-md rounded-tr-none bg-[#4318FF1A]' : 'rounded-md rounded-tl-none'
          } text-[0.83rem]  font-medium`}
        >
          {message?.platform_message_details?.subject && <h5 className="mt-.5 mb-1 text-[0.8rem] font-semibold text-heading ">{message?.platform_message_details?.subject}</h5>}
          <div className="line-clamp-2" dangerouslySetInnerHTML={{ __html: message?.platform_message_details?.message?.split('On') }} />

          {message?.platform_message_details?.message?.length > 250 && (
            <div className="absolute -bottom-1 left-0 h-8 w-full bg-gradient-to-b from-[rgba(255,255,255,0)] to-[#ffffff] pb-2 "></div>
          )}
          <div className={`absolute ${message?.platform_message_details?.type !== 'sender' ? '-left-2' : '-right-2'} -bottom-2 rounded-full border border-graybg bg-white p-1`}>
            <AiFillMail className="size-3.5 rounded-full text-column" />
          </div>
        </div>
        {roleStatus?.organization_access_list?.edit || roleStatus?.organization_owner ? (
          <div className="flex justify-end opacity-0 transition-opacity group-hover:opacity-100">
            {message?.platform_message_details?.type !== 'sender' && (
              <ImReply
                className=" -bottom-2 text-blueSecondary "
                onClick={(e) => {
                  e.stopPropagation();
                  handlereplyEditor();
                }}
              />
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ChatSection;
