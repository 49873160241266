import React from 'react';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay } from '@chakra-ui/modal';
import Button from 'components/common/Button';
import InputField from 'components/forms/InputField';
import { toast } from 'react-toastify';
import { toastState } from 'utility/utils';

import { BaseApi } from 'api/services/base-api';

const EditCardModal = (props: any) => {
  const { isOpen, onClose, cardData } = props;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [handleState, setHandleState] = React.useState({
    loading: false,
  });

  //   function formatExpiryDate(expMonth: number, expYear: number): string {
  //     // Ensure the month is always two digits (e.g., "05" instead of "5")
  //     const formattedMonth = String(expMonth).padStart(2, '0');
  //     return `${expYear}-${formattedMonth}`;
  //   }
  const [CardExtraData, setCardExtraData] = React.useState({
    date: '',
    name: '',
    nickname: '',
  });

  React.useEffect(() => {
    if (cardData && isOpen) {
      setCardExtraData({
        date: cardData.card.exp_month && cardData.card.exp_year ? `${cardData.card.exp_year}-${String(cardData.card.exp_month).padStart(2, '0')}` : '',
        name: cardData.billing_details?.name || '',
        nickname: cardData.metadata?.nickname || '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  console.log(cardData, 'cardData');

  const handleInputChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCardExtraData((prev) => ({
      ...prev,
      [name]: value,
    }));
    console.log(event.target.value);
  };

  const handleSubmitCardData = async () => {
    setHandleState({ loading: true });
    try {
      const response = await BaseApi.post(`/billing/usage/card/update`, {
        paymentMethodId: cardData.id,
        expMonth: parseInt(CardExtraData.date.split('-')[1]),
        expYear: parseInt(CardExtraData.date.split('-')[0]),
        name: CardExtraData.name,
        nickname: CardExtraData.nickname,
      });

      if (response.error) {
        toast.error(`Error updating card`, toastState.error);
      } else {
        toast.success('Card updated successfully!', toastState.success);
        // onClose();
      }
    } catch (error: any) {
      toast.error(`Error attaching card: ${error.message}`);
    } finally {
      // Set loading to false once everything is complete
      onClose();
      setHandleState({ loading: false });

      setCardExtraData({
        date: '',
        name: '',
        nickname: '',
      });
    }
  };

  //   const handleDefaultChangeEvent = async () => {
  //     setCardExtraData((prev) => ({
  //       ...prev,
  //       set_default: !prev.set_default,
  //     }));
  //   };

  return (
    <Modal isCentered isOpen={isOpen} onClose={() => {}} size="md">
      <ModalOverlay backdropFilter="blur(2px)" />
      <ModalContent overflow={'auto'} className="dark:bg-darkTwo">
        <ModalBody className="!p-4">
          <div className="flex w-full gap-3">
            <div className="w-9/12">
              <InputField
                autoComplete="off"
                extra=""
                inputClass="-mt-5 dark:!bg-darkFive brand_name_input !rounded-md !h-9"
                labelClass="!text-column dark:!text-white !font-[600] !ml-0 text-xs"
                label="Card number"
                placeholder="Card Number"
                id="card_number"
                name="card_number"
                disabled={true}
                value={cardData?.card?.last4 ? `**** **** **** ${cardData.card.last4}` : ''}
              />
            </div>
            <div className="w-3/12">
              <InputField autoComplete="off" extra="" inputClass="-mt-5 dark:!bg-darkFive brand_name_input !rounded-md !h-9" labelClass="!text-column dark:!text-white !font-[600] !ml-0 text-xs" label="CVC" placeholder="CVC" id="cvc" name="cvc" disabled={true} value={'***'} />
            </div>
          </div>
          <div className="mt-3 flex w-full gap-3">
            <div className="w-7/12">
              <InputField
                autoComplete="off"
                extra=""
                inputClass="!mt-0 dark:!bg-darkFive brand_name_input !rounded-md !h-9 ml-1 "
                labelClass=" !text-paused-color !text- dark:!text-white !font-[500] mx-1 !ml-1 text-sm !mt-0"
                customClass="!mt-0"
                label="Name on card"
                name="name"
                placeholder="Name on card"
                onChange={handleInputChangeEvent}
                id="name_on_card"
                type="text"
                value={CardExtraData?.name}
                //   value={formData?.name}?
              />
            </div>
            <div className=" w-5/12">
              <InputField
                autoComplete="off"
                extra=""
                inputClass="-mt-5 dark:!bg-darkFive brand_name_input !rounded-md !h-9"
                labelClass="!text-column dark:!text-white !font-[600] !ml-0 text-xs"
                label="Expiry Data"
                placeholder="Date"
                id="date"
                type="month"
                name="date"
                onChange={handleInputChangeEvent}
                value={CardExtraData?.date}
                //   value={formData?.name}?
                //   onChange={handleChange}?
                //   errorMessage={errorMessage?.name}
                //   state={errorMessage?.name ? 'error' : ''}
              />
              {/* <p className="mx-1 mb-2 text-sm font-[500] !text-paused-color">Expiry</p>
              <div className="mx-1 rounded-lg border p-2 ">
                <CardExpiryElement options={cardStyle} />
              </div> */}
            </div>
          </div>
          <div className="w-full">
            <InputField
              autoComplete="off"
              extra=""
              inputClass="!mt-0 dark:!bg-darkFive brand_name_input !rounded-md !h-9 ml-1 "
              labelClass="!text-paused-color dark:!text-white !font-[500] mx-1 !ml-1 text-sm !mt-0"
              customClass="!mt-0"
              label="Nick name (optional)"
              name="nickname"
              placeholder="Name on card"
              onChange={handleInputChangeEvent}
              id="nickname"
              type="text"
              value={CardExtraData?.nickname}
              //   value={formData?.name}?
              //   onChange={handleChange}?
              //   errorMessage={errorMessage?.name}
              //   state={errorMessage?.name ? 'error' : ''}
            />
          </div>
          {/* <div className="ml-2 mt-3 flex gap-2">
            <input type="checkbox" id="set_as_default" checked={CardExtraData.set_default} onChange={handleDefaultChangeEvent} className="size-[0.9rem] cursor-pointer accent-brand-500 dark:accent-purple-600" />
            <span className="text-sm text-paused-color "> Make as default</span>
          </div> */}
        </ModalBody>
        <ModalFooter className="!-mt-2 !mb-1">
          <>
            <Button id="btn-close" name="Cancel" onClick={() => onClose()} customClass="!bg-[#F4F7FE] w-full !text-blackDark border border-2  !font-medium duration-300 w-40 mr-3 py-2 text-[0.80rem] active:scale-95 transition-transform duration-150 ease-out" />
            <Button id="add-btn" disable={handleState?.loading} loading={handleState?.loading} name="Edit" onClick={handleSubmitCardData} customClass={` w-full ${''} dark:border-none confirm_btn_otp_page !font-medium duration-300 w-[10.5rem] py-2.5 text-[0.80rem] active:scale-95 transition-transform duration-150 ease-out`} />
          </>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditCardModal;
