import { useEffect, useRef } from 'react';

const useOutsideClick = (callback: () => void) => {
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback]);

  return ref;
};

export default useOutsideClick;

//   const [isOpen, setIsOpen] = useState(false);
//   const handleOutsideClick = () => {
//     setIsOpen(false);
//     console.log('Outside click detected, closing the box.');
//   };
//   const ref = useOutsideClick(handleOutsideClick);
//     <div>
//       <button onClick={() => setIsOpen(true)}>Open Box</button>
//       {isOpen && (
//         <div ref={ref} style={{ padding: '20px', border: '1px solid black', marginTop: '10px' }}>
//           <p>This is a box. Click outside to close it.</p>
//         </div>
//       )}
//     </div>
//   );
// };
