export function customVariablePlugin(FroalaEditor: any, variable_list: any[], missedVariables: any[]) {
  FroalaEditor.PLUGINS.customVariablePlugin = function (editor: any) {
    const replaceVariable = (content: string) => {
      let updatedContent = content;
      const regex = /{{\s*([^{}]+)\s*}}/g;
      updatedContent = updatedContent.replace(regex, (match: string, capturedValue: string) => {
        const matchedValue = variable_list?.find((variable) => variable?.variable === match)?.name;
        const variableClass = matchedValue ? (missedVariables?.includes(match) ? 'variable_missed' : 'variable_valid') : 'variable_invalid';
        return `<span contenteditable='false' class='sparkle_variable ${variableClass} fr-deletable' id='unique_id'>${matchedValue || capturedValue}</span>`;
      });

      return updatedContent;
    };

    // function setCursorPosition(position:any) {
    //   const selection = editor.selection.get();
    //   const range = editor.selection.ranges(0);
      
    //   // Set cursor to specific position
    //   range.setStart(range.startContainer, position);
    //   range.setEnd(range.startContainer, position);
    
    //   // Update selection to reflect the new position
    //   selection.removeAllRanges();
    //   selection.addRange(range);
    // }

    // function getCursorPosition() {
    //   const selection = editor.selection.get();
    //   // const range = editor.selection.ranges(0);
    //   return selection;
    // }

    function handleContentChanged() {
      // const selectionRange = getCursorPosition() // Get caret position
      // console.log('selectionRange', selectionRange)
      const content = editor.html.get();
      const updatedContent = replaceVariable(content);
      if (content !== updatedContent) {
        editor.html.set(updatedContent);
        // setCursorPosition(selectionRange)// append caret position
        editor.selection.setAtEnd(editor.$el.get(0));
        editor.selection.restore();
      }
    }

    function _init() {
      editor.events.on('contentChanged', function () {
        handleContentChanged();
      });
      // editor.events.on('paste.before', function () {
      //   handleContentChanged();
      // });
    }

    return {
      _init,
    };
  };
}
