import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialState } from 'dto/initial-value/redux-store/organization';

const userSettingsSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setModalView: (state, action: PayloadAction<boolean>) => {
      state.modal_view = action.payload;
    },
    setOrganizationId: (state, action) => {
      state.organization_id = action.payload;
    },
    setStepCount: (state, action) => {
      state.step_count = action.payload;
    },
    setOrganization: (state, action) => {
      state.organization = { ...state.organization, ...action.payload };
    },
    setWorkspace: (state, action) => {
      state.workspace = { ...state.workspace, ...action.payload };
    },
    setswitchOrganizationModalView: (state, action) => {
      state.organization_view = action.payload;
    },
    resetOrganization: (state) => {
      state.organization = initialState.organization;
    },
    resetWorkspace: (state) => {
      state.workspace = initialState.workspace;
    },
    setWorkspaceEditState: (state, action) => {
      state.workspaceEditState = { ...state.workspaceEditState, ...action.payload };
    },
    setTeamsWorkspaceEditState: (state, action) => {
      state.teamsWorkspaceEdit = { ...state.workspaceEditState, ...action.payload };
    },
    resetTeamsWorkspaceEditState: (state) => {
      state.teamsWorkspaceEdit = initialState.teamsWorkspaceEdit;
    },
    setWorkspaceInviteId: (state, action) => {
      state.workspaceInviteId = action.payload;
    },
    setTeamsRoleEditState: (state, action) => {
      state.teamsRoleEdit = { ...state.teamsRoleEdit, ...action.payload };
    },
    resetTeamsRoleEditState: (state) => {
      state.teamsRoleEdit = initialState.teamsRoleEdit;
    },
  },
});

export const {
  setModalView,
  setOrganizationId,
  setOrganization,
  setWorkspace,
  setStepCount,
  setswitchOrganizationModalView,
  resetOrganization,
  resetWorkspace,
  setWorkspaceEditState,
  setTeamsWorkspaceEditState,
  resetTeamsWorkspaceEditState,
  setWorkspaceInviteId,
  setTeamsRoleEditState,
  resetTeamsRoleEditState,
} = userSettingsSlice.actions;
export default userSettingsSlice.reducer;
