import React, { useState } from 'react';
import { AiFillDelete, AiOutlinePlus } from 'react-icons/ai';
import { BsThreeDots } from 'react-icons/bs';
import { MdModeEdit } from 'react-icons/md';
import { PiDotsSixVerticalBold } from 'react-icons/pi';
import { toast } from 'react-toastify';

import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import CommonButton from 'components/common/Button';
import { useAppDispatch, useAppSelector } from 'redux-store';
import { deleteCrmStage, onCrmOpportunitiesUpdated, setCrmStages } from 'redux-store/crm';
import { BaseApi } from 'api/services/base-api';
import { _getQueryParams, toastState, removeDuplicates } from 'utility/utils';

interface ModalComponentProps {
  isOpen?: boolean;
  onClose?: () => void;
}

interface PipelineStageDataDTO {
  _id: string;
  uuid: string;
  pipeline_id: string;
  brand_id: string;
  stage_name: string;
  color_code: string;
  order: number;
  status: number;
  total_count: number;
}

const StageEdit = (props: ModalComponentProps) => {
  const { onClose, isOpen } = props;
  const dispatch = useAppDispatch();
  const getQueryIds = _getQueryParams('_id');
  // const { pipeline_id, brand_id } = _getQueryParams('_id');
  const PipelineId = getQueryIds?.pipeline_id;
  const brandId = getQueryIds?.brand_id;
  // const stageStoreData = useAppSelector((state) => state?.crm?.crmStageData);
  const colors = ['#4318FF', '#E7C100', '#7F56D9', '#7E4400', '#12B76A'];
  const containerRef = React.useRef(null);
  const [stagesdata, setStagesData] = useState([]);
  const inputRefs = React.useRef<any[]>([]);
  const [editableStages, setEditableStages] = useState([]);
  const dragItem = React.useRef(null);
  const dragOverItem = React.useRef(null);
  const storeStages = useAppSelector((state) => state?.crm);

  const fetchList = async () => {
    try {
      if (PipelineId) {
        const response = await BaseApi.get(`/crm/pipeline/stage/${PipelineId}`);
        if (response.data) {
          if (response?.data?.length > 0) {
            const sortedStages = response?.data?.sort((a: any, b: any) => a.order - b.order);
            await dispatch(setCrmStages(sortedStages));
            setStagesData(sortedStages);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeStages.pipeline_id, isOpen]);

  React.useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [stagesdata]);

  const handleStageChange = (index: number, field: string, value: string) => {
    const updatedStages = stagesdata.map((stage: PipelineStageDataDTO, i: number) => (i === index ? { ...stage, [field]: value } : stage));
    setStagesData(updatedStages);
  };

  const hangleAddStage = async () => {
    const maxOrderNumber = stagesdata.length > 0 ? stagesdata.reduce((maxOrder: number, stage: PipelineStageDataDTO) => Math.max(maxOrder, stage.order), 0) : 0;
    setStagesData([
      ...stagesdata,
      {
        color_code: await colors[stagesdata.length % colors.length],
        pipeline_id: PipelineId,
        stage_name: '',
        order: maxOrderNumber + 1,
      },
    ]);
    // setStagesData([...stagesdata, { color_code: await colors[stagesdata.length % colors.length], pipeline_id: PipelineId, stage_name: '' }]);
    setEditableStages((prev) => [...prev, stagesdata.length]);
  };

  const handleEditStage = (index: number) => {
    setEditableStages((prev) => [...prev, index]);
    setTimeout(() => {
      inputRefs.current[index]?.focus();
    }, 0);
  };

  const handleDeleteStage = async (index: number, stage: PipelineStageDataDTO) => {
    const orderToDelete = stagesdata[index].order;
    const updatedStages = stagesdata
      .filter((_: any, i: number) => i !== index)
      .map((stage: PipelineStageDataDTO) => {
        if (stage.order > orderToDelete) {
          return {
            ...stage,
            order: stage.order - 1,
          };
        }
        return stage;
      });

    setStagesData(updatedStages);
    dispatch(deleteCrmStage(index));
    if (stage?.uuid) {
      const response = await BaseApi.delete(`/crm/pipeline/stage/`, { data: { delete_ids: [stage.uuid] } });
      if (response.error) {
        toast.error(`Error during delete`, toastState.error);
      }
    }
  };
  const handleApplyChanges = async () => {
    try {
      let uniqueStages = await removeDuplicates(stagesdata);
      const stagesToUpdate = await uniqueStages.filter((stage: PipelineStageDataDTO) => stage.uuid);
      const stagesToInsert = await uniqueStages.filter((stage: PipelineStageDataDTO) => !stage.uuid);
      const updatedStagesToInsert = await stagesToInsert.map((stage: PipelineStageDataDTO, index: number) => ({
        ...stage,
        brand_id: brandId,
      }));

      // Batch update API call
      if (stagesToUpdate.length > 0) {
        await BaseApi.patch(`/crm/pipeline/stage`, {
          stage_list: stagesToUpdate,
        });
      }
      if (updatedStagesToInsert.length > 0) {
        await BaseApi.post(`/crm/pipeline/stage`, {
          stage_list: updatedStagesToInsert,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setCrmStages([]));
      dispatch(onCrmOpportunitiesUpdated());
      setEditableStages([]);
      setStagesData([]);
      onClose();
    }
  };

  const handleModalCose = () => {
    dispatch(setCrmStages([]));
    dispatch(onCrmOpportunitiesUpdated());
    setEditableStages([]);
    setStagesData([]);
    onClose();
  };

  const handleDragStart = (e: any, position: any) => {
    dragItem.current = position;
    const check = document.getElementById(e.target.id);
    const dragImage: any = check.cloneNode(true);
    dragImage.style.position = 'absolute';
    dragImage.style.top = '-9999px';
    dragImage.style.left = '-9999px';
    document.body.appendChild(dragImage);
    e.dataTransfer.setDragImage(dragImage, 0, 0);

    // Remove the drag image after a slight delay
    setTimeout(() => {
      document.body.removeChild(dragImage);
    }, 0);
    // e.dataTransfer.effectAllowed = 'move';
    // e.dataTransfer.setData('text/html', e.currentTarget);
    // e.currentTarget.style.opacity = '0.5';
    // e.currentTarget.style.transform = 'scale(1.05)';
    // e.currentTarget.style.filter = 'blur(2px)';
  };

  const handleDragEnter = (e: any, position: any) => {
    dragOverItem.current = position;
  };

  const handleDragLeave = (e: any) => {
    e.preventDefault();
    e.currentTarget.classList.remove('animate-bounce');
  };

  const handleDragEnd = (e: any) => {
    const newStages = [...stagesdata];
    const dragItemContent = newStages[dragItem.current];
    newStages.splice(dragItem.current, 1);
    newStages.splice(dragOverItem.current, 0, dragItemContent);
    const updatedStages = newStages.map((stage, index) => ({
      ...stage,
      order: index + 1,
    }));
    setStagesData(updatedStages);
    e.currentTarget.style.opacity = '1';
    e.currentTarget.style.transform = 'scale(1)';
    e.currentTarget.style.filter = 'blur(0)';
    e.currentTarget.classList.remove('animate-bounce');
  };

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={() => {
        handleModalCose();
      }}
      size={'2xl'}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay backdropFilter="blur(2px)" />
      <ModalContent overflow={'auto'} className='dark:bg-darkThree'>
        <ModalHeader className="flex items-center justify-between !px-0">
          <div className="items-center space-y-1 px-4">
            <h5 className="text-[18px] font-medium text-[#667085] dark:text-white">Edit Stages</h5>
          </div>
          <ModalCloseButton size={'lg'} color={'#667085'} className="mr-3 scale-75 transform self-center text-[32px] hover:bg-white" />
        </ModalHeader>

        <hr className="-mt-2" />
        <ModalBody className=" !px-0">
          <div className="px-6">
            {stagesdata.map((item: PipelineStageDataDTO, index: number) => (
              <>
                <div
                  id={`index`}
                  className="mt-4 flex h-[40px] cursor-pointer items-center justify-between gap-1 rounded-md border dark:border-darkBorder border-[#D9D9D9] p-2"
                  draggable
                  onDragStart={(e) => handleDragStart(e, index)}
                  onDragEnter={(e) => handleDragEnter(e, index)}
                  onDragEnd={handleDragEnd}
                  onDragLeave={handleDragLeave}
                >
                  <div className="flex items-center gap-2">
                    <PiDotsSixVerticalBold  className='dark:text-white'/>
                    <div className="relative" onClick={(event) => event.stopPropagation}>
                      <input
                        id={`stage-name-edit-color`}
                        type="color"
                        name="color_code"
                        value={item?.color_code}
                        onChange={(e) => handleStageChange(index, 'color_code', e.target.value)}
                        // onChange={(e) =>
                        //   handleUpdateStage(index, 'color_code', e.target.value)
                        // }
                        disabled={!editableStages.includes(index)}
                        className="absolute dark:!bg-darkThree inset-0 h-full w-full  cursor-pointer opacity-0"
                      />
                      <div className="h-4 w-4 rounded-full" style={{ backgroundColor: item?.color_code }} />
                    </div>
                    <div>
                      <input
                        id={`stage-name-edit-input`}
                        value={item.stage_name}
                        ref={(el) => (inputRefs.current[index] = el)}
                        className="!w-72 !border-none !bg-white dark:!bg-darkThree dark:!text-white !p-0 outline-none "
                        onChange={(e) => handleStageChange(index, 'stage_name', e.target.value)}
                        disabled={!editableStages.includes(index)}
                      />
                    </div>
                  </div>
                  <div>
                    <Menu>
                      <MenuButton
                        id={`edit-stage-${index}`}
                        onClick={(event) => event.stopPropagation()}
                        as={Button}
                        _hover={{ bg: '#fff' }}
                        _active={{ bg: '#fff' }}
                        bg="#fff"
                        className={`!h-[25px] w-[20px] bg-white dark:!bg-darkThree !px-2 !py-0 !text-base !font-semibold edit-stage-${index}`}
                      >
                        <BsThreeDots className="text-base !text-[#8F9BBA]" />
                      </MenuButton>
                      <MenuList minW="0" w={'122px'} className="!h-[75px] !w-[140px] !rounded-lg bg-white !p-0 shadow-lg  " style={{ width: '140px', height: '109px' }}>
                        <MenuItem
                          onClick={() => handleEditStage(index)}
                          className="edit-stage-btn dark:!bg-darkThree dark:!text-gray-200 dark:hover:!text-blue-500 flex items-center space-x-2 rounded-t-lg !py-2 !pe-2 !ps-4 text-sm !text-[#2B3674] hover:rounded-t-lg hover:!bg-[#4318FF] hover:!text-white"
                        >
                          <MdModeEdit className="text-lg" />
                          <span>Edit</span>
                        </MenuItem>
                        <MenuItem
                          onClick={() => handleDeleteStage(index, item)}
                          className="flex items-center dark:!bg-darkThree dark:hover:!text-red-500 dark:!text-gray-200 space-x-2 rounded-b-lg !py-2 !pe-2 !ps-4 text-sm !text-[#2B3674] hover:rounded-b-lg hover:!bg-[#4318FF] hover:!text-white"
                        >
                          <AiFillDelete className="text-lg" />
                          <span>Delete</span>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </div>
                </div>
              </>
            ))}
            <div
              className="border-p-2 mt-4 flex h-[40px] cursor-pointer items-center justify-between gap-1 rounded-md border border-dashed dark: border-[#D9D9D9] pl-2"
              onClick={() => hangleAddStage()}
            >
              <AiOutlinePlus className="text-[#667085] dark:text-white" />
            </div>
          </div>
          <hr className="my-4" />

          <div className="flex w-full gap-4 px-12 pb-3 pt-5">
            <CommonButton
              id={`button-update-stage`}
              type="button"
              name="cancel"
              fontSize="sm"
              px="px-6"
              color="text-column"
              onClick={() => onClose()}
              customClass="text-column font-medium text-base w-1/2 !bg-white"
            />
            <CommonButton
              id="apply-stage-change-btn"
              type="button"
              name="Apply Changes"
              fontSize="sm"
              px="px-6"
              onClick={() => handleApplyChanges()}
              customClass="text-column font-medium text-sm w-1/2 dark:border-none"
            />
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default StageEdit;
