/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { toast } from 'react-toastify';
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, ModalHeader, ModalFooter } from '@chakra-ui/modal';

import Button from 'components/common/Button';
import { useAppDispatch, useAppSelector } from 'redux-store';
import { onCrmOpportunitiesUpdated } from 'redux-store/crm';
import { MergeComponentProps, MergeProspecDatatDto, StageData } from 'dto/types/views/crm';
import { BaseApi } from 'api/services/base-api';
import { CustomMenu } from 'components/common/customMenu';
import SearchBox from 'components/common/searchBox';
import InputField from 'components/forms/InputField';
import { useDebounce, _getQueryParams, setAndGetQueryParams, toastState } from 'utility/utils';

const MergeCardPopup = (props: MergeComponentProps) => {
  const { onClose, isOpen, primaryEmail, primaryId } = props;
  const stageOption = useAppSelector((state) => state.crm.stageData);
  const [selectData, setSelectData] = useState([]);
  const [openOption, setOpenOption] = React.useState(false);
  const [openProspectsOption, setOpenprosPectsOption] = React.useState(false);
  const [prospectsSearchdata, setProspectSearchData] = useState([]);
  const [selectedProspects, setSelectedProspects] = React.useState('');
  const [searchdata, setSearchData] = useState([]);
  const [queryParams, setQueryParams] = React.useState({
    limit: 25,
    offset: 0,
    search: '',
    filter: '',
  });
  const debouncedSearchValue = useDebounce(queryParams?.search, 300);
  const getQueryIds = _getQueryParams('_id');
  const BrandId = getQueryIds?.brand_id;
  const dispatch = useAppDispatch();
  const [selectedStage, setSelectedStage] = React.useState('');
  const [formData, setFormData] = React.useState({
    primary_opportunity_id: '',
    secondary_opportunity_id: '',
    merge_stage_id: '',
    deal_value: '',
  });
  const modalBodyRef = React.useRef(null);

  useEffect(() => {
    setSearchData(stageOption);
  }, [stageOption]);

  React.useEffect(() => {
    (async () => {
      try {
        if (isOpen) {
          const query = setAndGetQueryParams([
            { key: 'limit', value: queryParams?.limit },
            { key: 'offset', value: queryParams?.offset },
            { key: 'search', value: queryParams?.search },
            { key: 'filter', value: queryParams?.filter },
          ]);
          const response = await BaseApi.get(`crm/pipeline/opportunity/by/brand/${BrandId}?${query}`);
          if (response?.error) {
            toast.error(response?.message, toastState.error);
          } else {
            const filteredData = await response.data.filter((item: MergeProspecDatatDto) => item.prospect_email !== primaryEmail);
            setSelectData(filteredData);
            setProspectSearchData(filteredData);
          }
        }
      } catch (err) {
        console.log(err);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, debouncedSearchValue]);

  const handleinputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleCloseModal = () => {
    onClose();
    setQueryParams({
      limit: 25,
      offset: 0,
      search: '',
      filter: '',
    });
  };
  const handleProspectSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueryParams((prev) => ({
      ...prev,
      search: event.target.value.trim().toLowerCase(),
    }));
  };
  useEffect(() => {
    if (modalBodyRef.current) {
      modalBodyRef.current.scrollTo({ top: modalBodyRef.current.scrollHeight, behavior: 'smooth' });
    }
  }, [openOption]);

  const handleStageChange = (uuid: string, name: string) => {
    setSelectedStage(name);
    setFormData({
      ...formData,
      merge_stage_id: uuid,
    });
    setOpenOption(false);
  };

  const handlePropectsChange = (uuid: string, name: string) => {
    setSelectedProspects(name);
    setFormData({
      ...formData,
      secondary_opportunity_id: uuid,
    });
    setOpenprosPectsOption(false);
  };

  const handleMerge = async () => {
    if (!formData.secondary_opportunity_id || !formData.merge_stage_id) {
      toast.error('Please fill in all required fields.', toastState.error);
      return;
    }
    formData.primary_opportunity_id = primaryId;

    try {
      const response = await BaseApi.patch(`/crm/pipeline/opportunity/merge`, formData);
      if (response?.status === 200) {
        toast.success(response?.message, toastState.success);
        onClose();
        dispatch(onCrmOpportunitiesUpdated());
      } else {
        toast.error(`${response.message}`, toastState.error);
        onClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={handleCloseModal} size={'2xl'} closeOnOverlayClick={false} closeOnEsc={false}>
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent overflow={'auto'} className="dark:bg-darkThree">
          <ModalHeader className="flex items-center justify-between !px-0">
            <div className="items-center space-y-1 px-4">
              <h5 className="text-[18px] font-medium text-[#667085] dark:text-purple-500">Merge opportunities</h5>
            </div>
            <ModalCloseButton size={'lg'} color={'#667085'} className="mr-3 scale-75 transform self-center text-[32px] hover:bg-white" />
          </ModalHeader>

          <hr className="-mt-2" />
          <ModalBody className=" max-h-[40rem] overflow-y-auto !px-0" ref={modalBodyRef}>
            <div className="px-6">
              <div className="mt-2 flex flex-col gap-1">
                <p className="text-sm font-medium text-[#2B3674] dark:text-gray-200">Combine opportunity with</p>
                <CustomMenu
                  open={openProspectsOption}
                  onOpen={() => setOpenprosPectsOption(true)}
                  onClose={() => setOpenprosPectsOption(false)}
                  btnContent={selectedProspects ? selectedProspects : 'Select Account'}
                  btnClassName="w-full justify-between !text-sm !rounded-md !font-normal truncate mt-2 border border-gray-200 text-[#667085] focus:border-[#4318FF] !h-10 text-center items-cemter prospect-menu "
                  menuListClassName="w-full h-44 overflow-auto !mt-1 !p-0 border border-menuBorder rounded-md "
                  rightIcon={<BiChevronDown size={18} />}
                >
                  <div className="p-2">
                    <SearchBox
                      id="prospect-search-input"
                      placeholder="Search Acount"
                      inputClass="dark:!bg-darkThree dark:text-gray-200"
                      onChange={(e) => handleProspectSearch(e)}
                    />
                    {prospectsSearchdata.length ? (
                      <div className="max-h-25 mt-2 overflow-y-auto">
                        {prospectsSearchdata.map((item: MergeProspecDatatDto, index: number) => (
                          <div
                            key={item.uuid}
                            id={`prospect-email-${index}`}
                            className={`w-full cursor-pointer py-1 pl-2 text-left text-[.8125rem] font-medium text-column ${
                              selectedProspects === item.prospect_email ? 'bg-menuBorder text-white' : 'hover:bg-buttonBackground'
                            }`}
                            onClick={() => handlePropectsChange(item.uuid, item.prospect_email)}
                          >
                            {item.prospect_email}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <span className="w-full pl-4 text-center  text-xs text-gray-700 dark:text-gray-200 ">No results found</span>
                    )}
                  </div>
                </CustomMenu>
              </div>
              <div className="mt-4 flex flex-col gap-1">
                <p className="text-sm font-medium text-[#2B3674] dark:text-gray-200 ">Primary contact</p>
                <InputField label="" placeholder="Primary Contact mail" value={primaryEmail} disabled inputClass="!rounded-md !h-10 dark:text-gray-200" />
              </div>
              <div className="mt-4 flex flex-col gap-1">
                <p className="text-sm font-medium text-[#2B3674] dark:text-gray-200">Deal Value</p>
                <InputField id="deals-value-merge-card" name="deal_value" label="" placeholder="$250" inputClass="!rounded-md !h-10" onChange={handleinputChange} />
              </div>
              <div className="mt-4 flex flex-col gap-1">
                <p className="text-sm font-medium text-[#2B3674] dark:text-gray-200">Deal Stage</p>
                <CustomMenu
                  open={openOption}
                  onOpen={() => {
                    setOpenOption(true);
                  }}
                  onClose={() => setOpenOption(false)}
                  btnContent={selectedStage ? selectedStage : 'Select Stages'}
                  btnClassName="w-full justify-between !text-sm !rounded-md !font-normal truncate mt-2 border border-gray-200 text-[#667085] focus:border-[#4318FF] !h-10 text-center items-cemter stage-menu"
                  menuListClassName="w-full !mt-1 !p-0 border border-menuBorder rounded-md "
                  rightIcon={<BiChevronDown size={18} />}
                >
                  <div className="p-2">
                    <SearchBox id="brand-search-input" placeholder="Search stages" inputClass="dark:!bg-darkThree dark:text-gray-200" onChange={handleProspectSearch} />
                    {searchdata.length ? (
                      <div className="max-h-25 mt-2 overflow-y-auto">
                        {searchdata.map((item: StageData, index: number) => (
                          <div
                            key={item.uuid}
                            className={`stage-option-${index} w-full cursor-pointer py-1 text-center text-[.8125rem] font-medium text-column ${
                              selectedStage === item.stage_name ? 'bg-menuBorder text-white' : 'hover:bg-buttonBackground'
                            }`}
                            onClick={() => handleStageChange(item.uuid, item.stage_name)}
                          >
                            {item.stage_name}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <span className="w-full pl-4 text-center  text-xs text-gray-700 ">No results found</span>
                    )}
                  </div>
                </CustomMenu>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <hr className="my-4" />
            <div className="flex w-full justify-center px-6 pb-1">
              <Button
                type="button"
                id="merge-cards-btn"
                name="Merge"
                fontSize="sm"
                px="px-6"
                onClick={() => handleMerge()}
                customClass="text-column dark:border-none font-medium text-sm w-1/2"
              />
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MergeCardPopup;
