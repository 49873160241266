import { ChangeEvent, useEffect, useRef } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/modal';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { ModalComponentPropDTO } from 'dto/types/views/blacklist';
import { useAppDispatch, useAppSelector } from 'redux-store';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import TagsField from 'components/forms/TagsField';
import { AddBlacklistStateIV, frequencyOptionsIV } from 'dto/initial-value/views/blacklist';
import Button from 'components/common/Button';
import Input from 'components/common/Input';
import { HandleCloseBlacklistModal, HandleInfoBlacklistModal, HandleSelectBlackListFrequenct, ValidateBlackAddlistData } from 'shared/blacklist/event-handler/blacklist-event';
import { HandleBlacklistFormChangeEvent } from 'shared/blacklist/event-handler/index-event';

const AddBlacklistModal = (props: ModalComponentPropDTO) => {
  const navigate = useNavigate();
  const { isOpen, onClose, onSuccess } = props;
  const dispatch = useAppDispatch();
  const tooltipRef = useRef(null);
  const { errorMessage }: any = useAppSelector((state: { custom: any }) => state.custom);
  const userType = useAppSelector((state) => state?.globalsettings?.globalConfiguration?.billing_details);
  const [handleState, setHandleState] = React.useState(AddBlacklistStateIV);

  const handleTooltipClick = () => {
    setHandleState((prevState) => ({ ...prevState, showTooltip: !handleState?.showTooltip }));
  };

  const handleUpdateBlacklist = (blacklist: Array<object>) => {
    
    const black_list_data = blacklist.map((item: any) => {
      return { id: item?.id?.toString(), ip_or_domain: item?.name };
    });
    setHandleState((prevState) => ({ ...prevState, blackList: black_list_data }));
  };

  const handleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    HandleSelectBlackListFrequenct(setHandleState, e);
  };

  // const restriction = useRestrictionPlan('blacklist');
  const handleSubmit = async () => {
    await ValidateBlackAddlistData(setHandleState, handleState, userType, onSuccess, handleClose, navigate, dispatch);
  };

  const handleFormdataChange = (event:any) => {
    HandleBlacklistFormChangeEvent({ event, setHandleState, handleState, dispatch });
    // setHandleState((prevState) => ({ ...prevState, groupname: e.target.value }))
  };

  useEffect(() => {
    HandleInfoBlacklistModal(setHandleState, handleState, tooltipRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleState?.showTooltip]);

  const handleClose = () => {
    HandleCloseBlacklistModal(setHandleState, onClose, dispatch);
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={handleClose} size="4xl">
      <ModalOverlay backdropFilter="blur(2px)" />
      <ModalContent overflow={'auto'} className="!bg-[#F4F7FE] px-3 dark:!bg-darkTwo">
        <ModalHeader className="!mt-5 mb-1">
          <div className="flex items-center gap-3">
            <h5 className="text-lg font-semibold text-heading dark:text-white">Add a new Domain to Blacklist Monitor</h5>
          </div>
          <ModalCloseButton size={'lg'} color={'#4318FF'} id="add-blacklist-pop-close" className="mr-3 mt-3 dark:text-gray-400 dark:hover:!text-red-500" />
        </ModalHeader>
        <ModalBody>
          <div className="space-y-3 rounded-md border border-[##FFFFFF] bg-[#FFFFFF] px-6 py-3 dark:border-darkBorder dark:bg-darkThree " id="blacklist-add-modal">
            <div className="!mt-2 mb-7 flex flex-col gap-y-0.5 px-4">
              <p className="ml-2 font-semibold text-[#1B2559] dark:text-gray-200 ">
                Group <span className="text-red-500">*</span>
              </p>
              <Input
                placeholder="Group Name"
                id='groupname'
                customClass={`y-3 dark:border-darkBorder px-4 !text-sm add-gruop-name-blacklist dark:!bg-darkTwo dark:text-gray-200 ${
                  errorMessage?.group || errorMessage?.groupname ? `border-red-600 dark:!border-red-500` : `dark:border-darkBorder`
                }`}
                onChange={(event) => handleFormdataChange(event)}
              />
              {errorMessage?.group || errorMessage?.groupname ? (
                <span className="ml-1 mt-1  flex items-center text-xs font-medium tracking-wide text-red-500 dark:!text-red-400">{errorMessage?.group || errorMessage?.groupname}</span>
              ) : (
                <p className="ml-2 mt-1 text-[12px] text-[#4CBE67] dark:text-green-400">A friendly name for your monitor so you can easily track it down.</p>
              )}
            </div>

            <div className="!mb-7 flex flex-col gap-y-0.5 px-4">
              <p className="!ml-2 font-semibold text-[#1B2559] dark:text-gray-200">
                <span>
                  {' '}
                  Ip Address, Domain Name <span className="text-red-500">*</span>
                </span>
                <span className="group/tooltip_info">
                  <IoIosInformationCircleOutline
                    className="text-headings ml-1 inline-block cursor-pointer"
                    onClick={handleTooltipClick} // Toggle tooltip on click
                  />
                  {handleState?.showTooltip && (
                    <div ref={tooltipRef} className="absolute left-72 z-10  w-[23rem] rounded-md border border-gray-300 bg-white p-3 text-sm text-black shadow-lg">
                      <p className="font-bold">Valid Formats Examples:</p>
                      <ul className="list-inside list-disc text-column">
                        <li>
                          <strong>IPv4</strong>: 192.168.0.1, 123.45.67.89, 1.1.1.1
                        </li>
                        <li>
                          <strong>Domain</strong>: example.com, sub.example.com
                        </li>
                      </ul>
                      <p>Enter valid IP addresses (IPv4) or domain names for blacklisting.</p>
                    </div>
                  )}
                </span>{' '}
              </p>
              <TagsField
                id="description"
                onChange={(item: any) => {
                  handleUpdateBlacklist(item);
                }}
                placeholderTags={[
                  ...handleState?.blackList?.map((item) => {
                    return { id: parseInt(item?.id), name: item?.ip_or_domain };
                  }),
                ]}
                extra={`border dark:bg-darkTwo !rounded-md h-max-12 pb-12 ${errorMessage?.black_list ? `border-red-500 dark:!border-red-500` : ` dark:!border-white/10`}`}
                placeholder="IP address or Domain Name"
                pattern={
                  // eslint-disable-next-line no-useless-escape
                  /^(((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)|((?!:\/\/)([a-zA-Z0-9-_]+\.)*([a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?)))$/
                }
                className={`ip-domain-tagfield  !h-min-10 relative flex flex-row flex-wrap !rounded-[1px] border  bg-none px-2 pb-2 focus:border-brand-200  dark:text-gray-200 `}
              />
              {errorMessage?.black_list ? (
                <span className="ml-1 mt-1  flex items-center text-xs font-medium tracking-wide text-red-500 dark:!text-red-400">{errorMessage?.black_list}</span>
              ) : (
                <p className="ml-2 mt-1 text-[12px] text-[#4CBE67] dark:text-green-400">
                  E.g: Ipv4 : [1.2.3.4 ,192.168.0.1, 123.45.67.89 ] OR Domain: [example.com, sub.example.com]
                </p>
              )}
            </div>
            <div className="!mb-8 flex flex-col gap-y-0.5 px-4">
              <p className="!ml-2 font-semibold text-[#1B2559] dark:text-gray-200">
                Frequency <span className="text-red-500">*</span>
              </p>
              <select
                className={`frequency-select w-2/4 rounded-md border border-gray-200 px-2 py-3 text-xs text-[#667085] focus:text-blackDark dark:border-darkBorder dark:bg-darkTwo dark:text-gray-200 ${
                  errorMessage?.frequency?.value ? `border-red-500 dark:!border-red-500` : ` dark:!border-white/10`
                }`}
                onChange={handleSelect}
                defaultValue=""
              >
                <option value="" disabled>
                  Select Frequency
                </option>
                {frequencyOptionsIV?.map((option, index) => (
                  <option key={index} className={`frequency-option-${index}`} value={JSON.stringify(option)}>
                    {`${option.value} ${option.type}`}
                  </option>
                ))}
              </select>
              {errorMessage?.frequency?.value && (
                <span className="ml-1 mt-1  flex items-center text-xs font-medium tracking-wide text-red-500 dark:!text-red-400">{errorMessage?.frequency?.value}</span>
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="!-mt-2 !mb-5">
          <Button
            id="blacklist-close"
            name="Cancel"
            customClass="!bg-[#F4F7FE] !text-[#A3AED0] border border-2  !font-medium duration-300 w-40 mr-3 py-2 text-[0.80rem]"
            onClick={handleClose}
          />
          <Button
            id="add-blacklist"
            disable={handleState?.loading}
            loading={handleState?.loading}
            name="Add Domain"
            customClass="start_blacklist_btn dark:border-none !bg-[#4318FF] !text-[#FFFFFF] !font-medium duration-300 w-[10.5rem] py-2.5 text-[0.80rem]"
            onClick={() => handleSubmit()}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddBlacklistModal;
