import * as Yup from 'yup';
import REGEX_PATTERNS from 'constants/regex-patterns';

export const personalDetailsValidationSchema = Yup.object({
  first_name: Yup.string()
    .trim()
    .required('First name is required')
    .matches(REGEX_PATTERNS.NAME, 'Only alphabets are allowed for first name')
    .min(2, 'First name must be at least 2 characters long')
    .max(50, 'First name cannot be more than 50 characters long'),
  last_name: Yup.string()
    .trim()
    .required('Last name is required')
    .matches(REGEX_PATTERNS.NAME, 'Only alphabets are allowed for last name')
    .min(2, 'Last name must be at least 2 characters long')
    .max(50, 'Last name cannot be more than 50 characters long'),
  mobile: Yup.string()
    .trim()
    .required('Mobile Number is required')
    .min(5, 'Mobile Number is too short')
    .max(15, 'Mobile Number is too long'),
});