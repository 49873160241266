import { fetchGroupsList, setFetchData } from 'redux-store/blacklist';
import {
  CreateGroupForBlackList,
  DeleteBlacklistData,
  DeleteBlacklistGroup,
  DeleteOneBlacklistGroupList,
  GetBlackListDataDownload,
  GetBlacklistGroupsListApi,
  GetBlacklistListApi,
  GetOneBlackListDataResultModal,
  PauseBlacklistData,
  RecheckBlacklistData,
  RenameGroupForBlackList,
  ResumeBlacklistData,
  ValidateBlacklistData,
} from '../api-handler/blacklist-api';
import { AddBlacklistStateDTO, blacklistStateDTO } from 'dto/types/views/blacklist';
import { jwtEncrypt, restrictionPlan } from 'utility/utils';
import * as XLSX from 'xlsx';
import {
  AddBlacklistStateIV,
  BadNameColor,
  BadNameText,
  BLACKLIST,
  ConnectionFailColor,
  ConnectionFailText,
  DefaultColor,
  ListedColor,
  ListedText,
} from 'dto/initial-value/views/blacklist';
import { setUpdateSettingsData } from 'redux-store/global-configuration';
import { getErrorMessage } from 'redux-store/custom';
import { setPaymentStatus } from 'redux-store/account-settings';
import ROUTE_PATHS from 'constants/route-paths';
import { CONSTANT_TEXTS } from 'constants/constants';
import { ChangeEvent } from 'react';
import { getAuthUserConfiguration } from 'shared/layout/event-handler';

export const FetchBlacklistGroupsData = async (dispatch: any) => {
  try {
    const response = await GetBlacklistGroupsListApi();
    if (response?.data?.data && response?.data?.data?.length) {
      const finalData = await response?.data?.data?.sort((a: any, b: any) =>
        a?.name.toLowerCase() < b?.name.toLowerCase() ? -1 : a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : 0
      );
      const states = response?.data?.data?.reduce((acc: any, val: any) => {
        acc[val.uuid] = { show: false, rename: false };
        return acc;
      }, {});
      dispatch(setFetchData({ finalData, states }));
    }
    // else {
    //   console.log('response', response);

    //   const payload: any = {
    //     finalData: [],
    //     state: [],
    //   };
    //   dispatch(setFetchData(payload));
    // }
  } catch (error) {
    console.log(error);
  }
};

export const FetchBlacklistListData = async (
  setHandleState: React.Dispatch<React.SetStateAction<blacklistStateDTO>>,
  setData: any,
  columnData: any,
  handleState: blacklistStateDTO
) => {
  try {
    //   setShowLoading(true);
    setHandleState((prevState) => ({ ...prevState, showLoading: true }));
    //   const response = await BaseApi.get(`/blacklist/list?${query}`);
    const response = await GetBlacklistListApi({ ...handleState?.queryParams, offset: 0 });
    if (!response.data?.data) return setData({ data: [], count: 0 });
    if (response.data && response.data?.data?.length) {
      setData({
        data: response.data.data,
        count: response.data.total_records.count,
      });
    }
    setHandleState((prevState) => ({ ...prevState, columns: columnData }));
    //   setColumns(columnData);
  } catch (error) {
  } finally {
    setHandleState((prevState) => ({ ...prevState, showLoading: false }));
    //   setShowLoading(false);
  }
};

export const DeleteGroupsFromList = (setData: any, groupId: string) => {
  setData((prev: any) => {
    const updatedData = prev?.data?.map((list: any) => {
      const finalGroupList = list?.groups_list?.filter((group: any) => group?.uuid !== groupId);
      return {
        ...list,
        groups_list: finalGroupList,
      };
    });
    return {
      ...prev,
      data: updatedData,
    };
  });
};

export const SearchBlacklistData = (setHandleState: React.Dispatch<React.SetStateAction<blacklistStateDTO>>, event: React.ChangeEvent<HTMLInputElement>) => {
  setHandleState((prevState) => {
    const { queryParams } = prevState;
    return { ...prevState, queryParams: { ...queryParams, search: event.target.value.trim().toLowerCase() } };
  });
};

export const FilterBlacklistData = (setHandleState: React.Dispatch<React.SetStateAction<blacklistStateDTO>>, filteredData: any) => {
  const encryptedData = jwtEncrypt(filteredData);
  setHandleState((prevState) => {
    const { queryParams } = prevState;
    if (filteredData?.data?.length) {
      return { ...prevState, queryParams: { ...queryParams, filter: encryptedData } };
    } else {
      return { ...prevState, queryParams: { ...queryParams, filter: '' } };
    }
  });
  // setQueryParams((prev) => {
  //   if (filteredData?.data?.length) {
  //     return { ...prev, filter: encryptedData };
  //   } else {
  //     return { ...prev, filter: '' };
  //   }
  // });
};

export const BlacklistResultModalData = async (setHandleState: React.Dispatch<React.SetStateAction<blacklistStateDTO>>, uuid: string, blacklistValue: number) => {
  if (blacklistValue === 0) {
    return;
  }
  // const fetchResult = await BaseApi.get(`/blacklist/result/${uuid}`);
  const fetchResult = await GetOneBlackListDataResultModal({ uuid: uuid });

  if (fetchResult.status === 200) {
    //   setIsOpen(true);
    setHandleState((prevState) => ({ ...prevState, isOpen: true }));
    setHandleState((prevState) => ({ ...prevState, modalData: fetchResult?.data }));

    //   setModalData(fetchResult?.data);
  }
};

export const BlacklistScrollFunction = async (setHandleState: React.Dispatch<React.SetStateAction<blacklistStateDTO>>, setData: any, setIsFetching: any, handleState: any) => {
  try {
    setIsFetching(true);
    //   const query = setAndGetQueryParams([
    //     { key: 'limit', value: handleState?.queryParams?.limit },
    //     { key: 'offset', value: handleState?.queryParams?.offset + 1 },
    //     { key: 'search', value: handleState?.queryParams?.search },
    //     { key: 'filter', value: handleState?.queryParams?.filter },
    //   ]);
    //   const response = await BaseApi.get(`/blacklist/list?${query}`);
    const response = await GetBlacklistListApi({ ...handleState?.queryParams, offset: handleState?.queryParams?.offset + 1 });

    const responseData = response.data;
    if (!responseData?.data) return setData({ data: [], count: 0 });
    if (responseData?.data?.length < 25) {
      setData((prev: any) => ({ data: [...prev?.data, ...responseData?.data], count: prev?.data?.length }));
    } else {
      setData((prev: any) => ({ ...prev, data: [...prev?.data, ...responseData?.data] }));
    }
    setHandleState((prevState) => {
      const { queryParams } = prevState;
      return { ...prevState, queryParams: { ...queryParams, offset: queryParams?.offset + 1 } };
    });
    //   setQueryParams((prev) => ({
    //     ...prev,
    //     offset: prev?.offset + 1,
    //   }));
  } catch (error) {
    console.log(error);
  } finally {
    setIsFetching(false);
  }
};

export const BlacklistTableData = (setHandleState: React.Dispatch<React.SetStateAction<blacklistStateDTO>>, table: any) => {
  const tableSelectedData = table.getSelectedRowModel().rows.map((val: any) => val.original);
  setHandleState((prevState) => ({ ...prevState, selectedData: tableSelectedData }));

  // setSelectedData(tableSelectedData);
  if (table.getSelectedRowModel().rows.length > 1) {
    setHandleState((prevState) => ({ ...prevState, showGlobalOpt: true }));

    //   setGlobalOpt(true);
  } else {
    setHandleState((prevState) => ({ ...prevState, showGlobalOpt: false }));
    // setGlobalOpt(false);
  }
};

export const BlacklistDeleteListData = async (setHandleState: React.Dispatch<React.SetStateAction<blacklistStateDTO>>, handleState: any, uuid: Array<string>, table: any) => {
  try {
    //   const response = await BaseApi.delete(`/blacklist/list/delete`, {
    //     data: {
    //       delete_uuid: uuid ? uuid : handleState?.selectedData?.map((val: { uuid: string }) => val.uuid),
    //     },
    //   });
    const response = await DeleteBlacklistData(uuid ? uuid : handleState?.selectedData?.map((val: { uuid: string }) => val.uuid));
    if (response.status === 200) {
      setHandleState((prevState) => ({ ...prevState, load: handleState?.load + 1 }));
      // setLoad((prev: number) => prev + 1);
      table.reset();
    }
  } catch (error) {
    console.log(error);
  }
};

export const BlacklistPauseListData = async (setHandleState: React.Dispatch<React.SetStateAction<blacklistStateDTO>>, handleState: any, uuid: Array<string>, table: any) => {
  // const response = await BaseApi.patch(`/blacklist/list/pause`, {
  //   pause_uuid: uuid ? uuid : handleState?.selectedData?.map((val: { uuid: string }) => val.uuid),
  // });
  const response = await PauseBlacklistData(uuid ? uuid : handleState?.selectedData?.map((val: { uuid: string }) => val.uuid));
  if (response.status === 200) {
    setHandleState((prevState) => ({ ...prevState, load: handleState?.load + 1 }));

    //   setLoad((prev: number) => prev + 1);
    table.reset();
  }
};

export const BlacklistResumeListData = async (setHandleState: React.Dispatch<React.SetStateAction<blacklistStateDTO>>, handleState: any, uuid: Array<string>, table: any) => {
  // const response = await BaseApi.patch(`/blacklist/resume/validate`, {
  //   resume_uuid: uuid ? uuid : handleState?.selectedData?.map((val: { uuid: string }) => val.uuid),
  // });
  const response = await ResumeBlacklistData(uuid ? uuid : handleState?.selectedData?.map((val: { uuid: string }) => val.uuid));
  if (response.status === 200) {
    setHandleState((prevState) => ({ ...prevState, load: handleState?.load + 1 }));

    //   setLoad((prev: number) => prev + 1);
    table.reset();
  }
};

export const BlacklistRecheckListData = async (setHandleState: React.Dispatch<React.SetStateAction<blacklistStateDTO>>, handleState: any, uuid: Array<string>, table: any) => {
  // const response = await BaseApi.patch(`/blacklist/recheck/validate`, {
  //   recheck_uuid: uuid ? uuid : handleState?.selectedData?.map((val: { uuid: string }) => val.uuid),
  // });
  const response = await RecheckBlacklistData(uuid ? uuid : handleState?.selectedData?.map((val: { uuid: string }) => val.uuid));
  if (response.status === 200) {
    setHandleState((prevState) => ({ ...prevState, load: handleState?.load + 1 }));

    //   setLoad((prev: number) => prev + 1);
    table.reset();
  }
};

export const BlacklistDownloadListData = async (uuid: Array<string>, handleState: any) => {
  const uuids = uuid ? jwtEncrypt(uuid) : jwtEncrypt(handleState?.selectedData?.map((val: { uuid: string }) => val.uuid));
  // const response = await BaseApi.get(`/blacklist/excelresult/${uuids}`);
  const response = await GetBlackListDataDownload({ rowuuid: uuids });

  // console.log('response', response);
  const listData = response?.data;
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(listData);
  XLSX.utils.book_append_sheet(wb, ws, 'Blacklist Validation');
  const wbout = XLSX.write(wb, { bookType: 'csv', type: 'string' });
  const blob = new Blob([wbout], { type: 'text/csv;charset=utf-8;' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'blacklist_report.csv';
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
};

export const BlacklistOptionHandler = async (
  option: string,
  handleListDelete: any,
  handleListDownload: any,
  handleListPause: any,
  handleListResume: any,
  handleListRecheck: any
) => {
  if (option === 'delete') {
    handleListDelete();
  }
  if (option === 'download') {
    handleListDownload();
  }
  if (option === 'pause') {
    handleListPause();
  }
  if (option === 'resume') {
    handleListResume();
  }
  if (option === 'recheck') {
    handleListRecheck();
  }

  // if (option === 'delete') {
  //   handleListDelete();
  // }
  // if (option === 'download') {
  //   handleListDownload();
  // }
  // if (option === 'pause') {
  //   handleListPause();
  // }
  // if (option === 'resume') {
  //   handleListResume();
  // }
  // if (option === 'recheck') {
  //   handleListRecheck();
  // }
};

export const GetTextColorForResultModal = (blacklistDomain: string) => {
  switch (blacklistDomain) {
    case ConnectionFailText:
      return ConnectionFailColor;
    case BadNameText:
      return BadNameColor;
    case ListedText:
      return ListedColor;
    default:
      return DefaultColor;
  }
};

export const BlacklistDeleteData = async (setGroupTags: any, state: any, uuids: string, val: any, rowUuid: string) => {
  let uuid: string;

  if (!uuids) {
    const id = state?.grouplist?.find((group: { name: string }) => group?.name === val?.name);
    setGroupTags((prev: any) => {
      const updatedGroup = prev.filter((group: any) => group.name !== val?.name);
      return updatedGroup;
    });

    uuid = id?.uuid;
  } else {
    uuid = uuids;
    setGroupTags((prev: any) => {
      const updatedGroup = prev.filter((group: any) => group.uuid !== uuid);
      return updatedGroup;
    });
  }
  // setGroupTags((prev) => {
  //   const updatedGroup = prev.filter((group) => group.uuid !== uuid);
  //   return updatedGroup;
  // });
  try {
    //   await BaseApi.delete(`/groups/delete/into/linked/with/blacklist`, {
    //     data: {
    //       group_id: uuid,
    //       variant_id: row?.uuid,
    //     },
    //   });
    await DeleteBlacklistGroup(uuid, rowUuid);
  } catch (error) {
    console.log('Group removing from email error', error);
  }
};

export const BlacklistGroupSearchData = (handleCreateGroup: any, setIsOpen: any, setSearchedGroup: any, event: any, groups: any, rowUuid: string) => {
  if (event.key === 'Enter') {
    // Check if the searched group name exist on groupList
    const groupName = groups.some((val: { name: string }) => val?.name?.toLowerCase() === event?.target?.value?.toLowerCase());
    if (groupName) return;
    const name = event?.target?.value?.toLowerCase().replace(/\b\w/g, (char: string) => char.toUpperCase());
    const params = { name: name, variant_id: rowUuid };
    handleCreateGroup(params);
    setIsOpen(false);
  } else {
    const searchedValue = event?.target?.value?.toLowerCase();
    const searchedData = groups.length && (searchedValue ? groups?.filter((group: { name: string }) => group?.name?.toLowerCase()?.includes(searchedValue)) : groups);
    setSearchedGroup(searchedData);
  }
};

export const BlacklistCreateGroup = async (setSearchedGroup: any, setGroupTags: any, setGroups: any, params: any, fetchList: any, dispatch: any) => {
  try {
    setGroups((prev: any) => {
      const updatedList = prev.length
        ? [params, ...prev].sort((a: any, b: any) => (a?.name.toLowerCase() < b?.name.toLowerCase() ? -1 : a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : 0))
        : [params];
      return updatedList;
    });
    setSearchedGroup((prev: any) => {
      const updatedList = prev.length
        ? [params, ...prev].sort((a: any, b: any) => (a?.name.toLowerCase() < b?.name.toLowerCase() ? -1 : a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : 0))
        : [params];
      return updatedList;
    });
    setGroupTags((prev: any) => [params, ...prev]);
    //   await BaseApi.patch('/groups/link/with/blacklist', params);
    await CreateGroupForBlackList(params);

    //props.grouplistTrigger();
    dispatch(fetchGroupsList());
    //   fetchGroupsList()
  } catch (error) {
    console.error(error);
  } finally {
    fetchList();
  }
};

export const CreateNewGroupForBlacklistNew = async (setGroupTags: any, val: any, row: any) => {
  setGroupTags((prev: any) => {
    // Check if group name already exist
    const groupName = prev.some((group: any) => group.uuid === val.uuid);
    if (groupName) return prev;
    return [val, ...prev];
  });
  const params = { group_id: val?.uuid, variant_id: row?.uuid };
  try {
    //   await BaseApi.patch('/groups/link/with/blacklist', params);
    await CreateGroupForBlackList(params);

    //await dispatch(fetchGroupsList());
  } catch (error) {
    console.error(error);
  }
};

export const ValidateBlackAddlistData = async (
  setHandleState: React.Dispatch<React.SetStateAction<AddBlacklistStateDTO>>,
  handleState: AddBlacklistStateDTO,
  userType: any,
  onSuccess: any,
  handleClose: any,
  navigate: any,
  dispatch: any
) => {
  try {
    const restriction = restrictionPlan(BLACKLIST, userType?.data);
    if (!restriction) return;

    setHandleState((prevState) => ({ ...prevState, loading: true }));

    const payload = {
      group: handleState?.groupname,
      black_list: handleState?.blackList,
      frequency: handleState?.frequency,
    };
    const postNewBlacklists = await ValidateBlacklistData(payload);

    if (postNewBlacklists?.status === 200) {
      await getAuthUserConfiguration('billings', dispatch);
      dispatch(fetchGroupsList());
      onSuccess();
    }
  
    if (!postNewBlacklists?.error) {
      navigate(ROUTE_PATHS?.BLACKLIST_MONITOR);
      setHandleState((prevState) => ({ ...prevState, loading: false }));

      dispatch(setUpdateSettingsData(CONSTANT_TEXTS?.BILLINGS));
      dispatch(getErrorMessage(AddBlacklistStateIV));
      handleClose();
    } else {
      setHandleState((prevState) => ({ ...prevState, loading: false }));

      if (postNewBlacklists?.status === 402) {
        dispatch(setPaymentStatus(true));
      }
      dispatch(getErrorMessage(postNewBlacklists?.message));
    }
  } catch (error) {
    console.log(error);
  }
};

export const HandleCloseBlacklistModal = (setHandleState: React.Dispatch<React.SetStateAction<AddBlacklistStateDTO>>, onClose: any, dispatch: any) => {
  onClose();
  setHandleState((prevState) => ({
    ...prevState,
    blackList: [],
    groupname: '',
    loading: false,
    frequency: {
      value: '',
      type: 0,
    },
  }));
  dispatch(getErrorMessage(AddBlacklistStateIV));
};

export const HandleSelectBlackListFrequenct = (setHandleState: React.Dispatch<React.SetStateAction<AddBlacklistStateDTO>>, e: ChangeEvent<HTMLSelectElement>) => {
  const selectedValue = JSON.parse(e.target.value);
  setHandleState((prevState) => ({
    ...prevState,
    frequency: selectedValue,
  }));
};

export const HandleInfoBlacklistModal = (setHandleState: React.Dispatch<React.SetStateAction<AddBlacklistStateDTO>>, handleState: AddBlacklistStateDTO, tooltipRef: any) => {
  const handleClickOutside = (event: any) => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
      setHandleState((prevState) => ({ ...prevState, showTooltip: false }));
    }
  };

  if (handleState?.showTooltip) {
    document.addEventListener('mousedown', handleClickOutside);
  } else {
    document.removeEventListener('mousedown', handleClickOutside);
  }

  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
};

export const HandleRenameGroups = async (
  setGroupToggleState: React.Dispatch<React.SetStateAction<any>>,
  setGroupTags: React.Dispatch<React.SetStateAction<any>>,
  setSearchedGroup: React.Dispatch<React.SetStateAction<any>>,
  setGroups: React.Dispatch<React.SetStateAction<any>>,
  val: any,
  value: string,
  dispatch: any
) => {
  try {
    const params = {
      uuid: val?.uuid,
      name: value?.toLowerCase().replace(/\b\w/g, (char: string) => char.toUpperCase()),
    };
    setGroups((prev:any) => {
      const updatedList = prev
        .map((group:{uuid:string}) => {
          if (group?.uuid === val?.uuid) return { ...group, name: params?.name };
          return group;
        })
        .sort((a: any, b: any) => (a?.name.toLowerCase() < b?.name.toLowerCase() ? -1 : a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : 0));
      return updatedList;
    });
    setSearchedGroup((prev:any) => {
      const updatedList = prev
        .map((group:{uuid:string}) => {
          if (group?.uuid === val?.uuid) return { ...group, name: params?.name };
          return group;
        })
        .sort((a: any, b: any) => (a?.name.toLowerCase() < b?.name.toLowerCase() ? -1 : a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : 0));
      return updatedList;
    });
    setGroupTags((prev:any) => {
      const updatedGroup = prev.map((group:{uuid:string}) => {
        if (group?.uuid === val?.uuid) return { ...group, name: params?.name };
        return group;
      });
      return updatedGroup;
    });
    setGroupToggleState((prev: any) => ({
      ...prev,
      [val?.uuid]: { show: false, rename: false },
    }));
    // await BaseApi.patch('/groups', params);
    await RenameGroupForBlackList(params);
    dispatch(fetchGroupsList());
    // fetchGroupsList()
  } catch (error) {
    console.log('Group rename error', error);
  }
};

export const HandleDeleteGroupFromListData = async(
    setGroupToggleState: React.Dispatch<React.SetStateAction<any>>,
  setGroupTags: React.Dispatch<React.SetStateAction<any>>,
  setSearchedGroup: React.Dispatch<React.SetStateAction<any>>,
  setGroups: React.Dispatch<React.SetStateAction<any>>,
  setIsEditVisible: React.Dispatch<React.SetStateAction<any>>,
  uuid:string,
  hanleDeleteGroupFromSenderList:any,
  fetchGroupList:any
)=>{
    setGroups((prev:any) => {
        const updatedList = prev.filter((group:{uuid:string}) => group.uuid !== uuid);
        return updatedList;
      });
      setSearchedGroup((prev:any) => {
        const updatedList = prev.filter((group:{uuid:string}) => group.uuid !== uuid);
        return updatedList;
      });
      setGroupTags((prev:any) => {
        const updatedGroup = prev.filter((group:{uuid:string}) => group.uuid !== uuid);
        return updatedGroup;
      });
      setGroupToggleState((prev: any) => ({
        ...prev,
        [uuid]: { ...prev[uuid], show: false },
      }));
      setIsEditVisible(false);
      try {
        // await BaseApi.delete(`/groups/${uuid}`);
        await DeleteOneBlacklistGroupList(uuid);

        // dispatch(fetchGroupsList());
        await fetchGroupList();
        hanleDeleteGroupFromSenderList(uuid);
        // await fetchList()
      } catch (error) {
        console.log('Group delete error', error);
      }
}
