/* eslint-disable react-hooks/rules-of-hooks */
import { useFieldValidator, useFormValidator } from 'hooks/useFormValidation';
import { getErrorMessage } from 'redux-store/custom';
import { GenerateOAuthUrlApi, SmtpCreateApi } from '../api-handler/add-email-accounts-api';
import { smtpValidationSchema } from 'lib/validator-schema/senderAccountSchema';

export const HandleGoogleOAuthSetup = async () => {
  try {
    const apiResponse = await GenerateOAuthUrlApi();
    window.location.href = apiResponse?.data;
  } catch (error) {
    console.log('FetchGroupListEvent Error:', error);
  } finally {
  }
};

export const HandleFormChangeEvent = async ({ e, setFormData, dispatch }: any) => {
  try {
    setFormData((prev: any) => ({
      ...prev,
      [e.target.id]: e.target.value,
      ...(e.target.id === 'smtp_username' && { email: e.target.value }),
    }));
    const validate = await useFieldValidator(smtpValidationSchema, e.target.id, e.target.value);
    dispatch(getErrorMessage(validate?.message));
  } catch (error) {
    console.error('HandleFormChangeEvent Error:', error);
  }
};

export const HandleFormSubmitEvent = async ({ setFormData, dispatch, formData, navigate, setLoading }: any) => {
  try {
    const validate = await useFormValidator(smtpValidationSchema, formData);
    if (validate?.error) {
      dispatch(getErrorMessage(validate?.message));
      return;
    }
    setLoading(true);
    const payload = {
      ...formData,
      campaign_settings: {
        daily_limit: +formData?.daily_limit, 
        delay_between_emails: +formData?.delay_between_emails, 
      },
    }
    const data = await SmtpCreateApi(payload);
    if (data?.error) {
      dispatch(getErrorMessage(data?.message));
    } else {
      navigate('/sender-accounts');
    }
  } catch (error) {
    console.error('HandleFormSubmitEvent Error:', error);
  } finally {
    setLoading(false);
  }
};
