import { generateDateInterval } from "utility/utils";

export const trueVerifierFilterDataList = [
    {
      id: '1',
      name: 'Status',
      uniqueColumnName: 'ticket_status',
      conditions: [
        { id: '1', name: 'Is', uniqueName: 'is' },
        { id: '2', name: 'Is not', uniqueName: 'not' },
      ],
      resultValue: [
        { id: '1', name: 'In Progress', uniqueName: 0 },
        { id: '2', name: 'Completed', uniqueName: 1 },
        { id: '3', name: 'Reopen', uniqueName: 2 },
        { id: '4', name: 'Cancel', uniqueName: 3 },
      ],
    },
    {
      id: '2',
      name: 'Variant',
      uniqueColumnName: 'variant',
      conditions: [
        { id: '1', name: 'Is', uniqueName: 'is' },
        { id: '2', name: 'Is not', uniqueName: 'not' },
      ],
      resultValue: [
        { id: '1', name: 'True Verifier', uniqueName: 'true_verifier' },
        { id: '2', name: 'Blacklist', uniqueName: 'blacklist' },
        { id: '3', name: 'Campaign', uniqueName: 'campaign' },
        { id: '4', name: 'Email Verifier', uniqueName: 'email_verifier' },
      ],
    },
    {
      id: '3',
      name: 'Created On',
      uniqueColumnName: 'cdate',
      conditions: [{ id: '1', name: 'Is', uniqueName: 'is' }],
      resultValue: [
        { id: '1', name: 'Today', uniqueName: generateDateInterval(0, 0) },
        { id: '2', name: 'Yesterday', uniqueName: generateDateInterval(1) },
        { id: '3', name: 'Last 7 days', uniqueName: generateDateInterval(7) },
        { id: '4', name: 'Last 30 days', uniqueName: generateDateInterval(29) },
        { id: '5', name: 'This week', uniqueName: generateDateInterval('week', 0) },
        { id: '6', name: 'This month', uniqueName: generateDateInterval('month', 0) },
      ],
    },
  ];