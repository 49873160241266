import { Spinner } from '@chakra-ui/react';
import React from 'react';

type ButtonProps = {
  name?: string;
  color?: string;
  bg?: string;
  LeftIcon?: React.JSX.Element;
  RightIcon?: React.JSX.Element;
  px?: string;
  py?: string;
  fontSize?: string;
  rounded ?: string;
  onClick?: () => void;
  customClass?: string;
  id?: string;
  loading?: boolean;
  disable?: boolean;
  type?: 'button' | 'submit' | 'reset';
  clickEffect ?: boolean;
};

const Button = (props: ButtonProps) => {
  const {
    color,
    bg,
    LeftIcon,
    RightIcon,
    rounded,
    name,
    px,
    py,
    fontSize,
    onClick,
    loading = false,
    customClass,
    id,
    disable = false,
    type = 'button',
    clickEffect
  } = props;
  const [disabled, setDisabled] = React.useState(false);
  React.useEffect(() => {
    setDisabled(disable);
  }, [disable]);
  return (
    <button
      disabled={disabled}
      type={type}
      id={id}
      className={`relative transition-all transition-300 text-${color || 'white'} bg-${bg || 'brand-500'
        } text-${fontSize || '[0.7rem]'} ${rounded || 'rounded-md'} ${py || 'py-2'} ${px || 'px-2.5'
        } flex items-center justify-center gap-1 border hover:drop-shadow-md hover:filter ${customClass} ${!disabled ? '' : '!bg-gray-200 !border-gray-200 !text-gray-100 hover:!bg-gray-200 hover:drop-shadow-none'
        } ${clickEffect && 'active:scale-95'}`}
      onClick={() => {
        onClick?.()
      }}
    >
        {LeftIcon && LeftIcon}
        {name}
        {RightIcon && RightIcon}

      {loading && (
        <Spinner
          className="absolute"
          thickness="3px"
          speed="0.65s"
          color="gray.400"
          size="md"
        />
      )}
    </button>
  );
};

export default Button;
