import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import Button from 'components/common/Button';
import FileUploader from 'components/common/FileUploader';
import { useState } from 'react';
import { handleEmailUploadClickEvent, onCloseClickEvent } from 'shared/admin-verifier/event-handler/admin-verifier-event';

const EmailUploader = (props: any) => {
  const { isOpen, onClose, smartStateUpdate } = props;
  const [uploadData, setUploadData] = useState([]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(2px)" />
      <ModalContent className="!p-0" overflow={'auto'}>
        <ModalHeader className="!m-0 !p-0">
          <div className="space-y-1 bg-heading  p-4">
            <h5 className=" text-sm font-thin text-white ">Upload CSV</h5>
            <ModalCloseButton size="sm" color={'#fff'} id="add-blacklist-pop-close" className="mr-3 " />
          </div>
        </ModalHeader>
        <hr />
        <ModalBody className="p-3">
          <FileUploader onUpload={(data) => setUploadData(data)} />
          <div className="flex w-full gap-2 pt-4">
            <Button type="button" name="Cancel" customClass="w-1/2" onClick={() => onCloseClickEvent(smartStateUpdate)} />
            <Button type="button" name="Continue" customClass="w-1/2 bg-white !text-blueSecondary font-bold" onClick={() => handleEmailUploadClickEvent(uploadData, smartStateUpdate)} />
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EmailUploader;
