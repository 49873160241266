import moment from 'moment';
import { getErrorMessage, setCredits, setTableRowsList } from 'redux-store/custom';
import {
  setTicketId,
  setTicketDetails,
  showTicketDetailsStatus,
  setToggleDrawer,
  setTotalMessages,
  setTotalMessagesCount,
  setChatText,
  setTicketDrawerDetails,
} from 'redux-store/true-verifier';
import { CreateNewTicketAPI, FetchTChatListAPI, FetchTrueVerifyListAPI, SendMessageAPI, UpdateTicketAPI } from '../api-handler/true-verifier-api';
import { queryParamsCommonDTO } from 'dto/types/views/common';
import { commonBooleanStateDTO, messageLoadingDTO, ticketDrawerDetailsType, trueVerifierDataDTO } from 'dto/types/views/true-verfier';
import saveAs from 'file-saver';
import { generateRandomReportNumber, restrictionPlan, toastState } from 'utility/utils';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import { setUpdateSettingsData } from 'redux-store/global-configuration';
import { ticket } from 'dto/initial-value/views/true-verifier';
import { MdOutlineCloudDownload } from 'react-icons/md';
import { getAuthUserConfiguration } from 'shared/layout/event-handler';

export const HandleChatClickEvent = (row: Record<string, any>, dispatch: any) => {
  dispatch(setTicketId(row?.uuid));
  const createdDate = moment(row?.ticket_created_date).format('DD/MM/YYYY');
  const closedDate = row?.ticket_closed_date ? moment(row?.ticket_created_date).format('DD/MM/YYYY') : row?.ticket_closed_date;
  const details = {
    ticket_id: row?.ticket_id,
    status: row?.ticket_status,
    closed_date: closedDate,
    created_date: createdDate,
  };
  dispatch(setTicketDetails(details));
  dispatch(showTicketDetailsStatus(true));
};

export const HandleTrueVerifierOnSelectEvent = (data: string[], dispatch: any) => {
  dispatch(setTableRowsList(data));
};

export const HandleTrueVerifierOnScrollEvent = async (
  queryParams: queryParamsCommonDTO,
  data: trueVerifierDataDTO,
  smartStateUpdate: any,
  setIsFetching: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    setIsFetching(true);
    const response = await FetchTrueVerifyListAPI({
      ...queryParams,
      offset: queryParams?.offset + 1,
      sort: queryParams?.sort?.columnName ? `${queryParams?.sort?.columnName}%${queryParams?.sort?.order}` : '',
    });
    const responseData: any = response.data;
    if (!responseData?.data) return smartStateUpdate({ data: { data: [], count: 0 } });
    if (responseData?.data?.length < 25) {
      smartStateUpdate({ data: { data: [...data?.data, ...responseData?.data], count: responseData?.total_records?.count } });
    } else {
      smartStateUpdate({ data: { data: [...data?.data, ...responseData?.data] } });
    }

    smartStateUpdate({ queryParams: { offset: queryParams?.offset + 1 } });
  } catch (error) {
    console.log(error);
  } finally {
    setIsFetching(false);
  }
};

export const HandleTrueVerifierToggleDrawerClickEvent = (dispatch: any) => {
  dispatch(setToggleDrawer());
};

export const HandleTrueVerifierFetchListEvent = async (queryParams: queryParamsCommonDTO, columnData: any, smartStateUpdate: any) => {
  try {
    smartStateUpdate({ columns: columnData, tableLoading: true });
    const response = await FetchTrueVerifyListAPI({ ...queryParams, sort: queryParams?.sort?.columnName ? `${queryParams?.sort?.columnName}%${queryParams?.sort?.order}` : '' });
    if (!response?.error) {
      const responseData = response?.data?.length === 0 ? [] : response?.data?.data;
      smartStateUpdate({ data: { data: responseData || [], count: response?.data?.total_records?.count } });
    }
  } catch (err) {
    console.log(err, 'Error');
  } finally {
    smartStateUpdate({ tableLoading: false });
  }
};

const handleDownloadCSV = (rowDatas: any[]) => {
  const mapData = (downloadData: any[]) => {
    const csvData = downloadData.map((val) => {
      let { ticket_id: Ticket_ID, subject: Subject, ticket_created_date: Created_on, ticket_status: Status } = val;
      const finalStatus = ['In progress', 'Completed', 'Reopened', 'Cancelled'];
      return { Ticket_ID, Subject, Created_on, Status: finalStatus[Status] };
    });
    return csvData;
  };

  const downloadCSV = () => {
    const csvContent = convertArrayOfObjectsToCSV(mapData(rowDatas));
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'data.csv');
  };

  downloadCSV();
};

export const TrueVerifierRowSettingsOptionsClickEvent = () => {
  return [
    {
      icon: <MdOutlineCloudDownload className="text-brand-500 group-hover:text-white" />,
      accessName: 'download',
      name: 'Download',
      functioning: (rowuuid: string, rowDetails: any) => handleDownloadCSV([rowDetails]),
    },
  ];
};

export const TrueVerifierMultiSelectionOptionsClickEvent = (selectedIds: string[], data: any[]) => {
  const selectedRows = data?.filter((item) => selectedIds?.includes(item?.uuid));
  return [
    {
      icon: <MdOutlineCloudDownload />,
      accessName: 'download',
      name: 'Download',
      functioning: () => handleDownloadCSV(selectedRows),
    },
  ];
};

// Chat Options
export const GetChatListEvent = async (state: any, dispatch: any) => {
  try {
    const response = await FetchTChatListAPI(state?.ticketId);
    response.data.data.reverse();

    if (state?.totalMessagesCount !== response?.data?.total_records?.count) {
      const totalMessages = response?.data?.data?.map((message: any) => {
        const date = moment(message?.cdate).format('DD MMM YYYY, hh:mm A');
        return {
          message: message?.message,
          user_type: message?.user_type,
          ticket_type: message?.ticket_type,
          ticket_emails: message?.ticket_emails,
          emails: message?.emails,
          cdate: date,
        };
      });
      dispatch(setTotalMessages(totalMessages));
      dispatch(setTotalMessagesCount(response?.data?.total_records?.count));
    }
  } catch (err) {
    console.log('Error occured while collecting chat history', err);
  }
};

export const SendMessageClickEvent = async (state: any, setMessageLoading: React.Dispatch<React.SetStateAction<messageLoadingDTO>>, dispatch: any) => {
  try {
    const params = {
      ticket_uuid: state?.ticketId,
      message: state?.chatText,
      ticket_type: 'chat',
    };
    setMessageLoading((prev) => ({ ...prev, sending: true }));
    await SendMessageAPI(params);
    await GetChatListEvent(state, dispatch);
    dispatch(setChatText(''));
  } catch (err) {
    console.log('Error occured while sending message', err);
  } finally {
    setMessageLoading((prev) => ({ ...prev, sending: false }));
  }
};

const convertArrayOfObjectsToCSV = (array: any): string => {
  if (array.length === 0) return '';
  // Get CSV headers
  const headers = Object.keys(array[0]).join(',');
  // Convert rows and handle commas by enclosing values in double quotes
  const rows = array.map((obj: any) =>
    Object.values(obj)
      .map((value: any) =>
        typeof value === 'string' && value.includes(',')
          ? `"${value}"` // Enclose in quotes if the value contains a comma
          : value
      )
      .join(',')
  );
  return [headers, ...rows].join('\n');
};

export const HandleDownloadClickEvent = (report: any) => {
  const downloadData = report.map((item: any) => {
    const transformed: any = {};
    // Transform keys by capitalizing the first letter
    for (const key in item) {
      if (key !== 'id') {
        transformed[key.charAt(0).toUpperCase() + key.slice(1)] = item[key];
      }
    }
    return transformed;
  });

  // Convert the transformed data into CSV
  const csvContent = convertArrayOfObjectsToCSV(downloadData);

  // Create a blob and trigger the download
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  saveAs(blob, `${generateRandomReportNumber()}.csv`);
};

// File Attach
export const SendFileAttachClickEvent = async (state: any, emails: any) => {
  try {
    const params = {
      ticket_uuid: state?.ticketId,
      message: 'hi',
      ticket_type: 'file',
      ticket_emails: emails,
    };
    await SendMessageAPI(params);
  } catch (err) {
    console.log('Error occured while sending message', err);
  }
};

export const HandleFileOnDropEvent = (state: any, acceptedFiles: any[], getChat: () => Promise<void>, setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
  const fileMapUpload = new Map();

  acceptedFiles.forEach((file) => {
    const reader = new FileReader();
    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');
    fileMapUpload.set(reader, file);
    reader.onload = async (e: any) => {
      const fileAttr = fileMapUpload.get(e.target);
      if (!['csv', 'xlsx', 'xls'].includes(fileAttr.name.split('.').pop())) {
        toast.error('This file format is not accepted', toastState.error);
        return;
      }
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      jsonData.filter((val: any) => val?.length !== 0);

      const flattenedData = jsonData
        .flat()
        // eslint-disable-next-line no-useless-escape
        .filter((item: any) => /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(item))
        .map((item: any, index: number) => {
          return { id: index + 1, email: item };
        });
      try {
        setLoading(true);
        await SendFileAttachClickEvent(state, flattenedData);
        await getChat();
      } catch (error) {
        console.log('True verifier file attach error', error);
      } finally {
        setLoading(false);
      }
    };
    reader.readAsArrayBuffer(file);
  });
};

// TicketCreation
export const HandleTicketDetailsChangeEvent = (name: string, value: string | { id: string; email: string }[], ticketDrawerDetails: ticketDrawerDetailsType, dispatch: any) => {
  const drawerDetails = { ...ticketDrawerDetails };
  drawerDetails[name] = value as string | { id: string; email: string }[];
  dispatch(setTicketDrawerDetails(drawerDetails));
  // const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1)
  // let message = value.length ? '' : `${capitalizedName} is required`
  // if (!message) {
  //   if (name === 'subject') {
  //     message = value?.length >= 5 ? '' : 'Subject string limit should be between 5 to 50'
  //   }else if(name === 'message'){
  //     message = value?.length >= 5 ? '' : 'Message string limit should be between 5 to 500'
  //   }
  // }
  // dispatch(getErrorMessage({ ...custom?.errorMessage, [name]: message }));
};

export const HandleTicketCreationDrawerCloseEvent = (setCommonBooleanState: React.Dispatch<React.SetStateAction<commonBooleanStateDTO>>, dispatch: any) => {
  // const data: ticketDrawerDetailsType = {
  //   uuid: '',
  //   subject: '',
  //   message: '',
  //   emails: [],
  // };
  // dispatch(setTicketDrawerDetails(data));
  dispatch(setTicketId(''));
  dispatch(setToggleDrawer());
  setCommonBooleanState((prev) => ({ ...prev, showPaymentWarning: false }));
};

export const HandleSendMessageClickEvent = async (
  environment: any,
  userType: any,
  ticketDrawerDetails: ticketDrawerDetailsType,
  custom: any,
  fetchList: () => Promise<void>,
  handleCloseDrawer: () => void,
  setCommonBooleanState: React.Dispatch<React.SetStateAction<commonBooleanStateDTO>>,
  dispatch: any
) => {
  try {
    const restriction = environment?.app_default_feature?.is_org_enabled && restrictionPlan('true_verify', userType?.data);
    if (!restriction && environment?.app_default_feature?.is_org_enabled) return;
    setCommonBooleanState((prev) => ({ ...prev, loading: false }));
    const params: any = {
      uuid: ticketDrawerDetails?.uuid,
      subject: ticketDrawerDetails?.subject,
      message: ticketDrawerDetails?.message,
      verify_data_list: ticketDrawerDetails?.emails,
    };
    let response;
    if (params.uuid) {
      response = await UpdateTicketAPI(params);
    } else {
      response = await CreateNewTicketAPI(params);
    }

    if (response?.status === 200 && response?.message === 'credits_not_found') {
      setCommonBooleanState((prev) => ({ ...prev, showPaymentWarning: true }));
      return;
    }
    if (response?.error) {
      dispatch(getErrorMessage(response?.message));
    } else {
      dispatch(setUpdateSettingsData('billings'));
      handleCloseDrawer();
      dispatch(
        setCredits({
          totalAvailableCredits: custom.credits.totalAvailableCredits,
          totalCredits: custom.credits.totalCredits,
          hitCount: 1,
        })
      );
      const data: ticketDrawerDetailsType = {
        uuid: '',
        subject: '',
        message: '',
        emails: [],
      };
      dispatch(setTicketDrawerDetails(data));
    }
  } catch (error) {
    console.log('Send message error', error);
  } finally {
    setCommonBooleanState((prev) => ({ ...prev, loading: false }));
    await getAuthUserConfiguration('billings', dispatch);
    fetchList();
  }
};

// Ticket Details
export const HandleTicketDetailsDrawerCloseEvent = (dispatch: any) => {
  dispatch(showTicketDetailsStatus(false));
  dispatch(setTicketId(''));
  dispatch(setTotalMessages([]));
  dispatch(setChatText(''));
  dispatch(setTotalMessagesCount(0));
  dispatch(setTicketDetails(ticket));
};
