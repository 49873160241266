/* eslint-disable @typescript-eslint/no-unused-vars */
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader } from '@chakra-ui/modal';
import { KeyValuePair } from 'dto/types/views/crm';
// import { KeyValuePair } from 'dto/types/views/crm';
// import FileUploader from 'sparkle-common/FileUploader';
// import UploadSingleLead from './UploadSingleLead';
import React from 'react';
import { useAppDispatch } from 'redux-store';
import { setProspectList } from 'redux-store/crm-onboard';
import FileUploader from '../FileUploader';
import MapVariablePopup from 'views/crm/features/MapVariablePopup';
// import { setProspectList } from 'redux-store/crm-onboard';
// import MapVariablePopup from 'views/crm/features/MapVariablePopup';
// import OnboardProspectPopup from 'views/crm/features/OnboardProspectPopup';

interface ModalDataType {
  email: string;
  multiResult: KeyValuePair[];
  result: string;
}

interface ModalComponentProps {
  isOpen?: boolean;
  onClose?: () => void;
  modalData?: ModalDataType;
}

const UploadOpportunitiesCsvPopup = (props: ModalComponentProps) => {
  //const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [mapOpen, setMapOpen] = React.useState(false);

  const { onClose, isOpen } = props;
  const [isOpenVerify, setIsOpenVerify] = React.useState(false);

  const handleCsvUpload = (data: any) => {
    dispatch(setProspectList(data));
    setMapOpen(true);
    // onClose();
  };

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={() => onClose()} size={'xl'}>
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent overflow={'auto'} className='dark:bg-darkThree'>
          <ModalHeader className="h- !px-0 !pb-0">
            <div className="space-y-1 px-4">
              <h5 className="ml-2.5 text-sm text-heading dark:text-purple-500">Upload Opportunities</h5>
            </div>
          </ModalHeader>
          <ModalBody className="!px-0 !pt-0">
            <div className=" w-full gap-4 px-6 pb-3 ">
              <FileUploader
                customClass="!h-[164px]  bg-white  hover:border-[#4318FF]  bg-[#F8F8FF] rounded-[10px] border-[1px] shadow-lg py-4 !h-[200px]"
                onUpload={handleCsvUpload}
              />
              {/* <OnboardProspectPopup
                isOpen={isOpenVerify}
                onClose={() => {
                  setIsOpenVerify(false);
                  onClose();
                }}
              /> */}

              <MapVariablePopup isOpen={mapOpen} onClose={() => setMapOpen(false)} setIsOpenVerify={setIsOpenVerify} />
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UploadOpportunitiesCsvPopup;
