import { CONSTANTS } from 'constants/constants'
import * as XLSX from 'xlsx'
import { GetEmailVerifierListParamsAPI } from '../api-handler'
import { EmailVerifierListDTO } from 'dto/types/views/email-verifier'
import { PostEmailVerifierUserIntegrationAPI } from '../api-handler/export-options'
import { toast } from 'react-toastify'
import { toastState } from 'utility/utils'

export const handleEmailVerifierResultDownload = async (report: any, fileName: string) => {
  const formatHeaders = (obj: any) => {
    const formattedObj: any = {}
    Object?.keys(obj)?.forEach(key => {
      // Convert camelCase or snake_case to words with spaces
      const formattedKey = key?.replace(/([a-z])([A-Z])/g, '$1 $2')?.replace(/_/g, ' ')
      formattedObj[formattedKey] = obj[key]
    })
    return formattedObj
  }

  const formattedReport = report?.map((row: any) => formatHeaders(row))
  const wb = XLSX.utils.book_new()
  const ws = XLSX.utils.json_to_sheet(formattedReport)
  XLSX.utils.book_append_sheet(wb, ws, 'Email Verification')
  const wbout = XLSX.write(wb, { bookType: 'csv', type: 'string' })
  const blob = new Blob([wbout], { type: 'text/csv;charset=utf-8;' })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = fileName
  document?.body?.appendChild(a)
  a.click()
  window.URL.revokeObjectURL(url)
  a.remove()
}

export const handleEmailVerifierResultOptions = async (selectedOpt: string, totalCount: number, smartStateUpdate: any) => {
  try {
    const { EDIT_ID } = CONSTANTS
    smartStateUpdate({ showOptions: false })
    const listId = sessionStorage.getItem(EDIT_ID)
    const listData = await GetEmailVerifierListParamsAPI(listId, totalCount)
    if (listData.data.data.length) {
      const report = listData.data.data?.map((item: EmailVerifierListDTO) => ({
        email: item?.email,
        result: item?.verify_details?.status?.result,
        ...item?.verify_details?.multi_status,
      }))
      if (selectedOpt === 'all') {
        handleEmailVerifierResultDownload(report, 'email_verification_report.csv')
      } else if (selectedOpt === 'valid') {
        const result = report.filter((item: { result: string }) => item.result === 'valid')
        handleEmailVerifierResultDownload(result, 'email_verification_valid_report.csv')
      } else if (selectedOpt === 'error') {
        const result = report.filter((item: { result: string }) => item.result === 'error')
        handleEmailVerifierResultDownload(result, 'email_verification_error_report.csv')
      } else if (selectedOpt === 'unknown') {
        const result = report.filter((item: { result: string }) => item.result === 'unknown')
        handleEmailVerifierResultDownload(result, 'email_verification_unknown_report.csv')
      } else if (selectedOpt === 'catchall') {
        const result = report.filter((item: { result: string }) => item.result === 'catch_all')
        handleEmailVerifierResultDownload(result, 'email_verification_catchall_report.csv')
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export const handleEmailVerifierPopupData = (event: React.ChangeEvent<HTMLInputElement>, chartData: any, totalSelectedCount: number, selectedRow: any, smartStateUpdate: any) => {
  const { id, checked } = event.target
  const { count } = chartData?.find((value: any) => value?.originalName === id)
  smartStateUpdate({
    totalSelectedCount: checked ? totalSelectedCount + count : totalSelectedCount - count,
    selectedRow: { ...selectedRow, [id]: checked },
  })
}

export const handleEmailVerifierPopupSelectAll = (alreadySelectAll: boolean, chartData: any, smartStateUpdate: any) => {
  let selectedValue = { valid: false, in_valid: false, error: false, unknown: false, catch_all: false }
  let count = 0
  if (alreadySelectAll) {
    selectedValue = { valid: false, in_valid: false, error: false, unknown: false, catch_all: false }
  } else {
    count = chartData.reduce((acc: number, value: any) => value.count + acc, 0)
    selectedValue = chartData.reduce((acc: any, value: any) => {
      acc[value.originalName] = value.count ? true : false
      return acc
    }, {})
  }
  smartStateUpdate({ totalSelectedCount: count, selectedRow: selectedValue })
}

export const handleEmailVerifierExport = async (selectedRow :any, subscriberListId :string, accountId :string, onClose :()=> void) => {
  try {
    const { EDIT_ID } = CONSTANTS
    const curated_list_id = sessionStorage.getItem(EDIT_ID)
    const curated_list_status = Object.keys(selectedRow).filter(item => !selectedRow[item])
    const data = { curated_list_id, subscriber_list_id: subscriberListId, curated_list_status, account_id: accountId }
    const response: any = await PostEmailVerifierUserIntegrationAPI(data)
    if (!response?.error) {
      onClose()
      toast.success('Exported to MailChimp', toastState.success)
    } else {
      toast.error('Unable to fetch tools list', toastState.error)
    }
  } catch (error) {
    console.error(error)
  }
}
 