/* eslint-disable react-hooks/exhaustive-deps */

import { useCallback, useEffect, useState } from 'react';
import CommonLayout from '../common-Layout/CommonLayout';
import InboxSkeleton from '../inbox-skeleton/InboxSkeleton';
import { SkeletonText } from '@chakra-ui/react';
import NotificationListRow from '../notification/NotificationListRow';
import InfiniteScrollComponent from 'components/common/infinityScroll';
import { useAppDispatch, useAppSelector } from 'redux-store';
import { AZ_BUCKET } from 'config/appConfig';
import { HandleClearConversationClickEvent } from 'shared/inbox/event-handler/unified-email-inbox-data';
import useDebounce from 'hooks/useDebounce';
import { FetchMoreNotificationDataScrollEvent, HandleNotificationFetchData } from 'shared/inbox/event-handler/notification-row';
// import SnoozPickerPopup from './feature/SnoozPickerPopup';
// import ClearEmailAlertPopup from './feature/ClearEmailAlertPopup';

const UnifiedNotificationInboxData = () => {
  const dispatch = useAppDispatch();
  const [mesagedata, setMessageData] = useState({
    totalCount: 0,
    data: [],
  });
  const [loading, setLoading] = useState(false);
  const [fetchDataTrigger, setFetchDataTrigger] = useState(false);
  const { clearSelectData, inbox_status, variant } = useAppSelector((state) => state.unifiedInboxData);
  // const [queryParams, setQueryParams] = useState({ limit: 25, offset: 0, search: '', filter: '' });
  const { searchQuery, limit, offset, filter } = useAppSelector((state) => state.unifiedInboxData);
  const colors = ['#4318FF', '#E7C100', '#7F56D9', '#7E4400', '#12B76A'];
  function getRandomIndex(min = 0, max = 5) {
    return Math.floor(Math.random() * (max - min) + min);
  }
  const debouncedSearchValue = useDebounce(searchQuery, 300);

  useEffect(() => {
    (async () => {
      HandleNotificationFetchData({
        searchQuery,
        limit,
        filter,
        setLoading,

        dispatch,
        setMessageData,
        inbox_status,
      });
    })();
  }, [fetchDataTrigger, inbox_status, variant, debouncedSearchValue, filter]);

  const fetchDataTriggerEvent = () => {
    setFetchDataTrigger((prev) => !prev);
  };

  const handleClearConversation = async () => {
    HandleClearConversationClickEvent({ clearSelectData, dispatch, fetchDataTriggerEvent });
  };

  const InboxFetch = useCallback(
    async (setIsFetching: any) => {
      mesagedata?.data?.length && mesagedata?.data?.length < mesagedata?.totalCount && FetchMoreNotificationDataScrollEvent(setIsFetching, setMessageData, searchQuery, limit, filter, offset, dispatch, inbox_status);
    },
    [mesagedata?.data?.length, debouncedSearchValue, offset, filter]
  );

  return (
    <CommonLayout handleClearConversation={handleClearConversation}>
      {loading ? (
        <InboxSkeleton />
      ) : (
        <div>
          {!mesagedata?.data?.length ? (
            <figure className="mt-10 flex  h-[62vh] flex-col items-center justify-center">
              <img src={AZ_BUCKET?.SPARKLE_ILLUSTRATION} alt="not found" className="w-1/2 md:w-1/3 lg:w-1/4" />
              <figcaption className="mt-3 font-bold text-heading">No Data Found</figcaption>
            </figure>
          ) : (
            <div className="h-[calc(100vh-180px)] overflow-auto ">
              <div className="rounded-md border">{mesagedata?.data?.map((messagelist: any, index) => messagelist?.variant === 'notification' && <NotificationListRow index={index} key={messagelist.uuid} inboxData={messagelist} fetchTrigger={fetchDataTriggerEvent} AvatarColor={colors[getRandomIndex()]} />)}</div>
              <div>{mesagedata?.totalCount >= 25 && mesagedata?.totalCount !== mesagedata?.data?.length && <InfiniteScrollComponent loadMore={InboxFetch} loader={<SkeletonText mt="4" noOfLines={4} spacing="4" skeletonHeight="2" />} />}</div>
            </div>
          )}
        </div>
      )}
    </CommonLayout>
  );
};

export default UnifiedNotificationInboxData;
