import React from 'react';
import { _isObject } from 'utility/utils';

interface nestedObject {
  [key: string]: 'single' | 'all' | nestedObject;
} // example -> {data:'all',queryparams:{sort:'single'}}

export const useSmartSetState = (setSingleState?: React.Dispatch<React.SetStateAction<any>>) => {
  return React.useCallback(
    (value: any, setMultipleState?: React.Dispatch<React.SetStateAction<any>>, type: 'single' | 'all' | nestedObject = 'single') => {
      // value - > {data:{count:0},query:{filter:"something",limit:25,offset:1}} or any type -> {data:single,query:all}
      const setState = setMultipleState || setSingleState;
      setState((prev: any) => {
        if (type === 'all') {
          return { ...prev, ...value };
        } else {
          return recursiveFunction(value, prev, type);
        }
      });
    },
    [setSingleState]
  );
};

const recursiveFunction = (value: any, oldPrev: any, type: any) => {
  const prev = { ...oldPrev };
  if (!_isObject(value)) {
    // single state like boolean
    return value;
  } else {
    // multi state like {data:[],count:0}
    for (const key in value) {
      if (_isObject(value[key]) && (_isObject(type) || type === 'single')) {
        const updateType = _isObject(type) ? type[key] : type;
        prev[key] = recursiveFunction(value[key], prev[key], updateType);
      } else {
        prev[key] = value[key];
      }
    }
  }
  return prev;
};
