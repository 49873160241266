/* eslint-disable react-hooks/exhaustive-deps */
import ArrowedTooltip from 'components/common/ArrowedTooltip';
import { CustomMenu } from 'components/common/customMenu';
import SearchBox from 'components/common/searchBox';
import { InboxGroupPropsDTO } from 'dto/initial-value/views/inbox';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { GoPencil } from 'react-icons/go';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { IoMdAdd } from 'react-icons/io';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { useAppDispatch, useAppSelector } from 'redux-store';
import { HadleRenameGroupClickEvent, HandleAddNewGroupClickEvent, HandleCreateGroupClickEvent, HandleDeleteInboxGroups, HandleDelteGroupsFromInboxClickEvent } from 'shared/inbox/event-handler/message-row';

const GroupTags = (props: InboxGroupPropsDTO) => {
  const { row, hanleDeleteGroupFromSenderList } = props;
  const dispatch = useAppDispatch();
  const [groupTags, setGroupTags] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [showOpt, setShowOpt] = useState(false);
  const [groups, setGroups] = useState([]);
  const [isEditVisible, setIsEditVisible] = useState(false);
  const [groupsToggleState, setGroupToggleState] = useState<any>({});
  const [toggleduuid, setToggledUUID] = useState('');
  const [position, setPosition] = useState(null);
  const [searchedGroup, setSearchedGroup] = React.useState([]);

  const { groupList, toogleGroupState, searchGroupList } = useAppSelector((state) => state.unifiedInboxData);

  React.useEffect(() => {
    setGroups(groupList || []);
    setGroupToggleState(toogleGroupState || []);
    setSearchedGroup(searchGroupList || []);
  }, [groupList, searchGroupList, toogleGroupState, row?.groups_list]);

  React.useEffect(() => {
    const groups = row?.groups_list?.filter((group: any) => group?.status !== 2);
    setGroupTags(groups?.reverse());
  }, [row?.groups_list]);

  // Delete function for group from Email Inbox
  const handleDelteGroup = async (uuids: string, val: any) => {
    // setGroupTags((prev) => {
    //   const updatedGroup = prev.filter((group) => group.uuid !== uuid);
    //   return updatedGroup;
    // });
    HandleDelteGroupsFromInboxClickEvent({ uuids, val, setGroupTags, groupList, row });
  };

  // Tool tip component
  const ToolTipComponent = ({ groups }: { groups: { id?: string; name?: string; uuid?: string; status?: number }[] }) => {
    return (
      <div className="relative flex flex-col">
        {groups.map(
          (
            group: {
              id?: string;
              name?: string;
              uuid?: string;
              status?: number;
            },
            index: number
          ) => (
            <>
              {
                <div key={index} className="group/tag flex items-center justify-between">
                  <span className="cursor-default p-1 text-start text-xs text-gray-100"> {group.name}</span>
                  <button className=" hidden px-2 text-sm hover:text-gray-500  group-hover/tag:block" onClick={() => handleDelteGroup(group.uuid, group)}>
                    x
                  </button>
                </div>
              }
            </>
          )
        )}
      </div>
    );
  };

  // On change function for search input
  const handleChange = async (event: any) => {
    event.stopPropagation();
    if (event.key === 'Enter') {
      // Check if the searched group name exist on groupList
      const groupName = groups.some((val) => val?.name?.toLowerCase() === event?.target?.value?.toLowerCase());
      if (groupName) return;

      const name = event?.target?.value?.toLowerCase().replace(/\b\w/g, (char: string) => char.toUpperCase());
      const params = { name: name, variant_id: row?.uuid };
      await handleCreateGroup(params);
      setIsOpen(false);
    } else {
      const searchedValue = event?.target?.value?.toLowerCase();
      const searchedData = groups.length && (searchedValue ? groups?.filter((group) => group?.name?.toLowerCase()?.includes(searchedValue)) : groups);
      setSearchedGroup(searchedData);
    }
  };

  // Create new group and assaign to email when user press enter keywork in search box
  async function handleCreateGroup(params: any) {
    HandleCreateGroupClickEvent({ setGroups, params, setSearchedGroup, setGroupTags, dispatch });
  }
  const tooltipRef = React.useRef(null);
  const toggleRef = React.useRef(null);

  // OnBlur functionality for tooltip
  React.useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target) && !toggleRef?.current?.contains(event.target)) {
        setShowOpt(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [tooltipRef]);

  // Click function for add new group name for email
  const handleClick = async (val: any) => {
    HandleAddNewGroupClickEvent({ val, dispatch, setGroupTags, row });
  };

  const parentRef = React.useRef(null);

  // Component for email to edit and rename
  const EditBtnComponent = (props: { val: any; index: number }) => {
    const { val, index } = props;
    const [value, setValue] = useState(val?.name);
    // Click function for three dot button
    const handlethreeDotBtn = async (event: any, uuid: string) => {
      event.stopPropagation();
      if (elementRef?.current) {
        const position = elementRef?.current?.getBoundingClientRect();
        setPosition(position);
      }
      try {
        setGroupToggleState((prev: any) => ({
          ...prev,
          [uuid]: { ...prev[uuid], show: true },
        }));
      } catch (error) {
        console.log('error', error);
      } finally {
        setIsEditVisible(true);
        setToggledUUID(uuid);
      }
    };

    const editComponentRef = React.useRef(null);
    const toggleComponentRef = React.useRef(null);
    const elementRef = React.useRef(null);
    // OnBlur functionality for edit component
    React.useEffect(() => {
      function handleClickOutside(event: MouseEvent) {
        if (editComponentRef?.current && !editComponentRef?.current.contains(event?.target) && !toggleComponentRef?.current?.contains(event.target)) {
          setIsEditVisible(false);
          setGroupToggleState((prev: any) => ({
            ...prev,
            [toggleduuid]: { ...prev[toggleduuid], show: false },
          }));
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [editComponentRef]);

    // Click function for group delete button
    const handleDelete = async (uuid: string) => {
      HandleDeleteInboxGroups({
        uuid,
        dispatch,
        setGroups,
        setSearchedGroup,
        setGroupTags,
        setGroupToggleState,
        setIsEditVisible,
        hanleDeleteGroupFromSenderList,
      });
    };
    // Click function for rename button
    const handleRename = (uuid: string) => {
      setGroupToggleState((prev: any) => ({
        ...prev,
        [uuid]: { show: false, rename: true },
      }));
      setIsEditVisible(false);
    };
    const inputRef = React.useRef(null);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event?.target?.value);
    };

    // Rename Group api hitting function
    const renameGroup = async (value: string) => {
      HadleRenameGroupClickEvent({ value, val, setGroupToggleState, setGroups, setSearchedGroup, dispatch, setGroupTags });
    };

    const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') renameGroup(value);
    };

    React.useEffect(() => {
      if (inputRef?.current) {
        inputRef?.current?.focus();
      }
    }, []);

    useEffect(() => {
      if (editComponentRef?.current) {
        const childTop = position?.bottom + 9;
        const parentTop = parentRef?.current?.getBoundingClientRect()?.top;
        const top = Math.floor(childTop - parentTop);
        editComponentRef.current.style.top = `${top}px`;
      }
    }, [editComponentRef, parentRef]);
    return (
      <div ref={toggleComponentRef}>
        {groupsToggleState[val?.uuid]?.rename ? (
          <input value={value} ref={inputRef} onChange={handleChange} id="rename-input" onKeyUp={handleEnter} className="h-8 w-full border pl-3 pr-1 text-xs font-bold text-textBlue outline-none focus:border-textBlue" onBlur={() => renameGroup(value)} />
        ) : (
          <button onClick={() => handleClick(val)} ref={elementRef} className={`group/threedots flex h-8 w-full cursor-pointer items-center justify-between whitespace-nowrap border-b pl-3 pr-1 text-xs text-textBlue duration-200 hover:bg-gray-50 ${groupsToggleState[val?.uuid]?.show ? 'bg-gray-100' : ''}`}>
            <span className="flex h-full w-32 items-center overflow-hidden text-ellipsis whitespace-nowrap  font-bold" id={`group-${index}`}>
              {val?.name}
            </span>
            <HiOutlineDotsHorizontal id={`group-editbtn-${index}`} className="hidden h-full w-7 p-1 font-bold text-gray-800 group-hover/threedots:block" onClick={(event) => handlethreeDotBtn(event, val?.uuid)} />
          </button>
        )}
        {groupsToggleState[val?.uuid]?.show && (
          <div ref={editComponentRef} className={`absolute -right-10 flex w-24 flex-col gap-2 rounded-md border bg-white p-1 shadow-md ${groupsToggleState[val?.uuid].show && 'pointer-events-auto block'}`}>
            <button id="delete-btn" className="flex items-center gap-2 rounded-md px-2 py-1 text-sm text-red-600 duration-300 hover:bg-gray-50" onClick={() => handleDelete(val?.uuid)}>
              <RiDeleteBin6Line />
              <span>Delete</span>
            </button>
            <button id="rename-btn" className="flex items-center gap-2 rounded-md px-2 py-1 text-sm duration-300 hover:bg-gray-50" onClick={() => handleRename(val?.uuid)}>
              <GoPencil />
              <span>Rename</span>
            </button>
          </div>
        )}
      </div>
    );
  };

  const onClose = () => {
    setIsOpen(false);
    setIsEditVisible(false);
    //
  };

  const AddBtn = () => (
    <div className="group/add rounded-full border border-dashed border-[#d0d5dd] p-[0.1rem]">
      <IoMdAdd size={14} color="" />
      <ArrowedTooltip data="Add Group" className="hidden !w-16 group-hover/add:block" />
    </div>
  );
  return (
    <div className="group-tags-inbox flex items-center gap-1" key={row?.uuid}>
      <CustomMenu
        open={isOpen}
        condition={!isEditVisible && !groupsToggleState?.[toggleduuid]?.rename}
        onOpen={() => setIsOpen(true)}
        onClose={() => onClose()}
        btnContent={<AddBtn />}
        // shadow-[14px_17px_40px_4px_rgba(112, 144, 176, 0.08)
        btnClassName={`Add-Group-${row?.uuid} w-5 border-none flex justify-center items-center text-gray-700  duration-300 cursor-pointer`}
        menuListClassName={`border !w-60 border-menuBorder rounded-md ${isEditVisible ? 'pointer-events-none' : ''}`}
      >
        <div ref={parentRef}>
          <SearchBox id="group-search-input" placeholder="Search or create groups" onChange={handleChange} />
          {searchedGroup?.length ? (
            <div className="mb-2 max-h-48 overflow-y-auto">
              {searchedGroup?.map((val, key) => (
                <EditBtnComponent key={key} val={val} index={key} />
              ))}
            </div>
          ) : (
            <span className="w-full pl-1 text-center text-xs text-gray-700">Press enter to create new group</span>
          )}
        </div>
      </CustomMenu>
      {groupTags?.slice(0, 2)?.map((val, key) => (
        <>
          {
            <div
              key={key}
              className={`group/delete relative flex items-center justify-between  px-2 py-[0.1rem] ${key === 0 ? 'bg-buttonBorder text-[#5925DC]' : 'bg-[#e8f4fe] text-blueSecondary'}  cursor-default 
              whitespace-nowrap  rounded-full text-xs font-semibold`}
            >
              <p id={`added-group-${key}`} className="!max-w-[100px] !overflow-hidden truncate !text-ellipsis !whitespace-nowrap ">
                {val.name}
              </p>
              <span id={`delete-group-${key}`} className={` hidden px-1 text-xs  opacity-0 transition-opacity  duration-200 ease-in-out hover:cursor-pointer group-hover/delete:inline-block group-hover/delete:opacity-100`} onClick={() => handleDelteGroup(val?.uuid, val)}>
                x
              </span>
            </div>
          }
        </>
      ))}
      {groupTags?.length > 2 && (
        <div className="relative" ref={toggleRef}>
          <span className="flex cursor-pointer items-center justify-center !rounded-full bg-gray-50 px-2 text-xs font-semibold hover:bg-gray-100" onClick={() => setShowOpt((prev) => !prev)}>
            +{groupTags.length - 2}
          </span>
          {showOpt && (
            <div ref={tooltipRef}>
              <ArrowedTooltip data={<ToolTipComponent groups={groupTags?.slice(2)} />} className="!-left-10" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default GroupTags;
