// import { BaseApi } from 'common/api/common/base-api';
// import { checkRolePermissionStatus } from 'common/utils/utility';
import { BaseApi } from 'api/services/base-api';
import React from 'react';
import { useState } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { IoMdMore } from 'react-icons/io';
import { useAppDispatch, useAppSelector } from 'redux-store';
import { getErrorMessage, setFlagStatus } from 'redux-store/custom';
import { checkRolePermissionStatus } from 'utility/utils';

interface RowData {
  id?: string;
  uuidRow?: string;
  status?: number;
  table?: any;
  setData?: any;
  rowData?: any;
  fetchList?: () => void;
}

const RowSettings = (props: RowData) => {
  const { id, uuidRow } = props;
  const [donotemailStatus, setDonotemailStatus] = React.useState(null);
  const { flagStatus, globalOpt } = useAppSelector((state) => state?.custom);

  const dispatch = useAppDispatch();
  const [showOptions, setShowOptions] = useState(false);
  const { environment } = useAppSelector((state) => state.globalsettings);

  const userRolePermissionListStoreData = useAppSelector((state) => state?.globalsettings?.globalConfiguration?.user_organization_role_details);
  React.useEffect(() => {
    const getRolePermissionStatus = async () => {
      const status = await checkRolePermissionStatus(userRolePermissionListStoreData, 'do_not_email_list', environment?.app_default_feature?.is_org_enbaled);
      setDonotemailStatus(status);
    };
    getRolePermissionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRolePermissionListStoreData]);


  const handleDelete = async (event: any) => {
    event?.stopPropagation();
    const brandId = sessionStorage.getItem('brandId')
    try {
      const params: any = {
        uuid: brandId,
        data_variant_id: [uuidRow]
      }
      const response = await BaseApi.delete('/donot/list/delete/variant/list', { data: params })
      if (response?.error) {
        dispatch(getErrorMessage(response?.message))
      } else {
        dispatch(setFlagStatus(!flagStatus))
      }
    } catch (error) {
      console.log('Donot email list single delete error', error)
    }
  };

  return (
    <>
      <div
        className={`relative mr-3 w-min cursor-pointer rounded-md py-0.5 ${showOptions && 'bg-[#4318FF0A]'
          }`}
        onMouseOver={(e) => e.stopPropagation}
        tabIndex={0}
        onBlur={() => {
          setShowOptions(false);
        }}
      >
        <IoMdMore
          className="z-0 text-lg text-gray-700"
          id={`edit-btn-${id}`}
          onClick={(event) => {
            event?.stopPropagation();
            setShowOptions(true);
          }}
        />
        {showOptions && !globalOpt && (
          <div className="absolute -left-[75px] z-10 flex flex-col overflow-hidden rounded-md border bg-white">
            {(donotemailStatus?.organization_owner || donotemailStatus?.organization_access_list?.delete)&&<div
              className="delete_list group flex h-8 cursor-default items-center gap-2 px-3 font-medium transition-all duration-150 hover:bg-brand-500 hover:text-white"
              onClick={handleDelete}
            >
              <AiFillDelete className="text-brand-500 group-hover:text-white" />
              <h5 className="text-xs">Delete</h5>
            </div>}
          </div>
        )}
      </div>
    </>
  );
};

export default RowSettings;
