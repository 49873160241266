import { Avatar } from "@chakra-ui/react";
import { MdLinkOff, MdLink, MdCloudQueue } from "react-icons/md";
import ArrowedTooltip from "components/common/ArrowedTooltip";
import CommonRowSettings from "components/common/commonRowSetting/CommonRowSettings";
import GroupTagsEmail from "views/sender-accounts/components/GroupTagsEmail";

export const TableColumnData = ({ columnHelper, table }: any) => {
    const columnData = [
        columnHelper.accessor('id', {
            id: 'id',
            header: () => (
                <div className="ml-6 flex items-center">
                    <input
                        type="checkbox"
                        id="select-all"
                        checked={table.getIsAllRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()}
                        className="size-[0.9rem] cursor-pointer accent-brand-500 dark:accent-purple-600"
                    />
                </div>
            ),
            cell: ({ row }: any) => (
                <div className="ml-6 flex items-center">
                    <input
                        type="checkbox"
                        id={`row-${row.id + 1}`}
                        checked={row.getIsSelected()}
                        disabled={!row.getCanSelect()}
                        onChange={row.getToggleSelectedHandler()}
                        className="size-[0.9rem] cursor-pointer accent-brand-500 dark:accent-purple-600"
                    />
                </div>
            ),
        }),
        columnHelper.accessor('name', {
            id: 'name',
            enableSorting: false,
            header: () => <div className="select-none font-semibold">Name</div>,
            cell: (info: any) => {
                return (
                    <div className="flex w-full items-center gap-2">
                        <Avatar className="!text-white" size={'sm'} name={info.getValue() ? info.getValue() : info.row.original?.first_name} bg="teal.500" src={info.row.original?.picture} />
                        <div className="flex w-32 flex-col">
                            {/* Name section with its tooltip */}
                            <div className="group relative">
                                <p className="w-full truncate text-sm font-bold text-heading dark:text-white">
                                    {info.getValue() ? info.getValue() : info.row.original?.first_name + ' ' + info.row.original?.last_name}
                                </p>
                                {info.getValue()?.length > 10 && (
                                    <ArrowedTooltip
                                        data={<>{info.getValue() ? info.getValue() : info.row.original?.first_name + ' ' + info.row.original?.last_name}</>}
                                        className="-mt-1 ml-7 hidden !w-fit !px-4 group-hover:block"
                                    />
                                )}
                            </div>

                            {/* Email section with its tooltip */}
                            <div className="group relative">
                                <p className="w-full truncate text-xs text-column">{info?.row?.original?.account_details?.email}</p>
                                {info?.row?.original?.account_details?.email?.length > 18 && (
                                    <ArrowedTooltip data={<>{info?.row?.original?.account_details?.email}</>} className="-mt-1 ml-7 hidden !w-fit !px-4 group-hover:block" />
                                )}
                            </div>
                        </div>
                    </div>
                );
            },
        }),
        columnHelper.accessor('groups_list', {
            id: 'groups_list',
            enableSorting: false,
            header: () => <div className=" column-groupHeader select-none font-semibold">Email groups</div>,
            cell: (row: any) => {
                // BaseApi.post('groups', [])
                return (
                    // <p>sssssss</p>
                    <GroupTagsEmail
                        key={row?.row?.original?.uuid}
                        groupTags={row.getValue()}
                        row={row?.row?.original}
                        fetchList={()=>{}}
                        // hanleDeleteGroupFromSenderList={hanleDeleteGroupFromSenderList}
                        hanleDeleteGroupFromSenderList={()=>{}}

                    />
                );
            },
        }),
        columnHelper.accessor('account_details', {
            id: 'mailer_status',
            enableSorting: false,
            header: () => <div className=" column-mailStatusHeader relative select-none font-semibold">Status</div>,
            cell: (info: any) => {
                return (
                    <div className="ml-14 flex-col">
                        {info?.row?.original?.account_details?.mailer_status === 0 && (
                            <div
                                // onClick={() => handleUpdateAccountStatus('connect', info?.row?.original?.uuid)}
                                className="flex w-min cursor-pointer items-center gap-1 rounded-full bg-[#FEF3F2] px-2 py-1 text-[0.7rem] font-semibold text-[#d32318]"
                            >
                                <MdLinkOff />
                                <span className={`status-index-${info?.row?.id}`}>Disconnected</span>
                            </div>
                        )}
                        {info?.row?.original?.account_details?.mailer_status === 1 && (
                            <div
                                // onClick={() => handleUpdateAccountStatus('disconnect', info?.row?.original?.uuid)}
                                className="flex w-min cursor-pointer items-center gap-1 rounded-full bg-[#ECFDF3] px-2 py-1 text-[.7rem] font-semibold text-[#029A48]"
                            >
                                <MdLink />
                                <span className={`status-index-${info?.row?.id}`}>Connected</span>
                            </div>
                        )}
                        {info?.row?.original?.account_details?.mailer_status === 2 && (
                            <div
                                // onClick={() => handleUpdateAccountStatus('connect', info?.row?.original?.uuid)}
                                className="flex w-min cursor-pointer items-center gap-1 rounded-full bg-[#ECFDF3] px-2 py-1 text-[.65rem] font-semibold text-[#029A48]"
                            >
                                <MdLink />
                                <span className={`status-index-${info?.row?.id}`}>Paused</span>
                            </div>
                        )}
                    </div>
                );
            },
        }),
        columnHelper.accessor('campaign_settings', {
            id: 'limit',
            header: () => <div className="column-limitHeader select-none font-semibold">Daily limit</div>,
            cell: (info: any) => (
                <p className={`text-sm font-semibold`}>{`${info?.row?.original?.campaign_settings?.limit_sent_per_day}/${info?.row?.original?.campaign_settings?.daily_limit}`}</p>
            ),
        }),
        columnHelper.accessor('account_details', {
            id: 'health_score',
            header: () => <div className="select-none font-semibold">Health Score</div>,
            cell: (info: any) => (
                <div
                    className={`mx-auto w-min rounded-full px-2.5 py-1 text-xs text-white ${info?.row?.original?.account_details?.email_health_score >= 90
                        ? 'bg-green-600'
                        : info?.row?.original?.account_details?.email_health_score >= 70
                            ? 'bg-orange-600'
                            : 'bg-red-600'
                        }`}
                >
                    {info?.row?.original?.account_details?.email_health_score || 0}%
                </div>
            ),
        }),
        columnHelper.accessor('account_details', {
            id: 'custom_tracking',
            header: () => <div className="select-none font-semibold">Custom tracking</div>,
            cell: (info: any) => {
                return <MdCloudQueue className="mx-auto" />;
            },
        }),
        columnHelper.accessor('uuid', {
            id: 'edit',
            header: () => <div className="relative w-min font-thin">{''}</div>,
            cell: (row: any) => {
                return (
                    <CommonRowSettings buttonClassName={'custom_row_btn_0'} uuidRow={row?.row?.original?.uuid} rowData={row.row.original} />
                );
            },
        }),
    ];
    return columnData;
}