import React from 'react';
import { SessionStorage } from 'services/session.storage.service';
import { useAppSelector } from 'redux-store';
import { useSmartSetState } from 'hooks/useSmartSetState';
import { teamStateIV } from 'dto/initial-value/views/team';
import { TeamStateDTO } from 'dto/types/views/team';
import { GetRolePermissionStatusForTeamsEvent, HandleSwitchStateEvent } from 'shared/team/event-handler/index-events';

const WorkspaceManagementTable = React.lazy(() => import('../../workspace-management/pages'));
const UserManagementTable = React.lazy(() => import('../../user-management/pages'));
const RoleManagementTable = React.lazy(() => import('../../role-management/pages'));

const Team = () => {
  const [teamState, setTeamState] = React.useState<TeamStateDTO>(teamStateIV);
  const { roleStatus, userStatus, workspaceStatus, switchState } = teamState;
  const smartStateUpdate = useSmartSetState(setTeamState);

  const ActiveTab = SessionStorage.getString('Active_tab');
  const { environment } = useAppSelector((state) => state.globalsettings);
  const userRolePermissionListStoreData = useAppSelector((state) => state?.globalsettings?.globalConfiguration?.user_organization_role_details);

  const handleSwitchState = (state: number) => HandleSwitchStateEvent({ state, smartStateUpdate });

  React.useEffect(() => {
    GetRolePermissionStatusForTeamsEvent({ ActiveTab, smartStateUpdate, environment, userRolePermissionListStoreData, teamState });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRolePermissionListStoreData]);

  return (
    <div className="min-h-[82vh] overflow-y-auto ">
      <div className="sticky top-[-140px] mt-[8px] w-full rounded-tl-lg rounded-tr-lg bg-white py-2 dark:!border-darkBorder dark:!bg-darkOne dark:!text-white">
        <div className=" ml-4 mt-0  flex gap-5 text-sm font-semibold">
          {(userStatus?.organization_owner || userStatus?.organization_access_list?.view) && (
            <div className="user-management-nav-btn relative cursor-pointer text-center" id="user-management-tab" onClick={() => handleSwitchState(1)}>
              <h4 className={`mb-2 ${switchState === 1 ? '' : 'text-gray-700'}`}>User Management</h4>
              <div className={`absolute bottom-0 h-[.1600rem] w-full rounded-tl-md rounded-tr-md bg-navBlue ${switchState === 1 ? 'bolck' : 'hidden'}`} />
            </div>
          )}
          {(roleStatus?.organization_owner || roleStatus?.organization_access_list?.view) && (
            <div className="role-management-nav-btn relative cursor-pointer text-center" id="role-management-tab" onClick={() => handleSwitchState(2)}>
              <h4 className={`mb-2 ${switchState === 2 ? '' : 'text-gray-700'}`}>Role Management</h4>
              <div className={`absolute bottom-0 h-[.1600rem] w-full rounded-tl-md rounded-tr-md bg-navBlue ${switchState === 2 ? 'bolck' : 'hidden'}`} />
            </div>
          )}
          {(workspaceStatus?.organization_owner || workspaceStatus?.organization_access_list?.view) && (
            <div className="workspace-management-nav-btn relative cursor-pointer text-center" id="workspace-management-tab" onClick={() => handleSwitchState(3)}>
              <h4 className={`mb-2 ${switchState === 3 ? '' : 'text-gray-700'}`}>Workspace Management</h4>
              <div className={`dark:bg- absolute bottom-0 h-[.1600rem] w-full rounded-tl-md rounded-tr-md bg-navBlue ${switchState === 3 ? 'bolck' : 'hidden'}`} />
            </div>
          )}
        </div>
        <hr className="dark:border-gray-800" />
      </div>
      {switchState === 1 && <UserManagementTable />}
      {switchState === 2 && <RoleManagementTable />}
      {switchState === 3 && <WorkspaceManagementTable />}
    </div>
  );
};

export default Team;
