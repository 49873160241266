import * as Yup from 'yup';

export const blacklistValidationSchema = Yup.object({
  groupname: Yup.string().trim().required('Group Name is required').min(5, 'Group Name must be at least 5 characters long'),
  black_list: Yup.array().min(1, 'The array must contain at least one item').required('Ip or Domain is required'),
  frequency: Yup.object({
    value: Yup.string().required('Value is required').typeError('Value must be a string'),
    type: Yup.number().required('Type is required').typeError('Type must be a number'),
  }).required('Frequency is required'),
});
