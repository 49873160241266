import { createSlice } from '@reduxjs/toolkit';
const initialState: any = {
  source: '',
  search: '',
  filter: '',
  column_visibility: [],
  table_row_action_items_list: [],
};
const commonTableListSlice = createSlice({
  name: 'commontable',
  initialState,
  reducers: {
    setTableListData: (state, action) => {
      Object.assign(state, action.payload);
    },
  },
});

export const { setTableListData } = commonTableListSlice.actions;
export default commonTableListSlice.reducer;
