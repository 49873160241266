import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import Button from 'components/common/Button';
import { FaPlus } from 'react-icons/fa6';
import React, { useEffect, useRef, useState } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { BiChevronDown, BiChevronRight } from 'react-icons/bi';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FiSearch } from 'react-icons/fi';
import { MdModeEdit } from 'react-icons/md';
import { RiArrowUpDownFill } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { useAppSelector } from 'redux-store';
import { checkRolePermissionStatus, toastState } from 'utility/utils';
import { BaseApi } from 'api/services/base-api';
import DeleteConfirmModal from 'components/common/crm/DeleteConfrimModal';
import EditorPopup from 'components/common/crm/EditorPopup';
import { AZ_BUCKET } from 'config/appConfig';

interface modalTypeDTO {
  isOpen: boolean;
  uuid?: string;
}
const Notes = () => {
  const searchRef = useRef(null);
  const { opportunityInboxViewData } = useAppSelector((state) => state.crm);
  const { opportunity_id } = opportunityInboxViewData;
  const [openCreateModal, setOpenCreateModal] = useState<modalTypeDTO>({ isOpen: false, uuid: '' });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState({ uuid: '', isOpen: false });
  const [notesData, setNotesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [note, setNote] = useState('');
  const [success, setSuccess] = useState(false);
  const [rowIndex, setRowIndex] = useState([]);

  const [sort, setSort] = useState({ text: 'New-Old', query: '_id%dsc', search: '' });
  const [roleStatus, setRoleStatus] = useState(null);
  const userRolePermissionListStoreData = useAppSelector((state) => state?.globalsettings?.globalConfiguration?.user_organization_role_details);
  const custom = useAppSelector((state) => state?.custom);
  const { environment } = useAppSelector((state) => state.globalsettings);

  useEffect(() => {
    const getRolePermissionStatus = async () => {
      const status = await checkRolePermissionStatus(userRolePermissionListStoreData, 'crm', environment?.app_default_feature?.is_org_enbaled);
      setRoleStatus(status);
    };
    getRolePermissionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom?.globalDependencyLoading,userRolePermissionListStoreData]);
  const fetchNotesData = async () => {
    try {
      if (opportunity_id) {
        const response = await BaseApi.get(`/crm/notes/${opportunity_id}?sort=${sort?.query}&search=${sort?.search}`);
        if (response?.error) {
          toast.error('Failed to fetch Notes');
        }
        setNotesData(response?.data);
      }
    } catch (error) {
      toast.error('Failed to fetch Notes');
      console.log(error);
    }
  };

  const handleNotesDelete = async (id: string) => {
    if (id) {
      try {
        const response = await BaseApi.delete(`/crm/notes/`, { data: { delete_ids: [id] } });
        if (response.error) {
          toast.error(response.message);
        } else {
          toast.success('Note deleted successfully', toastState.success);
        }
      } catch (error) {
        console.error('Error deleting note:', error);
        toast.error('Failed to delete note');
      }
      setIsDeleteModalOpen({ uuid: '', isOpen: false });
    }
  };

  const formateDate = (dateStr: string) => {
    const date = new Date(dateStr);
    const options: Intl.DateTimeFormatOptions = {
      month: 'long',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    const formattedDate = date.toLocaleString('en-US', options);
    // Output: "June 28, 5:47 PM"
    return formattedDate;
  };

  const formatMonth = (monthNumber: string) => {
    const monthNames = ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return monthNames[parseFloat(monthNumber) - 1];
  };

  const toggleIndexInArray = (index: number) => {
    setRowIndex((prevArr) => {
      if (prevArr.includes(index)) {
        return prevArr.filter((item) => item !== index);
      } else {
        return [...prevArr, index];
      }
    });
  };
  const handleNoteChange = (value: string) => {
    const checkValue = stripHtmlTags(value);
    if (checkValue) {
      setNote(value);
    }
  };

  const stripHtmlTags = (html: string) => {
    return html.replace(/<[^>]*>?/gm, '');
  };
  const handleOnclickNoteCreate = async () => {
    setIsLoading(true);
    if (opportunity_id) {
      try {
        const payload = {
          opportunity_id,
          note: stripHtmlTags(note),
        };
        const response = await BaseApi.post(`/crm/notes`, payload);
        if (response.error) {
          toast.error(response.message);
        } else {
          setSuccess((prev) => !prev);
          toast.success('Note created successfully', toastState.success);
        }
      } catch (error) {
        console.error('Error creating note:', error);
        toast.error('Failed to create note');
      } finally {
        setIsDeleteModalOpen({ uuid: '', isOpen: false });
        setIsLoading(false);
        setOpenCreateModal({ isOpen: false, uuid: '' });
      }
    }
  };

  const handleOnclickNoteEdit = async (uuid: string) => {
    if (uuid) {
      try {
        const payload = {
          uuid,
          note: stripHtmlTags(note),
        };
        const response = await BaseApi.patch(`/crm/notes`, payload);
        if (response.error) {
          toast.error(response.message);
        } else {
          setSuccess((prev) => !prev);
          toast.success('Note created successfully', toastState.success);
        }
      } catch (error) {
        console.error('Error creating note:', error);
        toast.error('Failed to create note');
      } finally {
        setIsDeleteModalOpen({ uuid: '', isOpen: false });
        setIsLoading(false);
        setOpenCreateModal({ isOpen: false, uuid });
      }
    }
  };

  const handleListSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSort((prev) => ({
      ...prev,
      search: event?.target?.value.trim().toLowerCase(),
    }));
  };
  useEffect(() => {
    fetchNotesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleteModalOpen?.isOpen, sort, success, sort.search, opportunity_id]);
  return (
    <div>
      <div>
        <header className="z-10 mt-[1px] flex justify-between border bg-white p-3">
          <div className="flex gap-3">
            <div
              className="flex items-center gap-2 rounded-md border px-3 py-1.5 outline outline-0 focus-within:outline-2 focus-within:outline-brand-400 md:w-[13rem] lg:w-[18rem]"
              onClick={() => searchRef.current.focus()}
            >
              <FiSearch className="size-4 cursor-pointer" />
              <input
                ref={searchRef}
                type="text"
                placeholder="Search"
                id="campaign-search-input"
                className="w-full text-sm font-bold text-gray-800 caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:bg-navy-700 dark:placeholder:text-white "
                onChange={handleListSearch}
              />
            </div>
          </div>
          <div className="flex gap-4">
            {(roleStatus?.organization_access_list?.add || roleStatus?.organization_owner) && (
              <Button id="create-notes-btn" name="Create Notes" LeftIcon={<FaPlus />} onClick={() => setOpenCreateModal({ isOpen: true })} />
            )}
            <div
              className="flex cursor-pointer items-center gap-1.5"
              onClick={() =>
                setSort((prev) =>
                  prev.query === '_id%asc' ? { text: 'New-Old', query: '_id%dsc', search: prev.search } : { text: 'Old-New', query: '_id%asc', search: prev.search }
                )
              }
            >
              <RiArrowUpDownFill className="size-4 text-column" />
              <h5 className="text-[0.8rem] text-column">{sort.text}</h5>
            </div>
          </div>
        </header>

        {notesData?.length ? (
          notesData?.map((item) => (
            <main className="h-[32rem] space-y-4 overflow-auto p-4" key={item?.uuid}>
              {/* TimeStamp */}
              <div className="mt-1 flex items-center justify-center gap-2">
                <hr className="border-t-[#EAECF0 w-full" />
                <p className="text-nowrap text-center text-xs text-column">
                  {formatMonth(item.month)} {item.year}
                </p>
                <hr className="w-full border-t-[#EAECF0]" />
              </div>
              {/* Lead generation */}
              {item?.messages.map((data: any, index: number) => (
                <div className="flex flex-col gap-4 rounded-md border border-[#EAECF0] p-3">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-3">
                      {rowIndex.includes(index) ? (
                        <BiChevronDown className={`notes-show-btn-${index} size-8 cursor-pointer text-[#A3AED0]`} onClick={() => toggleIndexInArray(index)} />
                      ) : (
                        <BiChevronRight className="size-8 cursor-pointer text-[#A3AED0]" onClick={() => toggleIndexInArray(index)} />
                      )}
                      <h5 className="text-[0.93rem] font-semibold text-heading">
                        Note by {data?.added_by?.first_name} {data?.added_by?.last_name}
                      </h5>
                    </div>
                    <div className="flex items-center gap-2">
                      <h5 className="text-[0.6rem] font-medium text-paused-color">{formateDate(data?.cdate)}</h5>
                      {(roleStatus?.organization_access_list?.edit || roleStatus?.organization_owner || roleStatus?.organization_access_list?.delete) && (
                        <Menu>
                          <MenuButton _hover={{ bg: '#fff' }} _active={{ bg: '#fff' }} bg="#fff" className={` bg-white !px-2 !text-base !font-semibold notes-menu-btn-${index} `}>
                            <BsThreeDotsVertical className="text-sm text-[#8F9BBA]" />
                          </MenuButton>
                          <MenuList minW="0" w={'122px'} className="!h-[80px] !w-[122px] !rounded-lg bg-white !p-0 shadow-lg  " style={{ width: '120px', height: '109px' }}>
                            {(roleStatus?.organization_access_list?.edit || roleStatus?.organization_owner) && (
                              <MenuItem
                                onClick={() => {
                                  setOpenCreateModal({ isOpen: true, uuid: data.uuid });
                                  setNote(data.note);
                                }}
                                className="notes-edit-btn flex items-center space-x-2 rounded-t-lg !py-2 !pe-2 !ps-4 text-sm !text-[#2B3674] hover:rounded-t-lg hover:!bg-[#4318FF] hover:!text-white "
                              >
                                <MdModeEdit className="text-lg" />
                                <span>Edit</span>
                              </MenuItem>
                            )}

                            {(roleStatus?.organization_access_list?.delete || roleStatus?.organization_owner) && (
                              <MenuItem
                                onClick={() => setIsDeleteModalOpen({ uuid: data.uuid, isOpen: true })}
                                className="notes-delete-btn flex items-center space-x-2 rounded-b-lg !py-2 !pe-2 !ps-4 text-sm !text-[#2B3674] hover:rounded-b-lg hover:!bg-[#4318FF] hover:!text-white"
                              >
                                <AiFillDelete className=" text-lg" />
                                <span>Delete</span>
                              </MenuItem>
                            )}
                          </MenuList>
                        </Menu>
                      )}
                    </div>
                  </div>
                  <div className="relative w-[38rem] text-justify">
                    <p className=" whitespace-pre text-[0.8rem] font-medium leading-4 text-heading">
                      {rowIndex.includes(index)
                        ? data?.note.replace(/&nbsp;/g, ' ')
                        : `${data?.note.replace(/&nbsp;/g, ' ').length > 150 ? data?.note.replace(/&nbsp;/g, ' ').substring(0, 150) + '...' : data?.note.replace(/&nbsp;/g, ' ')}`}
                    </p>
                    {rowIndex.includes(index) ? null : (
                      <div className="absolute -bottom-1 left-0 h-8 w-full bg-gradient-to-b from-[rgba(255,255,255,0)] to-[rgba(255,255,255,1)]" />
                    )}
                  </div>
                </div>
              ))}
            </main>
          ))
        ) : (
          <figure className="mt-10 flex flex-col items-center justify-center">
            <img src={AZ_BUCKET?.SPARKLE_ILLUSTRATION} alt="not found" />
            <figcaption className="mt-3 font-bold text-heading">Notes you add appear here</figcaption>
          </figure>
        )}
      </div>
      <DeleteConfirmModal
        isOpen={isDeleteModalOpen.isOpen}
        onClose={() => {
          setIsDeleteModalOpen({ uuid: '', isOpen: false });
        }}
        handleDelete={() => handleNotesDelete(isDeleteModalOpen.uuid)}
      />
      {openCreateModal?.uuid ? (
        <EditorPopup
          handleMessageOnChange={handleNoteChange}
          title="Edit Note"
          isOpen={openCreateModal.isOpen}
          onClose={() => {
            setOpenCreateModal({ isOpen: false, uuid: '' });
            setNote('');
          }}
          message={note}
          isLoading={isLoading}
          submitText="Apply changes"
          type={'note'}
          disableCondition={note === '' ? true : false}
          onSubmit={() => handleOnclickNoteEdit(openCreateModal.uuid)}
        />
      ) : (
        <EditorPopup
          handleMessageOnChange={handleNoteChange}
          title="Create Note"
          disableCondition={note === '' ? true : false}
          isOpen={openCreateModal.isOpen}
          onClose={() => {
            setOpenCreateModal({ isOpen: false, uuid: '' });
            setNote('');
          }}
          message={note}
          type={'note'}
          isLoading={isLoading}
          submitText="Create Note"
          onSubmit={() => handleOnclickNoteCreate()}
        />
      )}
    </div>
  );
};

export default Notes;
