import Button from 'components/common/Button';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'redux-store';
import { getPipelineDetail } from 'redux-store/crm-onboard';
import React from 'react';
import Input from 'components/common/Input';
import { BaseApi } from 'api/services/base-api';
import OnboardBackground from 'components/common/crmOnboard/OnbardBackground';
import { AZ_BUCKET } from 'config/appConfig';

const OnboardSelectBrand = () => {
  const [selectedBrand, setSelectedBrand] = React.useState('');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const brandId = useAppSelector((state) => state.crmOnboard.brand);

  React.useEffect(() => {
    const fetchList = async () => {
      try {
        const response = await BaseApi.get(`/brand/${brandId}`);
        if (response.data) {
          // setSearchData(response.data?.data);
          setSelectedBrand(response.data?.name);
          // if (response.data?.data.length) {
          // }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick = async () => {
    try {
      const response = await BaseApi.get(`crm/pipeline/list/${brandId}`);
      if (response?.data) {
        const pipelineName = await response?.data?.pipeline_name.replace(/_/g, ' '); // Replace underscores with spaces
        const pipelinePayload = {
          pipelinename: pipelineName,
          pipelineid: response?.data?.uuid,
        };
        dispatch(getPipelineDetail(pipelinePayload));
      }
    } catch (error) {
      console.log(error);
    } finally {
      navigate('/crm/onboard/upload/prospect');
    }
  };

  return (
    <>
      <OnboardBackground count={1}>
        <div className="flex justify-between">
          <div className="flex w-3/5 flex-col justify-start gap-8 py-16">
            <div>
              <p className="text-[1.8125rem] font-bold text-heading">Choose a template to get started</p>
              <p className="mt-3 w-[75%] text-sm font-medium text-heading">
                We'll give you a head start by setting up your smart CRM with basics likes pipelines, report dashboards, custom views, and more
              </p>
            </div>
            <div>
              <p className="text-lg font-bold text-heading">Choose a pipeline style for you needs:</p>
              <p className="text-sm font-medium text-heading">You can edit any of these changes later.</p>
              <div className="mt-4 flex gap-3">
                <Button type="button" name="Sales Pipeline" color="text-column" fontSize="sm" disable={true} customClass="bg-white text-column font-medium text-medium" />
                <Button type="button" name="Marketing" color="text-column" fontSize="sm" disable={true} customClass="bg-white text-column font-medium text-medium" />
                <Button
                  type="button"
                  name="Custom pipeline"
                  color="!text-blueSecondary"
                  fontSize="sm"
                  customClass="bg-white border-blueSecondary text-blueSecondary bg-[#f2f1fb] font-medium text-medium"
                />
              </div>
            </div>
            <div>
              <p className="mt- text-lg font-bold text-heading">Brand</p>
              <p className="w-7/12 text-sm font-medium text-heading">The following brand is connected to this pipeline. Each brand has its own unique pipeline</p>
              <div className="w-2/4">
                <Input
                  value={selectedBrand}
                  // onChange={(e) => handleBrandChange((e)=> e.target.value)}
                  placeholder="Enter Brand Input"
                  customClass="!w-96 mr-2 mt-1"
                  disabled={true}
                />
              </div>
              <Button
                name="Get Started"
                id="get-started-btn"
                // disable={selectedBrand ? false : true}
                px="px-10"
                py="py-3"
                fontSize="xs"
                customClass={`mt-9 ${'button_next_onboard'}`}
                onClick={() => onClick()}
              />
            </div>
          </div>
          <div className="mt-[-1.5rem] h-full ">
            <img src={AZ_BUCKET?.SPARKLE_CRM_ONBOARD_VIEW} alt="crm-pipeline" />
          </div>
        </div>
      </OnboardBackground>
    </>
  );
};

export default OnboardSelectBrand;

