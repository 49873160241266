/* eslint-disable react-hooks/exhaustive-deps */
import Button from 'components/common/Button';
import React from 'react';
import Input from 'components/common/Input';
import { Card, Stack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { AiFillDelete } from 'react-icons/ai';
import { IoMdMore } from 'react-icons/io';
import { useAppDispatch, useAppSelector } from 'redux-store';
import { addPipelineStage, deletePipelineStage, setPipelineStages, updatePipelineStage, setProspectListResult, removePipelineData } from 'redux-store/crm-onboard';
import { toast } from 'react-toastify';
import { setBrandId, setIsSwitchPipeline, setPipelineId } from 'redux-store/crm';
import { BaseApi } from 'api/services/base-api';
import OnboardBackground from 'components/common/crmOnboard/OnbardBackground';
import { toastState, jwtEncrypt } from 'utility/utils';

const OnboardDefineStages = () => {
  const dispatch = useAppDispatch();
  const pipelineName = useAppSelector((state) => state?.crmOnboard?.pipelinename);
  const brandId = useAppSelector((state) => state.crmOnboard.brand);
  const pipelineId = useAppSelector((state) => state.crmOnboard.pipelineid);
  const stagesstate = useAppSelector((state) => state.crmOnboard.stages);
  const colors = ['#4318FF', '#E7C100', '#7F56D9', '#7E4400', '#12B76A'];
  const navigate = useNavigate();
  const containerRef = React.useRef(null);
  const [stages, setStages] = React.useState([{ text: '', color: '#4318FF' }]);

  const fetchList = async () => {
    try {
      const response = await BaseApi.get(`/crm/pipeline/stage/${pipelineId}`);
      if (response.data) {
        if (response?.data?.length > 0) {
          await dispatch(setPipelineStages(response.data));
        } else {
          const addPayload = {
            color_code: await colors[stages.length % colors.length],
            pipeline_id: pipelineId,
            stage_name: '',
          };
          dispatch(addPipelineStage(addPayload));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    fetchList();
  }, []);
  React.useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [stages]);

  const addStage = async () => {
    setStages([...stages, { text: '', color: colors[stages.length % colors.length] }]);

    const addPayload = {
      color_code: await colors[stages.length % colors.length],
      pipeline_id: pipelineId,
      stage_name: '',
    };
    dispatch(addPipelineStage(addPayload));
  };

  const handleUpdateStage = React.useCallback(
    (index: number, field: string, value: string | number) => {
      dispatch(updatePipelineStage({ index, stage: { [field]: value } }));
    },
    [dispatch]
  );

  const deleteStage = async (index: number, stage: any) => {
    // const Stages = await stagesstate.filter((_, i) => i !== index);
    dispatch(deletePipelineStage(index));
    if (stage?.uuid) {
      const response = await BaseApi.delete(`/crm/pipeline/stage`, { data: { delete_ids: [stage.uuid] } });
      if (response.error) {
        toast.error(`Error during delete`, toastState.error);
      }
    }
  };

  const removeDuplicates = (stages: any) => {
    const seen = new Set();
    return stages.filter((stage: any) => {
      const duplicate = seen.has(stage.stage_name);
      seen.add(stage.stage_name);
      return !duplicate;
    });
  };

  const onClick = async () => {
    let uniqueStages = await removeDuplicates(stagesstate);
    // Validation: Check if any stage_name is empty
    const hasEmptyStageName = uniqueStages.some((stage: any) => !stage.stage_name || stage.stage_name.trim() === '');
    if (hasEmptyStageName) {
      toast.error('Stage name cannot be empty.', toastState.error);
      return;
    }

    try {
      const stagesToUpdate = await uniqueStages.filter((stage: any) => stage.uuid);
      const maxOrderNumber = stagesToUpdate.length > 0 ? stagesToUpdate.reduce((maxOrder: any, stage: any) => Math.max(maxOrder, stage.order), 0) : 0;
      const stagesToInsert = await uniqueStages.filter((stage: any) => !stage.uuid);
      const updatedStagesToInsert = await stagesToInsert.map((stage: any, index: number) => ({
        ...stage,
        order: maxOrderNumber + index + 1,
        brand_id: brandId,
      }));

      await BaseApi.patch(`/crm/pipeline`, { uuid: pipelineId, is_first_visit: false });

      // let maxOrder = stagesstate.reduce((max: any, stage: any) => (stage.order > max ? stage.order : max), 0);

      // stagesToInsert.forEach((stage: any, index: number) => {
      //   if (!stage.order) {
      //     stage.order = maxOrder + 1 + index; // Assign unique order sequentially
      //   }
      // });

      if (stagesToUpdate.length > 0) {
        await BaseApi.patch(`/crm/pipeline/stage`, {
          stage_list: stagesToUpdate,
        });
      }
      if (updatedStagesToInsert.length > 0) {
        await BaseApi.post(`/crm/pipeline/stage`, {
          stage_list: updatedStagesToInsert,
        });
      }
      dispatch(setPipelineId(pipelineId));
      dispatch(setBrandId(brandId));
    } catch (error) {
    } finally {
      dispatch(setIsSwitchPipeline(true));
      dispatch(setPipelineId(pipelineId));
      dispatch(setBrandId(brandId));
      dispatch(setProspectListResult([]));
      const data = await jwtEncrypt({
        pipeline_id: pipelineId,
        brand_id: brandId,
        pipelineName: pipelineName,
      });
      navigate(`/crm?_id=${data}`);
      dispatch(removePipelineData());
    }
  };

  return (
    <OnboardBackground count={5}>
      <div className="flex justify-between gap-x-10">
        <div className="flex w-[35rem] flex-col justify-start gap-6 py-12">
          <div>
            <p className="w-[80%] text-justify text-sm font-medium text-heading">Setup your sales process</p>
            <p className="mt-4 text-[1.8125rem] font-bold text-heading dark:!text-purple-600">Define your deal stages</p>
            <p className="mt-3 w-[82%] text-justify text-sm font-medium text-heading">
              Your pipeline is an essential tool for tracking for your deals as the progress through the sales process- all the way from first contact, to final purchase decision.
            </p>
            <p className="mt-4 w-[82%] text-sm font-medium text-heading">
              Start with Sparkle's suggestions, or rename them as you like. you can also add, remove or rename stages later.
            </p>
          </div>
          <div>
            <p className="mb-4 text-[1.2rem] font-semibold text-heading dark:text-purple-500">Stages name</p>
            <div>
              <div className="max-h-48 overflow-y-auto" ref={containerRef}>
                {stagesstate?.map((stage: any, index: number) => (
                  <div key={index} className="mb-2 flex items-center">
                    <div className="relative mr-2">
                      <input
                        type="color"
                        value={stage.color_code}
                        onChange={(e) => handleUpdateStage(index, 'color_code', e.target.value)}
                        className="absolute inset-0 h-full w-full cursor-pointer opacity-0"
                      />
                      <div className="h-4 w-4 rounded-full" style={{ backgroundColor: stage?.color_code }} />
                    </div>
                    <Input
                      value={stage?.stage_name}
                      onChange={(e) => handleUpdateStage(index, 'stage_name', e.target.value)}
                      placeholder="Enter Stage Name"
                      customClass="!w-96 mr-2 mt-1"
                    />
                    <button onClick={() => deleteStage(index, stage)}>
                      <AiFillDelete color="#A3AED0" className="dark:!text-red-500" />
                    </button>
                  </div>
                ))}
              </div>
              <button onClick={addStage} className="text-[13px] font-medium text-[#4318FF]" id={'add-stages-onboard'}>
                + Add another stage
              </button>
            </div>
          </div>
          <div>
            <Button
              id="define-last-stage-button"
              name="Complete Onboard"
              px="px-6"
              py="py-3"
              fontSize="xs"
              disable={stagesstate?.length > 0 && stagesstate[0].stage_name ? false : true}
              customClass="mt-6!"
              onClick={() => onClick()}
            />
          </div>
        </div>
        <div className="mt-9 h-full w-[51.5rem]">
          <div className="rounded-lg bg-[#fff] p-2 dark:bg-darkThree">
            <Stack>
              <Card className="h-10 px-4 py-2 dark:bg-darkBorder">
                <p className="font-semibold text-[#2B3674] dark:text-purple-400">{pipelineName ? pipelineName : 'Pipeline name'}</p>
              </Card>
            </Stack>
            <Card className="mt-2 h-[29rem] p-4 dark:bg-darkBorder">
              <div className="mb-2 flex scale-y-[-1] transform overflow-x-auto">
                {stagesstate?.map((stage: any, index: number) => (
                  <div
                    className="mr-2 flex min-w-[18rem] max-w-[18rem] scale-y-[-1] transform flex-col items-center border-t-[3px] pb-20 pr-4 pt-1.5"
                    key={index}
                    style={{ borderTopColor: stage.color_code }}
                  >
                    <div className="flex w-full items-center justify-between">
                      <div className="flex items-center gap-1.5 pl-2.5">
                        <h5 className="relative rounded-full bg-[#4318FF0D] p-2.5 dark:bg-purple-500">
                          <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-[0.55rem] font-semibold dark:text-white">0</span>
                        </h5>
                        <h5 className="text-md font-bold text-[#101828] dark:text-white">{stage?.stage_name || 'Default stage name'}</h5>
                      </div>
                      <IoMdMore className="size-6 cursor-pointer rounded-md text-column hover:bg-gray-100" />
                    </div>
                  </div>
                ))}
              </div>
            </Card>
          </div>
        </div>
      </div>
    </OnboardBackground>
  );
};

export default OnboardDefineStages;
