import { PromoCodeIVDTO } from 'dto/types/views/promo-code';
import { VerifyPromoCodeApi } from '../api-handler';
import { getAuthUserConfiguration } from 'shared/layout/event-handler';

export const HandleVerifyPromoCodeApi = async (promoCode: PromoCodeIVDTO, inputRef: any, updatePromoCode: (data: PromoCodeIVDTO) => void,dispatch:any) => {
  try {
    if (promoCode?.promo_code?.length) {
      updatePromoCode({ loading: true });
      const response = await VerifyPromoCodeApi({ promo_code: promoCode?.promo_code });
      const data = response?.data;
      if (response?.error) {
        if (response?.message === 'You have already used this promo code.') {
          updatePromoCode({ status: 'used' });
        } else if (response?.message === 'Promo code is invalid') {
          updatePromoCode({ status: 'invalid' });
        } else if (response?.message === 'This promo code. has expired') {
          updatePromoCode({ status: 'expired' });
        }
      } else {
        updatePromoCode({ status: 'valid', sparkle: data?.discount_value, offerType: 'sparkle' });
      }
    }
  } catch (error) {
    console.log('Promo code verify error', error);
  } finally {
    inputRef?.current?.focus();
    updatePromoCode({ loading: false });
    await getAuthUserConfiguration('billings', dispatch);
  }
};

export const HandleCloseModalEvent = (setPromoCode: React.Dispatch<PromoCodeIVDTO>, onClose: () => void) => {
  setPromoCode({
    promo_code: '',
    status: null,
    loading: false,
    sparkle: 0,
    offerType: null,
  });
  onClose();
};
