import { generateDateInterval } from "utility/utils";

export const donotEmailFilterOption = [
    // {
    //   id: '1',
    //   name: 'Added By',
    //   uniqueColumnName: 'added_by',
    //   conditions: [
    //     { id: '1', name: 'Is', uniqueName: 'is' },
    //     { id: '2', name: 'Is not', uniqueName: 'not' },
    //   ],
    //   resultValue: [{ id: '1', name: 'Me', uniqueName: 'me' }],
    // },
    {
      id: '2',
      name: 'Added On',
      uniqueColumnName: 'cdate',
      conditions: [{ id: '1', name: 'Is', uniqueName: 'is' }],
      resultValue: [
        { id: '1', name: 'Today', uniqueName: generateDateInterval(0, 0) },
        { id: '2', name: 'Yesterday', uniqueName: generateDateInterval(1) },
        { id: '3', name: 'Last 7 days', uniqueName: generateDateInterval(7) },
        { id: '4', name: 'Last 30 days', uniqueName: generateDateInterval(29) },
        { id: '5', name: 'This week', uniqueName: generateDateInterval('week', 0) },
        { id: '6', name: 'This month', uniqueName: generateDateInterval('month', 0) },
      ],
    },
  ];