/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import ConfirmationPopup from 'components/common/confirmationPopup/ConfirmationPopup';
import TableLoader from 'components/common/Loaders/TableViewLoader';
import MultiSelectBottomActionBar from 'components/common/muitiSelectcomponent/MultiselectGlobalOpt';
import { SenderAccountListRowObject } from 'dto/types/views/sender-accounts';
import { useAppDispatch, useAppSelector } from 'redux-store';
import { columnWidthIV, queryParamsIV } from 'dto/initial-value/views/sender-accounts';
import TableHeader from 'views/sender-accounts/components/TableHeader';
import TableBody from 'views/sender-accounts/components/TableBody';
import { BottomActionBarListEvent, CancelArchievePopupEvent, FetchGroupListEvent, FetchListEvent, HandleMultiArchieveEvent, HandleMultiSelectArchieveEvent, HandleRowArchieveEvent, HandleRowDataEditEvent, HandleRowDownloadEvent, UpdateTableColumnDataEvent, UpdateTableRowActionItemsListEvent } from 'shared/sender-accounts/event-handler/email-account-event';
import { BaseApi } from 'api/services/base-api';
import { setSenderFetchData } from 'dto/initial-value/redux-store/sender-accounts-old';


const EmailAccountsList = () => {

    const [state, setState] = React.useState({
        loading: false, loader: false, queryParams: queryParamsIV, tableColumns: [], selected_rows: [], editGetData: {},
        archieveConfirm: {
            isOpen: false,
            headerContent: '',
            bodyContent: '',
            bodyRedContent: '',
            descriptionContent: '',
            uuid: [],
        },
        bottomActionBarList: []
    });
    const tableRef = React.useRef(null);
    const columnHelper = createColumnHelper<SenderAccountListRowObject>();
    const dispatch = useAppDispatch();
    const { commonTableList, senderAccounts } = useAppSelector((state) => state);
    const table = useReactTable({ data: senderAccounts?.data_list, columns: state?.tableColumns, getCoreRowModel: getCoreRowModel(), debugTable: true, });

    React.useEffect(() => {
        const tableSelectedData = table.getSelectedRowModel().rows.map((val: { original: { uuid?: string } }) => val.original?.uuid);
        setState((prev) => ({ ...prev, selected_rows: tableSelectedData }))
    }, [table.getSelectedRowModel()]);

    const handleCancelArchievePopup = async () => {
        await CancelArchievePopupEvent(setState)
    };

    const handleSubmitMultiArchieve = async () => {
        await HandleMultiArchieveEvent({ state, table, setState })
    };

    React.useEffect(() => {
        FetchListEvent({ dispatch, queryParams: state?.queryParams, commonTableList })
    }, [commonTableList?.search, commonTableList?.filter]);


    const handleRowDataEdit = async (rowUuid: string, rowDetails: any) => {
        await HandleRowDataEditEvent({ rowUuid, rowDetails, setState })
    };
    const handleRowArchieve = async (rowUuid: string, rowDetails: any) => {
        await HandleRowArchieveEvent({ rowUuid, rowDetails, setState })
    };
    const handleMultiSelectArchieve = async () => {
        const multiSelectIds = table.getSelectedRowModel().rows.map((val: any) => val.original?.uuid);
        await HandleMultiSelectArchieveEvent({ multiSelectIds, setState })
    };
    const handlDownload = async (rowUuid: string) => {
        await HandleRowDownloadEvent({ id_list: [rowUuid] })
    };
    const handleMultiSelectDownload = async () => {
        const selectedData = table.getSelectedRowModel().rows.map((val: any) => val.original?.uuid);
        await HandleRowDownloadEvent({ id_list: selectedData })
    };
    const functionMap = {
        handleRowDataEdit: handleRowDataEdit,
        handleRowArchieve: handleRowArchieve,
        handlDownload: handlDownload,
        handleMultiSelectArchieve: handleMultiSelectArchieve,
        handleMultiSelectDownload: handleMultiSelectDownload,
    }
    React.useEffect(() => {
        FetchGroupListEvent({ dispatch })
        UpdateTableRowActionItemsListEvent({ functionMap, dispatch })
        BottomActionBarListEvent({ functionMap, setState })
        UpdateTableColumnDataEvent({ columnHelper, table, setState })
    }, []);

    const fetchGroupList = async () => {
        try {
          const data = await BaseApi.get(`/groups/linked/with/email`);
          if (data?.data && data?.data?.data?.length) {
            const finalData = await data?.data?.data?.sort((a: any, b: any) =>
              a?.name.toLowerCase() < b?.name.toLowerCase() ? -1 : a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : 0
            );
            const states = data?.data?.data?.reduce((acc: any, val: any) => {
              acc[val.uuid] = { show: false, rename: false };
              return acc;
            }, {});
            dispatch(setSenderFetchData({ finalData, states }));
          } else {
            dispatch(setSenderFetchData([]));
          }
        } catch (error) {
          console.log(error);
        }
      };
      React.useEffect(() => {
        fetchGroupList();
      }, []);

    return (
        <>
            <div className={`mt-2.5 w-full rounded-lg bg-white pb-2 pt-2.5 shadow-sm dark:bg-darkThree  dark:text-white sm:overflow-x-auto`}>
                <div className="h-[80vh] overflow-y-auto overflow-x-scroll scroll-smooth xl:overflow-x-hidden" ref={tableRef}>
                    {!state?.loader ? (
                        <>
                            <table className=" sm:w-[150%] lg:w-[120%] xl:w-full">
                                <TableHeader table={table} />
                                <TableBody data={senderAccounts?.data_list} table={table} columnWidthIV={columnWidthIV} />
                            </table>
                        </>
                    ) : (
                        <TableLoader
                            width="w-full"
                            border="border border-gray-100"
                            column={5}
                            row={15}
                            skeletonHeight="h-2"
                            padding="px-5 py-4"
                            tableViewBg={{ headBg: 'bg-gray-50' }}
                            columnWidth="4% 16% 60% 16% 4%"
                        />
                    )}
                </div>
            </div>
            {(state?.selected_rows?.length > 1) && (
                <MultiSelectBottomActionBar
                    options={[
                        {
                            icon: <p className="text-xs">{table.getSelectedRowModel().rows.map((val: any) => val.original?.uuid)?.length}</p>,
                            name: 'Selected',
                            functioning: () => { },
                        },
                        ...state?.bottomActionBarList,
                    ]}
                />
            )}
            <ConfirmationPopup
                isOpen={state?.archieveConfirm?.isOpen}
                loading={state?.loading}
                headerContent={state?.archieveConfirm?.headerContent}
                bodyContent={state?.archieveConfirm?.bodyContent}
                bodyRedContent={state?.archieveConfirm?.bodyRedContent}
                descriptionContent={state?.archieveConfirm?.descriptionContent}
                handleCancel={handleCancelArchievePopup}
                handleConfirmFunction={handleSubmitMultiArchieve}
                btn2='Archive'
            />
        </>
    );
};

export default EmailAccountsList;
