import { generateDateInterval } from "utility/utils";

export const brandsFilterDataList = [
    {
      id: '1',
      name: 'Created On',
      uniqueColumnName: 'cdate',
      conditions: [{ id: '1', name: 'Is', uniqueName: 'is' }],
      resultValue: [
        { id: '1', name: 'Today', uniqueName: generateDateInterval(0, 0) },
        { id: '2', name: 'Yesterday', uniqueName: generateDateInterval(1) },
        { id: '3', name: 'Last 7 days', uniqueName: generateDateInterval(7) },
        { id: '4', name: 'Last 30 days', uniqueName: generateDateInterval(29) },
        { id: '5', name: 'This week', uniqueName: generateDateInterval('week', 0) },
        { id: '6', name: 'This month', uniqueName: generateDateInterval('month', 0) },
      ],
    },
    {
      id: '2',
      name: 'Campaigns Count',
      uniqueColumnName: 'campaign_count',
      conditions: [
        { id: '1', name: 'More Than', uniqueName: 'greater_than' },
        { id: '2', name: 'Less than', uniqueName: 'less_than' },
      ],
      resultValue: [] as any,
      result_value_field_type: 'input',
    },
    // {
    //   id: '3',
    //   name: 'Interests',
    //   uniqueColumnName: 'campaign_statistics.email_open_percentage',
    //   conditions: [
    //     { id: '1', name: 'More Than', uniqueName: 'greater_than' },
    //     { id: '2', name: 'Less than', uniqueName: 'less_than' },
    //   ],
    //   resultValue: [] as any,
    //   result_value_field_type: 'input',
    // },
    // {
    //   id: '4',
    //   name: 'Meeting booked',
    //   uniqueColumnName: 'campaign_statistics.email_open_percentage',
    //   conditions: [
    //     { id: '1', name: 'More Than', uniqueName: 'greater_than' },
    //     { id: '2', name: 'Less than', uniqueName: 'less_than' },
    //   ],
    //   resultValue: [] as any,
    //   result_value_field_type: 'input',
    // },
    // {
    //   id: '5',
    //   name: 'Opportunites Won',
    //   uniqueColumnName: 'campaign_statistics.email_open_percentage',
    //   conditions: [
    //     { id: '1', name: 'More Than', uniqueName: 'greater_than' },
    //     { id: '2', name: 'Less than', uniqueName: 'less_than' },
    //   ],
    //   resultValue: [] as any,
    //   result_value_field_type: 'input',
    // },
    // {
    //   id: '6',
    //   name: 'Total deal value',
    //   uniqueColumnName: 'campaign_statistics.email_open_percentage',
    //   conditions: [
    //     { id: '1', name: 'More Than', uniqueName: 'greater_than' },
    //     { id: '2', name: 'Less than', uniqueName: 'less_than' },
    //   ],
    //   resultValue: [] as any,
    //   result_value_field_type: 'input',
    // },
  ];