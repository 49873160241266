import {
  BLACKLIST_DELETE,
  BLACKLIST_GROUP_CREATE,
  BLACKLIST_GROUP_TAGS,
  BLACKLIST_GROUPS_DELETE,
  BLACKLIST_LIST,
  BLACKLIST_PAUSE,
  BLACKLIST_RECHECK,
  BLACKLIST_RESUME,
  BLACKLIST_VALIDATE,
  CREATE_TICKET_BLACKLIST,
  DOWNLOAD_BLACKLIST_EXCELDATA,
  GET_ONE_BLACKLIST_DATA,
  GROUP_ONE_BLACKLIST,
  GROUPS,
} from 'api/endpoints/blacklistEndpoints';
import { BaseApi } from 'api/services/base-api';
import { buildUrl } from 'utility/utils';

export const GetBlacklistGroupsListApi = async () => {
  try {
    const getUrl = BLACKLIST_GROUP_TAGS;
    const response = await BaseApi.get(getUrl);
    return response;
  } catch (error) {
    console.error('BlacklklistListApi Error:', error);
    throw error;
  }
};

export const GetBlacklistListApi = async (queryParams: Record<string, any>) => {
  try {
    const getUrl = buildUrl({ url: BLACKLIST_LIST, query: queryParams });
    const response = await BaseApi.get(getUrl);
    return response;
  } catch (error) {
    console.error('BlacklklistListApi Error:', error);
    throw error;
  }
};

export const DeleteBlacklistData = async (uuids: string[]) => {
  try {
    const getUrl = BLACKLIST_DELETE;
    const response = await BaseApi.delete(getUrl, {
      data: { delete_uuid: uuids },
    });
    return response;
  } catch (error) {
    console.error('BlacklklistListDeleteApi Error:', error);
    throw error;
  }
};

export const PauseBlacklistData = async (uuids: string[]) => {
  try {
    const getUrl = BLACKLIST_PAUSE;
    const response = await BaseApi.patch(getUrl, {
      pause_uuid: uuids,
    });
    return response;
  } catch (error) {
    console.error('BlacklklistListDeleteApi Error:', error);
    throw error;
  }
};

export const ResumeBlacklistData = async (uuids: string[]) => {
  try {
    const getUrl = BLACKLIST_RESUME;
    const response = await BaseApi.patch(getUrl, {
      resume_uuid: uuids,
    });
    return response;
  } catch (error) {
    console.error('BlacklklistListDeleteApi Error:', error);
    throw error;
  }
};

export const ValidateBlacklistData = async (payload: {}) => {
  try {
    const getUrl = BLACKLIST_VALIDATE;
    const response = await BaseApi.post(getUrl, payload);
    return response;
  } catch (error) {
    console.error('BlacklklistListDeleteApi Error:', error);
    throw error;
  }
};

export const RecheckBlacklistData = async (uuids: string[]) => {
  try {
    const getUrl = BLACKLIST_RECHECK;
    const response = await BaseApi.patch(getUrl, {
      recheck_uuid: uuids,
    });
    return response;
  } catch (error) {
    console.error('BlacklklistListDeleteApi Error:', error);
    throw error;
  }
};

export const GetBlackListDataDownload = async ({ rowuuid }: { rowuuid: string | string[] }) => {
  try {
    const getUrl = DOWNLOAD_BLACKLIST_EXCELDATA(rowuuid);
    const response = await BaseApi.get(getUrl);
    return response;
  } catch (error) {
    console.error('BlacklklistListGetOneApi Error:', error);
    throw error;
  }
};

export const GetOneBlackListDataResultModal = async ({ uuid }: { uuid: string }) => {
  try {
    const getUrl = GET_ONE_BLACKLIST_DATA(uuid);
    const response = await BaseApi.get(getUrl);
    return response;
  } catch (error) {
    console.error('BlacklklistListGetOneApi Error:', error);
    throw error;
  }
};

export const CreateTicketForBlackList = async (params: any) => {
  try {
    const getUrl = CREATE_TICKET_BLACKLIST;
    const response = await BaseApi.post(getUrl, params);
    return response;
  } catch (error) {
    console.error('BlacklklistListGetOneApi Error:', error);
    throw error;
  }
};

//------------------------------------------------------------------//

export const CreateGroupForBlackList = async (params: any) => {
  try {
    const getUrl = BLACKLIST_GROUP_CREATE;
    const response = await BaseApi.patch(getUrl, params);
    return response;
  } catch (error) {
    console.error('BlacklklistListGetOneApi Error:', error);
    throw error;
  }
};

export const DeleteBlacklistGroup = async (uuid: string, variant_id: string) => {
  try {
    const getUrl = BLACKLIST_GROUPS_DELETE;
    const response = await BaseApi.delete(getUrl, {
      data: {
        group_id: uuid,
        variant_id: variant_id,
      },
    });
    return response;
  } catch (error) {
    console.error('BlacklklistListDeleteApi Error:', error);
    throw error;
  }
};

export const RenameGroupForBlackList = async (params: any) => {
  try {
    const getUrl = GROUPS;
    const response = await BaseApi.patch(getUrl, params);
    return response;
  } catch (error) {
    console.error('BlacklklistListGetOneApi Error:', error);
    throw error;
  }
};

export const DeleteOneBlacklistGroupList = async (uuid: string) => {
  try {
    const getUrl = GROUP_ONE_BLACKLIST(uuid);
    const response = await BaseApi.delete(getUrl);
    return response;
  } catch (error) {
    console.error('BlacklklistListDeleteApi Error:', error);
    throw error;
  }
};
