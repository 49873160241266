import Button from 'components/common/Button';
import { useEffect, useState } from 'react';
import { BsDownload } from 'react-icons/bs';
import EmailUploader from '../component/EmailUploader';
import moment from 'moment';
import { AdminVerifierListIV } from 'dto/initial-value/views/admin-verifier';
import { useSmartSetState } from 'hooks/useSmartSetState';
import { fetchAdminVerifierList, getAdminVerificationResultClickEvent, onCloseClickEvent } from 'shared/admin-verifier/event-handler/admin-verifier-event';

const AdminVerificationList = () => {
  const [data, setData] = useState(AdminVerifierListIV);
  const smartStateUpdate = useSmartSetState(setData);
  useEffect(() => {
    fetchAdminVerifierList(smartStateUpdate);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.isOpen]);

  return (
    <div className="m-3 flex flex-col gap-4 p-3">
      <div className="flex justify-end">
        <Button type="button" name="Add List" onClick={() => smartStateUpdate({ isOpen: true })} />
      </div>
      <div className="flex items-center justify-between border font-bold">
        <p className="w-1/4 p-3 text-start ">List Name</p>
        <p className="w-1/4 p-3 text-start ">Created On</p>
        <p className="w-1/4 p-3 ">Total Count</p>
        <p className="w-1/4 p-3 ">Verified Count</p>
        <p className="w-1/4 p-3 ">Status</p>
        <p className=" p-3 ">Icon</p>
      </div>
      {data?.data?.map((item) => (
        <div className="flex items-center justify-between border">
          <p className="w-1/4 p-3 text-start ">{item.list_name}</p>
          <p className="w-1/4 p-3 text-start ">{moment(item.cdate).format('ddd DD MMM YYYY')}</p>
          <p className="w-1/4 p-3 ">{item.total_count}</p>
          <p className="w-1/4 p-3 ">{item.verified_count}</p>
          <p className="w-1/4 p-3 ">{item.total_count === item.verified_count ? 'Completed' : 'Inprogress'}</p>
          <p className=" p-3 ">
            <BsDownload className={`${item.total_count === item.verified_count ? 'cursor-pointer' : 'cursor-default'} ${data.loading && 'animate-pulse'}`} onClick={() => (item.total_count === item.verified_count ? getAdminVerificationResultClickEvent(item?.uuid, smartStateUpdate) : undefined)} />
          </p>
        </div>
      ))}

      <EmailUploader isOpen={data.isOpen} onClose={() => onCloseClickEvent(smartStateUpdate)} smartStateUpdate={smartStateUpdate} />
    </div>
  );
};

export default AdminVerificationList;
