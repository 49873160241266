import { Spinner } from '@chakra-ui/react';
import Button from 'components/common/Button';
import InputField from 'components/forms/InputField';
import TextField from 'components/forms/TextField';
import { createBrandFormDataIV } from 'dto/initial-value/views/brands';
import { CreateBrandProps } from 'dto/types/views/brands';
import React from 'react';
import { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux-store';
// import { setBrandInput } from 'redux-store/campaign-v2';
import { HandleFormChangeEvent, HandleFormSubmitEvent } from 'shared/brands/event-handler/index-event';

const CreateBrand = (props?: CreateBrandProps) => {
  const { bodycustomClass, onParentDataChange, onChangeloading } = props;

  const state = useAppSelector((state) => state?.campaignv2);
  const BrandInputData = state?.brand_input;
  const dispatch = useDispatch();
  const errorMessage = useAppSelector((state) => state.custom.errorMessage);
  const [formData, setFormData] = React.useState(createBrandFormDataIV);

  React.useEffect(() => {
    setFormData(BrandInputData);
  }, [BrandInputData]);

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    HandleFormChangeEvent({ event, setFormData, dispatch });
  };

  const handleSave = async (e: React.FormEvent<HTMLFormElement>) => {
    console.log("hitted")
    e.preventDefault();
    HandleFormSubmitEvent({ onParentDataChange, formData, dispatch });
    // const { uuid, ...rest } = formData;
    // dispatch(setBrandInput(formData));
    // onParentDataChange({ uuid: formData?.uuid, ...rest });
    // onParentDataChange({ uuid: state?.ids?.brand_id, ...state.brand_input });
  };

  return (
    <div className="mt-2 flex justify-center">
      {state?.editLoading && <Spinner className="absolute top-1/2 text-brand-300" speed="0.8s" size={'md'} />}
      <div className={`  rounded-md bg-white dark:bg-darkThree ${bodycustomClass}`}>
        <form onSubmit={handleSave}>
          <div className="flex justify-between px-4 pb-2 pt-5">
            <h4 className="mt-2 text-sm font-bold text-column dark:text-white">Brand inputs</h4>
          </div>
          <div className="border-y px-4 py-2 dark:border-darkBorder">
            {/* Brand name */}
            <InputField
              autoComplete="off"
              extra="mb-3"
              inputClass="mt-2 dark:!bg-darkFive brand_name_input !rounded-md !h-9"
              labelClass="!text-column dark:!text-white !font-[600] !ml-0 text-xs"
              label="Brand Name *"
              placeholder="Enter brand name"
              id="name"
              type="text"
              value={formData?.name}
              onChange={handleChange}
              errorMessage={errorMessage?.name}
              state={errorMessage?.name ? 'error' : ''}
            />
            {/* ICP */}
            <TextField
              extra="mb-1"
              inputClass="mt-2 dark:!bg-darkFive brand_icp_input resize-none !rounded-md"
              labelClass="!text-column dark:!text-white !font-[600] !ml-0 text-xs"
              label="Who is your ICP? *"
              placeholder="Enter a description..."
              id="icp"
              cols={30}
              rows={4}
              value={formData?.icp}
              onChange={handleChange}
              errorMessage={errorMessage?.icp}
              state={errorMessage?.icp ? 'error' : ''}
            />
          </div>
          <div className="border-b px-4 py-2 dark:border-darkBorder">
            {/* Paint points */}
            <TextField
              extra="mb-4"
              inputClass="mt-2 dark:!bg-darkFive brand_pain_points_input resize-none !rounded-md"
              labelClass="!text-column dark:!text-white !font-[600] !ml-0 text-xs"
              label="What are their pain points? *"
              placeholder="Enter a description..."
              id="pain_points"
              cols={30}
              rows={4}
              onChange={handleChange}
              value={formData?.pain_points}
              errorMessage={errorMessage?.pain_points}
              state={errorMessage?.pain_points ? 'error' : ''}
            />
          </div>
          <div className="border-b px-4 py-2 dark:border-darkBorder">
            {/* Solution*/}
            <TextField
              extra="mb-4"
              inputClass="mt-2 dark:!bg-darkFive brand_solution_input resize-none !rounded-md"
              labelClass="!text-column dark:!text-white !font-[600] !ml-0 text-xs"
              label="What is your solution? *"
              placeholder="Enter a description..."
              id="solution"
              cols={30}
              rows={4}
              onChange={handleChange}
              value={formData?.solution}
              errorMessage={errorMessage?.solution}
              state={errorMessage?.solution ? 'error' : ''}
            />
          </div>
          <div className="px-4 py-2">
            {/* Geography target */}
            <TextField
              extra="mb-4"
              inputClass="mt-2 dark:!bg-darkFive brand_geography_target_input resize-none !rounded-md"
              labelClass="!text-column dark:!text-white !font-[600] !ml-0 text-xs"
              label="Which geography do you want to target? *"
              placeholder="Enter a description..."
              id="geography_target"
              cols={30}
              rows={4}
              onChange={handleChange}
              value={formData?.geography_target}
              errorMessage={errorMessage?.geography_target}
              state={errorMessage?.geography_target ? 'error' : ''}
            />
          </div>
          <div className="px-4 py-2">
            {/* Geography target */}
            <TextField
              extra="mb-4"
              inputClass="mt-2 dark:!bg-darkFive brand_about_brand_details_input resize-none !rounded-md"
              labelClass="!text-column dark:!text-white !font-[600] !ml-0 text-xs"
              label="What are your brand's USPs? *"
              placeholder="Enter a description..."
              id="about_brand_details"
              cols={30}
              rows={4}
              onChange={handleChange}
              value={formData?.about_brand_details}
              errorMessage={errorMessage?.about_brand_details}
              state={errorMessage?.about_brand_details ? 'error' : ''}
            />
          </div>
          <div className="flex w-full justify-center px-4 pb-3">
            <Button
              id="brand_save_or_update_btn"
              name={'Save Brand'}
              type='submit'
              disable={onChangeloading}
              customClass="w-full !py-2 brand_save_btn dark:border-none"
            // onClick={(e:any)=>handleSave(e)}
              loading={onChangeloading}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateBrand;
