import { toast } from 'react-toastify';
import { setClearSelectData, setInboxlistData, setOffset, setTriggerCount } from 'redux-store/unified-inbox';
import { toastState } from 'utility/utils';
import { getNotificationInboxDataApi, updateInboxData } from '../api-handler/inbox-api';

export const HandleClearNotificationsClickEvent = async ({ e, uuid, dispatch, inbox_status, fetchTrigger }: { e: React.MouseEvent; uuid?: string; dispatch: any; inbox_status: any; fetchTrigger: () => void }) => {
  e.stopPropagation();

  dispatch(setTriggerCount((prev: boolean) => !prev));
  try {
    const payload = {
      unified_inbox_status: inbox_status === 'cleared' ? 'pending' : 'cleared',
      uuid: [uuid],
    };
    const response = await updateInboxData(payload);
    toast.success(response.message, toastState.success);
    fetchTrigger();
  } catch (error) {
    console.error('error', error);
  }
};

const HandleStarredViewClickEvent = ({ setStarredMessages, inboxData }: { setStarredMessages: any; inboxData: any }) => {
  setStarredMessages((prevArr: any) => {
    if (prevArr.includes(inboxData?.uuid)) {
      return prevArr.filter((item: any) => item !== inboxData?.uuid);
    } else {
      return [...prevArr, inboxData?.uuid];
    }
  });
};

export const HandleStaredStatusClickEvent = async ({ status, id, dispatch, setStarredMessages, inboxData }: { status: boolean; id: string; dispatch: any; setStarredMessages: any; inboxData: any }) => {
  HandleStarredViewClickEvent({ setStarredMessages, inboxData });
  dispatch(setTriggerCount((prev: boolean) => !prev));
  try {
    const payload = {
      uuid: id,
      is_starred_message: !status,
    };
    const response = await updateInboxData(payload);

    if (response.error) {
      toast.error(response.message, toastState.error);
    }
    // else {
    //   props.fetchTrigger();
    // }
  } catch (error) {
    console.log(error);
  }
};

export const HandleAvatarClickEvent = ({ e, uuid, dispatch, setIsChecked, isChecked }: { e: any; uuid: string; dispatch: any; setIsChecked: React.Dispatch<React.SetStateAction<boolean>>; isChecked: boolean }) => {
  e.stopPropagation();
  dispatch(setClearSelectData(uuid));
  setIsChecked(!isChecked);
};

export const HandleNotificationFetchData = async ({
  searchQuery,
  limit,
  filter,
  setLoading,

  dispatch,
  setMessageData,
  inbox_status,
}: {
  searchQuery: string;
  limit: number;
  filter: any;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  dispatch: any;
  setMessageData: any;

  inbox_status: string;
}) => {
  try {
    const query = {
      search: searchQuery,
      limit: limit,
      filter: filter,
    };

    const data = {
      query: query,
      inbox_status: inbox_status,
    };

    setLoading(true);
    const response = await getNotificationInboxDataApi(data);
    if (response?.error) {
      toast.error(response?.message, toastState.error);
    } else {
      dispatch(setInboxlistData(response?.data?.data));
      setMessageData({
        totalCount: response?.data?.total_count,
        data: response?.data?.data,
      });
    }
  } catch (err) {
    console.log(err);
  } finally {
    setLoading(false);
  }
};

export const FetchMoreNotificationDataScrollEvent = async (
  setIsFetching: React.Dispatch<React.SetStateAction<boolean>>,
  setMessageData: React.Dispatch<React.SetStateAction<any>>,
  searchQuery: string,
  limit: number,
  filter: any,
  offset: number,

  dispatch: any,
  inbox_status: string
) => {
  try {
    setIsFetching(true);
    const data = {
      inbox_status,
      query: {
        limit: limit,
        offset: offset,
        search: searchQuery,
        filter: filter,
      },
    };
    const response = await getNotificationInboxDataApi(data);
    const responseData: any = response.data;
    if (!responseData?.data) return setMessageData({ data: [], totalCount: 0 });
    if (responseData?.data?.length < 25) {
      setMessageData((prev: { data: Array<object> }) => ({ data: [...prev?.data, ...responseData?.data], totalCount: prev?.data?.length }));
    } else {
      setMessageData((prev: { data: Array<object> }) => ({ ...prev, data: [...prev?.data, ...responseData?.data] }));
    }
    dispatch(setOffset(1));
  } catch (error) {
    console.log(error);
  } finally {
    setIsFetching(false);
  }
};
