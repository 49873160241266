// import { AZ_BUCKET } from constants/constants"
import { AZ_BUCKET } from "config/appConfig"
import { BiSolidBriefcase } from "react-icons/bi"
import { BsFillPeopleFill } from "react-icons/bs"
import { HiBuildingOffice2 } from "react-icons/hi2"

export const campaignStepsCount = [1, 4, 1, 0, 2, 0]

export const colViewOpt = [
  { name: 'Campaign Name', id: 'campaign_name', campaign_name: true, disabled: true },
  { name: 'Created On', id: 'cdate', cdate: true, disabled: true },
  { name: 'Status', id: 'campaign_status', campaign_status: true, disabled: true },
  { name: 'Progress', id: 'campaign_statistics.overall_campaign_sent_percentage', campaign_progress_step_percent: true },
  { name: 'Prospects', id: 'total_prospects_count', total_prospects_count: true },
  { name: 'Sent', id: 'sent_count', sent_count: true },
  { name: 'Opened', id: 'open_count', open_count: true },
  { name: 'Replied', id: 'replied_count', replied_count: true },
  { name: 'Bounced', id: 'bounced_count', bounced_count: false },
  { name: 'Unsubscribed', id: 'unsubscribed_count', unsubscribed_count: false },
]

export const dummyData: any = [
  {
    id: '54faf68a746869790f2c6901',
    first_name: 'Cyrus',
    last_name: 'Kariuki',
    full_name: 'Cyrus Kariuki',
    linkedin_url: 'http://www.linkedin.com/in/cyrus-kariuki-9ab23480',
    title: 'Consultant',
    photo_url: 'https://static.licdn.com/aero-v1/sc/h/244xhbkr7g40x6bsu4gi6q4ry',
    twitter_url: null,
    facebook_url: null,
    email: 'email_not_unlocked@domain.com',
    state: null,
    city: null,
    country: 'Kenya',
    organization_name: 'Dhanush Group',
    organization_phone: '+91 40 4201 2454',
    organization_website_url: 'http://www.dhanushhealthcare.com',
    organization_linkedin_url: 'http://www.linkedin.com/company/dhanush-group',
    organization_twitter_url: 'https://twitter.com/DhanushHealth',
    organization_facebook_url: 'https://www.facebook.com/DhanushDigitalHealth',
    organization_founded_year: 2006,
    organization_logo_url: 'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66bd44860c12c70001175e18/picture',
    organization_primary_domain: 'dhanushhealthcare.com',
    uuid: '132a99a0-2bde-4773-a0f4-e01b0ce518d9',
    sparkle_leads_id: '9223dbac-bd01-49ac-9d13-ff7ea89c9b77',
    campaign_id: '83536433-6680-42d3-bf36-efd554e69e32',
    temprory_id: '9580326e-14f0-43f2-8689-f0b9119458b0'
  },
  {
    id: '57e1cafda6da98573f2898f2',
    first_name: 'Eswar',
    last_name: 'Chakravarthi',
    full_name: 'Eswar Chakravarthi',
    linkedin_url: 'http://www.linkedin.com/in/eswar-chakravarthi-a29b24123',
    title: 'Oracle Functional Consultant',
    photo_url: 'https://media.licdn.com/dms/image/v2/C5103AQEiKxhFvUFLog/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517341372106?e=2147483647&v=beta&t=tgKFuMKlRn-EXsDj_UtDn1LhYRXU9sNkdFE5O8HyQU8',
    twitter_url: null,
    facebook_url: null,
    email: 'email_not_unlocked@domain.com',
    state: 'Andhra Pradesh',
    city: null,
    country: 'India',
    organization_name: 'Dhanush Group',
    organization_phone: '+91 40 4201 2454',
    organization_website_url: 'http://www.dhanushhealthcare.com',
    organization_linkedin_url: 'http://www.linkedin.com/company/dhanush-group',
    organization_twitter_url: 'https://twitter.com/DhanushHealth',
    organization_facebook_url: 'https://www.facebook.com/DhanushDigitalHealth',
    organization_founded_year: 2006,
    organization_logo_url: 'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66bd44860c12c70001175e18/picture',
    organization_primary_domain: 'dhanushhealthcare.com',
    uuid: 'f854cfd7-e533-4573-9f3a-570ffe8e132a',
    sparkle_leads_id: '9223dbac-bd01-49ac-9d13-ff7ea89c9b77',
    campaign_id: '83536433-6680-42d3-bf36-efd554e69e32',
    temprory_id: '9580326e-14f0-43f2-8689-f0b9119458b0'
  },
  {
    id: '57dfa356a6da980b414b2031',
    first_name: 'Balakrishnan',
    last_name: 'Venkatesan',
    full_name: 'Balakrishnan Venkatesan',
    linkedin_url: 'http://www.linkedin.com/in/balakrishnan-venkatesan-769b2b10',
    title: 'Competency Head',
    photo_url: 'https://static.licdn.com/aero-v1/sc/h/9c8pery4andzj6ohjkjp54ma2',
    twitter_url: null,
    facebook_url: null,
    email: 'email_not_unlocked@domain.com',
    state: 'Telangana',
    city: 'Hyderabad',
    country: 'India',
    organization_name: 'Dhanush Group',
    organization_phone: '+91 40 4201 2454',
    organization_website_url: 'http://www.dhanushhealthcare.com',
    organization_linkedin_url: 'http://www.linkedin.com/company/dhanush-group',
    organization_twitter_url: 'https://twitter.com/DhanushHealth',
    organization_facebook_url: 'https://www.facebook.com/DhanushDigitalHealth',
    organization_founded_year: 2006,
    organization_logo_url: 'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66bd44860c12c70001175e18/picture',
    organization_primary_domain: 'dhanushhealthcare.com',
    uuid: 'b4f6d816-e9fe-4028-bb68-98021048c0f5',
    sparkle_leads_id: '9223dbac-bd01-49ac-9d13-ff7ea89c9b77',
    campaign_id: '83536433-6680-42d3-bf36-efd554e69e32',
    temprory_id: '9580326e-14f0-43f2-8689-f0b9119458b0'
  },
  {
    id: '614c16aa9737c40001726149',
    first_name: 'Kishore',
    last_name: 'Puri',
    full_name: 'Kishore Puri',
    linkedin_url: 'http://www.linkedin.com/in/kishore-puri-b01b59126',
    title: 'oracle apps finance functional consultant',
    photo_url: 'https://media.licdn.com/dms/image/C5603AQHk7yKeR8XBkg/profile-displayphoto-shrink_200_200/0/1517019600423?e=2147483647&v=beta&t=qBIsWvSDO1rlwJhJTOF0EJImrrylVYEEq6U-ixvzy_4',
    twitter_url: null,
    facebook_url: null,
    email: 'email_not_unlocked@domain.com',
    state: 'Haryana',
    city: 'Gurgaon',
    country: 'India',
    organization_name: 'Dhanush Group',
    organization_phone: '+91 40 4201 2454',
    organization_website_url: 'http://www.dhanushhealthcare.com',
    organization_linkedin_url: 'http://www.linkedin.com/company/dhanush-group',
    organization_twitter_url: 'https://twitter.com/DhanushHealth',
    organization_facebook_url: 'https://www.facebook.com/DhanushDigitalHealth',
    organization_founded_year: 2006,
    organization_logo_url: 'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66bd44860c12c70001175e18/picture',
    organization_primary_domain: 'dhanushhealthcare.com',
    uuid: '5acab0cf-18c1-4b2d-96bf-c7ec0a06e77e',
    sparkle_leads_id: '9223dbac-bd01-49ac-9d13-ff7ea89c9b77',
    campaign_id: '83536433-6680-42d3-bf36-efd554e69e32',
    temprory_id: '9580326e-14f0-43f2-8689-f0b9119458b0'
  },
  {
    id: '55714ea773696452f9d09900',
    first_name: 'Shridhar',
    last_name: 'Kurra',
    full_name: 'Shridhar Kurra',
    linkedin_url: 'http://www.linkedin.com/in/shridhar-kurra-187a5760',
    title: 'Sr Database Architect',
    photo_url: 'https://media.licdn.com/dms/image/v2/D5603AQEYfu4LI2dGOA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722947251992?e=2147483647&v=beta&t=EAB9i0DK5idFlzS6tfyu49zA9jGSEsIhyrpWcRdMmQ8',
    twitter_url: null,
    facebook_url: null,
    email: 'email_not_unlocked@domain.com',
    state: 'Telangana',
    city: 'Hyderabad',
    country: 'India',
    organization_name: 'Dhanush Group',
    organization_phone: '+91 40 4201 2454',
    organization_website_url: 'http://www.dhanushhealthcare.com',
    organization_linkedin_url: 'http://www.linkedin.com/company/dhanush-group',
    organization_twitter_url: 'https://twitter.com/DhanushHealth',
    organization_facebook_url: 'https://www.facebook.com/DhanushDigitalHealth',
    organization_founded_year: 2006,
    organization_logo_url: 'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66bd44860c12c70001175e18/picture',
    organization_primary_domain: 'dhanushhealthcare.com',
    uuid: '352a292d-6b2c-4d23-a3ec-af7f5cedf44a',
    sparkle_leads_id: '9223dbac-bd01-49ac-9d13-ff7ea89c9b77',
    campaign_id: '83536433-6680-42d3-bf36-efd554e69e32',
    temprory_id: '9580326e-14f0-43f2-8689-f0b9119458b0'
  },
  {
    id: '611b3cbcb6342d0001086b5b',
    first_name: 'Dhanush',
    last_name: 'Chandra',
    full_name: 'Dhanush Chandra',
    linkedin_url: 'http://www.linkedin.com/in/dhanushchandra',
    title: 'Business Head',
    photo_url: 'https://media.licdn.com/dms/image/C4E03AQHbC7YBlESUGw/profile-displayphoto-shrink_200_200/0/1516646448201?e=2147483647&v=beta&t=4Uj3LL6WvfIfgwxWrbeUgT5hPwMcnR_q5WnQs3rIT4E',
    twitter_url: null,
    facebook_url: null,
    email: 'email_not_unlocked@domain.com',
    state: 'Karnataka',
    city: 'Bengaluru',
    country: 'India',
    organization_name: 'Ninjacart',
    organization_phone: '+91 80468 00159',
    organization_website_url: 'http://www.ninjacart.in',
    organization_linkedin_url: 'http://www.linkedin.com/company/ninja-cart',
    organization_twitter_url: 'https://twitter.com/ninjacart',
    organization_facebook_url: 'https://www.facebook.com/ninjacart/',
    organization_founded_year: 2015,
    organization_logo_url: 'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66d24bba5d733c0001fd93bf/picture',
    organization_primary_domain: 'ninjacart.in',
    uuid: 'baadfcab-1caf-4b6f-8724-b11f282e8ab7',
    sparkle_leads_id: '9223dbac-bd01-49ac-9d13-ff7ea89c9b77',
    campaign_id: '83536433-6680-42d3-bf36-efd554e69e32',
    temprory_id: '9580326e-14f0-43f2-8689-f0b9119458b0'
  },
  {
    id: '64ff32d31053c0000172f23b',
    first_name: 'Srinivasa',
    last_name: 'Lanke',
    full_name: 'Srinivasa Lanke',
    linkedin_url: 'http://www.linkedin.com/in/srinivasa-rao-lanke-281129236',
    title: 'Area manager',
    photo_url: 'https://media.licdn.com/dms/image/C4E03AQF3YmmNbYSe-w/profile-displayphoto-shrink_800_800/0/1648545960859?e=2147483647&v=beta&t=jUKvii8mGfM_5z67uZnp4DqbHyPD_2SUzU-ArxJSoFI',
    twitter_url: null,
    facebook_url: null,
    email: 'email_not_unlocked@domain.com',
    state: 'Telangana',
    city: 'Hyderabad',
    country: 'India',
    organization_name: 'Dhanush Softech',
    organization_phone: null,
    organization_website_url: 'http://www.dhanushinfotech.com',
    organization_linkedin_url: 'http://www.linkedin.com/company/dhanushsoftech',
    organization_twitter_url: 'https://twitter.com/dhanushkenya',
    organization_facebook_url: 'https://facebook.com/dhanush-infotech-limited-kenya-167808186701616',
    organization_founded_year: 2006,
    organization_logo_url: 'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/6695a56c2372e200017fa2c9/picture',
    organization_primary_domain: 'dhanushinfotech.com',
    uuid: '85e77c3a-a426-4974-8b8b-3ef59fbbadf0',
    sparkle_leads_id: '9223dbac-bd01-49ac-9d13-ff7ea89c9b77',
    campaign_id: '83536433-6680-42d3-bf36-efd554e69e32',
    temprory_id: '9580326e-14f0-43f2-8689-f0b9119458b0'
  },
  {
    id: '6019d1975045730001c13dc8',
    first_name: 'Rajender',
    last_name: 'Ameelpur',
    full_name: 'Rajender Ameelpur',
    linkedin_url: 'http://www.linkedin.com/in/rajender-ameelpur-015762192',
    title: 'Senior Software Developer',
    photo_url: 'https://media.licdn.com/dms/image/C5103AQFx0hv5uiijug/profile-displayphoto-shrink_100_100/0/1567069760576?e=2147483647&v=beta&t=hrvQJzgG2T_klPZ6G7-PUKN5cK7jDxgqdH1xzxTU06w',
    twitter_url: null,
    facebook_url: null,
    email: 'email_not_unlocked@domain.com',
    state: 'Queensland',
    city: 'Brisbane',
    country: 'Australia',
    organization_name: 'Dhanush Group',
    organization_phone: '+91 40 4201 2454',
    organization_website_url: 'http://www.dhanushhealthcare.com',
    organization_linkedin_url: 'http://www.linkedin.com/company/dhanush-group',
    organization_twitter_url: 'https://twitter.com/DhanushHealth',
    organization_facebook_url: 'https://www.facebook.com/DhanushDigitalHealth',
    organization_founded_year: 2006,
    organization_logo_url: 'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66bd44860c12c70001175e18/picture',
    organization_primary_domain: 'dhanushhealthcare.com',
    uuid: '26e0a69d-76b1-419d-8c03-920aa5ca0949',
    sparkle_leads_id: '9223dbac-bd01-49ac-9d13-ff7ea89c9b77',
    campaign_id: '83536433-6680-42d3-bf36-efd554e69e32',
    temprory_id: '9580326e-14f0-43f2-8689-f0b9119458b0'
  },
  {
    id: '63dd3a6741acfc0001927ef7',
    first_name: 'Dhanush',
    last_name: 'S',
    full_name: 'Dhanush S',
    linkedin_url: 'http://www.linkedin.com/in/dhanush-s-5896b01b3',
    title: 'Project Manager',
    photo_url: 'https://media.licdn.com/dms/image/C4D03AQF2rh6bzwx0CQ/profile-displayphoto-shrink_800_800/0/1659025942237?e=2147483647&v=beta&t=KADRLr-jlOysr14eSF94GVHTNBsQLy6aLob_NYsrpkw',
    twitter_url: null,
    facebook_url: null,
    email: 'email_not_unlocked@domain.com',
    state: 'Tamil Nadu',
    city: null,
    country: 'India',
    organization_name: 'Baker Hughes',
    organization_phone: '+1 346-330-1150',
    organization_website_url: 'http://www.bakerhughes.com',
    organization_linkedin_url: 'http://www.linkedin.com/company/bakerhughes',
    organization_twitter_url: 'https://twitter.com/bakerhughesco',
    organization_facebook_url: null,
    organization_founded_year: null,
    organization_logo_url: 'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66d6d9dcab51030001128294/picture',
    organization_primary_domain: 'bakerhughes.com',
    uuid: 'a5f89f06-f180-4a14-a84a-fbd6b7575fe2',
    sparkle_leads_id: '9223dbac-bd01-49ac-9d13-ff7ea89c9b77',
    campaign_id: '83536433-6680-42d3-bf36-efd554e69e32',
    temprory_id: '9580326e-14f0-43f2-8689-f0b9119458b0'
  },
  {
    id: '57d60560a6da986148b90711',
    first_name: 'Shylaja',
    last_name: 'Mallela',
    full_name: 'Shylaja Mallela',
    linkedin_url: 'http://www.linkedin.com/in/shylaja-mallela-600340103',
    title: 'QA Analysts',
    photo_url: 'https://static.licdn.com/aero-v1/sc/h/244xhbkr7g40x6bsu4gi6q4ry',
    twitter_url: null,
    facebook_url: null,
    email: 'email_not_unlocked@domain.com',
    state: 'Telangana',
    city: 'Hyderabad',
    country: 'India',
    organization_name: 'Dhanush Group',
    organization_phone: '+91 40 4201 2454',
    organization_website_url: 'http://www.dhanushhealthcare.com',
    organization_linkedin_url: 'http://www.linkedin.com/company/dhanush-group',
    organization_twitter_url: 'https://twitter.com/DhanushHealth',
    organization_facebook_url: 'https://www.facebook.com/DhanushDigitalHealth',
    organization_founded_year: 2006,
    organization_logo_url: 'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66bd44860c12c70001175e18/picture',
    organization_primary_domain: 'dhanushhealthcare.com',
    uuid: 'f00f987c-964c-4485-917b-fa1d48b37ddf',
    sparkle_leads_id: '9223dbac-bd01-49ac-9d13-ff7ea89c9b77',
    campaign_id: '83536433-6680-42d3-bf36-efd554e69e32',
    temprory_id: '9580326e-14f0-43f2-8689-f0b9119458b0'
  },
  {
    id: '626bb6f1b113c800014c6c21',
    first_name: 'V',
    last_name: 'Euphc',
    full_name: 'V Euphc',
    linkedin_url: 'http://www.linkedin.com/in/v-v-satyanarayana-mak-euphc-10277b18b',
    title: 'Area Manager',
    photo_url: 'https://static.licdn.com/aero-v1/sc/h/9c8pery4andzj6ohjkjp54ma2',
    twitter_url: null,
    facebook_url: null,
    email: 'email_not_unlocked@domain.com',
    state: 'Andhra Pradesh',
    city: null,
    country: 'India',
    organization_name: 'Dhanush Group',
    organization_phone: '+91 40 4201 2454',
    organization_website_url: 'http://www.dhanushhealthcare.com',
    organization_linkedin_url: 'http://www.linkedin.com/company/dhanush-group',
    organization_twitter_url: 'https://twitter.com/DhanushHealth',
    organization_facebook_url: 'https://www.facebook.com/DhanushDigitalHealth',
    organization_founded_year: 2006,
    organization_logo_url: 'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66bd44860c12c70001175e18/picture',
    organization_primary_domain: 'dhanushhealthcare.com',
    uuid: '7781d99c-a3b6-4cf1-b46a-636a4b460ad3',
    sparkle_leads_id: '9223dbac-bd01-49ac-9d13-ff7ea89c9b77',
    campaign_id: '83536433-6680-42d3-bf36-efd554e69e32',
    temprory_id: '9580326e-14f0-43f2-8689-f0b9119458b0'
  },
  {
    id: '54a2209a74686935be180c0f',
    first_name: 'Srikanth',
    last_name: 'Amara',
    full_name: 'Srikanth Amara',
    linkedin_url: 'http://www.linkedin.com/in/srikanth-amara-0b677843',
    title: 'Technical Support Engineer',
    photo_url: 'https://static.licdn.com/aero-v1/sc/h/9c8pery4andzj6ohjkjp54ma2',
    twitter_url: null,
    facebook_url: null,
    email: 'email_not_unlocked@domain.com',
    state: 'Telangana',
    city: 'Hyderabad',
    country: 'India',
    organization_name: 'Dhanush Group',
    organization_phone: '+91 40 4201 2454',
    organization_website_url: 'http://www.dhanushhealthcare.com',
    organization_linkedin_url: 'http://www.linkedin.com/company/dhanush-group',
    organization_twitter_url: 'https://twitter.com/DhanushHealth',
    organization_facebook_url: 'https://www.facebook.com/DhanushDigitalHealth',
    organization_founded_year: 2006,
    organization_logo_url: 'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66bd44860c12c70001175e18/picture',
    organization_primary_domain: 'dhanushhealthcare.com',
    uuid: 'edde6f74-0882-4879-b9a0-c550ea816618',
    sparkle_leads_id: '9223dbac-bd01-49ac-9d13-ff7ea89c9b77',
    campaign_id: '83536433-6680-42d3-bf36-efd554e69e32',
    temprory_id: '9580326e-14f0-43f2-8689-f0b9119458b0'
  },
  {
    id: '625314b646490100019d6e4a',
    first_name: 'Dhanush',
    last_name: 'Arul',
    full_name: 'Dhanush Arul',
    linkedin_url: 'http://www.linkedin.com/in/dhanush-arul-543a66bb',
    title: 'Assistant Manager',
    photo_url: 'https://media.licdn.com/dms/image/v2/D5603AQH9Or8Ofc5l0A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694501795187?e=2147483647&v=beta&t=V-jPcrGWRNjIlU0VaGWLTjZZzHzcF8KzYQhZ-8GVW4A',
    twitter_url: null,
    facebook_url: null,
    email: 'email_not_unlocked@domain.com',
    state: 'Tamil Nadu',
    city: 'Coimbatore',
    country: 'India',
    organization_name: 'Larsen & Toubro',
    organization_phone: '+91 22 6752 5656',
    organization_website_url: 'http://www.larsentoubro.com',
    organization_linkedin_url: 'http://www.linkedin.com/company/larsen-&-toubro-limited',
    organization_twitter_url: 'https://twitter.com/larsentoubro',
    organization_facebook_url: 'https://facebook.com/lntelectricalandautomation',
    organization_founded_year: 1938,
    organization_logo_url: 'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66d15c1d382c2600018661c7/picture',
    organization_primary_domain: 'larsentoubro.com',
    uuid: '1609971a-2cdf-4efe-8c42-a737cae53065',
    sparkle_leads_id: '9223dbac-bd01-49ac-9d13-ff7ea89c9b77',
    campaign_id: '83536433-6680-42d3-bf36-efd554e69e32',
    temprory_id: '9580326e-14f0-43f2-8689-f0b9119458b0'
  },
  {
    id: '54a13a5a69702de5b3a9c900',
    first_name: 'Deepika',
    last_name: 'Macha',
    full_name: 'Deepika Macha',
    linkedin_url: 'http://www.linkedin.com/in/deepika-macha-19427245',
    title: 'Software Engineer',
    photo_url: 'https://static.licdn.com/aero-v1/sc/h/9c8pery4andzj6ohjkjp54ma2',
    twitter_url: null,
    facebook_url: null,
    email: 'email_not_unlocked@domain.com',
    state: 'Telangana',
    city: 'Hyderabad',
    country: 'India',
    organization_name: 'Dhanush Group',
    organization_phone: '+91 40 4201 2454',
    organization_website_url: 'http://www.dhanushhealthcare.com',
    organization_linkedin_url: 'http://www.linkedin.com/company/dhanush-group',
    organization_twitter_url: 'https://twitter.com/DhanushHealth',
    organization_facebook_url: 'https://www.facebook.com/DhanushDigitalHealth',
    organization_founded_year: 2006,
    organization_logo_url: 'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66bd44860c12c70001175e18/picture',
    organization_primary_domain: 'dhanushhealthcare.com',
    uuid: '4d75ec07-2d25-48bb-8c0c-cee157806ad3',
    sparkle_leads_id: '9223dbac-bd01-49ac-9d13-ff7ea89c9b77',
    campaign_id: '83536433-6680-42d3-bf36-efd554e69e32',
    temprory_id: '9580326e-14f0-43f2-8689-f0b9119458b0'
  },
  {
    id: '5f50997f5ca1ca0001f35c9d',
    first_name: 'Venumadhav',
    last_name: 'Sreeramoju',
    full_name: 'Venumadhav Sreeramoju',
    linkedin_url: 'http://www.linkedin.com/in/venumadhav-sreeramoju-5b3aa318b',
    title: 'Business Head',
    photo_url: 'https://media.licdn.com/dms/image/C5103AQEnfDxEm8pxFQ/profile-displayphoto-shrink_200_200/0/1563471151079?e=2147483647&v=beta&t=5o97ScQ69uCOmqddFtNkvUWJ3fKFvvkdOZkUn44s84c',
    twitter_url: null,
    facebook_url: null,
    email: 'email_not_unlocked@domain.com',
    state: 'Telangana',
    city: 'Hyderabad',
    country: 'India',
    organization_name: 'Dhanush Group',
    organization_phone: '+91 40 4201 2454',
    organization_website_url: 'http://www.dhanushhealthcare.com',
    organization_linkedin_url: 'http://www.linkedin.com/company/dhanush-group',
    organization_twitter_url: 'https://twitter.com/DhanushHealth',
    organization_facebook_url: 'https://www.facebook.com/DhanushDigitalHealth',
    organization_founded_year: 2006,
    organization_logo_url: 'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66bd44860c12c70001175e18/picture',
    organization_primary_domain: 'dhanushhealthcare.com',
    uuid: 'ef7680e1-f15d-4109-9e8b-2edaf899e412',
    sparkle_leads_id: '9223dbac-bd01-49ac-9d13-ff7ea89c9b77',
    campaign_id: '83536433-6680-42d3-bf36-efd554e69e32',
    temprory_id: '9580326e-14f0-43f2-8689-f0b9119458b0'
  },
  {
    id: '6433126661b9f40001176268',
    first_name: 'Dhanush',
    last_name: null,
    full_name: 'Dhanush',
    linkedin_url: 'http://www.linkedin.com/in/ndhanush',
    title: 'Student',
    photo_url: 'https://media.licdn.com/dms/image/C5603AQEX8wIgkQdpOw/profile-displayphoto-shrink_200_200/0/1630056296588?e=2147483647&v=beta&t=-EYVikSnPxeJKTMSduj62wVw_1pU-7wNVVpGWFFRnKY',
    twitter_url: null,
    facebook_url: null,
    email: 'email_not_unlocked@domain.com',
    state: 'Karnataka',
    city: 'Mangaluru',
    country: 'India',
    organization_name: 'Sahyadri College of Engineering & Management',
    organization_phone: '+91 82 4227 7222',
    organization_website_url: 'http://www.sahyadri.edu.in',
    organization_linkedin_url: 'http://www.linkedin.com/school/sahyadri',
    organization_twitter_url: 'https://twitter.com/sahyadri_campus',
    organization_facebook_url: 'https://www.facebook.com/Sahyadri.edu/',
    organization_founded_year: 2007,
    organization_logo_url: 'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66c225555c86fb00012087d6/picture',
    organization_primary_domain: 'sahyadri.edu.in',
    uuid: '9c6b72a3-c3fd-4356-9c31-6af25cd676a4',
    sparkle_leads_id: '9223dbac-bd01-49ac-9d13-ff7ea89c9b77',
    campaign_id: '83536433-6680-42d3-bf36-efd554e69e32',
    temprory_id: '9580326e-14f0-43f2-8689-f0b9119458b0'
  },
  {
    id: '57d85a31a6da987813ee6e20',
    first_name: 'Raj',
    last_name: 'Srinivas',
    full_name: 'Raj Srinivas',
    linkedin_url: 'http://www.linkedin.com/in/raj-srinivas-025332121',
    title: 'Software Engineer',
    photo_url: 'https://static.licdn.com/aero-v1/sc/h/9c8pery4andzj6ohjkjp54ma2',
    twitter_url: null,
    facebook_url: null,
    email: 'email_not_unlocked@domain.com',
    state: 'Andhra Pradesh',
    city: null,
    country: 'India',
    organization_name: 'Dhanush Group',
    organization_phone: '+91 40 4201 2454',
    organization_website_url: 'http://www.dhanushhealthcare.com',
    organization_linkedin_url: 'http://www.linkedin.com/company/dhanush-group',
    organization_twitter_url: 'https://twitter.com/DhanushHealth',
    organization_facebook_url: 'https://www.facebook.com/DhanushDigitalHealth',
    organization_founded_year: 2006,
    organization_logo_url: 'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66bd44860c12c70001175e18/picture',
    organization_primary_domain: 'dhanushhealthcare.com',
    uuid: 'f0eeca13-df1c-4299-b756-b4e5214b7125',
    sparkle_leads_id: '9223dbac-bd01-49ac-9d13-ff7ea89c9b77',
    campaign_id: '83536433-6680-42d3-bf36-efd554e69e32',
    temprory_id: '9580326e-14f0-43f2-8689-f0b9119458b0'
  },
  {
    id: '64249087a15fc20001ab0f50',
    first_name: 'Dhanush',
    last_name: 'Shetty',
    full_name: 'Dhanush Shetty',
    linkedin_url: 'http://www.linkedin.com/in/dhanush-shetty-771a20234',
    title: 'Sr. Business Analyst',
    photo_url: 'https://media.licdn.com/dms/image/D4D03AQF3N7KywkHZiw/profile-displayphoto-shrink_200_200/0/1716313812061?e=2147483647&v=beta&t=dJn4aZl8pNKQnD5HTgMceIxnlIuj4PyCxNyK5APgseI',
    twitter_url: null,
    facebook_url: null,
    email: 'email_not_unlocked@domain.com',
    state: 'Maharashtra',
    city: 'Mumbai',
    country: 'India',
    organization_name: 'Neebal Technologies',
    organization_phone: '+91 22285 74118',
    organization_website_url: 'http://www.neebal.com',
    organization_linkedin_url: 'http://www.linkedin.com/company/neebal-technologies',
    organization_twitter_url: 'https://twitter.com/NeebalTech',
    organization_facebook_url: 'https://www.facebook.com/NeebalTech',
    organization_founded_year: 2012,
    organization_logo_url: 'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66c2078efc7ebf0001a1394b/picture',
    organization_primary_domain: 'neebal.com',
    uuid: '0db7f5d1-b6f5-4414-91ed-ee2336a8d20f',
    sparkle_leads_id: '9223dbac-bd01-49ac-9d13-ff7ea89c9b77',
    campaign_id: '83536433-6680-42d3-bf36-efd554e69e32',
    temprory_id: '9580326e-14f0-43f2-8689-f0b9119458b0'
  },
  {
    id: '57ddc447a6da987b015b39f2',
    first_name: 'Oumar',
    last_name: 'Arabi',
    full_name: 'Oumar Arabi',
    linkedin_url: 'http://www.linkedin.com/in/oumar-mahamat-arabi-3b529314a',
    title: 'Oracle Functional Consultant',
    photo_url: 'https://media.licdn.com/dms/image/C5603AQGgyfI7TAxgjA/profile-displayphoto-shrink_200_200/0/1517838935374?e=2147483647&v=beta&t=_DT0r_9XzTUMdTWZ6QvMmID2XRxVcM9rWAxMC2mInTA',
    twitter_url: null,
    facebook_url: null,
    email: 'email_not_unlocked@domain.com',
    state: null,
    city: null,
    country: 'Chad',
    organization_name: 'Dhanush Group',
    organization_phone: '+91 40 4201 2454',
    organization_website_url: 'http://www.dhanushhealthcare.com',
    organization_linkedin_url: 'http://www.linkedin.com/company/dhanush-group',
    organization_twitter_url: 'https://twitter.com/DhanushHealth',
    organization_facebook_url: 'https://www.facebook.com/DhanushDigitalHealth',
    organization_founded_year: 2006,
    organization_logo_url: 'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66bd44860c12c70001175e18/picture',
    organization_primary_domain: 'dhanushhealthcare.com',
    uuid: 'c23f9fe6-1e86-4743-b846-15659a64dd86',
    sparkle_leads_id: '9223dbac-bd01-49ac-9d13-ff7ea89c9b77',
    campaign_id: '83536433-6680-42d3-bf36-efd554e69e32',
    temprory_id: '9580326e-14f0-43f2-8689-f0b9119458b0'
  },
  {
    id: '637357986664f5000109b33a',
    first_name: 'Amit',
    last_name: 'Singh',
    full_name: 'Amit Singh',
    linkedin_url: 'http://www.linkedin.com/in/amit-singh-431a39224',
    title: 'Talent Acquisition Specialist',
    photo_url: 'https://media.licdn.com/dms/image/D5603AQEZjsCOFWbbVA/profile-displayphoto-shrink_200_200/0/1699729680831?e=2147483647&v=beta&t=kFvdBT7MkKYpljaGuwPvSNhH3OCipo9RdssKSfgvhKI',
    twitter_url: null,
    facebook_url: null,
    email: 'email_not_unlocked@domain.com',
    state: 'Telangana',
    city: 'Hyderabad',
    country: 'India',
    organization_name: 'Dhanush Group',
    organization_phone: '+91 40 4201 2454',
    organization_website_url: 'http://www.dhanushhealthcare.com',
    organization_linkedin_url: 'http://www.linkedin.com/company/dhanush-group',
    organization_twitter_url: 'https://twitter.com/DhanushHealth',
    organization_facebook_url: 'https://www.facebook.com/DhanushDigitalHealth',
    organization_founded_year: 2006,
    organization_logo_url: 'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66bd44860c12c70001175e18/picture',
    organization_primary_domain: 'dhanushhealthcare.com',
    uuid: '2925ff7e-f5df-46cb-941d-f35d7f6619ab',
    sparkle_leads_id: '9223dbac-bd01-49ac-9d13-ff7ea89c9b77',
    campaign_id: '83536433-6680-42d3-bf36-efd554e69e32',
    temprory_id: '9580326e-14f0-43f2-8689-f0b9119458b0'
  },
  {
    id: '54a7b34e74686968290fc14c',
    first_name: 'Sharath',
    last_name: 'Prabhakar',
    full_name: 'Sharath Prabhakar',
    linkedin_url: 'http://www.linkedin.com/in/sharath-prabhakar-7557b32a',
    title: 'consultant',
    photo_url: 'https://static.licdn.com/aero-v1/sc/h/9c8pery4andzj6ohjkjp54ma2',
    twitter_url: null,
    facebook_url: null,
    email: 'email_not_unlocked@domain.com',
    state: 'Telangana',
    city: 'Hyderabad',
    country: 'India',
    organization_name: 'Dhanush Group',
    organization_phone: '+91 40 4201 2454',
    organization_website_url: 'http://www.dhanushhealthcare.com',
    organization_linkedin_url: 'http://www.linkedin.com/company/dhanush-group',
    organization_twitter_url: 'https://twitter.com/DhanushHealth',
    organization_facebook_url: 'https://www.facebook.com/DhanushDigitalHealth',
    organization_founded_year: 2006,
    organization_logo_url: 'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66bd44860c12c70001175e18/picture',
    organization_primary_domain: 'dhanushhealthcare.com',
    uuid: 'faf9d0fa-5f47-4de2-bc5b-15346dec41c0',
    sparkle_leads_id: '9223dbac-bd01-49ac-9d13-ff7ea89c9b77',
    campaign_id: '83536433-6680-42d3-bf36-efd554e69e32',
    temprory_id: '9580326e-14f0-43f2-8689-f0b9119458b0'
  },
  {
    id: '5e6c319e11be420001bb14a4',
    first_name: 'Rachaveti',
    last_name: 'Dhanush',
    full_name: 'Rachaveti Dhanush',
    linkedin_url: 'http://www.linkedin.com/in/rachaveti-dhanush',
    title: 'Associate Professor',
    photo_url: 'https://media.licdn.com/dms/image/C5603AQHlHzE3cFCDwA/profile-displayphoto-shrink_200_200/0/1627472064180?e=2147483647&v=beta&t=tdRNf3WcmBdcDMg6-uDZBbLaR92yJuIL4r0YhlWQhWI',
    twitter_url: null,
    facebook_url: null,
    email: 'email_not_unlocked@domain.com',
    state: 'Tamil Nadu',
    city: 'Chennai',
    country: 'India',
    organization_name: 'Vellore Institute of Technology',
    organization_phone: '+91 41622 43091',
    organization_website_url: 'http://www.vit.ac.in',
    organization_linkedin_url: 'http://www.linkedin.com/school/vellore-institute-of-technology',
    organization_twitter_url: 'https://twitter.com/vit_univ',
    organization_facebook_url: 'https://facebook.com/vituniversity',
    organization_founded_year: 1984,
    organization_logo_url: 'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66d303a6a883e1000121f8b4/picture',
    organization_primary_domain: 'vit.ac.in',
    uuid: '6d1ee15e-6fce-4c2e-9ee9-65d4bdb97ff5',
    sparkle_leads_id: '9223dbac-bd01-49ac-9d13-ff7ea89c9b77',
    campaign_id: '83536433-6680-42d3-bf36-efd554e69e32',
    temprory_id: '9580326e-14f0-43f2-8689-f0b9119458b0'
  },
  {
    id: '5ae09fc0a6da984cbf89b75f',
    first_name: 'Sruthi',
    last_name: 'Reddy',
    full_name: 'Sruthi Reddy',
    linkedin_url: 'http://www.linkedin.com/in/sruthi-reddy-272a6720',
    title: 'Business Analyst',
    photo_url: 'https://static.licdn.com/aero-v1/sc/h/9c8pery4andzj6ohjkjp54ma2',
    twitter_url: null,
    facebook_url: null,
    email: 'email_not_unlocked@domain.com',
    state: 'Telangana',
    city: 'Hyderabad',
    country: 'India',
    organization_name: 'Dhanush Group',
    organization_phone: '+91 40 4201 2454',
    organization_website_url: 'http://www.dhanushhealthcare.com',
    organization_linkedin_url: 'http://www.linkedin.com/company/dhanush-group',
    organization_twitter_url: 'https://twitter.com/DhanushHealth',
    organization_facebook_url: 'https://www.facebook.com/DhanushDigitalHealth',
    organization_founded_year: 2006,
    organization_logo_url: 'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66bd44860c12c70001175e18/picture',
    organization_primary_domain: 'dhanushhealthcare.com',
    uuid: '01c3cb43-ece0-410d-8787-18718c42df5e',
    sparkle_leads_id: '9223dbac-bd01-49ac-9d13-ff7ea89c9b77',
    campaign_id: '83536433-6680-42d3-bf36-efd554e69e32',
    temprory_id: '9580326e-14f0-43f2-8689-f0b9119458b0'
  },
  {
    id: '54a265af7468693a7ebdd51f',
    first_name: 'Anirud',
    last_name: 'Thapliyal',
    full_name: 'Anirud Thapliyal',
    linkedin_url: 'http://www.linkedin.com/in/anirud-thapliyal-6984b224',
    title: 'Software Trainee',
    photo_url: 'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
    twitter_url: null,
    facebook_url: null,
    email: 'email_not_unlocked@domain.com',
    state: 'Telangana',
    city: 'Hyderabad',
    country: 'India',
    organization_name: 'Dhanush Group',
    organization_phone: '+91 40 4201 2454',
    organization_website_url: 'http://www.dhanushhealthcare.com',
    organization_linkedin_url: 'http://www.linkedin.com/company/dhanush-group',
    organization_twitter_url: 'https://twitter.com/DhanushHealth',
    organization_facebook_url: 'https://www.facebook.com/DhanushDigitalHealth',
    organization_founded_year: 2006,
    organization_logo_url: 'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66bd44860c12c70001175e18/picture',
    organization_primary_domain: 'dhanushhealthcare.com',
    uuid: 'c9ab9ebb-ce88-4c8f-a35b-33df3a77d6c6',
    sparkle_leads_id: '9223dbac-bd01-49ac-9d13-ff7ea89c9b77',
    campaign_id: '83536433-6680-42d3-bf36-efd554e69e32',
    temprory_id: '9580326e-14f0-43f2-8689-f0b9119458b0'
  },
  {
    id: '60eaff353cab8f0001fa378d',
    first_name: 'Vikhil',
    last_name: 'Neerati',
    full_name: 'Vikhil Neerati',
    linkedin_url: 'http://www.linkedin.com/in/vikhil-neerati-31772618a',
    title: 'ICT Engineer',
    photo_url: 'https://static.licdn.com/aero-v1/sc/h/9c8pery4andzj6ohjkjp54ma2',
    twitter_url: null,
    facebook_url: null,
    email: 'email_not_unlocked@domain.com',
    state: 'Telangana',
    city: 'Hyderabad',
    country: 'India',
    organization_name: 'Dhanush Group',
    organization_phone: '+91 40 4201 2454',
    organization_website_url: 'http://www.dhanushhealthcare.com',
    organization_linkedin_url: 'http://www.linkedin.com/company/dhanush-group',
    organization_twitter_url: 'https://twitter.com/DhanushHealth',
    organization_facebook_url: 'https://www.facebook.com/DhanushDigitalHealth',
    organization_founded_year: 2006,
    organization_logo_url: 'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66bd44860c12c70001175e18/picture',
    organization_primary_domain: 'dhanushhealthcare.com',
    uuid: 'cad24ff2-301d-4030-a92a-ab50134a1deb',
    sparkle_leads_id: '9223dbac-bd01-49ac-9d13-ff7ea89c9b77',
    campaign_id: '83536433-6680-42d3-bf36-efd554e69e32',
    temprory_id: '9580326e-14f0-43f2-8689-f0b9119458b0'
  }
]



export const seconds = new Array(60).map(
  (item, index, arr) => (arr[index] = index + 1)
)

export const hours = new Array(12).map(
  (item, index, arr) => (arr[index] = index + 1)
)

export const weeks = ['M', 'T', 'W', 'Th', 'F', 'S', 'Sun']

export const countryList = ["AD",
  "AE",
  "AF",
  "AG",
  "AI",
  "AL",
  "AM",
  "AO",
  "AQ",
  "AR",
  "AS",
  "AT",
  "AU",
  "AW",
  "AX",
  "AZ",
  "BA",
  "BB",
  "BD",
  "BE",
  "BF",
  "BG",
  "BH",
  "BI",
  "BJ",
  "BL",
  "BM",
  "BN",
  "BO",
  "BQ",
  "BR",
  "BS",
  "BT",
  "BV",
  "BW",
  "BY",
  "BZ",
  "CA",
  "CC",
  "CD",
  "CF",
  "CG",
  "CH",
  "CI",
  "CK",
  "CL",
  "CM",
  "CN",
  "CO",
  "CR",
  "CU",
  "CV",
  "CW",
  "CX",
  "CY",
  "CZ",
  "DE",
  "DJ",
  "DK",
  "DM",
  "DO",
  "DZ",
  "EC",
  "EE",
  "EG",
  "EH",
  "ER",
  "ES",
  "ET",
  "FI",
  "FJ",
  "FK",
  "FM",
  "FO",
  "FR",
  "GA",
  "GB",
  "GD",
  "GE",
  "GF",
  "GG",
  "GH",
  "GI",
  "GL",
  "GM",
  "GN",
  "GP",
  "GQ",
  "GR",
  "GS",
  "GT",
  "GU",
  "GW",
  "GY",
  "HK",
  "HM",
  "HN",
  "HR",
  "HT",
  "HU",
  "ID",
  "IE",
  "IL",
  "IM",
  "IN",
  "IO",
  "IQ",
  "IR",
  "IS",
  "IT",
  "JE",
  "JM",
  "JO",
  "JP",
  "KE",
  "KG",
  "KH",
  "KI",
  "KM",
  "KN",
  "KP",
  "KR",
  "KW",
  "KY",
  "KZ",
  "LA",
  "LB",
  "LC",
  "LI",
  "LK",
  "LR",
  "LS",
  "LT",
  "LU",
  "LV",
  "LY",
  "MA",
  "MC",
  "MD",
  "ME",
  "MF",
  "MG",
  "MH",
  "MK",
  "ML",
  "MM",
  "MN",
  "MO",
  "MP",
  "MQ",
  "MR",
  "MS",
  "MT",
  "MU",
  "MV",
  "MW",
  "MX",
  "MY",
  "MZ",
  "NA",
  "NC",
  "NE",
  "NF",
  "NG",
  "NI",
  "NL",
  "NO",
  "NP",
  "NR",
  "NU",
  "NZ",
  "OM",
  "PA",
  "PE",
  "PF",
  "PG",
  "PH",
  "PK",
  "PL",
  "PM",
  "PN",
  "PR",
  "PS",
  "PT",
  "PW",
  "PY",
  "QA",
  "RE",
  "RO",
  "RS",
  "RU",
  "RW",
  "SA",
  "SB",
  "SC",
  "SD",
  "SE",
  "SG",
  "SH",
  "SI",
  "SJ",
  "SK",
  "SL",
  "SM",
  "SN",
  "SO",
  "SR",
  "SS",
  "ST",
  "SV",
  "SX",
  "SY",
  "SZ",
  "TC",
  "TD",
  "TF",
  "TG",
  "TH",
  "TJ",
  "TK",
  "TL",
  "TM",
  "TN",
  "TO",
  "TR",
  "TT",
  "TV",
  "TW",
  "TZ",
  "UA",
  "UG",
  "UM",
  "US",
  "UY",
  "UZ",
  "VA",
  "VC",
  "VE",
  "VG",
  "VI",
  "VN",
  "VU",
  "WF",
  "WS",
  "YE",
  "YT",
  "ZA",
  "ZM",
  "ZW"]


export const timeZones = [
  'Asia/Kolkata',
  'Europe/Andorra',
  'Asia/Dubai',
  'Asia/Kabul',
  'Europe/Tirane',
  'Asia/Yerevan',
  'Antarctica/Casey',
  'Antarctica/Davis',
  'Antarctica/DumontDUrville',
  'Antarctica/Mawson',
  'Antarctica/Palmer',
  'Antarctica/Rothera',
  'Antarctica/Syowa',
  'Antarctica/Troll',
  'Antarctica/Vostok',
  'America/Argentina/Buenos_Aires',
  'America/Argentina/Cordoba',
  'America/Argentina/Salta',
  'America/Argentina/Jujuy',
  'America/Argentina/Tucuman',
  'America/Argentina/Catamarca',
  'America/Argentina/La_Rioja',
  'America/Argentina/San_Juan',
  'America/Argentina/Mendoza',
  'America/Argentina/San_Luis',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Ushuaia',
  'Pacific/Pago_Pago',
  'Europe/Vienna',
  'Australia/Lord_Howe',
  'Antarctica/Macquarie',
  'Australia/Hobart',
  'Australia/Currie',
  'Australia/Melbourne',
  'Australia/Sydney',
  'Australia/Broken_Hill',
  'Australia/Brisbane',
  'Australia/Lindeman',
  'Australia/Adelaide',
  'Australia/Darwin',
  'Australia/Perth',
  'Australia/Eucla',
  'Asia/Baku',
  'America/Barbados',
  'Asia/Dhaka',
  'Europe/Brussels',
  'Europe/Sofia',
  'Atlantic/Bermuda',
  'Asia/Brunei',
  'America/La_Paz',
  'America/Noronha',
  'America/Belem',
  'America/Fortaleza',
  'America/Recife',
  'America/Araguaina',
  'America/Maceio',
  'America/Bahia',
  'America/Sao_Paulo',
  'America/Campo_Grande',
  'America/Cuiaba',
  'America/Santarem',
  'America/Porto_Velho',
  'America/Boa_Vista',
  'America/Manaus',
  'America/Eirunepe',
  'America/Rio_Branco',
  'America/Nassau',
  'Asia/Thimphu',
  'Europe/Minsk',
  'America/Belize',
  'America/St_Johns',
  'America/Halifax',
  'America/Glace_Bay',
  'America/Moncton',
  'America/Goose_Bay',
  'America/Blanc-Sablon',
  'America/Toronto',
  'America/Nipigon',
  'America/Thunder_Bay',
  'America/Iqaluit',
  'America/Pangnirtung',
  'America/Atikokan',
  'America/Winnipeg',
  'America/Rainy_River',
  'America/Resolute',
  'America/Rankin_Inlet',
  'America/Regina',
  'America/Swift_Current',
  'America/Edmonton',
  'America/Cambridge_Bay',
  'America/Yellowknife',
  'America/Inuvik',
  'America/Creston',
  'America/Dawson_Creek',
  'America/Fort_Nelson',
  'America/Vancouver',
  'America/Whitehorse',
  'America/Dawson',
  'Indian/Cocos',
  'Europe/Zurich',
  'Africa/Abidjan',
  'Pacific/Rarotonga',
  'America/Santiago',
  'America/Punta_Arenas',
  'Pacific/Easter',
  'Asia/Shanghai',
  'Asia/Urumqi',
  'America/Bogota',
  'America/Costa_Rica',
  'America/Havana',
  'Atlantic/Cape_Verde',
  'America/Curacao',
  'Indian/Christmas',
  'Asia/Nicosia',
  'Asia/Famagusta',
  'Europe/Prague',
  'Europe/Berlin',
  'Europe/Copenhagen',
  'America/Santo_Domingo',
  'Africa/Algiers',
  'America/Guayaquil',
  'Pacific/Galapagos',
  'Europe/Tallinn',
  'Africa/Cairo',
  'Africa/El_Aaiun',
  'Europe/Madrid',
  'Africa/Ceuta',
  'Atlantic/Canary',
  'Europe/Helsinki',
  'Pacific/Fiji',
  'Atlantic/Stanley',
  'Pacific/Chuuk',
  'Pacific/Pohnpei',
  'Pacific/Kosrae',
  'Atlantic/Faroe',
  'Europe/Paris',
  'Europe/London',
  'Asia/Tbilisi',
  'America/Cayenne',
  'Africa/Accra',
  'Europe/Gibraltar',
  'America/Godthab',
  'America/Danmarkshavn',
  'America/Scoresbysund',
  'America/Thule',
  'Europe/Athens',
  'Atlantic/South_Georgia',
  'America/Guatemala',
  'Pacific/Guam',
  'Africa/Bissau',
  'America/Guyana',
  'Asia/Hong_Kong',
  'America/Tegucigalpa',
  'America/Port-au-Prince',
  'Europe/Budapest',
  'Asia/Jakarta',
  'Asia/Pontianak',
  'Asia/Makassar',
  'Asia/Jayapura',
  'Europe/Dublin',
  'Asia/Jerusalem',
  'Indian/Chagos',
  'Asia/Baghdad',
  'Asia/Tehran',
  'Atlantic/Reykjavik',
  'Europe/Rome',
  'America/Jamaica',
  'Asia/Amman',
  'Asia/Tokyo',
  'Africa/Nairobi',
  'Asia/Bishkek',
  'Pacific/Tarawa',
  'Pacific/Enderbury',
  'Pacific/Kiritimati',
  'Asia/Pyongyang',
  'Asia/Seoul',
  'Asia/Almaty',
  'Asia/Qyzylorda',
  'Asia/Qostanay',
  'Asia/Aqtobe',
  'Asia/Aqtau',
  'Asia/Atyrau',
  'Asia/Oral',
  'Asia/Beirut',
  'Asia/Colombo',
  'Africa/Monrovia',
  'Europe/Vilnius',
  'Europe/Luxembourg',
  'Europe/Riga',
  'Africa/Tripoli',
  'Africa/Casablanca',
  'Europe/Monaco',
  'Europe/Chisinau',
  'Pacific/Majuro',
  'Pacific/Kwajalein',
  'Asia/Yangon',
  'Asia/Ulaanbaatar',
  'Asia/Hovd',
  'Asia/Choibalsan',
  'Asia/Macau',
  'America/Martinique',
  'Europe/Malta',
  'Indian/Mauritius',
  'Indian/Maldives',
  'America/Mexico_City',
  'America/Cancun',
  'America/Merida',
  'America/Monterrey',
  'America/Matamoros',
  'America/Mazatlan',
  'America/Chihuahua',
  'America/Ojinaga',
  'America/Hermosillo',
  'America/Tijuana',
  'America/Bahia_Banderas',
  'Asia/Kuala_Lumpur',
  'Asia/Kuching',
  'Africa/Maputo',
  'Africa/Windhoek',
  'Pacific/Noumea',
  'Pacific/Norfolk',
  'Africa/Lagos',
  'America/Managua',
  'Europe/Amsterdam',
  'Europe/Oslo',
  'Asia/Kathmandu',
  'Pacific/Nauru',
  'Pacific/Niue',
  'Pacific/Auckland',
  'Pacific/Chatham',
  'America/Panama',
  'America/Lima',
  'Pacific/Tahiti',
  'Pacific/Marquesas',
  'Pacific/Gambier',
  'Pacific/Port_Moresby',
  'Pacific/Bougainville',
  'Asia/Manila',
  'Asia/Karachi',
  'Europe/Warsaw',
  'America/Miquelon',
  'Pacific/Pitcairn',
  'America/Puerto_Rico',
  'Asia/Gaza',
  'Asia/Hebron',
  'Europe/Lisbon',
  'Atlantic/Madeira',
  'Atlantic/Azores',
  'Pacific/Palau',
  'America/Asuncion',
  'Asia/Qatar',
  'Indian/Reunion',
  'Europe/Bucharest',
  'Europe/Belgrade',
  'Europe/Kaliningrad',
  'Europe/Moscow',
  'Europe/Simferopol',
  'Europe/Kirov',
  'Europe/Astrakhan',
  'Europe/Volgograd',
  'Europe/Saratov',
  'Europe/Ulyanovsk',
  'Europe/Samara',
  'Asia/Yekaterinburg',
  'Asia/Omsk',
  'Asia/Novosibirsk',
  'Asia/Barnaul',
  'Asia/Tomsk',
  'Asia/Novokuznetsk',
  'Asia/Krasnoyarsk',
  'Asia/Irkutsk',
  'Asia/Chita',
  'Asia/Yakutsk',
  'Asia/Khandyga',
  'Asia/Vladivostok',
  'Asia/Ust-Nera',
  'Asia/Magadan',
  'Asia/Sakhalin',
  'Asia/Srednekolymsk',
  'Asia/Kamchatka',
  'Asia/Anadyr',
  'Asia/Riyadh',
  'Pacific/Guadalcanal',
  'Indian/Mahe',
  'Africa/Khartoum',
  'Europe/Stockholm',
  'Asia/Singapore',
  'America/Paramaribo',
  'Africa/Juba',
  'Africa/Sao_Tome',
  'America/El_Salvador',
  'Asia/Damascus',
  'America/Grand_Turk',
  'Africa/Ndjamena',
  'Indian/Kerguelen',
  'Asia/Bangkok',
  'Asia/Dushanbe',
  'Pacific/Fakaofo',
  'Asia/Dili',
  'Asia/Ashgabat',
  'Africa/Tunis',
  'Pacific/Tongatapu',
  'Europe/Istanbul',
  'America/Port_of_Spain',
  'Pacific/Funafuti',
  'Asia/Taipei',
  'Europe/Kiev',
  'Europe/Uzhgorod',
  'Europe/Zaporozhye',
  'Pacific/Wake',
  'America/New_York',
  'America/Detroit',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Indiana/Indianapolis',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Vevay',
  'America/Chicago',
  'America/Indiana/Tell_City',
  'America/Indiana/Knox',
  'America/Menominee',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/North_Dakota/Beulah',
  'America/Denver',
  'America/Boise',
  'America/Phoenix',
  'America/Los_Angeles',
  'America/Anchorage',
  'America/Juneau',
  'America/Sitka',
  'America/Metlakatla',
  'America/Yakutat',
  'America/Nome',
  'America/Adak',
  'Pacific/Honolulu',
  'America/Montevideo',
  'Asia/Samarkand',
  'Asia/Tashkent',
  'America/Caracas',
  'Asia/Ho_Chi_Minh',
  'Pacific/Efate',
  'Pacific/Wallis',
  'Pacific/Apia',
  'Africa/Johannesburg'
]

export const searchProspectsToolLists: { name: string, title: string, subName: string, icon: JSX.Element, image: null | string, comingSoon: boolean }[] = [
  {
    name: 'db_companies',
    title: 'Find companies',
    subName: 'Search for companies',
    icon: <HiBuildingOffice2 size={19} />,
    image: null,
    comingSoon: true
  },
  {
    name: 'db_people',
    title: 'Find people',
    subName: 'Search for prospects',
    icon: <BsFillPeopleFill size={19} />,
    image: null,
    comingSoon: true
  },
  {
    name: 'db_jobs',
    title: 'Find jobs',
    subName: 'Find companies hiring for jobs',
    icon: <BiSolidBriefcase size={19} />,
    image: null,
    comingSoon: true
  },
  {
    name: 'apollo',
    title: 'Apollo.io',
    subName: 'Find prospects using Apollo',
    icon: null,
    comingSoon: false,
    image: AZ_BUCKET?.SPARKLE_APOLLO,
  },
]