export const InboxFilterDataList = [
  {
    id: '1',
    name: 'Read Status',
    uniqueColumnName: 'read_history.is_viewed',
    conditions: [
      { id: '1', name: 'Is', uniqueName: 'is' },
      // { id: '2', name: 'Is not', uniqueName: 'not' },
    ],
    resultValue: [
      { id: '1', name: 'Unread', uniqueName: false },
      { id: '2', name: 'Read', uniqueName: true },
    ],
  },
  {
    id: '2',
    name: 'Starred Status',
    uniqueColumnName: 'is_starred_message',
    conditions: [
      { id: '1', name: 'Is', uniqueName: 'is' },
      // { id: '2', name: 'Is not', uniqueName: 'not' },
    ],
    resultValue: [
      { id: '1', name: 'Important', uniqueName: true },
      { id: '2', name: 'Not Important', uniqueName: false },
    ],
  },
  // {
  //   id: '3',
  //   name: 'Date',
  //   uniqueColumnName: 'platform_message_details.mail_sent_date',
  //   conditions: [
  //     { id: '1', name: 'Is', uniqueName: 'is' },
  //     // { id: '2', name: 'Is not', uniqueName: 'not' },
  //   ],
  //   resultValue: [
  //     { id: '1', name: 'Today', uniqueName: generateDateInterval(0, 0) },
  //     { id: '2', name: 'Yesterday', uniqueName: generateDateInterval(1) },
  //     { id: '3', name: 'Last 7 days', uniqueName: generateDateInterval(7) },
  //     { id: '4', name: 'Last 30 days', uniqueName: generateDateInterval(29) },
  //     { id: '5', name: 'This week', uniqueName: generateDateInterval('week', 0) },
  //     { id: '6', name: 'This month', uniqueName: generateDateInterval('month', 0) },
  //   ],
  // },
];
