import { useState } from 'react';
import { IoMdArchive, IoMdMore } from 'react-icons/io';
import { MdDelete } from 'react-icons/md';
import { FaCirclePause, FaCirclePlay } from 'react-icons/fa6';
import React from 'react';
import { ListRowDataDTO } from 'dto/types/views/blacklist';
import { useAppSelector } from 'redux-store';
import { checkRolePermissionStatus, toastState } from 'utility/utils';
import { toast } from 'react-toastify';
import { CreateTicketForBlackList } from 'shared/blacklist/api-handler/blacklist-api';
// import { useAppSelector } from 'store';
// import { ListRowDataDTO } from 'common/dto/types/views/blacklist';
// import { BaseApi } from 'common/api/common/base-api';
// import { toast } from 'react-toastify';
// import { checkRolePermissionStatus, toastState } from 'common/utils/utility';

const BlacklistRowSettings = (props: ListRowDataDTO) => {
  const [roleStatus, setRoleStatus] = React.useState(null);
  const userRolePermissionListStoreData = useAppSelector((state) => state?.globalsettings?.globalConfiguration?.user_organization_role_details);
  const custom = useAppSelector((state) => state?.custom);
  const { environment } = useAppSelector((state) => state.globalsettings);

  React.useEffect(() => {
    const getRolePermissionStatus = async () => {
      const status = await checkRolePermissionStatus(userRolePermissionListStoreData, 'deliverability_blacklist', environment?.app_default_feature?.is_org_enabled);
      setRoleStatus(status);
    };
    getRolePermissionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom?.globalDependencyLoading,userRolePermissionListStoreData]);
  const { uuidRow, handleListDelete, id, handleListPause, handleListResume, handleListDownload, blacklistStatus, rowData } = props;
  const [showOptions, setShowOptions] = useState(false);

  const handleRaiseTicket = async (blacklistName: string) => {
    try {
      const params: any = {
        subject: blacklistName,
        message: blacklistName,
        verify_data_list: rowData?.blacklist_result,
        variant_uuid: uuidRow,
      };
      let response;
      response = await CreateTicketForBlackList(params);

      if (response.status === 200) {
        toast.success('Ticket created, you can track tickets in true verifier', toastState.success);
      }
    } catch (error) {
      console.log('Send message error', error);
    }
  };

  const handleOptions = (selectedOpt: string, uuidSingle?: string) => {
    if (selectedOpt === 'download') {
      handleListDownload([uuidSingle]);
    }
    setShowOptions(false);
    if (selectedOpt === 'delete') {
      handleListDelete([uuidSingle]);
    }
    if (selectedOpt === 'pause') {
      handleListPause([uuidSingle]);
    }
    if (selectedOpt === 'resume') {
      handleListResume([uuidSingle]);
    }
    if (selectedOpt === 'raiseTicket') {
      handleRaiseTicket(uuidSingle);
    }
  };

  return (
    <>
      <div className={`relative mr-6 w-min cursor-pointer rounded-md py-0.5 ${showOptions && 'bg-[#4318FF0A]'}`} tabIndex={0} onBlur={() => setShowOptions(false)}>
        <IoMdMore className="blacklist-row-setting-btn z-50 text-lg text-gray-700" id={`edit-btn-${id}`} onClick={() => setShowOptions(true)} />
        {showOptions && (
          <div className="absolute -left-[75px] z-10 flex flex-col overflow-hidden rounded-md border bg-white dark:border-darkBorder dark:bg-darkTwo">
            
            {/* Role Restriction for Downloading __________________________Now only for Owners*/}

            {(roleStatus?.organization_owner || roleStatus?.organization_access_list?.download) && (
              <div
                className="blacklist-monitor-download-btn campaign-download-status-btn group flex cursor-default gap-2 px-3 py-2 font-medium transition-all duration-150 hover:bg-brand-500 hover:text-white"
                onClick={(e) => handleOptions('download', uuidRow)}
              >
                <IoMdArchive className="text-brand-500 group-hover:text-white" />
                <h5 className="text-xs">Download</h5>
              </div>
            )}

            {/* Role Restriction for Pause or Resume */}
            {(roleStatus?.organization_owner || roleStatus?.organization_access_list?.pause_or_resume) &&
              (blacklistStatus === 2 ? (
                <div
                  className="blacklist-monitor-resume-btn group flex cursor-default gap-2 px-3 py-2 font-medium transition-all duration-150 hover:bg-brand-500 hover:text-white"
                  onClick={() => handleOptions('resume', uuidRow)}
                  id={`resume-${id}`}
                >
                  <FaCirclePlay className="text-brand-500 group-hover:text-white" /> <h5 className="text-xs">Resume</h5>
                </div>
              ) : (
                <div
                  className="blacklist-monitor-pause-btn group flex cursor-default gap-2 px-3 py-2 font-medium transition-all duration-150 hover:bg-brand-500 hover:text-white"
                  onClick={() => handleOptions('pause', uuidRow)}
                  id={`pause-${id}`}
                >
                  <FaCirclePause className="text-brand-500 group-hover:text-white" />
                  <h5 className="text-xs">Pause</h5>
                </div>
              ))}

            {/* Role Restriction for Delete row */}

            {(roleStatus?.organization_owner || roleStatus?.organization_access_list?.archieve_or_delete) && (
              <div
                className="blacklist-monitor-delete-btn group flex cursor-default gap-2 px-3 py-2 font-medium transition-all duration-150 hover:bg-brand-500 hover:text-white"
                onClick={() => handleOptions('delete', uuidRow)}
                id={`delete-${id}`}
              >
                <MdDelete className="text-brand-500 group-hover:text-white" />
                <h5 className="text-xs">Delete</h5>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default BlacklistRowSettings;
