import React from 'react';
import { FiSearch } from 'react-icons/fi';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { checkRolePermissionStatus, setAndGetQueryParams, useDebounce } from 'utility/utils';
import { useAppSelector } from 'redux-store';
import { useNavigate } from 'react-router-dom';
import { MdEdit } from 'react-icons/md';
import moment from 'moment';
import { BaseApi } from 'api/services/base-api';
import InfiniteScrollComponent from 'components/common/infinityScroll';
import TableLoader from 'components/common/Loaders/TableViewLoader';

const DonotEmailList = () => {
  const navigate = useNavigate();
  const columnWidth = ['w-[35%]', 'w-[15%]', 'w-[15%]', 'w-[25%]', 'w-[20%]', 'w-[10%]'];
  const [data, setData] = React.useState({
    data: [],
    count: 0,
    loading: false,
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tableSelection, setTableSelection] = React.useState({
    selectedIds: [],
    globalOpt: false,
  });
  const [queryParams, setQueryParams] = React.useState({
    limit: 25,
    offset: 0,
    sort: { columnName: '', order: '' },
    search: '',
    filter: '',
  });

  const [donotemailStatus, setDonotemailStatus] = React.useState(null);
  const userRolePermissionListStoreData = useAppSelector((state) => state?.globalsettings?.globalConfiguration?.user_organization_role_details);
  const { environment } = useAppSelector((state) => state.globalsettings);
  React.useEffect(() => {
    const getRolePermissionStatus = async () => {
      const status = await checkRolePermissionStatus(userRolePermissionListStoreData, 'do_not_email_list', environment?.app_default_feature?.is_org_enbaled);
      setDonotemailStatus(status);
    };
    getRolePermissionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRolePermissionListStoreData]);
  const debouncedSearchValue = useDebounce(queryParams?.search, 300);

  const custom = useAppSelector((state) => state?.custom);
  React.useEffect(() => {
    const fetchBrandsList = async () => {
      try {
        setLoading(true);
        const query = setAndGetQueryParams([
          { key: 'search', value: queryParams?.search },
          {
            key: 'sort',
            value: queryParams?.sort?.columnName ? `${queryParams?.sort?.columnName}%${queryParams?.sort?.order}` : '',
          },
          { key: 'filter', value: queryParams?.filter },
        ]);
        const brandListResponse = await BaseApi.get(`/donot/list/linked/with/email?${query}`);
        console.log('Brands Res', brandListResponse?.data);
        brandListResponse?.data?.data?.length
          ? setData({ ...data, data: brandListResponse?.data?.data, count: brandListResponse?.data?.total_records?.count })
          : setData({ ...data, data: [], count: 0 });
        setQueryParams((prev) => ({
          ...prev,
          offset: 2,
        }));
        setLoading(false);
      } catch (error) {
        console.error('Error From Brand Table Page Catch block', error);
      }
    };
    fetchBrandsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom?.flagStatus, queryParams?.filter, debouncedSearchValue, queryParams?.sort?.columnName, queryParams?.sort?.order, custom?.globalDependencyLoading]);
  const handleBrandSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueryParams((prev) => ({
      ...prev,
      search: event.target.value.trim().toLowerCase(),
    }));
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = React.useState(false);
  const columnHelper = createColumnHelper<any>();
  const columnData = [
    columnHelper.accessor('brands_data', {
      id: 'brands_data',
      enableSorting: false,
      header: () => <div className="text-md pl-6">Name</div>,
      cell: (info) => <h5 className="truncate pl-6 text-sm font-bold text-heading dark:!text-gray-200">{info.getValue()?.name}</h5>,
    }),
    columnHelper.accessor('prospects_count', {
      id: 'prospects_count',
      enableSorting: false,
      header: () => <div>Prospects Count</div>,
      cell: (info) => <h5 className="pl-3 text-[.8125rem] font-medium text-gray-700 dark:!text-gray-300">{info.getValue()}</h5>,
    }),
    columnHelper.accessor('unsubscribed_count', {
      id: 'unsubscribed_count',
      header: () => <div className="text-start">Unsubscribed Count</div>,
      cell: (info) => <h5 className="pl-3 text-[.8125rem] font-medium text-gray-700 dark:!text-gray-300">{info.getValue()}</h5>,
    }),
    columnHelper.accessor('owner_details', {
      id: 'owner_details',
      enableSorting: false,
      header: () => <div>Owner</div>,
      cell: (info) => <h5 className="pl-3 text-[.8125rem] font-medium text-gray-700 dark:!text-gray-300">{info?.row.original?.owner_details?.first_name + ' ' + info?.row.original?.owner_details?.last_name}</h5>,
    }),
    columnHelper.accessor('mdate', {
      id: 'mdate',
      header: () => <div className="text-start">Last Updated</div>,
      cell: (info) => {
        const date = info.getValue() ? moment(info.getValue()).format('MMM DD, YYYY') : '-';
        return <h5 className="text-[.8125rem] font-medium text-gray-700 dark:!text-gray-300">{date}</h5>;
      },
    }),
    columnHelper.accessor('id', {
      id: 'id',
      header: () => <div className="text-start"></div>,
      cell: (info) => (
        <div className=" w-fit rounded-md p-2 text-gray-500 hover:bg-gray-100 dark:hover:bg-gray-800">
          <MdEdit />
        </div>
      ),
    }),
  ];
  const table = useReactTable({
    data: data?.data,
    columns: columnData,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });
  React.useEffect(() => {
    const tableSelectedData = table.getSelectedRowModel().rows.map((val: any) => val.original?.uuid);
    setTableSelection((prev) => ({ ...prev, selectedIds: tableSelectedData }));
    if (table.getSelectedRowModel().rows.length > 1) setTableSelection((prev) => ({ ...prev, globalOpt: true }));
    else setTableSelection((prev) => ({ ...prev, globalOpt: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getSelectedRowModel()]);

  // Scroll function
  const scrollFunction = React.useCallback(
    async (setIsFetching: any) => {
      const fetchEntryList = async () => {
        try {
          setIsFetching(true);
          const query = setAndGetQueryParams([
            { key: 'limit', value: queryParams?.limit },
            { key: 'offset', value: queryParams?.offset },
            { key: 'search', value: queryParams?.search },
            {
              key: 'sort',
              value: queryParams?.sort?.columnName ? `${queryParams?.sort?.columnName}%${queryParams?.sort?.order}` : '',
            },
            { key: 'filter', value: queryParams?.filter },
          ]);
          const response = await BaseApi.get(`/donot/list/linked/with/email?${query}`);
          const responseData: any = response.data;
          if (!responseData?.data) return setData((prev) => ({ ...prev, data: [], count: 0 }));
          if (responseData?.data?.length < 25) {
            setData((prev) => ({
              ...prev,
              data: [...prev?.data, ...responseData?.data],
              count: responseData?.total_records?.count,
            }));
          } else {
            setData((prev) => ({
              ...prev,
              data: [...prev?.data, ...responseData?.data],
            }));
          }
          setQueryParams((prev) => ({
            ...prev,
            offset: prev?.offset + 1,
          }));
          // console.log('data', data);
        } catch (error) {
          console.log(error);
        } finally {
          setIsFetching(false);
        }
      };
      data?.data?.length && data?.data?.length < data?.count && fetchEntryList();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.data?.length, queryParams?.filter, queryParams?.sort?.columnName, queryParams?.sort?.order, queryParams?.offset]
  );

  const handleClickRow = (rowId: any) => {
    sessionStorage.setItem('brandId', rowId);
    navigate('/settings/donot/list/add');
  };
  return (
    <div className="mt-3 min-h-[89vh] rounded-md bg-white dark:dark:bg-darkTwo">
      <div className="-mt-2 flex w-full items-center rounded-tl-lg rounded-tr-lg dark:dark:bg-darkTwo bg-white px-2 py-4 sm:gap-32 lg:gap-32 xl:justify-between">
        <div className="flex items-center gap-2.5">
          <div
            className="ml-2 dark:border-darkBorder flex items-center gap-2 rounded-md border px-3 py-1.5 outline outline-0 focus-within:outline-2 focus-within:outline-brand-400 md:w-[13rem] lg:w-[26rem]"
          // onClick={() => searchRef.current.focus()}
          >
            <FiSearch className="size-4 cursor-pointer text-gray-800 dark:text-gray-400" />
            <input
              type="text"
              placeholder="Search"
              id="donot-search-input"
              className="w-full text-sm  text-gray-800 dark:text-gray-200 caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:dark:bg-darkTwo dark:placeholder:text-white "
              onChange={handleBrandSearch}
            />
          </div>
        </div>
      </div>
      {!loading ? (
        <>
          <div className="h-[79vh] overflow-y-auto overflow-x-scroll scroll-smooth xl:overflow-x-hidden">
            <table className="w-full border-t-2">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header, index, arr) => {
                      const RowDataWidth = columnWidth;
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          onClick={header.column.getToggleSortingHandler()}
                          className={`${RowDataWidth[index]} border-b border-gray-100 bg-grayBorder py-3.5 text-start text-column dark:border-white/10 dark:bg-darkOne`}
                        >
                          <div className="text-[12px] font-semibold">
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: '',
                              desc: '',
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody className="divide-y-[1px] dark:divide-darkBorder">
                {data?.data?.length ? (
                  table.getRowModel().rows.map((row) => {
                    return (
                      <tr
                        key={row.id}
                        onClick={() => {
                          (donotemailStatus?.organization_owner || donotemailStatus?.organization_access_list?.edit) && handleClickRow(row.original.uuid);
                        }}
                        className={`cursor-pointer text-start hover:bg-hover dark:hover:bg-darkOne`}
                      >
                        {row.getVisibleCells().map((cell, i, arr) => {
                          const RowDataWidth = columnWidth;
                          return (
                            <td key={cell.id} className={`${RowDataWidth[i]} py-3.5 text-gray-700 dark:text-white`}>
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={9} className="pt-2 text-center text-[13px] font-medium text-gray-700">
                      No Result Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <InfiniteScrollComponent
              loadMore={scrollFunction}
              loader={
                <TableLoader width="w-full" border="border border-gray-100" column={6} row={1} skeletonHeight="h-2" padding="px-5 py-4" columnWidth="4% 25% 22% 22% 25% 4%" />
              }
            />
          </div>
        </>
      ) : (
        <TableLoader
          width="w-full"
          border="border border-gray-100"
          column={9}
          row={3}
          skeletonHeight="h-2"
          padding="px-5 py-4"
          tableViewBg={{ headBg: 'bg-gray-50' }}
          columnWidth="4% 14% 14% 14% 14% 12% 12% 12% 4%"
        />
      )}
    </div>
  );
};
export default DonotEmailList;
