import React from 'react';
import { toast } from 'react-toastify';
import { RemoveClearSelectData, setFetchGroupListData, setFilterQuery, setInboxlistData, setinboxStatus, setSearchQuery, setVariant } from 'redux-store/unified-inbox';
import { jwtEncrypt, toastState } from 'utility/utils';
import { getEmailInboxDataApi, getInboxCountApi, getInboxData, getInboxGroupData, updateEmailMessage, updateInboxData } from '../api-handler/inbox-api';
import { setMessageSent } from 'redux-store/crm';

export const fetchInboxData = async (setLoading: React.Dispatch<React.SetStateAction<boolean>>, setMessageData: React.Dispatch<React.SetStateAction<any>>, searchQuery: string, limit: number, filter: any, dispatch: any, inbox_status: string) => {
  try {
    setLoading(true);
    const query = {
      search: searchQuery,
      limit: limit,
      filter: filter,
    };
    const data = {
      inbox_status,
      query,
    };
    const response = await getInboxData(data);
    if (response?.error) {
      toast.error(response?.message, toastState.error);
    } else {
      dispatch(setInboxlistData(response?.data?.data));
      setMessageData({
        totalCount: response?.data?.total_count,
        data: response?.data?.data,
      });
    }
  } catch (err) {
    console.log(err);
  } finally {
    setLoading(false);
  }
};

export const hanleDeleteGroupFromSenderList = (groupId: string, setMessageData: React.Dispatch<React.SetStateAction<any>>) => {
  setMessageData((prev: any) => {
    const updatedData = prev?.data?.map((list: any) => {
      const finalGroupList = list?.groups_list?.filter((group: any) => group?.uuid !== groupId);
      return {
        ...list,
        groups_list: finalGroupList,
      };
    });
    return {
      ...prev,
      data: updatedData,
    };
  });
};

export const fetchGroupLinkedWithInbox = async (dispatch: any) => {
  try {
    const response = await getInboxGroupData();
    if (response?.error) {
      toast.error(response?.message, toastState.error);
    } else {
      if (response?.data?.data && response?.data?.data?.length) {
        const finalData = await response?.data?.data?.sort((a: any, b: any) => (a?.name.toLowerCase() < b?.name.toLowerCase() ? -1 : a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : 0));
        const states = await response?.data?.data?.reduce((acc: any, val: any) => {
          acc[val.uuid] = { show: false, rename: false };
          return acc;
        }, {});
        const payload: any = {
          finalData,
          states,
        };
        dispatch(setFetchGroupListData(payload));
      }
    }
  } catch (err) {
    console.log(err);
  }
};

export const handleClearConversation = async (clearSelectData: Array<string>, dispatch: any, setFetchDataTrigger: React.Dispatch<React.SetStateAction<boolean>>, handleFetchDataTrigger: () => void) => {
  try {
    if (clearSelectData.length) {
      const payload = {
        unified_inbox_status: 'cleared',
        uuid: clearSelectData,
      };
      const response = await updateInboxData(payload);
      if (response.status === 200) {
        toast.success(response.message, toastState.success);
        handleFetchDataTrigger();
        dispatch(RemoveClearSelectData());
      }
    }
  } catch (error) {
    console.error('error', error);
  }
};

export const fetchDataScrollEvent = async (setIsFetching: React.Dispatch<React.SetStateAction<boolean>>, setMessageData: React.Dispatch<React.SetStateAction<any>>, searchQuery: string, limit: number, filter: any, offset: number, setOffset: React.Dispatch<React.SetStateAction<number>>, dispatch: any, inbox_status: string) => {
  try {
    setIsFetching(true);
    const data = {
      inbox_status,
      query: {
        limit: limit,
        offset: offset,
        search: searchQuery,
        filter: filter,
      },
    };
    const response = await getInboxData(data);
    const responseData: any = response.data;
    if (!responseData?.data) return setMessageData({ data: [], totalCount: 0 });
    if (responseData?.data?.length < 25) {
      setMessageData((prev: { data: Array<object> }) => ({ data: [...prev?.data, ...responseData?.data], totalCount: prev?.data?.length }));
    } else {
      setMessageData((prev: { data: Array<object> }) => ({ ...prev, data: [...prev?.data, ...responseData?.data] }));
    }
    dispatch(setOffset(1));
  } catch (error) {
    console.log(error);
  } finally {
    setIsFetching(false);
  }
};

// Common Layout Events

export const HandleInboxVariantClickEvent = ({ variant, path, dispatch, navigate }: { variant: string; path: string; dispatch: any; navigate: any }) => {
  navigate(path);
  dispatch(setVariant(variant));
  dispatch(setinboxStatus('pending'));
  dispatch(setFilterQuery(''));
};

export const HandleInboxFilterOnChangeEvent = async ({ filteredData, dispatch }: { filteredData: any; dispatch: any }) => {
  const encryptedData = jwtEncrypt(filteredData);
  if (filteredData?.data?.length) {
    dispatch(setFilterQuery(encryptedData));
  } else {
    dispatch(setFilterQuery(''));
  }
};

export const HandleInboxSearchInputChangeEvent = ({ event, dispatch }: { event: React.ChangeEvent<HTMLInputElement>; dispatch: any }) => {
  const query = event?.target?.value;
  dispatch(setSearchQuery(query));
};

export const HandleFetchInboxMessageCount = async ({ variant, setTabDataCount }: { variant: string; setTabDataCount: any }) => {
  try {
    const payload = variant.toLowerCase();
    const response = await getInboxCountApi({ variant: payload });
    if (response?.status === 200) {
      setTabDataCount(response.data.data);
    }
  } catch (error) {
    console.error(error);
  }
};

// Email Inbox Data
export const FetchEmailInboxData = async (setLoading: React.Dispatch<React.SetStateAction<boolean>>, setMessageData: React.Dispatch<React.SetStateAction<any>>, searchQuery: string, limit: number, filter: any, dispatch: any, inbox_status: string) => {
  try {
    console.log(inbox_status, 'inbox_status');
    setLoading(true);
    const query = {
      search: searchQuery,
      limit: limit,
      filter: filter,
    };
    const data = {
      inbox_status,
      query,
    };
    const response = await getEmailInboxDataApi(data);
    if (response?.error) {
      toast.error(response?.message, toastState.error);
    } else {
      dispatch(setInboxlistData(response?.data?.data));
      setMessageData({
        totalCount: response?.data?.total_count,
        data: response?.data?.data,
      });
    }
  } catch (err) {
    console.log(err);
  } finally {
    setLoading(false);
  }
};

export const FetchMoreEmailDataScrollEvent = async (setIsFetching: React.Dispatch<React.SetStateAction<boolean>>, setMessageData: React.Dispatch<React.SetStateAction<any>>, searchQuery: string, limit: number, filter: any, offset: number, setOffset: React.Dispatch<React.SetStateAction<number>>, dispatch: any, inbox_status: string) => {
  try {
    setIsFetching(true);
    const data = {
      inbox_status,
      query: {
        limit: limit,
        offset: offset,
        search: searchQuery,
        filter: filter,
      },
    };
    const response = await getEmailInboxDataApi(data);
    const responseData: any = response.data;
    if (!responseData?.data) return setMessageData({ data: [], totalCount: 0 });
    if (responseData?.data?.length < 25) {
      setMessageData((prev: { data: Array<object> }) => ({ data: [...prev?.data, ...responseData?.data], totalCount: prev?.data?.length }));
    } else {
      setMessageData((prev: { data: Array<object> }) => ({ ...prev, data: [...prev?.data, ...responseData?.data] }));
    }
    dispatch(setOffset(1));
  } catch (error) {
    console.log(error);
  } finally {
    setIsFetching(false);
  }
};

export const HandleUpdateMessage = async ({
  threadData,
  primary_email,
  dropdownValue,
  dispatch,
  setShowBcc,
  setDropDownValue,
  setIsLoading,

  setShowCc,
  onTyperClose,
}: {
  threadData: any;
  primary_email: any;
  dropdownValue: any;
  dispatch: any;
  setShowBcc: any;
  setDropDownValue: React.Dispatch<React.SetStateAction<{ from: any; to: any; subject: string; uuid: string; message: string; cc: any[]; bcc: any[] }>>;
  setIsLoading: any;

  setShowCc: any;
  onTyperClose: () => void;
}) => {
  if (threadData?.type === 'reply') {
    const isPrimaryEmailPresent = await primary_email.some((primaryEmail: any) => dropdownValue.to.some((email: { name: string }) => email.name === primaryEmail.email));

    if (!isPrimaryEmailPresent) {
      toast.error('Primary Reply email is required', toastState.error);
      return;
    }
  }
  const prospect_email = await dropdownValue?.to?.map((item: { name: string; prospect_name: any }) => ({
    email: item.name,
    name: item.prospect_name ? item?.prospect_name : item?.name?.split('@')?.[0],
  }));

  //: `-------Forwarded Message-------<br>from: ${dropdownValue?.to[0]?.name}<br>Date: ${threadData?.date}<br>Subject: ${dropdownValue.subject} <br>To: ${dropdownValue?.from[0]?.name}<br><br> ${dropdownValue?.message}`,
  // const to_Emails = dropdownValue?.to?.map((item) => item.email).join(', ');
  // const updateTo = [...prospect_email, ...primary_email]?.filter((item, index, self) => index === self.findIndex((t) => t['email'] === item['email']));
  const updatedCC = dropdownValue?.cc?.map((item: { prospect_name: any; name: string }) => ({
    email: item.name,
    name: item.prospect_name ? item?.prospect_name : item?.name.split('@')[0],
  }));
  const updatedBCc = dropdownValue?.bcc?.map((item: { prospect_name: any; name: string }) => ({
    email: item.name,
    name: item.prospect_name ? item?.prospect_name : item?.name.split('@')[0],
  }));
  try {
    setIsLoading(true);
    if (dropdownValue?.message) {
      const payload = {
        sender_id: threadData.sender_id,
        conversation_details: {
          variant: 'email', // sms, whatsapp, linkedin, email
          message: threadData?.type === 'reply' ? dropdownValue?.message : dropdownValue.message + threadData.originalMessagedata,

          // conversation_id: dropdownValue.uuid,
          cc: updatedCC,
          prospect_email: prospect_email,
          bcc: updatedBCc,
          subject: dropdownValue.subject,
          main_message_id: threadData.main_message_id,
          individual_message_id: threadData?.individual_message_id,
        },
      };
      const response = await updateEmailMessage(payload);
      if (response?.status === 200) {
        toast.success(response.message, toastState.success);
        setDropDownValue((prev: any) => ({ from: prev.from, to: prev.to, subject: prev?.subject, uuid: prev?.uuid, message: '', cc: [], bcc: [] }));
        dispatch(setMessageSent());
      } else {
      }
    }
  } catch (error) {
    console.log(error);
  } finally {
    setIsLoading(false);

    setShowBcc((prev: any) => {
      if (prev === true) {
        return false;
      }
      return prev;
    });
    setShowCc((prev: any) => {
      if (prev === true) {
        return false;
      }
      return prev;
    });
    onTyperClose();
    //dispatch(setisReplyEditorOpen(false));
  }
};
