import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { FiSearch } from 'react-icons/fi';
import React from 'react';
import { GoHourglass } from 'react-icons/go';
import { MdAdd, MdCheck } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';

import { TrueVerifierRowObject } from 'dto/types/views/true-verfier';
import { calculateTimeAgo, checkRolePermissionStatus } from 'utility/utils';
import CheckBox from 'components/common/MultiSelectCheckBox';
import ArrowedTooltip from 'components/common/ArrowedTooltip';
import CustomFilter from 'components/common/customFilter';
import { trueVerifierFilterDataList } from 'lib/page-filters/true-verifier';

import { useAppSelector } from 'redux-store';
import { setCredits } from 'redux-store/custom';
// Initial value
import { trueVerifierStateIV } from 'dto/initial-value/views/true-verifier';
import { GetSupscriptionPaymentStatusAPI } from 'shared/true-verifier/api-handler/true-verifier-api';
import {
  HandleChatClickEvent,
  HandleTrueVerifierFetchListEvent,
  HandleTrueVerifierOnScrollEvent,
  HandleTrueVerifierOnSelectEvent,
  HandleTrueVerifierToggleDrawerClickEvent,
  TrueVerifierMultiSelectionOptionsClickEvent,
  TrueVerifierRowSettingsOptionsClickEvent,
} from 'shared/true-verifier/event-handler/true-verifier-event';
import { HandleFilterCommonEvent } from 'shared/common/event-handler/common-event';
import { useSmartSetState } from 'hooks/useSmartSetState';

// Components
const CommonRowSettings = React.lazy(() => import('components/common/commonRowSetting/CommonRowSettings'));
const MultiselectGlobalOpt = React.lazy(() => import('components/common/muitiSelectcomponent/MultiselectGlobalOpt'));
const InfiniteScrollComponent = React.lazy(() => import('components/common/infinityScroll'));
const TableLoader = React.lazy(() => import('components/common/Loaders/TableViewLoader'));
const TicketDetails = React.lazy(() => import('../components/TicketDetails'));
const TicketCreationComponent = React.lazy(() => import('../components/TicketCreation'));

const TrueVerifierTable = () => {
  //Redux states
  const { environment } = useAppSelector((state) => state.globalsettings);
  const { ticketId } = useAppSelector((state) => state?.trueVerifier);
  const custom = useAppSelector((state) => state?.custom);
  const userRolePermissionListStoreData = useAppSelector((state) => state?.globalsettings?.globalConfiguration?.user_organization_role_details);
  // Local states
  const [trueVerifierState, setTrueVerifierState] = React.useState(trueVerifierStateIV);
  const searchRef = React.useRef(null);

  // Destructured state data
  const { data, columns, queryParams, tableLoading, roleStatus, optionData, tableSelection } = trueVerifierState;

  // Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const columnHelper = createColumnHelper<TrueVerifierRowObject>();
  const smartStateUpdate = useSmartSetState(setTrueVerifierState);

  const table = useReactTable({
    data: data?.data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });

  const orderId = searchParams.get('orderId');

  const rowSettingsOption = TrueVerifierRowSettingsOptionsClickEvent();
  const multiSelectOptions = TrueVerifierMultiSelectionOptionsClickEvent(tableSelection?.selectedIds, data?.data);

  const columnData = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <div className=" flex size-full items-center">
          <input
            type="checkbox"
            id="select-all"
            checked={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
            className="block size-[0.9rem] w-full cursor-pointer accent-brand-500 dark:accent-purple-600"
          />
        </div>
      ),
      cell: (info) => {
        return (
          <div className=" flex  items-center" onClick={(event) => event?.stopPropagation()}>
            <CheckBox onSelect={handleOnSelect} info={info} table={table} className="h-[14.4px] w-[14.4px]" />
          </div>
        );
      },
    }),
    // columnHelper.accessor('id', {
    //   id: 'id',
    //   header: () => (
    //     <div className="ml-[1.2rem] flex items-center">
    //       <input
    //         type="checkbox"
    //         id="select-all"
    //         checked={table.getIsAllRowsSelected()}
    //         onChange={table.getToggleAllRowsSelectedHandler()}
    //         className="size-[0.9rem] cursor-pointer accent-brand-500 dark:accent-purple-600"
    //       />
    //     </div>
    //   ),
    //   cell: (info) => {
    //     return (
    //       <div className=" flex items-center" onClick={(event) => event?.stopPropagation()}>
    //         <CheckBox onSelect={onSelect} info={info} table={table} />
    //       </div>
    //     );
    //   },
    // }),
    columnHelper.accessor('ticket_id', {
      id: 'ticket_id',
      enableSorting: false,
      header: () => <div>Ticket ID</div>,
      cell: (info) => (
        <h5 className="group relative text-xs">
          {info.getValue()}
          {/* <ArrowedTooltip className={`hidden group-hover:block !min-w-fit !px-4 !mx-auto`} toolTipId={`row-cdate-tooltip-${info?.row?.id}`} data={info.getValue()} /> */}
        </h5>
      ),
    }),
    columnHelper.accessor('subject', {
      id: 'subject',
      header: () => <div>Subject</div>,

      cell: (info) => {
        return (
          <h5 className="w-80 truncate text-xs" id={`subject-${info.row.id}`}>
            {info.getValue()}
          </h5>
        );
      },
    }),

    columnHelper.accessor('variant', {
      id: 'variant',
      header: () => <div>Variant</div>,

      cell: (info) => {
        const text = info.getValue()?.replace(/_/g, ' ');
        return (
          <h5 className="text-xs capitalize" id={`variant-${info.row.id}`}>
            {text}
          </h5>
        );
      },
    }),

    columnHelper.accessor('ticket_created_date', {
      id: 'ticket_created_date',
      header: () => <div className="text-start">Created on</div>,

      cell: (info) => {
        const utcDateString = info.getValue();
        const userTimeZone = 'Asia/Kolkata';
        const utcDate = new Date(utcDateString);
        const localDate = utcDate.toLocaleString('en-US', {
          timeZone: userTimeZone,
        });
        const date = moment(new Date(localDate));
        return (
          <div className="group relative w-full text-sm font-semibold" id="table-click">
            <span className={` row-cdate-${info?.row?.id}`}>{calculateTimeAgo(info.getValue())}</span>
            <ArrowedTooltip className={`hidden group-hover:block`} toolTipId={`row-cdate-tooltip-${info?.row?.id}`} data={date.format('DD MMM YYYY, hh:mm A')} />
          </div>
        );
      },
    }),
    columnHelper.accessor('ticket_status', {
      id: 'ticket_status',
      header: () => <div>Status</div>,

      cell: (info) => (
        <div id="table-click">
          {
            <>
              {info.getValue() === 0 && (
                <div className="flex w-min items-center gap-0.5 text-nowrap rounded-full bg-[#EFF8FF] px-2 py-0.5">
                  <GoHourglass className="text-xs text-[#175CD3]" />
                  <h5 className={`text-xs font-semibold text-[#175CD3] status-index-${info?.row?.id}`}>In progress</h5>
                </div>
              )}

              {info.getValue() === 1 && (
                <div className="flex w-min items-center gap-0.5 rounded-full bg-completed-bg px-2 py-0.5">
                  <MdCheck className="text-xs text-completed-color" />
                  <h5 className={`text-xs font-semibold text-completed-color status-index-${info?.row?.id}`}>Completed</h5>
                </div>
              )}
              {info.getValue() === 2 && (
                <div className="flex w-min items-center gap-0.5 rounded-full bg-orange-50 px-2 py-0.5">
                  <MdCheck className="text-xs text-orange-500" />
                  <h5 className={`text-xs font-semibold text-orange-500 status-index-${info?.row?.id}`}>Reopened</h5>
                </div>
              )}
              {info.getValue() === 3 && (
                <div className="flex w-min items-center gap-0.5 rounded-full bg-red-50 px-2 py-0.5">
                  <MdCheck className="text-xs text-red-500" />
                  <h5 className={`text-xs font-semibold text-red-500 status-index-${info?.row?.id}`}>Cancelled</h5>
                </div>
              )}
            </>
          }
        </div>
      ),
    }),
    columnHelper.accessor('uuid', {
      id: 'edit',
      header: () => <div className="relative w-min font-thin">{''}</div>,
      cell: (row) => {
        return <>{<CommonRowSettings uuidRow={row?.row?.original?.uuid} rowData={row?.row?.original} options={rowSettingsOption} />}</>;
        // return <>{<RowSettings id={row.row.id} uuidRow={row.getValue()} rowData={row.row.original} table={table} setData={setData} />}</>;
      },
    }),
  ];

  // Functions
  const handleFetchList = async () => {
    await HandleTrueVerifierFetchListEvent(queryParams, columnData, smartStateUpdate);
  };

  // Events
  const handleChat = (row: any) => {
    HandleChatClickEvent(row, dispatch);
  };

  const handleOnSelect = (data: string[]) => {
    HandleTrueVerifierOnSelectEvent(data, dispatch);
  };

  const handleOnScroll = React.useCallback(
    async (setIsFetching: React.Dispatch<React.SetStateAction<boolean>>) => {
      data?.data?.length && data?.data?.length < data?.count && HandleTrueVerifierOnScrollEvent(queryParams, data, smartStateUpdate, setIsFetching);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.data?.length, queryParams?.offset]
  );

  const handleFilter = (filteredData: any) => {
    HandleFilterCommonEvent(filteredData, smartStateUpdate);
  };

  const handleToggleDrawer = () => HandleTrueVerifierToggleDrawerClickEvent(dispatch);

  // Use Effects
  React.useEffect(() => {
    if (orderId) {
      navigate(window.location.pathname);
      const updateResponse = async () => {
        try {
          const response = await GetSupscriptionPaymentStatusAPI(orderId);
          if (response.data) {
            dispatch(
              setCredits({
                totalAvailableCredits: custom.credits.totalAvailableCredits,
                totalCredits: custom.credits.totalCredits,
                hitCount: 1,
              })
            );
          }
        } catch (error) {
          console.log(error);
        }
      };
      updateResponse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  React.useEffect(() => {
    const tableSelectedData = table.getSelectedRowModel().rows.map((val: any) => val.original?.uuid);
    smartStateUpdate({ tableSelection: { selectedIds: tableSelectedData } });

    if (table.getSelectedRowModel().rows.length > 1) smartStateUpdate({ tableSelection: { globalOpt: true } });
    else smartStateUpdate({ tableSelection: { globalOpt: false } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getSelectedRowModel()]);

  // React.useEffect(() => {
  //   (async () => {
  //     await handleFetchList();
  //   })();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [custom?.globalDependencyLoading]);

  React.useEffect(() => {
    handleFetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams?.filter, custom?.globalDependencyLoading]);

  React.useEffect(() => {
    const getRolePermissionStatus = async () => {
      const status = await checkRolePermissionStatus(userRolePermissionListStoreData, 'true_verifier', environment?.app_default_feature?.is_org_enabled);
      smartStateUpdate({ roleStatus: status });
    };
    getRolePermissionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom?.globalDependencyLoading, userRolePermissionListStoreData]);

  React.useEffect(() => {
    smartStateUpdate({ optionData: trueVerifierFilterDataList });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className={`mt-2.5 w-full rounded-lg bg-white pb-2 pt-2.5 shadow-sm dark:bg-darkThree dark:text-white sm:overflow-x-auto`}>
        <div className="relative mb-3 flex items-center py-0.5 sm:gap-32 md:px-10 lg:gap-32 lg:px-3 xl:justify-between">
          <div className="flex gap-2.5">
            <div
              className="flex items-center gap-2 rounded-md border px-3 py-1.5 outline 
              outline-0 focus-within:outline-2 focus-within:outline-brand-400 
              dark:border-darkBorder md:w-[13rem] lg:w-[26rem]"
              onClick={() => searchRef.current.focus()}
            >
              <FiSearch className="size-4 cursor-pointer" />
              <input
                ref={searchRef}
                type="text"
                placeholder="Search"
                id="campaign-search-input"
                className="w-full text-sm font-bold
                             text-gray-800 caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:bg-darkThree dark:text-gray-200 dark:placeholder:text-white "
              />
            </div>
            <CustomFilter optionData={optionData} handleFilter={handleFilter} />
          </div>

          {/* View is for the Create Ticket access */}
          {(roleStatus?.organization_access_list?.add || roleStatus?.organization_owner || !environment?.app_default_feature?.is_org_enabled) && (
            <button
              className="mx-1 flex items-center justify-center gap-0.5 rounded-md bg-brand-500 px-2 py-1.5 text-sm font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              onClick={handleToggleDrawer}
              id="new-ticket-btn"
            >
              <MdAdd className="float-right text-lg" /> <span className="text-nowrap text-[11px]">Create ticket</span>
            </button>
          )}
        </div>
        <div className="h-[80vh] overflow-y-auto overflow-x-scroll scroll-smooth xl:overflow-x-hidden">
          {!tableLoading ? (
            <>
              <table className="w-full">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header, index, arr) => {
                        return (
                          <th
                            key={header.id}
                            colSpan={header.colSpan}
                            onClick={header.column.getToggleSortingHandler()}
                            className={`cursor-pointer border-y border-gray-200 bg-gray-50 py-4 text-start text-column dark:border-white/10 dark:bg-darkOne`}
                          >
                            <div className="text-[11px]">
                              {flexRender(header.column.columnDef.header, header.getContext())}
                              {{
                                asc: '',
                                desc: '',
                              }[header.column.getIsSorted() as string] ?? null}
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody className="divide-y-[1px] dark:divide-darkBorder">
                  {data?.data?.length ? (
                    table.getRowModel().rows.map((row) => {
                      const clicked = row.getVisibleCells()[0]?.row?.original?.uuid === ticketId;
                      return (
                        <tr
                          key={row.id}
                          onClick={() => handleChat(row.getVisibleCells()[0]?.row?.original)}
                          className={`cursor-pointer hover:bg-hover dark:hover:bg-darkOne ${clicked ? 'bg-hover' : ''}`}
                        >
                          {row.getVisibleCells().map((cell, i, arr) => {
                            const RowDataWidth = ['w-[5%]', 'w-[20%]', 'w-[30%]', 'w-[15%]', 'w-[14%]', 'w-[13%]', 'w-[3%]'];
                            return (
                              <td key={cell.id} className={`${RowDataWidth[i]} border-white/0 py-3 text-xs font-semibold text-heading dark:text-white`}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  ) : (
                    <tr></tr>
                  )}
                </tbody>
              </table>
              <InfiniteScrollComponent
                loadMore={handleOnScroll}
                loader={
                  <TableLoader width="w-full" border="border border-gray-100" column={6} row={1} skeletonHeight="h-2" padding="px-5 py-4" columnWidth="4% 25% 22% 22% 25% 4%" />
                }
              />
            </>
          ) : (
            <TableLoader
              width="w-full"
              border="border border-gray-100"
              column={6}
              row={13}
              skeletonHeight="h-2"
              padding="px-5 py-4"
              tableViewBg={{ headBg: 'bg-gray-50' }}
              columnWidth="4% 25% 22% 22% 25% 4%"
            />
          )}
        </div>
      </div>
      {tableSelection?.globalOpt && <MultiselectGlobalOpt options={multiSelectOptions} />}
      <TicketDetails />

      {/* New ticket creation drawer */}
      <TicketCreationComponent fetchList={handleFetchList} />
    </>
  );
};

export default TrueVerifierTable;
