import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

const ArrowedTooltip = (props: {
  data?: string | ReactJSXElement;
  className?: string;
  toolTipId?: string;
  position?: 'top' | 'bottom' | 'left' | 'right';
  positionAxis?: string;
  contentclass?: string;
  width?: string;
}) => {
  const { data, className, position, toolTipId, contentclass, positionAxis, width } = props;
  return (
    <>
      {position === 'left' ? (
        <div className={`absolute top-4 z-10 flex h-6 w-28 flex-col items-center justify-center rounded-md bg-blueSecondary p-1.5 text-center ${className}`}>
          <div className={`absolute left-[1.2px] top-[5px] z-0 size-[0.9rem] -translate-x-1/2 rotate-45 bg-blueSecondary `}></div>
          <h5 className={`relative z-10 flex items-center justify-center bg-blueSecondary text-[10px] font-medium text-white ${contentclass}`} id={toolTipId}>
            {data}
          </h5>
        </div>
      ) : (
        <div className={`absolute z-10 ${width ? width : `w-28`} rounded-md bg-[#2B3674] p-1 text-center dark:bg-white ${positionAxis || '-left-6 top-7'} ${className}`}>
          <div className={`absolute z-0 ${position === 'bottom' ? '-bottom-1' : '-top-1'} left-1/2 size-3 -translate-x-1/2 rotate-45 bg-[#2B3674] dark:bg-white`}></div>
          <h5 className={`relative z-10 text-[9px] font-thin text-white dark:font-semibold dark:text-darkTwo ${contentclass}`} id={toolTipId}>
            {data}
          </h5>
        </div>
      )}
    </>
  );
};

export default ArrowedTooltip;
