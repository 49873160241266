import React from 'react';

interface GlobalOptProps {
  options: any;
}
const MultiselectGlobalOpt = (props: GlobalOptProps) => {
  const { options } = props;

  const handleClick = async (clickFunction: any) => {
    await clickFunction();
  };
  return (
    <div id="globalOpt" className="absolute bottom-8 left-1/2 flex rounded-md bg-blackDark dark:bg-purple-900">
      {options?.map((item: any, index: number) => (
        <div
          key={`${item?.name}-${index}`}
          className={`flex cursor-default items-center gap-2 
            ${options.length === 1 && index === 0 ? 'rounded-md' : index === 0 ? 'rounded-s-md' : index === options.length - 1 ? 'rounded-e-md' : ''} 
            px-4 py-3.5 text-white transition-all hover:bg-brand-500`}
          onClick={() => handleClick(item?.functioning)}
          id={item?.id || ''}
        >
          {item?.icon}
          <h5 className="text-sm">{item?.name}</h5>
        </div>
      ))}
    </div>
  );
};

export default MultiselectGlobalOpt;
