import { BaseApi } from 'api/services/base-api';
import React from 'react';
import { toast } from 'react-toastify';
import { setClearSelectData, setfetchGrouplistTrigger, setSnoozeId, setSnoozePopupState, setTriggerCount } from 'redux-store/unified-inbox';
import { base64Decode, toastState } from 'utility/utils';
import { getThreadMessage, UpdateGroupsFromInboxApi } from '../api-handler/inbox-api';
import moment from 'moment';
import { setOpportunityInboxViewData, setProspectDetails, setReplyThreadData } from 'redux-store/crm';
import { setSideBarFullView } from 'redux-store/custom';
const fetchDbEmails = async (main_message_id: string, setIsLoading: any, setEmailData: any) => {
  try {
    setIsLoading(true);
    setEmailData((prev: any) => ({ ...prev, loading: true }));
    const response = await getThreadMessage(main_message_id);
    if (response?.data) {
      const name = response?.data[0]?.platform_message_details?.from?.address || response?.data[0]?.platform_message_details?.from?.address?.split('@')[0];
      const date = moment(response?.data[0]?.platform_message_details?.mail_sent_date_local).format('MMM DD, hh:mm A');
      let message: string;
      if (response?.data[0]?.platform_message_details?.email_type === 'smtp_imap') {
        message = response.data?.map((item: any) => {
          const attachment = item?.platform_message_details?.inbox_mail_data?.attachments
            ?.map((attachment: any) => {
              if (attachment?.filename) {
                return attachment;
              } else {
                return null;
              }
            })
            .filter(Boolean);
          return { fullMessage: item?.platform_message_details?.inbox_mail_data?.html || '', attachment, ...item };
        });
        // message = inbox_mail_data?.text
      } else if (response.data[0]?.platform_message_details?.email_type === 'oauth2') {
        message = response?.data?.map((item: any) => {
          const base64String = item?.platform_message_details?.inbox_mail_data?.payload?.parts?.[1]?.body?.data?.replace(/-/g, '+')?.replace(/_/g, '/');
          const base64String2 = item?.platform_message_details?.inbox_mail_data?.payload?.body?.data?.replace(/-/g, '+')?.replace(/_/g, '/');
          // const fullMessages = base64String ? atob(base64String) : base64String2 ? atob(base64String2) : item?.platform_message_details?.inbox_mail_data?.snippet;
          const fullMessage = base64String ? base64Decode(base64String) : base64String2 ? base64Decode(base64String2) : item?.platform_message_details?.inbox_mail_data?.snippet;
          const attachment = item?.platform_message_details?.inbox_mail_data?.payload?.parts
            ?.slice(1)
            .map((attachment: any) => {
              if (attachment?.filename) {
                return {
                  filename: attachment.filename,
                  contentType: attachment.mimeType,
                  content: attachment.body?.content,
                  size: attachment.body?.size,
                };
              } else {
                return null;
              }
            })
            .filter((attachment: any) => attachment !== null);
          return { fullMessage, attachment, ...item };
        });
      }
      const profile = {
        name,
        receiver_email: response.data[0]?.platform_message_details?.from?.address,
        subject: response.data[0]?.platform_message_details?.subject,
        message,
        date,
      };
      setEmailData((prev: any) => ({ ...prev, profile }));
    }
  } catch (error) {
    console.log('Unmatched email fetching error', error);
  } finally {
    setIsLoading(false);
    setEmailData((prev: any) => ({ ...prev, loading: false }));
  }
};

export const handleReply = (item: any, sender_id: string, setShowTyper: any, dispatch: any) => {
  let payload;
  if (item.type === 'sender') {
    if (item?.email_type === 'smtp_imap') {
      payload = {
        from: item?.inbox_mail_data?.from.value,
        to: item?.inbox_mail_data?.to?.value,
        delivered_to: item.delivered_to,
        main_message_id: item.main_message_id,
        subject: item.subject,
        sender_id: sender_id,
        type: 'reply',
        individual_message_id: item.individual_message_id,
      };
    } else {
      payload = {
        main_message_id: item.main_message_id,
        from: item.from,
        to: item.to,
        delivered_to: item.delivered_to,
        // cc: [],
        // bcc: [],
        subject: item.subject,
        sender_id: sender_id,
        type: 'reply',
        individual_message_id: item?.oauth_delivery_message_id,
      };
    }
  } else {
    if (item?.email_type === 'smtp_imap') {
      payload = {
        main_message_id: item.main_message_id,
        individual_message_id: item.individual_message_id,
        from: item?.inbox_mail_data?.to?.value,
        to: item?.inbox_mail_data?.from?.value,
        delivered_to: item.delivered_to,
        subject: item.subject,
        sender_id: sender_id,
        type: 'reply',
      };
    } else {
      payload = {
        main_message_id: item.main_message_id,
        from: item.to,
        to: item.from,
        delivered_to: item.delivered_to,
        subject: item.subject,
        sender_id: sender_id,
        type: 'reply',
        individual_message_id: item?.oauth_delivery_message_id,
      };
    }
  }
  // const payload = item.type === 'sender' ? createPayload(item?.from, item?.to) : createPayload(item?.to, item?.from);
  setShowTyper(true);
  dispatch(setReplyThreadData(payload));
};

export const handleMainReply = (item: any, sender_id: string, setShowTyper: any, dispatch: any) => {
  let payload;
  if (item.type === 'sender') {
    if (item?.email_type === 'smtp_imap') {
      payload = {
        from: item?.from,
        to: item?.to,
        delivered_to: item.delivered_to,
        individual_message_id: item.individual_message_id,
        main_message_id: item.main_message_id,
        subject: item.subject,
        sender_id: sender_id,
        type: 'reply',
      };
    } else {
      payload = {
        main_message_id: item.main_message_id,
        from: item.from,
        to: item.to,
        delivered_to: item.delivered_to,
        // cc: [],
        // bcc: [],
        subject: item.subject,
        sender_id: sender_id,
        type: 'reply',
        individual_message_id: item?.oauth_delivery_message_id,
      };
    }
  } else {
    if (item?.email_type === 'smtp_imap') {
      payload = {
        main_message_id: item.main_message_id,
        from: item?.to,
        to: item?.from,
        delivered_to: item.delivered_to,
        subject: item.subject,
        sender_id: sender_id,
        type: 'reply',
        individual_message_id: item.individual_message_id,
      };
    } else {
      payload = {
        main_message_id: item.main_message_id,
        from: item.to,
        to: item.from,
        delivered_to: item.delivered_to,
        subject: item.subject,
        sender_id: sender_id,
        type: 'reply',
        individual_message_id: item?.oauth_delivery_message_id,
      };
    }
  }
  // const payload = item.type === 'sender' ? createPayload(item?.from, item?.to) : createPayload(item?.to, item?.from);
  setShowTyper(true);
  dispatch(setReplyThreadData(payload));
};

export const handleReplyAll = (data: any, sender_id: string, setShowTyper: any, dispatch: any) => {
  setShowTyper(false);
  let payload;
  if (data.type === 'sender') {
    if (data?.email_type === 'smtp_imap') {
      payload = {
        from: data?.inbox_mail_data?.from?.value || [data?.from],
        to: data?.inbox_mail_data?.to?.value || [data?.to],
        delivered_to: data?.platform_message_details?.delivered_to,
        cc: data?.inbox_mail_data?.cc?.value ? data?.inbox_mail_data?.cc?.value : [],
        bcc: data?.inbox_mail_data?.bcc?.value ? data?.inbox_mail_data?.bcc?.value : [],
        subject: data?.subject,
        sender_id: sender_id,
        message: '',
        main_message_id: data?.main_message_id,
        individual_message_id: data?.individual_message_id,
        type: 'reply',
      };
    } else {
      payload = {
        main_message_id: data?.main_message_id,
        from: data?.from,
        to: data?.to,
        delivered_to: data?.delivered_to,
        cc: data?.cc ? data?.cc : [],
        bcc: data?.bcc ? data?.bcc : [],
        subject: data?.subject,
        sender_id: sender_id,
        message: '',
        type: 'reply',
        individual_message_id: data?.oauth_delivery_message_id,
      };
    }
  } else {
    if (data?.email_type === 'smtp_imap') {
      payload = {
        from: data?.inbox_mail_data?.to?.value || data?.to,
        to: data?.inbox_mail_data ? [...data?.inbox_mail_data?.from?.value, ...data?.inbox_mail_data?.to?.value?.slice(1, data?.inbox_mail_data?.to?.value?.length)] : [data?.from, ...data?.to?.slice(1, data?.to?.length)],
        delivered_to: data?.delivered_to,
        cc: data?.inbox_mail_data?.cc?.value ? data?.inbox_mail_data?.cc?.value : [],
        bcc: data?.inbox_mail_data?.bcc?.value ? data?.inbox_mail_data?.bcc?.value : [],
        subject: data.subject,
        sender_id: sender_id,
        type: 'reply',
        message: '',
        individual_message_id: data?.individual_message_id,
      };
    } else {
      payload = {
        main_message_id: data.main_message_id,
        from: data.to,
        to: [data?.from, ...(data?.to?.length > 1 ? data?.to.slice(1) : [])],
        delivered_to: data.delivered_to,
        cc: data.cc ? data.cc : [],
        bcc: data.bcc ? data.bcc : [],
        subject: data.subject,
        sender_id: sender_id,
        type: 'reply',
        message: '',
        individual_message_id: data?.oauth_delivery_message_id,
      };
    }
  }
  // const payload = item.type === 'sender' ? createPayload(item.from, item.to) : createPayload(item.to, item.from);
  dispatch(setReplyThreadData(payload));
  setShowTyper(true);
};

export const handleForward = (data: any, sender_id: string, setShowTyper: any, dispatch: any) => {
  setShowTyper(false);
  let payload;
  if (data.type === 'sender') {
    if (data?.email_type === 'smtp_imap') {
      const toEmails = data?.inbox_mail_data?.to?.value?.map((item: any) => item.address).join(', ');

      payload = {
        from: data?.inbox_mail_data?.from?.value,
        to: [],
        delivered_to: data?.platform_message_details?.delivered_to,
        cc: data?.inbox_mail_data?.cc ? data?.inbox_mail_data?.cc?.value : [],
        bcc: data?.inbox_mail_data?.bcc ? data?.inbox_mail_data?.bcc?.value : [],
        subject: data?.subject,
        sender_id: sender_id,
        main_message_id: data?.main_message_id,
        message: '',
        individual_message_id: data?.individual_message_id,
        type: 'forward',
        date: moment(data?.platform_message_details?.date).format('MMM DD, hh:mm A'),
        originalMessagedata: `<br> -------Forwarded Message-------<br>from: ${data?.inbox_mail_data?.from?.value?.[0]?.address}<br>Date: ${moment(data?.platform_message_details?.date).format('MMM DD, hh:mm A')}<br>Subject: ${data?.subject} <br>To: ${toEmails}<br><br>`,
      };
    } else {
      const toEmailoriginalData = Array.isArray(data?.to?.value) ? data?.to.value?.map((item: any) => item.address).join(', ') : data?.to?.address || '';
      payload = {
        main_message_id: data?.main_message_id,
        from: data?.from,
        to: [],
        delivered_to: data?.delivered_to,
        cc: data?.cc ? data?.cc : [],
        bcc: data?.bcc ? data?.bcc : [],
        subject: data?.subject,
        sender_id: sender_id,
        message: '',
        type: 'forward',
        date: moment(data?.platform_message_details?.date).format('MMM DD, hh:mm A'),
        individual_message_id: data?.oauth_delivery_message_id,
        originalMessagedata: `<br> -------Forwarded Message-------<br>from: ${data?.from?.address}<br>Date: ${moment(data?.platform_message_details?.date).format('MMM DD, hh:mm A')}<br>Subject: ${data?.subject} <br>To: ${toEmailoriginalData}<br><br>`,
      };
    }
  } else {
    if (data?.email_type === 'smtp_imap') {
      const toEmaildata = data?.inbox_mail_data?.to?.value?.map((item: any) => item.address).join(', ');
      payload = {
        from: data?.inbox_mail_data?.to?.value,
        to: [],
        delivered_to: data.delivered_to,
        cc: data?.inbox_mail_data?.cc?.value ? data?.inbox_mail_data?.cc?.value : [],
        bcc: data?.inbox_mail_data?.bcc?.value ? data?.inbox_mail_data?.bcc?.value : [],
        subject: data.subject,
        message: '',
        sender_id: sender_id,
        individual_message_id: data?.individual_message_id,
        main_message_id: data?.main_message_id,
        type: 'forward',
        date: moment(data?.platform_message_details?.date).format('MMM DD, hh:mm A'),
        originalMessagedata: `<br> -------Forwarded Message-------<br>from: ${data?.inbox_mail_data?.from?.value?.[0]?.address}<br>Date: ${moment(data?.platform_message_details?.date).format('MMM DD, hh:mm A')}<br>Subject: ${data?.subject} <br>To: ${toEmaildata}<br><br>`,
      };
    } else {
      const toEmaildoauth = Array.isArray(data?.to) ? data?.to.value?.map((item: any) => item.address).join(', ') : data?.to?.address || '';
      payload = {
        main_message_id: data.main_message_id,
        from: data.to,
        to: [],
        delivered_to: data.delivered_to,
        cc: data.cc ? data.cc : [],
        bcc: data.bcc ? data.bcc : [],
        subject: data.subject,
        sender_id: sender_id,
        message: '',
        type: 'forward',
        date: moment(data?.platform_message_details?.date).format('MMM DD, hh:mm A'),
        individual_message_id: data?.platform_message_details?.oauth_delivery_message_id,
        originalMessagedata: `<br> -------Forwarded Message-------<br>from: ${data?.from?.address}<br>Date: ${moment(data?.platform_message_details?.date).format('MMM DD, hh:mm A')}<br>Subject: ${data?.subject} <br>To: ${toEmaildoauth}<br><br>`,
      };
    }
  }
  dispatch(setReplyThreadData(payload));
  setTimeout(() => {
    setShowTyper(true);
  }, 500);
};

export const formatIndianDateTime = (timestamp: string) => {
  const date = new Date(timestamp);
  const options: Intl.DateTimeFormatOptions = {
    timeZone: 'Asia/Kolkata',
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };
  const formattedDate = date.toLocaleDateString('en-IN', options).replace(',', '');
  return `${formattedDate}`;
};

export const downloadFile = (attachment: any, saveAs: any) => {
  if (attachment?.content?.data && attachment?.filename && attachment?.contentType) {
    const byteArray = new Uint8Array(attachment.content.data);
    const blob = new Blob([byteArray], { type: attachment.contentType });
    const filename = attachment.filename || 'attachment';
    saveAs(blob, filename);
  } else {
    console.error('Invalid attachment data');
  }
};

const toggleIndexInArray = (index: number, message_id: string, setRowIndex: React.Dispatch<React.SetStateAction<Array<string>>>, emailData: any, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>, setEmailData: any) => {
  setRowIndex((prevArr: any) => {
    if (prevArr.includes(index)) {
      return prevArr.filter((item: number) => item !== index);
    } else {
      if (!emailData?.profile?.receiver_email) {
        console.log(message_id, 'message_id');
        fetchDbEmails(message_id, setIsLoading, setEmailData);
      }
      return [...prevArr, index];
    }
  });
};

const handleStarredView = (setStarredMessages: any, inboxData: { uuid: string }) => {
  setStarredMessages((prevArr: string[]) => {
    if (prevArr.includes(inboxData?.uuid)) {
      return prevArr.filter((item) => item !== inboxData?.uuid);
    } else {
      return [...prevArr, inboxData?.uuid];
    }
  });
};

export const handleAccordionClick = (event: React.MouseEvent, index: number, message_id: string, setRowIndex: React.Dispatch<React.SetStateAction<Array<string>>>, emailData: any, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>, setEmailData: any) => {
  console.log(message_id, 'message_id');
  const targetElement = event.target as Element;
  if (!targetElement.closest('.group-tags-inbox') && !targetElement.closest('.delete-and-view-mail')) {
    toggleIndexInArray(index, message_id, setRowIndex, emailData, setIsLoading, setEmailData);
  }
};
export const handleMessageReadStatus = async (data: any, dispatch: any, setReadMessages: any, inboxData: { uuid: string }) => {
  try {
    dispatch(setTriggerCount((prev: boolean) => !prev));
    setReadMessages((prev: string[]) => [...prev, inboxData?.uuid]);
    if (data?.read_history?.is_viewed === false) {
      const payload = {
        uuid: data?.uuid,
        is_viewed: true,
        variant: 'email',
      };
      const response = await BaseApi.patch(`/unified/inbox/`, payload);
      if (response.error === true) {
        toast.error(response.message, toastState.error);
      }
    }
  } catch (error) {
    console.error(error);
  }
};

export const handleAvatarClick = (e: any, uuid: string, dispatch: any, setIsChecked: React.Dispatch<React.SetStateAction<boolean>>, isChecked: boolean) => {
  e.stopPropagation();
  dispatch(setClearSelectData(uuid));
  setIsChecked(!isChecked);
};

export const handleDeleteConversation = async (deleteId: string, fetchTrigger: any, setDeleteId: React.Dispatch<React.SetStateAction<string | null>>, setOpenDeleteConfirmation: React.Dispatch<React.SetStateAction<boolean>>) => {
  if (deleteId) {
    try {
      const response = await BaseApi.delete(`/unified/inbox/${deleteId}`);
      if (response.error) {
        toast.error(response.message, toastState.error);
      } else {
        toast.success(response.message, toastState.success);
        fetchTrigger();
      }
    } catch (error) {
      setDeleteId(null);
    } finally {
      setDeleteId(null);
      setOpenDeleteConfirmation(false);
    }
  }
};

export const handleDeleteThreadConversation = async (uuid: string, fetchTrigger: any) => {
  if (uuid) {
    try {
      const response = await BaseApi.delete(`/unified/inbox/mail/thread`, {
        data: {
          delete_ids: [uuid],
        },
      });
      if (response.error) {
        toast.error(response.message, toastState.error);
      } else {
        toast.success(response.message, toastState.success);
        fetchTrigger();
      }
    } catch (error) {
    } finally {
    }
  }
};

export const handleStaredStatus = async (status: boolean, id: string, setStarredMessages: any, inboxData: { uuid: string }, dispatch: any, setDeleteId: React.Dispatch<React.SetStateAction<string | null>>) => {
  handleStarredView(setStarredMessages, inboxData);
  dispatch(setTriggerCount((prev: boolean) => !prev));
  try {
    const payload = {
      uuid: id,
      is_starred_message: !status,
    };
    const response = await BaseApi.patch(`/unified/inbox/`, payload);
    if (response.error) {
      toast.error(response.message, toastState.error);
    }
  } catch (error) {
    setDeleteId(null);
  }
};

export const handleClearConversation = async (e: React.MouseEvent, dispatch: any, inbox_status: string, fetchTrigger: any, uuid?: string) => {
  e.stopPropagation();
  dispatch(setTriggerCount((prev: boolean) => !prev));
  try {
    const payload = {
      unified_inbox_status: inbox_status === 'cleared' ? 'pending' : 'cleared',
      uuid: [uuid],
    };
    const response = await BaseApi.patch(`/unified/inbox/`, payload);
    if (response.status === 200) {
      toast.success(response.message, toastState.success);
      fetchTrigger();
    }
  } catch (error) {
    console.error('error', error);
  }
};

export const calculateDate = (type: string) => {
  const now = moment();
  let calculatedDate;
  switch (type) {
    case 'later':
      calculatedDate = now.add(2, 'hours');
      break;
    case 'tomorrow':
      calculatedDate = now.add(1, 'days').set({ hour: 8, minute: 0, second: 0, millisecond: 0 });
      break;
    case 'weekend':
      calculatedDate = now.day(6).set({ hour: 8, minute: 0, second: 0, millisecond: 0 });
      if (calculatedDate.isBefore(now)) {
        calculatedDate.add(1, 'weeks');
      }
      break;
    case 'nextWeek':
      calculatedDate = now.day(1 + 7).set({ hour: 8, minute: 0, second: 0, millisecond: 0 });
      break;
    default:
      calculatedDate = now;
  }

  return calculatedDate.toDate();
};

export const handleSnooze = async (fetchTrigger: any, uuid?: string, type?: string) => {
  try {
    const payload = {
      unified_inbox_status: 'snoozed',
      uuid: [uuid],
      snoozed_date: await calculateDate(type),
      is_viewed: false,
    };
    console.log(payload, 'pay');
    const response = await BaseApi.patch(`/unified/inbox/`, payload);
    if (response.status === 200) {
      toast.success(response.message, toastState.success);
      fetchTrigger();
    }
  } catch (error) {
    console.error('error', error);
  }
};

export const handleCustomSnooze = async (uuid: string, dispatch: any) => {
  console.log('id', uuid);
  dispatch(setSnoozePopupState(true));
  dispatch(setSnoozeId(uuid));
};

export const handlePipelineView = async (main_message_id: string, dispatch: any, navigate: any) => {
  try {
    const response = await BaseApi.get(`crm/pipeline/opportunity/by/email/${main_message_id}`);
    if (response.status === 200 && response?.data?.data?.data?.[0]?.uuid) {
      dispatch(
        setOpportunityInboxViewData({
          opportunity_id: response?.data?.data?.data?.[0]?.uuid,
          opportunity_domain: response?.data?.data?.data?.[0]?.opportunity?.[0]?.prospect_email_domain,
          brand_id: response?.data?.data?.data?.[0]?.brand_id,
          pipeline_id: response?.data?.data?.data?.[0]?.pipeline_id,
        })
      );
      dispatch(setProspectDetails({ ...response?.data?.data?.data, opportunity: response?.data?.data?.data?.[0]?.opportunity }));
      dispatch(setSideBarFullView(false));
      navigate(`/crm/inbox?opportunityid=${response.data.data.data[0]?.uuid}`);
    }
  } catch (error) {
    console.error('error', error);
  }
};

// Delete function for group from Email Inbox

export const HandleDelteGroupsFromInboxClickEvent = async ({ uuids, val, setGroupTags, groupList, row }: { uuids: string; val: any; setGroupTags: any; groupList: any; row: any }) => {
  // setGroupTags((prev) => {
  //   const updatedGroup = prev.filter((group) => group.uuid !== uuid);
  //   return updatedGroup;
  // });

  let uuid: string;

  if (!uuids) {
    const id = groupList?.find((group: { name: string }) => group?.name === val?.name);
    setGroupTags((prev: any) => {
      const updatedGroup = prev.filter((group: { name: string }) => group.name !== val?.name);
      return updatedGroup;
    });

    uuid = id?.uuid;
  } else {
    uuid = uuids;
    setGroupTags((prev: any) => {
      const updatedGroup = prev.filter((group: { uuid: string }) => group.uuid !== uuid);
      return updatedGroup;
    });
  }

  try {
    await BaseApi.delete(`/groups/delete/into/linked/with/unified_inbox`, {
      data: {
        group_id: uuid,
        variant_id: row?.uuid,
      },
    });
  } catch (error) {
    console.log('Group removing from email error', error);
  }
};

// Create new group and assaign to email when user press enter keywork in search box
export const HandleCreateGroupClickEvent = async ({ setGroups, params, setSearchedGroup, setGroupTags, dispatch }: { params: any; setGroups: any; setSearchedGroup: any; setGroupTags: any; dispatch: any }) => {
  try {
    setGroups((prev: any) => {
      const updatedList = prev.length ? [params, ...prev].sort((a: any, b: any) => (a?.name.toLowerCase() < b?.name.toLowerCase() ? -1 : a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : 0)) : [params];
      return updatedList;
    });
    setSearchedGroup((prev: any) => {
      const updatedList = prev.length ? [params, ...prev].sort((a: any, b: any) => (a?.name.toLowerCase() < b?.name.toLowerCase() ? -1 : a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : 0)) : [params];
      return updatedList;
    });
    setGroupTags((prev: any) => [params, ...prev]);
    const response = await UpdateGroupsFromInboxApi({ payload: params });
    if (response.status === 200) {
      dispatch(setfetchGrouplistTrigger());
    }
  } catch (error) {
    console.error(error);
  } finally {
    // fetchList();
  }
};

// Click function for add new group name for email
export const HandleAddNewGroupClickEvent = async ({ val, dispatch, setGroupTags, row }: { val: any; dispatch: any; setGroupTags: any; row: any }) => {
  setGroupTags((prev: any) => {
    // Check if group name already exist
    const groupName = prev.some((group: { uuid: string }) => group.uuid === val.uuid);
    if (groupName) return prev;
    return [val, ...prev];
  });
  const params = { group_id: val?.uuid, variant_id: row?.uuid };
  try {
    const response = await UpdateGroupsFromInboxApi({ payload: params });
    if (response.status === 200) {
      dispatch(setfetchGrouplistTrigger());
    }
  } catch (error) {
    console.error(error);
  }
};

// Delete Groups from button
export const HandleDeleteInboxGroups = async ({
  uuid,
  dispatch,
  setGroups,
  setSearchedGroup,
  setGroupTags,
  setGroupToggleState,
  setIsEditVisible,
  hanleDeleteGroupFromSenderList,
}: {
  uuid: string;
  dispatch: any;
  setGroups: any;
  setSearchedGroup: any;
  setGroupTags: any;
  setGroupToggleState: any;
  setIsEditVisible: any;
  hanleDeleteGroupFromSenderList: any;
}) => {
  setGroups((prev: any) => {
    const updatedList = prev.filter((group: { uuid: string }) => group.uuid !== uuid);
    return updatedList;
  });
  setSearchedGroup((prev: any) => {
    const updatedList = prev.filter((group: { uuid: string }) => group.uuid !== uuid);
    return updatedList;
  });
  setGroupTags((prev: any) => {
    const updatedGroup = prev.filter((group: { uuid: string }) => group.uuid !== uuid);
    return updatedGroup;
  });
  setGroupToggleState((prev: any) => ({
    ...prev,
    [uuid]: { ...prev[uuid], show: false },
  }));
  setIsEditVisible(false);
  try {
    const response = await BaseApi.delete(`/groups/${uuid}`);
    if (response.status === 200) {
      dispatch(setfetchGrouplistTrigger());
      hanleDeleteGroupFromSenderList(uuid);
    }
  } catch (error) {
    console.log('Group delete error', error);
  }
};

// Rename Group api function
export const HadleRenameGroupClickEvent = async ({ value, val, setGroupToggleState, setGroups, setSearchedGroup, dispatch, setGroupTags }: { value: string; val: any; setGroupToggleState: any; setGroups: any; setSearchedGroup: any; dispatch: any; setGroupTags: any }) => {
  try {
    const params = {
      uuid: val?.uuid,
      name: value?.toLowerCase().replace(/\b\w/g, (char: string) => char.toUpperCase()),
    };
    setGroups((prev: any) => {
      const updatedList = prev
        .map((group: { uuid: string }) => {
          if (group?.uuid === val?.uuid) return { ...group, name: params?.name };
          return group;
        })
        .sort((a: any, b: any) => (a?.name.toLowerCase() < b?.name.toLowerCase() ? -1 : a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : 0));
      return updatedList;
    });
    setSearchedGroup((prev: any) => {
      const updatedList = prev
        .map((group: { uuid: string }) => {
          if (group?.uuid === val?.uuid) return { ...group, name: params?.name };
          return group;
        })
        .sort((a: any, b: any) => (a?.name.toLowerCase() < b?.name.toLowerCase() ? -1 : a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : 0));
      return updatedList;
    });
    setGroupTags((prev: any) => {
      const updatedGroup = prev.map((group: { uuid: string }) => {
        if (group?.uuid === val?.uuid) return { ...group, name: params?.name };
        return group;
      });
      return updatedGroup;
    });
    setGroupToggleState((prev: any) => ({
      ...prev,
      [val?.uuid]: { show: false, rename: false },
    }));
    const response = await BaseApi.patch('groups', params);
    if (response.status === 200) {
      dispatch(setfetchGrouplistTrigger());
    }
  } catch (error) {
    console.log('Group rename error', error);
  }
};
