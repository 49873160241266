import { DeleteStripeUserCardApi, GetStripeUserCardApi, GetUserInvoiceDataApi, UpdateInvoiceMemo } from '../api-handler';

export const FetchCardDataforUser = async ({ setLoading, setData }: { setLoading: React.Dispatch<React.SetStateAction<boolean>>; setData: any }) => {
  try {
    setLoading(true);

    const response = await GetStripeUserCardApi();
    if (!response?.error) {
      console.log(response);
      setData(response?.data);
    }
  } catch (error) {
    console.error(error);
  } finally {
    setLoading(false);
  }
};

export const FetchUserInvoiceData = async ({ setInvoiceMemo, setData }: { setInvoiceMemo: React.Dispatch<React.SetStateAction<string>>; setData: any }) => {
  try {
    // setLoading(true);

    const response = await GetUserInvoiceDataApi();
    if (!response?.error) {
      console.log(response);
      setInvoiceMemo(response?.data?.invoice_memo);
    }
  } catch (error) {
    console.error(error);
  }
};

export const DeleteCardDataforUser = async ({ setLoading, setData }: { setLoading: React.Dispatch<React.SetStateAction<boolean>>; setData: any }) => {
  try {
    setLoading(true);

    const response = await GetStripeUserCardApi();
    if (!response?.error) {
      console.log(response);
      setData(response?.data);
    }
  } catch (error) {
    console.error(error);
  }
};

export async function DeleteCardforUser(paymentMethodId: string, setTrigger: any, setDeleteModal: any) {
  try {
    // Detach the payment method from the customer
    const response = await DeleteStripeUserCardApi(paymentMethodId);
    if (response) {
      setTrigger((prev: any) => !prev);
      setDeleteModal(false);
    }
    return response;
  } catch (error) {
    console.error('Error deleting card:', error);
    throw error;
  }
}

export async function InvoiceMemoUpdate(invoiceMemo: string) {
  try {
    // Detach the payment method from the customer
    const response = await UpdateInvoiceMemo(invoiceMemo);
    return response;
  } catch (error) {
    console.error('Error Update Invoice:', error);
    throw error;
  }
}
