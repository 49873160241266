/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Menu, MenuButton, MenuItem, MenuList, SkeletonText } from '@chakra-ui/react';
import { IoMail } from 'react-icons/io5';
import { LuCornerUpRight } from 'react-icons/lu';
import { AiFillDelete } from 'react-icons/ai';
import saveAs from 'file-saver';
import { useEffect, useRef, useState } from 'react';
import { CgFile } from 'react-icons/cg';
import { BsReply, BsReplyAll } from 'react-icons/bs';
import React from 'react';
import { TiArrowForwardOutline } from 'react-icons/ti';
import { useAppDispatch } from 'redux-store';
import {
  downloadFile,
  fetchMatchOptionData,
  formatIndianDateTime,
  getRandomIndex,
  handleAccordionClick,
  handleForward,
  handleMainReply,
  handleMergeSelect,
  handleMessageReadStatus,
  handleReply,
  handleReplyAll,
  handleSearch,
  toggleIndexInArray,
} from 'shared/unmatch/event-handler/unmatch-event';
import GroupTags from './UnmatchGroupTags';
import { setMergeSearch } from 'redux-store/unmatched';
import SearchBox from 'components/common/searchBox';
import { calculateTimeAgo } from 'utility/utils';
import UnmatchMergeConfirmPopUp from './UnmatchMergeConfirmPopup';
import UploadSingleLead from 'components/common/crm/UploadSingleLead';

interface ListRowTypes {
  data: any;
  index: number;
  updateIgnoreStatus: (e: any, status: string, uuid: string, ignored_contact?: string) => void;
  mergeOption?: Array<any>;
  count: number;
  avatarColor: string;
  roleStatus: any;
}
const UnmatchListRow = (props: ListRowTypes) => {
  const { data, index, updateIgnoreStatus, count, avatarColor, roleStatus } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [readMessages, setReadMessages] = useState([]);
  const typerRef = useRef<HTMLDivElement>(null);
  const [rowIndex, setRowIndex] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [emailData, setEmailData] = useState<any>({ loading: true, profile: {} });
  const [showTyper, setShowTyper] = useState(false);
  const [openMergeModal, setOpenMergeModal] = useState(false);
  const [primaryMergeData, setPrimaryMergeData] = useState({});
  const [secondaryMergeData, setSecondaryMergeData] = useState({});
  const [mergeOption, setMergeOption] = useState([]);

  const colors = ['#4318FF', '#E7C100', '#7F56D9', '#7E4400', '#12B76A'];

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (data.read_history.is_viewed) {
      setReadMessages((prev) => [...prev, data?.uuid]);
    }
  }, [data.uuid]);

  useEffect(() => {
    if (showTyper && typerRef.current) {
      typerRef.current.scrollIntoView({ behavior: 'smooth' });
      typerRef.current.focus();
    }
  }, [showTyper]);

  return (
    <div className="list-row cursor-pointer dark:bg-darkTwo" id={`row-${index}`} aria-valuetext={data?.uuid}>
      <div
        className={`group/list flex justify-between ${index !== 0 ? 'border-t dark:border-darkBorder' : ''}  ${readMessages?.includes(data.uuid) && 'bg-[#f7f9fb] dark:bg-darkTwo'} 
           ${
             readMessages?.includes(data?.uuid) && rowIndex.includes(index)
               ? '!bg-[#f9f8ff] dark:!bg-darkOne '
               : readMessages?.includes(data?.uuid) && 'bg-[#f7f9fb] dark:bg-darkOne'
           }
        `}
        onClick={(e) => {
          handleAccordionClick(e, index, setRowIndex, emailData, setIsLoading, setEmailData, data);
          handleMessageReadStatus(data, setReadMessages);
        }}
      >
        <div className="flex gap-2 px-2 py-3">
          <div className="flex items-center justify-start gap-2 ">
            <div className={`mr-1 size-[5px] rounded-full ${!readMessages?.includes(data?.uuid) && 'bg-green-500 '} `} />
            <div>
              <Avatar className="size-2" size={'xs'} name={data?.platform_message_details?.from?.address?.split('@')[0][0]} bg={avatarColor} color={'white'} />
            </div>
            <div className="max-w-[200px] truncate text-sm font-semibold text-[#344054] dark:text-gray-100">
              {data?.platform_message_details?.from?.name || data?.platform_message_details?.from?.address?.split('@')[0]}
            </div>
            <div className="ml-4 flex size-6 items-center justify-center rounded-full bg-[#e8effb]">
              <IoMail className=" text-sm text-[#015AFF]" />
            </div>
          </div>
          <div className="ml-5 flex items-center justify-start gap-2">
            <div className="!max-w-[18rem]">
              <GroupTags row={data} />
            </div>
          </div>
          {!rowIndex.includes(index) ? (
            <div className="ml-5 flex items-center justify-start gap-2 ">
              <p className="max-w-[18rem] truncate  text-sm font-medium">{data?.platform_message_details?.subject}</p>
              <p className="max-w-[18rem] truncate  text-xs text-column dark:text-gray-500" dangerouslySetInnerHTML={{ __html: data?.platform_message_details?.message }} />
            </div>
          ) : null}
        </div>
        {/* {!rowIndex.includes(index) ? ( */}
        <div className="relative flex items-center justify-end gap-4 ">
          <div
            className={`flex ${readMessages?.includes(data?.uuid) && 'bg-[#f7f9fb] dark:bg-darkTwo'}  ${
              rowIndex.includes(index) ? 'bg-[#f9f8ff]' : 'opacity-0 transition-opacity group-hover/list:opacity-100'
            }`}
          >
            <div id={`match-menu-${index}`}>
              <Menu id={`match-menu-${index}`}>
                <MenuButton
                  name="Match"
                  id="match-menu"
                  className={`flex !px-2  ${readMessages?.includes(data.uuid) && 'bg-[#f7f9fb]'} dark:bg-darkTwo`}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    dispatch(setMergeSearch(data?.platform_message_details?.from?.address?.split('@')[1]));
                    fetchMatchOptionData(data?.platform_message_details?.from?.address?.split('@')[1], setMergeOption);
                  }}
                >
                  {/* Role Restriction for the Match in the  */}
                  {(roleStatus?.organization_owner || roleStatus?.organization_access_list?.match) && (
                    <div className={`flex gap-2 text-xs `}>
                      Match
                      <LuCornerUpRight className="text-sm text-[#8F9BBA]" />
                    </div>
                  )}
                </MenuButton>
                <MenuList className={`match-search-unmatched right-0 mt-2 w-[20rem] rounded-md border-2 border-blueSecondary`}>
                  <>
                    <div className="flex flex-col gap-1.5">
                      <div className="mx-2 mt-2 flex gap-2 text-column">
                        <h5 className="cursor-default text-xs font-medium">Check match from you company</h5>
                      </div>
                      <div className="flex items-center text-column">
                        <SearchBox
                          id="search-opportunity"
                          placeholder="Search campaigns or oppotunities"
                          extraClassName="w-full"
                          inputClass="!text-xs font-normal match-search-unmatched"
                          onChange={(e) => handleSearch(e, setMergeOption)}
                        />
                      </div>
                      <div className="px-4 text-xs text-column">Recommended</div>
                      {!mergeOption?.length ? <div className="px-4 text-center text-xs text-column">Search prospects to match..</div> : null}

                      <div className="h-48 overflow-y-auto">
                        {mergeOption?.map((options, index) => (
                          <div
                            className="mx-1 mt-1 flex items-center px-2"
                            key={index}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleMergeSelect(primaryMergeData, setPrimaryMergeData, setSecondaryMergeData, setOpenMergeModal, options);
                            }}
                          >
                            <div className="flex  w-full gap-3">
                              <div className="flex h-8 w-8  items-center justify-center rounded-full p-1 shadow-md">
                                {options?.photo ? (
                                  <img className=" h-6 w-6 rounded-full" src={''} alt="" />
                                ) : (
                                  <div className="flex h-6 w-6 items-center justify-center rounded-full border bg-gray-100 transition-all duration-300 group-hover/list:bg-brand-50">
                                    <Avatar
                                      size="xs"
                                      className={`size-4 text-sm text-gray-400 duration-500 `}
                                      name={options?.email?.split('@')[0][0]}
                                      bg={colors[getRandomIndex()]}
                                    />
                                  </div>
                                )}
                              </div>
                              <div className="flex flex-col justify-center">
                                <h6 className="w-60 overflow-hidden text-ellipsis whitespace-nowrap  text-xs font-semibold">{options?.email}</h6>
                                <p className="w-60 overflow-hidden text-ellipsis whitespace-nowrap  text-[10px] text-column">
                                  {options?.campaign_name ? options?.campaign_name : ''}
                                </p>
                              </div>
                            </div>
                            {/* <div className="mt-0.5 flex w-full flex-col items-end gap-1.5">
                                <span className="text-[.625rem]">{options?.cdate && moment(options?.cdate).format('MMM DD, h:mm A')}</span>
                              </div> */}
                          </div>
                        ))}
                      </div>
                      <div className="mt-2 border-t-2 border-dashed p-2 text-center text-[10px] font-bold">
                        Not able to find your match?{' '}
                        <span
                          className="cursor-pointer select-none text-blueSecondary underline"
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsModalOpen(true);
                          }}
                          id="add-new"
                        >
                          Add new
                        </span>
                      </div>
                    </div>
                  </>
                </MenuList>
              </Menu>
            </div>

            <Menu>
              <MenuButton
                name="Match"
                className={`!hover:text-blueSecondary flex ${data?.read_history?.is_viewed && 'bg-[#f7f9fb] dark:bg-darkTwo'} !px-2 `}
                onClick={() => toggleIndexInArray(index, setRowIndex, emailData, setIsLoading, setEmailData, data)}
              >
                {(roleStatus?.organization_owner || roleStatus?.organization_access_list?.ignore) && (
                  <div className="flex items-center justify-center gap-2 text-xs text-blueSecondary">
                    <AiFillDelete className="text-blueSecondary" /> Ignore
                  </div>
                )}
              </MenuButton>
              <MenuList minW="0" className="!h-[111px] !w-[15rem] !rounded-lg border-blueSecondary bg-white !p-0 shadow-lg  ">
                <MenuItem
                  onClick={(e) => updateIgnoreStatus(e, 'ignore_once', data.uuid)}
                  className="group/once flex items-center space-x-2 rounded-t-lg !py-2 !pe-2 !ps-4 text-sm !text-[#2B3674] hover:rounded-t-lg hover:!bg-[#4318FF] hover:!text-white "
                >
                  <span>
                    Ignore <span className="text-blueSecondary group-hover/once:text-white">once</span>
                  </span>
                </MenuItem>
                <MenuItem
                  onClick={(e) => updateIgnoreStatus(e, 'ignore_future_domain', data.uuid, data?.platform_message_details?.from?.address?.split('@')[1])}
                  className="group/domain flex items-center space-x-2 !py-2 !pe-2  !ps-4 text-sm !text-[#2B3674] hover:!bg-[#4318FF] hover:!text-white "
                >
                  <span>
                    Ignore entire <span className="text-blueSecondary group-hover/domain:text-white">domain</span> for future
                  </span>
                </MenuItem>
                <MenuItem
                  onClick={(e) => updateIgnoreStatus(e, 'ignore_future_email', data.uuid, data?.platform_message_details?.from?.address)}
                  className="group/email flex items-center space-x-2 !py-2 !pe-2  !ps-4 text-sm !text-[#2B3674] hover:rounded-b-lg hover:!bg-[#4318FF]  hover:!text-white"
                >
                  <span>
                    Ignore entire <span className="text-blueSecondary group-hover/email:text-white">email</span> for future
                  </span>
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
          <p className=" -mr-5 w-[100px] truncate text-xs text-column dark:text-gray-300">
            {data?.platform_message_details?.last_thread_date
              ? calculateTimeAgo(data?.platform_message_details?.last_thread_date)
              : calculateTimeAgo(data?.platform_message_details?.mail_sent_date_local)}
          </p>
        </div>
        {/* ) : null} */}
      </div>
      <>
        {rowIndex?.includes(index) && (
          <div className={`max-h-[30rem] ${count < 6 && 'min-h-[30rem]'} overflow-auto border-b !bg-[#f9f8ff] dark:!bg-darkTwo `} key={index}>
            {isLoading ? (
              <div className="p-4">
                <div className=" flex items-center gap-3">
                  <SkeletonText mt="2" noOfLines={4} spacing="2" skeletonHeight="2" className="w-full" />
                </div>
                <SkeletonText mt="8" noOfLines={3} spacing="1.5" skeletonHeight="2.5" className="w-full" />
              </div>
            ) : (
              emailData?.profile?.message?.map((item: any, ind: number) => (
                <>
                  {item?.platform_message_details?.from?.address ? (
                    <div className="!bg-[#f9f8ff] py-4 dark:!bg-darkTwo" key={item?.uuid}>
                      <div className="flex items-center justify-start px-4">
                        <div className="flex size-7 items-center justify-center rounded-full bg-[#e3e7f2] dark:!bg-darkTwo">
                          <BsReply
                            className="text-column"
                            onClick={(e) => {
                              e?.stopPropagation();
                              handleReply(item?.platform_message_details, item?.sender_id, setShowTyper, dispatch);
                              setShowTyper(!showTyper);
                            }}
                          />
                        </div>
                        <div className="w-[20px] border bg-[#e3e7f2] dark:!bg-darkOne" />
                        <div className=" w-full rounded-md border bg-white shadow dark:border-darkBorder dark:!bg-darkThree">
                          <div className=" p-3">
                            <div className="flex cursor-pointer justify-between">
                              <div className="flex gap-3 ">
                                <Avatar
                                  className="border"
                                  style={{ height: '30px', width: '30px' }}
                                  name={item?.platform_message_details?.from?.address?.[0]}
                                  bg="#ece7ff"
                                  color={'white'}
                                />
                                <div>
                                  <p className="text-sm font-bold text-heading">
                                    {item?.platform_message_details?.from?.name || item?.platform_message_details?.from?.address?.split('@')[0]}{' '}
                                    <span className="text-xs font-medium text-column">{`<${item?.platform_message_details?.from?.address}>`}</span>
                                  </p>
                                  <div className="flex py-1">
                                    <span className=" line-clamp-2 max-w-96 select-text overflow-hidden whitespace-normal text-xs text-column">
                                      to -{' '}
                                      {!item?.platform_message_details?.to?.length
                                        ? item?.platform_message_details?.to?.address
                                        : item?.platform_message_details?.to?.map((val: { address: string }) => val.address).join(',')}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div></div>
                              <div className="flex items-start">
                                <div className="mr-3 flex items-center justify-end gap-2">
                                  <div
                                    onClick={() => {
                                      handleMainReply(item?.platform_message_details, item?.sender_id, setShowTyper, dispatch);
                                    }}
                                  >
                                    <BsReply className="text-column" />
                                  </div>
                                  <div
                                    onClick={() => {
                                      handleReplyAll(item?.platform_message_details, item?.sender_id, setShowTyper, dispatch);
                                    }}
                                  >
                                    <BsReplyAll className="text-column" />
                                  </div>
                                  <div
                                    onClick={() => {
                                      handleForward(item?.platform_message_details, item?.sender_id, setShowTyper, dispatch);
                                    }}
                                  >
                                    <TiArrowForwardOutline className="text-column" />
                                  </div>
                                </div>
                                <p className="text-xs text-column">{formatIndianDateTime(item?.platform_message_details?.mail_sent_date)}</p>
                              </div>
                            </div>
                            <div className="textEditor mt-2 overflow-x-auto text-xs" dangerouslySetInnerHTML={{ __html: item?.fullMessage }} />
                            {/* <div className='text-xs w-full border border-red-500 leading-5 text-paused-color text-wrap'>{emailData?.profile?.message}</div> */}
                          </div>
                          {item?.attachment?.length ? (
                            <div className="mx-3 my-4 mt-1 rounded-md border">
                              <div className="bg-headerbg rounded-md px-3 py-1 text-[.6875rem] text-column">Attachment</div>
                              <div className="flex flex-wrap border-t p-3">
                                {/* files */}
                                {item?.attachment?.map((attachment: any, index: number) => {
                                  const formatBytes = (bytes: number) => {
                                    if (bytes < 1024) return `${bytes} Bytes`;
                                    let kb = bytes / 1024;
                                    if (kb < 1024) return `${kb.toFixed(3)} KB`;
                                    let mb = kb / 1024;
                                    if (mb < 1024) return `${mb.toFixed(3)} MB`;
                                    let gb = mb / 1024;
                                    return `${gb.toFixed(3)} GB`;
                                  };
                                  const size = formatBytes(attachment?.size);

                                  return (
                                    <div className="mt-2 flex w-1/2 gap-2" key={`${index}_attachment`}>
                                      <div className="h-fit rounded-full bg-hover p-2 text-sm text-blueSecondary">
                                        <CgFile />
                                      </div>
                                      <div className="flex flex-col gap-1 pt-1">
                                        <span className="text-xs font-medium">{attachment?.filename}</span>
                                        <div className="flex text-[.6875rem]">
                                          <span>{size} -</span>
                                          <span
                                            className="cursor-pointer pl-1 pr-1.5 text-blueSecondary"
                                            onClick={(e) => {
                                              e?.stopPropagation();
                                              downloadFile(attachment,saveAs);
                                            }}
                                          >
                                            Download
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </>
              ))
            )}
            {showTyper && (
              <div className="!bg-[#f9f8ff] px-2 py-2  dark:!bg-darkTwo" ref={typerRef}>
                {/* <Typer
                  containerClass="!p-0 border-none"
                  onTyperClose={() => {
                    setShowTyper(false);
                    // onClose();
                  }}
                /> */}
              </div>
            )}
          </div>
        )}
      </>
      <UploadSingleLead isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} textColor={'text-column'} is_campaign_required={true} editData={data} />
      <UnmatchMergeConfirmPopUp
        isOpen={openMergeModal}
        onClose={() => {
          setOpenMergeModal(false);
          setPrimaryMergeData('');
          setSecondaryMergeData('');
        }}
        secondaryEmailData={secondaryMergeData}
        primaryEmailData={primaryMergeData}
      />
    </div>
  );
};
export default UnmatchListRow;
