import { BILLING_SUBSCRIPTION_CREATE, BILLING_SUBSCRIPTION_PLAN, Delete_CARD_DATA, STRIPE_USER_CARD, USER_CARD_DATA, USER_INVOICE_MEMO } from 'api/endpoints/billingEndpoints';
import { BaseApi } from 'api/services/base-api';
import { AxiosResponse } from 'axios';

export const CreateSubscriptionPlanAPI = async (data: any) => {
  try {
    const response: AxiosResponse<any, any> = await BaseApi.post(BILLING_SUBSCRIPTION_CREATE, data);
    return response;
  } catch (error) {
    console.error('Failed to create plan:', error);
  }
};

export const GetSubscriptionPlanAPI = async () => {
  try {
    const response: AxiosResponse<any, any> = await BaseApi.get(BILLING_SUBSCRIPTION_PLAN);
    return response;
  } catch (error) {
    console.error('Failed to get plan:', error);
  }
};

export const GetStripeUserCardApi = async () => {
  try {
    const response = await BaseApi.get(USER_CARD_DATA);
    return response;
  } catch (error) {
    console.error('Failed to get card data:', error);
  }
};

export const DeleteStripeUserCardApi = async (payment_method_id: string) => {
  try {
    const response = await BaseApi.patch(Delete_CARD_DATA, { payment_method_id: payment_method_id });
    return response;
  } catch (error) {
    console.error('Failed to delete card data:', error);
  }
};

export const GetUserInvoiceDataApi = async () => {
  try {
    const response = await BaseApi.get(USER_INVOICE_MEMO);
    return response;
  } catch (error) {
    console.error('Failed to get card data:', error);
  }
};

export const UpdateInvoiceMemo = async (invoiceMemo: string) => {
  try {
    const response = await BaseApi.patch(USER_INVOICE_MEMO, { invoice_memo: invoiceMemo });
    return response;
  } catch (error) {
    console.error('Failed to delete card data:', error);
  }
};

export const StripeAddCardApi = async ({ data }: { data: any }) => {
  try {
    const response = await BaseApi.post(STRIPE_USER_CARD, data);
    return response;
  } catch (error) {
    console.error('Failed to get id data:', error);
  }
};
