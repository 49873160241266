import { ADMIN_VERIFIER_CREATE_LIST, ADMIN_VERIFIER_EXPORT_RESULT, ADMIN_VERIFIER_GET_LIST } from "api/endpoints/adminVerifierEndpoint";
import { BaseApi } from "api/services/base-api";
import { taskDTO } from "dto/types/views/admin.verifer";


export const fetchDataList = async () => {
    try {
        const response = await BaseApi.get(ADMIN_VERIFIER_GET_LIST);
        return response;
    } catch (error) {
        console.error('fetchDataList Error:', error);
        throw error;
    }
};

export const fetchExportData = async (uuid: string) => {
    try {
        const response = await BaseApi.get(`${ADMIN_VERIFIER_EXPORT_RESULT}/${uuid}`);
        return response;
    } catch (error) {
        console.error('fetchExportData Error:', error);
        throw error;
    }
};

export const uploadList = async (payload: taskDTO) => {
    try {
        const response = await BaseApi.post(`${ADMIN_VERIFIER_CREATE_LIST}`, payload);
        return response;
    } catch (error) {
        console.error('uploadList Error:', error);
        throw error;
    }
};