import { SENDER_EMAIL_ARCHIEVE, SENDER_EMAIL_DOWNLOAD, SENDER_EMAIL_LIST, SENDER_EMAIL_SINGLE_GET, SENDER_GROUP_LIST } from 'api/endpoints/senderAccountEndpoints';
import { BaseApi } from 'api/services/base-api';

export const FetchListApi = async (query: string) => {
  try {
    const response = await BaseApi.get(SENDER_EMAIL_LIST + `?${query}`);
    return response;
  } catch (error) {
    console.error('FetchListApi Error:', error);
    throw error;
  }
};

export const FetchGroupListApi = async () => {
  try {
    const response = await BaseApi.get(SENDER_GROUP_LIST);
    return response;
  } catch (error) {
    console.error('FetchGroupListApi Error:', error);
    throw error;
  }
};

export const FetchSingleGetApi = async (uuid: string) => {
  try {
    const response = await BaseApi.get(SENDER_EMAIL_SINGLE_GET(uuid));
    return response;
  } catch (error) {
    console.error('FetchGroupListApi Error:', error);
    throw error;
  }
};

export const FetchSenderEmailListDownloadApi = async (data: any) => {
  try {
    const response = await BaseApi.post(SENDER_EMAIL_DOWNLOAD, data);
    return response;
  } catch (error) {
    console.error('FetchSenderEmailListDownload Error:', error);
    throw error;
  }
};

export const MultiArchieveSenderAccountApi = async (data: any) => {
  try {
    const response = await BaseApi.delete(SENDER_EMAIL_ARCHIEVE, { data });
    return response;
  } catch (error) {
    console.error('FetchSenderEmailListDownload Error:', error);
    throw error;
  }
};
