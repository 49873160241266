import { TOAST_MESSAGES } from 'constants/messages';
import { GetNotificationKeyData, SetNotificationSettingsType, SubscribeNotification } from '../api-handler';
import { toastState } from 'utility/utils';
import { toast } from 'react-toastify';
import { setNotificationData, setNotificationType } from 'redux-store/notification-settings';
import { PUBLIC_VAPID_KEY } from 'config/envConfig';
import { NOTIFICATION_DATA } from 'dto/initial-value/views/notification';
import { AccordionState } from 'dto/types/views/notification';

export const FetchNotificationDataFunction = async (setIsopen: React.Dispatch<React.SetStateAction<any>>, key: string, dispatch: any) => {
  try {
    const response = await GetNotificationKeyData(key);
    // const response = await BaseApi.get(`/notification/${key}`);
    if (response?.error) {
      toast.error(TOAST_MESSAGES?.FETCH_ERROR, toastState.error);
    } else {
      dispatch(setNotificationData(response?.data));
      dispatch(setNotificationType(key));

      setIsopen(true);
    }
  } catch (err) {
    console.error(err);
  }
};

export const BrowserConfigurationFunction = async (fetchNotificationData: any) => {
  const publicVapidKey = PUBLIC_VAPID_KEY;
  function urlBase64ToUint8Array(base64String: string): Uint8Array {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    // eslint-disable-next-line no-useless-escape
    const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  if (NOTIFICATION_DATA?.SERVICE_WORKER in navigator && NOTIFICATION_DATA?.PUSH_MANAGER in window) {
    const permissionResult = await Notification.requestPermission();
    if (permissionResult === NOTIFICATION_DATA?.GRANTED) {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then(async (registration) => {
          fetchNotificationData(NOTIFICATION_DATA?.BROWSER_NOTIFY);
          const subscription = await registration.pushManager.getSubscription();
          if (!subscription) {
            const subscribeOptions = {
              userVisibleOnly: true,
              applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
            };

            const pushSubscription = await registration.pushManager.subscribe(subscribeOptions);
            await SubscribeNotification(pushSubscription);
            // await BaseApi.post(`/notification/subscribe`, { pushSubscription });
            toast.success(TOAST_MESSAGES?.NOTIFICATION_SUCCESS, toastState.success);
          }
        })
        .catch((err) => {
          console.error('Service Worker registration failed:', err);
        });
    } else if (permissionResult === 'denied') {
      toast.error(TOAST_MESSAGES?.NOTIFICATION_DENIED, toastState.error);
    }
  } else {
    toast.error(TOAST_MESSAGES?.NOTIFICATION_NOT_FOUND_ERROR, toastState.error);
    return;
  }
};

export const AccordionChangeSaveData = async (notificationSettingsType: string, openAccordion: any, onClose: any, dispatch: any) => {
  const settings = {
    campaign_pause: openAccordion.switches[0][0],
    email_limit_reached: openAccordion.switches[0][1],
    holiday_alert: openAccordion.switches[0][2],
    email_disconnected: openAccordion.switches[0][3],
    whatsapp_disconnected: openAccordion.switches[0][4],
    linkedin_disconnected: openAccordion.switches[0][5],
    task_assigned: openAccordion.switches[0][6],
    // prospects_replied: openAccordion.switches[0][7],
    email_verification: openAccordion.switches[0][7],
    ticket_resolved: openAccordion.switches[1][0],
    ticket_replied: openAccordion.switches[1][1],
    blacklist_notify: openAccordion.switches[1][2],
    credits_over: openAccordion.switches[2][0],
    billing_end_alert: openAccordion.switches[2][1],
    billing_over: openAccordion.switches[2][2],
    credits_recharge: openAccordion.switches[2][3],
    billing_recharge: openAccordion.switches[2][4],
    trail_ended: openAccordion.switches[2][5],
    user_accept_invite: openAccordion.switches[3][0],
  };

  try {
    const response = await SetNotificationSettingsType(notificationSettingsType, settings);
    // const response = await BaseApi.patch(`/notification/${notificationSettingsType}`, {
    //   setting_data: settings,
    // });

    if (response?.error) {
      toast.error('error while updating', toastState.error);
    } else {
      toast.success('successfully updated', toastState.success);
      dispatch(setNotificationData(null));
      dispatch(setNotificationType(null));
      onClose();
    }
  } catch (err) {
    console.error(err);
  }
};

export const CommonToogleFunction = (setOpenAccordion: React.Dispatch<React.SetStateAction<any>>, openAccordion: any, accordionIndex: number) => {
  const allOn = Object.values(openAccordion.switches[accordionIndex]).every((value) => value);
  setOpenAccordion((prevState: any) => ({
    ...prevState,
    switches: {
      ...prevState.switches,
      [accordionIndex]: Object.keys(prevState.switches[accordionIndex]).reduce((acc, keyIndex) => {
        acc[parseInt(keyIndex)] = !allOn; // Toggle all switches based on the current state
        return acc;
      }, {} as { [keyIndex: number]: boolean }),
    },
  }));
};

export const ChangeToogleDataFunction = (setOpenAccordion: React.Dispatch<React.SetStateAction<any>>, accordionIndex: number, keyIndex: number) => {
  setOpenAccordion((prevState: any) => ({
    ...prevState,
    switches: {
      ...prevState.switches,
      [accordionIndex]: {
        ...prevState.switches[accordionIndex],
        [keyIndex]: !prevState.switches[accordionIndex]?.[keyIndex],
      },
    },
  }));
};

export const IntialSettingsFunction = (setOpenAccordion: any, notificationSettingsData: any) => {
  const switches: AccordionState['switches'] = {
    0: {
      0: notificationSettingsData?.campaign_pause,
      1: notificationSettingsData?.email_limit_reached,
      2: notificationSettingsData?.holiday_alert,
      3: notificationSettingsData?.email_disconnected,
      4: notificationSettingsData?.whatsapp_disconnected,
      5: notificationSettingsData?.linkedin_disconnected,
      //   6: notificationSettingsData?.twitter_disconnected,
      6: notificationSettingsData?.task_assigned,
      // 7: notificationSettingsData?.prospects_replied,
      7: notificationSettingsData?.email_verification,
    },
    1: {
      0: notificationSettingsData?.ticket_resolved,
      1: notificationSettingsData?.ticket_replied,
      2: notificationSettingsData?.blacklist_notify,
    },
    2: {
      0: notificationSettingsData?.credits_over,
      1: notificationSettingsData?.billing_end_alert,
      2: notificationSettingsData?.billing_over,
      3: notificationSettingsData?.credits_recharge,
      4: notificationSettingsData?.billing_recharge,
      5: notificationSettingsData?.trail_ended,
    },
    // 3: {
    //   0: notificationSettingsData?.blog,
    // },
    3: {
      0: notificationSettingsData?.user_accept_invite,
    },
  };

  setOpenAccordion((prevState: any) => ({
    ...prevState,
    switches,
  }));
};
