import { generateDateInterval } from 'utility/utils';

export const campaignFilterDataList = [
  {
    id: '1',
    name: 'Status',
    uniqueColumnName: 'campaign_status',
    conditions: [
      { id: '1', name: 'Is', uniqueName: 'is' },
      { id: '2', name: 'Is not', uniqueName: 'not' },
    ],
    resultValue: [
      { id: '1', name: 'In Progress', uniqueName: 5 },
      { id: '2', name: 'Draft', uniqueName: 0 },
      { id: '3', name: 'Active', uniqueName: 1 },
      { id: '4', name: 'Paused', uniqueName: 3 },
      { id: '3', name: 'Completed', uniqueName: 4 },
    ],
  },
  {
    id: '2',
    name: 'Open Rate',
    uniqueColumnName: 'campaign_statistics.open_percentage',
    conditions: [
      { id: '1', name: 'More Than', uniqueName: 'greater_than' },
      { id: '2', name: 'Less than', uniqueName: 'less_than' },
    ],
    resultValue: [],
    result_value_field_type: 'input',
  },
  {
    id: '3',
    name: 'Sent Rate',
    uniqueColumnName: 'campaign_statistics.sent_percentage',
    conditions: [
      { id: '1', name: 'More Than', uniqueName: 'greater_than' },
      { id: '2', name: 'Less than', uniqueName: 'less_than' },
    ],
    resultValue: [],
    result_value_field_type: 'input',
  },
  {
    id: '4',
    name: 'Reply Rate',
    uniqueColumnName: 'campaign_statistics.replied_percentage',
    conditions: [
      { id: '1', name: 'More Than', uniqueName: 'greater_than' },
      { id: '2', name: 'Less than', uniqueName: 'less_than' },
    ],
    resultValue: [],
    result_value_field_type: 'input',
  },
  {
    id: '5',
    name: 'Bounce Rate',
    uniqueColumnName: 'campaign_statistics.bounced_percentage',
    conditions: [
      { id: '1', name: 'More Than', uniqueName: 'greater_than' },
      { id: '2', name: 'Less than', uniqueName: 'less_than' },
    ],
    resultValue: [],
    result_value_field_type: 'input',
  },
  {
    id: '6',
    name: 'Unsub Rate',
    uniqueColumnName: 'campaign_statistics.unsubscribed_percentage',
    conditions: [
      { id: '1', name: 'More Than', uniqueName: 'greater_than' },
      { id: '2', name: 'Less than', uniqueName: 'less_than' },
    ],
    resultValue: [] as any[],
    result_value_field_type: 'input',
  },
  {
    id: '7',
    name: 'Created On',
    uniqueColumnName: 'cdate',
    conditions: [{ id: '1', name: 'Is', uniqueName: 'is' }],
    resultValue: [
      { id: '1', name: 'Today', uniqueName: generateDateInterval(0, 0) },
      { id: '2', name: 'Yesterday', uniqueName: generateDateInterval(1) },
      { id: '3', name: 'Last 7 days', uniqueName: generateDateInterval(7) },
      { id: '4', name: 'Last 30 days', uniqueName: generateDateInterval(29) },
      { id: '5', name: 'This week', uniqueName: generateDateInterval('week', 0) },
      { id: '6', name: 'This month', uniqueName: generateDateInterval('month', 0) },
    ],
  },
];
