import { Avatar, SkeletonCircle, SkeletonText } from '@chakra-ui/react';
// import WindowsSvg from '../../assets/svg/windows.svg';
import { FaInstagramSquare } from 'react-icons/fa';
import { TfiLinkedin } from 'react-icons/tfi';
import { FaClock, FaXTwitter } from 'react-icons/fa6';
import { useAppSelector } from 'redux-store';
import moment from 'moment';

const ProfileDetails = (props: { loading: boolean }) => {
  const { loading } = props;
  const { prospect_details, opportunityInboxViewData } = useAppSelector((state) => state.crm);
  return (
    <>
      {loading ? (
        <>
          <SkeletonCircle size="10" />
          <SkeletonText mt="4" noOfLines={4} spacing="4" skeletonHeight="2" />
        </>
      ) : (
        <div className="flex w-full flex-col gap-2 border-b pb-3 pt-0.5">
          <div className="flex justify-start gap-1.5">
            <Avatar
              className="border"
              bg="#3457d5"
              src={opportunityInboxViewData?.prospect_enrich_details?.source_details?.photo_url}
              name={prospect_details?.opportunity?.[0]?.prospect_email.split('@')[0][0]}
              style={{ height: '42px', width: '42px' }}
            />
            <div className="w-full">
              <div className="flex w-full justify-between">
                <p className="text-base font-semibold capitalize text-heading">
                  {opportunityInboxViewData?.prospect_enrich_details?.source_details?.first_name
                    ? `${opportunityInboxViewData?.prospect_enrich_details?.source_details?.first_name} ${opportunityInboxViewData?.prospect_enrich_details?.source_details?.last_name}`
                    : prospect_details?.opportunity?.[0]?.prospect_name || prospect_details?.opportunity?.[0]?.prospect_email.split('@')[0]}
                </p>
                <div className="flex items-center justify-center gap-3 text-[#8F9BBA]">
                  {opportunityInboxViewData?.prospect_enrich_details?.source_details?.twitter_url ? (
                    <a href={opportunityInboxViewData?.prospect_enrich_details?.source_details?.twitter_url} target="_blank" rel="noopener noreferrer">
                      <FaXTwitter className="text-[0.6rem] text-white" />
                    </a>
                  ) : null}
                  {opportunityInboxViewData?.prospect_enrich_details?.source_details?.linkedin_url ? (
                    <div className="flex size-4 items-center justify-center rounded-full bg-[#99a4c0]">
                      <a href={opportunityInboxViewData?.prospect_enrich_details?.source_details?.linkedin_url} target="_blank" rel="noopener noreferrer">
                        <TfiLinkedin className="text-[0.6rem] text-white" />
                      </a>
                    </div>
                  ) : null}
                  {opportunityInboxViewData?.prospect_enrich_details?.source_details?.instagram ? <FaInstagramSquare className="rounded-full" /> : null}
                </div>
              </div>

              <p className="mt-1.5 flex gap-2 text-[0.71rem] font-medium text-heading">
                <span className="text-xs font-bold capitalize">
                  {/* <b>Chairman</b> and <b> CEO </b> at <b>Microsoft</b> */}
                  {opportunityInboxViewData?.prospect_enrich_details?.source_details?.organization?.name
                    ? opportunityInboxViewData?.prospect_enrich_details?.source_details?.organization?.name
                    : prospect_details?.opportunity?.[0]?.prospect_email.split('@')[1] !== 'gmail.com'
                      ? prospect_details?.opportunity?.[0]?.prospect_email.split('@')[1].split('.')[0]
                      : ''}
                </span>
                <span>{/* <img src={WindowsSvg} alt="Window Icon" className="size-4" /> */}</span>
              </p>
            </div>
          </div>
          <p className="flex items-center gap-1 text-xs text-column">
            <span className="flex size-min items-center justify-center rounded-full bg-lightBlue p-1 text-[#8F9BBA]">
              <FaClock className="size-[11px]" />
            </span>
            <span className="text-xs">{moment(prospect_details.cdate).format('MMM DD, hh:mm A')}</span>
          </p>
          <p className="text-xs text-column">
            {/* Satya Nadella, focused and innovative leader, as Chairman and CEO of Microsoft aims to empower every individual and organization to achieve their potential */}
          </p>
          <div className="flex w-full flex-wrap gap-3">
            {['Focused', 'Calculated-Risk Taker', 'Innovative'].map((item) => (
              <p className="border-none bg-[#eff9fb] px-2 py-0.5 text-[0.65rem] font-semibold text-heading shadow-light" key={item}>
                {item}
              </p>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileDetails;
