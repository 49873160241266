import { GENERATE_ACCESS_TOKEN, PERSONAL_DETAILS } from 'api/endpoints/authEndpoints';
import { BaseApi } from 'api/services/base-api';

export const GenerateAccessTokenApi = async (data: Record<string, any>) => {
  try {
    const response = await BaseApi.post(GENERATE_ACCESS_TOKEN, data);
    return response;
  } catch (error) {
    console.error('GenerateAccessTokenApi Error:', error);
    throw error;
  }
};

export const UpdatePersonalDetailsApi = async (data: Record<string, any>) => {
  try {
    const response = await BaseApi.patch(PERSONAL_DETAILS, data);
    return response;
  } catch (error) {
    console.error('UpdatePersonalDetailsApi Error:', error);
    throw error;
  }
};

export const GetPersonalDetailsApi = async () => {
  try {
    const response = await BaseApi.get(PERSONAL_DETAILS);
    return response;
  } catch (error) {
    console.error('GetPersonalDetailsApi Error:', error);
    throw error;
  }
};
